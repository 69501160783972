import pxToRem from "hooks/usePxToRem";

import {Grid} from "@mui/material";

import {CustomInput, CustomInputWrapper} from "ui";
import {DefaultCardStructure} from "components";
import {Controller, useFormContext} from "react-hook-form";

const Receipt = () => {
  const {control} = useFormContext();

  return (
    <DefaultCardStructure
      title="Recebimento"
      hasDivider={false}
      isSubcard={true}
    >
      <Grid container rowSpacing={pxToRem(20)} columnSpacing={pxToRem(8)}>
        <CustomInputWrapper md={5} title="Tipo Recebimento" isDisabled>
          <Controller
            name="charges.typeReceipt"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="charges.typeReceipt"
                value={value}
                placeholder="Forma de Cobrança"
                onChange={onChange}
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={2} title="Quantidade" isDisabled>
          <Controller
            name="charges.quantityReceipt"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="charges.quantityReceipt"
                type="text"
                value={value}
                placeholder="Quantidade"
                onChange={onChange}
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={5} title="Receber De" isDisabled>
          <Controller
            name="charges.receiveFrom"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="charges.receiveFrom"
                value={value}
                placeholder="Receber de"
                onChange={onChange}
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={12} title="Pessoa" isDisabled>
          <Controller
            name="charges.receiptPerson"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="charges.receiptPerson"
                type="text"
                value={value?.name}
                placeholder="Pessoa"
                onChange={onChange}
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={2.4} title="Moeda" isDisabled>
          <Controller
            name="charges.receiptCurrency"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="charges.receiptCurrency"
                value={value}
                onChange={onChange}
                placeholder="Moeda"
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={2.1} title="Valor Unitário" isDisabled>
          <Controller
            name="charges.valueReceiptUnit"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="charges.valueReceiptUnit"
                type="text"
                value={value}
                placeholder="Valor un."
                onChange={onChange}
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={2.1} title="Valor Mínimo" isDisabled>
          <Controller
            name="charges.valueReceiptMinimum"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="charges.valueReceiptMinimum"
                type="text"
                placeholder="Valor min."
                value={value}
                onChange={onChange}
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={2.1} title="Valor Total" isDisabled>
          <Controller
            name="charges.valueReceiptTotal"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="charges.valueReceiptTotal"
                type="text"
                value={value}
                placeholder="Valor tot."
                onChange={onChange}
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={3.3} title="Modalidade" isDisabled>
          <Controller
            name="charges.modalityReceipt"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="charges.modalityReceipt"
                value={value}
                onChange={onChange}
                placeholder="Modalidade"
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={4.5} title="Faturamento" isDisabled>
          <Controller
            name="charges.billingReceipt"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="charges.billingReceipt"
                value={value}
                placeholder="Faturamento"
                onChange={onChange}
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>
      </Grid>
    </DefaultCardStructure>
  );
};

export default Receipt;
