import {
  Grid,
  Icon,
  ListItem,
  ListItemIcon,
  Stack,
  Typography,
} from "@mui/material";
import {ConfirmModal} from "components";
import pxToRem from "hooks/usePxToRem";
import {Dispatch, SetStateAction} from "react";

interface ModalCompleteInBatchConfirmProps {
  activityName: string;
  activitiesToCompleteInBatch: {id: number; processNumber: string}[];
  confirmCompleteInBatchFn: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const ModalCompleteInBatchConfirm = (
  props: ModalCompleteInBatchConfirmProps,
) => {
  const {
    activityName,
    activitiesToCompleteInBatch,
    confirmCompleteInBatchFn,
    isOpen,
    setIsOpen,
  } = props;

  const handleClose = () => {
    setIsOpen(false);
  };

  const confirmButtonFn = () => {
    confirmCompleteInBatchFn();
    handleClose();
  };

  const listRender = (rest: 0 | 1) => {
    return (
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {activitiesToCompleteInBatch
          ?.filter((_, index) => index % 2 === rest)
          ?.map((item) => (
            <ListItem key={item?.id} sx={{width: "auto"}}>
              <ListItemIcon sx={{minWidth: pxToRem(16)}}>
                <Icon sx={{fontSize: pxToRem(5)}}>circle</Icon>
              </ListItemIcon>
              <Typography sx={{fontWeight: 500}}>
                {item?.processNumber}
              </Typography>
            </ListItem>
          ))}
      </Grid>
    );
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      title={"Conclusão em lote"}
      subtitle={`ATENÇÃO: Essa ação NÃO pode ser desfeita! Confirme a conclusão em lote de ${activityName} para os processos:`}
      confirmButtonFn={confirmButtonFn}
      onClose={handleClose}
      titleIcon="warning"
      confirmButtonText="Confirmar"
      color="warning"
    >
      <Stack
        direction="row"
        alignItems={"flex-start"}
        justifyContent={"center"}
        width={"100%"}
      >
        {listRender(0)}
        {listRender(1)}
      </Stack>
    </ConfirmModal>
  );
};

export default ModalCompleteInBatchConfirm;
