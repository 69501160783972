import pxToRem from "hooks/usePxToRem";

import {Paper, styled} from "@mui/material";

interface StyledPaperProps {
  isSidebarOpen?: boolean;
}

const forwardProp = {
  shouldForwardProp: (prop) => prop !== "isSidebarOpen",
};

// prettier-ignore
const StyledPaper = styled(Paper, forwardProp)<StyledPaperProps>(({theme, isSidebarOpen}) => `
  position: relative;
  
  display: grid;
  align-content: center;
  
  padding: ${pxToRem(8)};
  height: ${pxToRem(49)};
  border: 1px solid ${theme.palette.primary[50]};
  border-radius: 4px;
  width: 100%;
  cursor: ${!isSidebarOpen && "pointer"};

  transition: border-color 280ms ease-in-out;

  &:hover {
    border-color: ${!isSidebarOpen && theme.palette.primary.main};
  }

  .card {
    &__avatar {
      height: ${pxToRem(29)};
      width: ${pxToRem(29)};
      font-size: ${pxToRem(14)};
      border: 1px solid ${theme.palette.primary.main};
      background-color: ${theme.palette.primary[50]};
      box-sizing: content-box;
      color: ${theme.palette.primary[700]};
      text-transform: uppercase;
    }

    &__text {
      opacity: ${isSidebarOpen ? "1" : "0"};
      transition: opacity 300ms ease-in-out;
    }

    &__username, &__role {
      width: ${pxToRem(112)};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__role {
      color: ${theme.palette.mode === "dark" 
        ? theme.palette.primary[500] 
        : theme.palette.primary[700]
      };
    }

    &__icon-button {
      position: ${isSidebarOpen ? "unset" : "absolute"};
      inset: 0;
      margin: 0 auto;

      border-radius: ${!isSidebarOpen && pxToRem(4)};
      padding: 0;
      color: ${theme.palette.text.secondary};
      opacity: ${!isSidebarOpen && "0"};

      transition: color 280ms ease-in-out;

      &:hover {
        color: ${theme.palette.text.primary};
      }

      span {
        font-size: ${pxToRem(22)};
      }
    }
  }
`,
);

export default StyledPaper;
