/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
import pxToRem from "hooks/usePxToRem";

import {Box, Chip, Grid, Stack, Typography} from "@mui/material";

import {
  CustomAutocomplete,
  CustomButton,
  CustomIconButton,
  CustomInput,
  CustomInputWrapper,
  CustomSelect,
} from "ui";
import {ConfirmModal, DefaultCardStructure} from "components";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import {
  DeleteLogisticBillLadingLoad,
  GetEquipmentsList,
  GetPackageContainersList,
} from "services/api_v2/Processes/Processes.service";
import useHandleFieldLists from "views/ProcessDetails/hooks/useHandleFieldLists";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";
import {getTooltipText} from "views/ProcessDetails/utils/getTooltipText";
import useChangeDAteAndAutocomplete from "views/ProcessDetails/hooks/useChangeDAteAndAutocomplete";
import useAlertMessage from "hooks/useAlertMessage";
import useToast from "hooks/useToast";
import {useDebounceCallback} from "hooks/useDebounceCallback";
import {ProcessEquipment} from "views/ProcessDetails/Process.types";
import ProcessInput from "views/ProcessDetails/components/ProcessInput";
import {TypeItemLoad} from "util/globalEnums";
import {calcConsideredWeight} from "../../utils/calculateConsideredWeight";

type Props = {
  loadIndex: number;
};

const CargoItems = (props: Props) => {
  const {
    control,
    setValue,
    getValues,
    register,
    setError,
    clearErrors,
    watch,
    reset,
    formState,
  } = useFormContext();
  const [showToast] = useToast();
  const [showAlert] = useAlertMessage();

  const cancelledProcess = watch("agencyingSituation") === "Cancelado";
  const houseId = useWatch({name: "houseId"});
  const equipments = useWatch({name: "processEquipments"});

  const {fields, append, remove} = useFieldArray({
    name: "logisticBillLadingLoads",
    keyName: "idForm",
  });

  const cargoItems: Array<{[key: number]: boolean}> = getValues(
    `logisticBillLadingLoads`,
  );
  const [errorsState, setErrorState] = useState(
    cargoItems.reduce((acc, _, index) => ({...acc, [index]: false}), {}),
  );
  const [equipmentsOptions, setEquipmentsOptions] = useState(
    [] as AutoCompleteData[],
  );
  const [search, setSearch] = useState<string>();

  const {
    getOptions: getEquipmentsOptions,
    onBottomScroll: onBottomScrollEquipments,
    isLoadingOptions: isLoadingEquipmentsOptions,
  } = useHandleFieldLists(GetEquipmentsList);

  const {handleChangeAutocomplete, mountOptions} =
    useChangeDAteAndAutocomplete();

  const totalOfEquipments = () => {
    const total = equipments.reduce((acc, curr) => acc + curr?.quantity, 0);
    return total;
  };

  const handleAppendNewItem = () => {
    if (getValues("logisticBillLadingLoads").length === totalOfEquipments()) {
      showToast(
        "O número de equipamentos é igual ou maior ao número de itens",
        "warning",
      );
    } else {
      append({
        number: "",
        seal: "",
        equipment: null,
        quantity: 0,
        netWeight: 0,
        grossWeight: 0,
        measurement: 0,
        tare: 0,
        typeItemLoad: "",
        barSeal: "",
        typePackages: null,
        packageContainer: null,
        dangerousGoodsClass: "",
        dangerousGoodsCode: "",
      });
    }
  };

  const setHouseMeasurements = () => {
    const cargoItems = getValues("logisticBillLadingLoads");
    const totalGrossWeight = cargoItems.reduce(
      (acc, prev) => acc + prev.grossWeight,
      0,
    );
    setValue(`processLoads[${props.loadIndex}].grossWeight`, totalGrossWeight, {
      shouldDirty: true,
    });
    const totalMeasurements = cargoItems.reduce(
      (acc, prev) => acc + prev.measurement,
      0,
    );
    setValue(
      `processLoads[${props.loadIndex}].cubicMeters`,
      totalMeasurements,
      {
        shouldDirty: true,
      },
    );

    const totalQuantities = cargoItems.reduce(
      (acc, prev) => acc + prev.quantity,
      0,
    );

    setValue(`processLoads[${props.loadIndex}].quantities`, totalQuantities, {
      shouldDirty: true,
    });

    const loadData = calcConsideredWeight(
      totalMeasurements ?? 0,
      totalGrossWeight ?? 0,
    );
    setValue(
      `processLoads[${props.loadIndex}].consideredWeight`,
      loadData.consideredWeight,
      {shouldDirty: true},
    );
    setValue(
      `processLoads[${props.loadIndex}].cubeWeight`,
      loadData.cubeWeight,
      {shouldDirty: true},
    );
  };
  const setHouseMeasurementsDebounced = useDebounceCallback(
    setHouseMeasurements,
    1000,
  );

  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{
    itemId?: number;
    index?: number;
  }>({});

  const handleDeleteLogisticBillLading = async (field: any, index: number) => {
    if (!field.id) {
      remove(index);
      return;
    }

    setItemToDelete({itemId: field.id as number, index: index});
    setConfirmModalIsOpen(true);
  };

  const handleClose = () => {
    setConfirmModalIsOpen(false);
  };
  const confirmDeletionPackage = async () => {
    if (!itemToDelete?.itemId || itemToDelete?.index === undefined) {
      return;
    }
    try {
      await DeleteLogisticBillLadingLoad(itemToDelete.itemId);
      remove(itemToDelete.index);

      const newBillLadingLoads = getValues("logisticBillLadingLoads").filter(
        (item) => item !== itemToDelete.itemId,
      );

      reset({
        ...formState.defaultValues,
        logisticBillLadingLoads: newBillLadingLoads,
      });

      handleClose();

      showToast("Item removido com sucesso", "success");
    } catch (e: any) {
      console.error(e);
      showAlert(e?.response?.data?.message, "error");
    }
  };

  const getEquipmentsIds = () => {
    const equipments: ProcessEquipment[] = getValues("processEquipments");
    const idsList = equipments?.map((item) => item?.equipment?.id);
    return idsList;
  };

  const [equipmentsIdsList] =
    useState<(number | undefined)[]>(getEquipmentsIds());

  const equipmentsList: ProcessEquipment[] = useWatch({
    name: "processEquipments",
  });
  const getEquipmentsQuantity = () => {
    const totalOfEquipments = equipmentsList?.reduce(
      (acc, curr) => acc + curr?.quantity,
      0,
    );
    return totalOfEquipments;
  };

  useEffect(() => {
    getEquipmentsQuantity();
  }, []);

  const fieldIsRequired = (index: number) => {
    const number = getValues(`logisticBillLadingLoads.${index}.number`);
    const seal = getValues(`logisticBillLadingLoads.${index}.seal`);
    const equipment = getValues(`logisticBillLadingLoads.${index}.equipmentId`);
    const quantity = getValues(`logisticBillLadingLoads.${index}.quantity`);
    const netWeight = getValues(`logisticBillLadingLoads.${index}.netWeight`);
    const grossWeight = getValues(
      `logisticBillLadingLoads.${index}.grossWeight`,
    );
    const measurement = getValues(
      `logisticBillLadingLoads.${index}.measurement`,
    );
    const packageContainer = getValues(
      `logisticBillLadingLoads.${index}.packageContainer`,
    );
    const typeItemLoad = getValues(
      `logisticBillLadingLoads.${index}.typeItemLoad`,
    );

    const typePackages = getValues(
      `logisticBillLadingLoads.${index}.typePackages`,
    );
    const dangerousGoodsClass = getValues(
      `logisticBillLadingLoads.${index}.dangerousGoodsClass`,
    );
    const dangerousGoodsCode = getValues(
      `logisticBillLadingLoads.${index}.dangerousGoodsCode`,
    );
    if (number !== "" && number !== null) {
      clearErrors(`processDate.N° do container`);
    }

    if (getValues(`logisticBillLadingLoads.${index}.id`)) {
      setErrorState((prev) => ({...prev, [index]: true}));
      if (number === "") {
        setError(`processDate.N° do container`, {
          message: "N° do container",
        });
      }
      return true;
    }
    if (
      seal !== "" ||
      equipment !== undefined ||
      quantity !== 0 ||
      netWeight !== 0 ||
      grossWeight !== 0 ||
      measurement !== 0 ||
      packageContainer !== undefined ||
      typePackages !== null ||
      typeItemLoad !== "" ||
      dangerousGoodsClass !== "" ||
      dangerousGoodsCode !== ""
    ) {
      setErrorState((prev) => ({...prev, [index]: true}));
      if (number === "") {
        setError(`processDate.N° do container`, {
          message: "N° do container",
        });
      }

      return true;
    }

    return false;
  };

  const [packagesOptions, setPackagesOptions] = useState(
    [] as AutoCompleteData[],
  );
  const [searchPackages, setSearchPackages] = useState<string>();

  const {
    getOptions: getPackagesOptions,
    onBottomScroll: onBottomScrollPackages,
    isLoadingOptions: isLoadingPackagesOptions,
  } = useHandleFieldLists(GetPackageContainersList);

  const [
    extraSpaceWhenCargoIsNotDangerous,
    setExtraSpaceWhenCargoIsNotDangerous,
  ] = useState(0);

  const DANGEROUS_FIELD_SIZE = 1;
  const [isCargoDangerous] = useState(
    getValues(`processLoads[0].cargoDangerous`),
  );

  useEffect(() => {
    if (!isCargoDangerous) {
      setExtraSpaceWhenCargoIsNotDangerous((2 * DANGEROUS_FIELD_SIZE) / 10);
    }
  }, []);

  return (
    <>
      {confirmModalIsOpen && (
        <ConfirmModal
          isOpen={confirmModalIsOpen}
          title={"Deletar item de carga"}
          subtitle={`Esta ação não pode ser revertida!`}
          confirmButtonFn={() => confirmDeletionPackage()}
          onClose={handleClose}
        />
      )}
      <DefaultCardStructure
        title="Itens Da Carga - FCL"
        hasDivider={true}
        isSubcard={false}
        button={
          <CustomButton
            startIcon="add"
            onClickFn={handleAppendNewItem}
            disabled={
              !houseId ||
              getValues("processEquipments").length === 0 ||
              cancelledProcess
            }
          >
            Incluir item
          </CustomButton>
        }
      >
        <Stack sx={{gap: pxToRem(8)}}>
          {fields.length === 0 && (
            <Typography>Não existem itens de carga no processo.</Typography>
          )}
          {fields.map((field: any, index) => (
            <Grid key={field.idForm} container columnSpacing={pxToRem(1)}>
              <CustomInputWrapper xs={0.4} title="">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <Chip label={index + 1} color="primary" size="small" />
                </Box>
              </CustomInputWrapper>

              <CustomInputWrapper
                title="Tipo item"
                xs={0.9 + extraSpaceWhenCargoIsNotDangerous}
                tooltip="Tipo item carga"
              >
                <Controller
                  name={`logisticBillLadingLoads.${index}.typeItemLoad`}
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <CustomSelect
                      content={Object.values(TypeItemLoad)}
                      value={value ?? ""}
                      name={`logisticBillLadingLoads.${index}.typeItemLoad`}
                      onChange={(e) => {
                        onChange(e);
                        fieldIsRequired(index);
                      }}
                      size="small"
                    />
                  )}
                />
              </CustomInputWrapper>

              <CustomInputWrapper
                xs={0.8 + extraSpaceWhenCargoIsNotDangerous}
                title="C. Num "
              >
                <ProcessInput
                  {...register(`logisticBillLadingLoads.${index}.number`, {
                    required: errorsState[index],
                    onChange: () => fieldIsRequired(index),
                  })}
                  placeholder="N° container"
                />
              </CustomInputWrapper>

              <CustomInputWrapper
                xs={0.8 + extraSpaceWhenCargoIsNotDangerous}
                title="Selo"
              >
                <Controller
                  name={`logisticBillLadingLoads.${index}.seal`}
                  control={control}
                  render={({field: {value, onChange}}) => (
                    <CustomInput
                      name={`logisticBillLadingLoads.${index}.seal`}
                      type="text"
                      placeholder="Selo"
                      value={value ?? ""}
                      onChange={(e) => {
                        onChange(e);
                        fieldIsRequired(index);
                      }}
                      size="small"
                    />
                  )}
                />
              </CustomInputWrapper>

              <CustomInputWrapper
                xs={1 + extraSpaceWhenCargoIsNotDangerous}
                title="BarSeal"
              >
                <Controller
                  name={`logisticBillLadingLoads.${index}.barSeal`}
                  control={control}
                  render={({field: {value, onChange}}) => (
                    <CustomInput
                      name={`logisticBillLadingLoads.${index}.barSeal`}
                      type="text"
                      placeholder="BarSeal"
                      value={value ?? ""}
                      onChange={(e) => {
                        onChange(e);
                        fieldIsRequired(index);
                      }}
                      size="small"
                    />
                  )}
                />
              </CustomInputWrapper>

              <CustomInputWrapper
                xs={1.2 + extraSpaceWhenCargoIsNotDangerous}
                title="Equipamento"
              >
                <Controller
                  name={`logisticBillLadingLoads.${index}.equipment`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomAutocomplete
                      name={`logisticBillLadingLoads.${index}.equipment`}
                      placeholder="Equipamento"
                      tooltipText={getTooltipText(value?.name, 3)}
                      loading={isLoadingEquipmentsOptions}
                      options={mountOptions(value, equipmentsOptions)}
                      value={value?.name}
                      onOpen={
                        equipmentsOptions.length > 0
                          ? undefined
                          : () =>
                              getEquipmentsOptions(
                                setEquipmentsOptions,
                                1,
                                search,
                              )
                      }
                      onInputChange={(e, value) => {
                        if (e !== null) {
                          setSearch(value);
                          getEquipmentsOptions(setEquipmentsOptions, 1, search);
                        }
                      }}
                      getNextPage={() => {
                        onBottomScrollEquipments(setEquipmentsOptions, search);
                      }}
                      onChange={(e, value) => {
                        handleChangeAutocomplete(
                          e,
                          value,
                          `logisticBillLadingLoads.${index}.equipment`,
                        );
                        fieldIsRequired(index);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        value === option?.label
                      }
                      getOptionDisabled={(option) =>
                        !equipmentsIdsList?.includes(option?.id)
                      }
                    />
                  )}
                />
              </CustomInputWrapper>

              <CustomInputWrapper
                xs={0.5 + extraSpaceWhenCargoIsNotDangerous}
                title="Quant."
                tooltip="Quantidade"
              >
                <Controller
                  name={`logisticBillLadingLoads.${index}.quantity`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomInput
                      name={`logisticBillLadingLoads.${index}.quantity`}
                      type="number"
                      placeholder="Quant."
                      value={value ?? ""}
                      onChange={(e) => {
                        setValue(
                          `logisticBillLadingLoads.${index}.quantity`,
                          +e.target.value,
                          {shouldDirty: true},
                        );
                        setHouseMeasurementsDebounced();

                        fieldIsRequired(index);
                      }}
                      size="small"
                      inputProps={{
                        style: {
                          fontSize: pxToRem(14),
                        },
                        min: 0.0,
                        step: 1.0,
                        onClick: (e) => value === 0 && e.currentTarget.select(),
                      }}
                      required
                    />
                  )}
                />
              </CustomInputWrapper>
              <CustomInputWrapper
                xs={1.2 + extraSpaceWhenCargoIsNotDangerous}
                title={`Pacote`}
              >
                <Controller
                  name={`logisticBillLadingLoads.${index}.packageContainer`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomAutocomplete
                      name={`logisticBillLadingLoads.${index}.packageContainer`}
                      placeholder="Pacote"
                      tooltipText={getTooltipText(value?.name, 3)}
                      loading={isLoadingPackagesOptions}
                      options={mountOptions(value, packagesOptions)}
                      value={value?.name}
                      onOpen={
                        packagesOptions.length > 0
                          ? undefined
                          : () =>
                              getPackagesOptions(
                                setPackagesOptions,
                                1,
                                searchPackages,
                              )
                      }
                      onInputChange={(e, value) => {
                        if (e !== null) {
                          setSearchPackages(value);
                          getPackagesOptions(
                            setPackagesOptions,
                            1,
                            searchPackages,
                          );
                        }
                      }}
                      getNextPage={() => {
                        onBottomScrollPackages(
                          setPackagesOptions,
                          searchPackages,
                        );
                      }}
                      onChange={(e, value) => {
                        handleChangeAutocomplete(
                          e,
                          value,
                          `logisticBillLadingLoads.${index}.packageContainer`,
                        );
                      }}
                      isOptionEqualToValue={(option, value) =>
                        value === option?.label
                      }
                    />
                  )}
                />
              </CustomInputWrapper>
              {/* Peso líquido oculto por enquanto */}
              {/* <CustomInputWrapper md={1.5} title="Peso líq.">
                <Controller
                  name={`logisticBillLadingLoads.${index}.netWeight`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomInput
                      name={`logisticBillLadingLoads.${index}.netWeight`}
                      type="number"
                      placeholder="Peso liq."
                      value={value ?? ""}
                      onChange={(e) => {
                        setValue(
                          `logisticBillLadingLoads.${index}.netWeight`,
                          +e.target.value,
                          {shouldDirty: true},
                        );
                        fieldIsRequired(index);
                      }}
                      size="small"
                      inputProps={{
                        style: {
                          fontSize: pxToRem(14),
                        },
                        min: 0.0,
                        step: 0.001,
                      }}
                    />
                  )}
                />
              </CustomInputWrapper> */}
              <CustomInputWrapper
                xs={1 + extraSpaceWhenCargoIsNotDangerous}
                title="P. Bruto"
                tooltip="Peso bruto - kg"
              >
                <Controller
                  name={`logisticBillLadingLoads.${index}.grossWeight`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomInput
                      name={`logisticBillLadingLoads.${index}.grossWeight`}
                      type="number"
                      placeholder="Peso bruto"
                      value={value ?? ""}
                      onChange={(e) => {
                        setValue(
                          `logisticBillLadingLoads.${index}.grossWeight`,
                          +e.target.value,
                          {shouldDirty: true},
                        );
                        setHouseMeasurementsDebounced();
                        fieldIsRequired(index);
                      }}
                      size="small"
                      inputProps={{
                        style: {
                          fontSize: pxToRem(14),
                        },
                        min: 0.0,
                        step: 0.001,
                        onClick: (e) => value === 0 && e.currentTarget.select(),
                      }}
                    />
                  )}
                />
              </CustomInputWrapper>

              <CustomInputWrapper
                xs={1 + extraSpaceWhenCargoIsNotDangerous}
                title="CBM"
              >
                <Controller
                  name={`logisticBillLadingLoads.${index}.measurement`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomInput
                      name={`logisticBillLadingLoads.${index}.measurement`}
                      type="number"
                      placeholder="medidas"
                      value={value ?? ""}
                      onChange={(e) => {
                        setValue(
                          `logisticBillLadingLoads.${index}.measurement`,
                          +e.target.value,
                          {shouldDirty: true},
                        );

                        setHouseMeasurementsDebounced();
                        fieldIsRequired(index);
                      }}
                      size="small"
                      inputProps={{
                        style: {
                          fontSize: pxToRem(14),
                        },
                        min: 0.0,
                        step: 0.001,
                        onClick: (e) => value === 0 && e.currentTarget.select(),
                      }}
                    />
                  )}
                />
              </CustomInputWrapper>

              <CustomInputWrapper
                md={1 + extraSpaceWhenCargoIsNotDangerous}
                title="Tara"
              >
                <Controller
                  name={`logisticBillLadingLoads.${index}.tare`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomInput
                      name={`logisticBillLadingLoads.${index}.tare`}
                      type="number"
                      placeholder="Tara"
                      value={value}
                      onChange={(e) => {
                        setValue(
                          `logisticBillLadingLoads.${index}.tare`,
                          +e.target.value,
                          {shouldDirty: true},
                        );
                      }}
                      size="small"
                      inputProps={{
                        style: {
                          fontSize: pxToRem(14),
                        },
                        min: 0.0,
                        step: 0.001,
                        onClick: (e) => value === 0 && e.currentTarget.select(),
                      }}
                    />
                  )}
                />
              </CustomInputWrapper>

              {isCargoDangerous && (
                <>
                  <CustomInputWrapper
                    xs={1 + extraSpaceWhenCargoIsNotDangerous}
                    title="Classe"
                    tooltip="Classe mercadoria perigosa"
                  >
                    <ProcessInput
                      {...register(
                        `logisticBillLadingLoads.${index}.dangerousGoodsClass`,
                        {
                          onChange: () => fieldIsRequired(index),
                        },
                      )}
                      placeholder="Classe"
                    />
                  </CustomInputWrapper>

                  <CustomInputWrapper
                    xs={1}
                    title="Cód."
                    tooltip="Cód. mercadoria perigosa"
                  >
                    <ProcessInput
                      {...register(
                        `logisticBillLadingLoads.${index}.dangerousGoodsCode`,
                        {
                          onChange: () => fieldIsRequired(index),
                        },
                      )}
                      placeholder="Classe"
                    />
                  </CustomInputWrapper>
                </>
              )}

              <Grid
                item
                xs={0.2}
                display="flex"
                alignItems="end"
                justifyContent="flex-start"
              >
                <CustomIconButton
                  iconName="delete"
                  disabled={cancelledProcess}
                  sx={{
                    pr: 0,
                    ":hover": {
                      color: "red",
                    },
                  }}
                  onClick={() => {
                    handleDeleteLogisticBillLading(field, index);
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Stack>
      </DefaultCardStructure>
    </>
  );
};

export default CargoItems;
