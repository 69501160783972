import {ButtonGroup, Icon, Menu, MenuItem, useTheme} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import React, {ReactNode, useState} from "react";
import {CustomButton} from "ui";

interface ButtonWithDropdownProps {
  children: ReactNode;
  options: {name: string; actionFn: () => void | Promise<void>}[];
  variant?: "text" | "contained" | "outlined";
  lightBackground?: boolean;
  disabled?: boolean;
}

const ButtonWithDropdown = (props: ButtonWithDropdownProps) => {
  const {
    options,
    variant = "contained",
    children,
    lightBackground,
    disabled,
  } = props;

  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const lightBackgroundStyle = lightBackground
    ? {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.primary[700],
        "&:hover": {
          backgroundColor: theme.palette.grey[100],
        },
      }
    : {};

  const handleOpenOrCloseOptions = () => {
    setIsOpen((state) => !state);
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    handleOpenOrCloseOptions();
  };

  const handleClick = (_, actionFn: () => void | Promise<void>) => {
    handleOpenOrCloseOptions();
    actionFn();
    setAnchorEl(null);
  };

  return (
    <>
      <ButtonGroup data-testid={"button-with-dropdown"}>
        <CustomButton
          variant={variant}
          disabled={disabled}
          sx={{
            borderRadius: `${pxToRem(5)} 0 0 ${pxToRem(4)}`,
            height: `${pxToRem(42.5)}`,
            ...lightBackgroundStyle,
          }}
        >
          {children}
        </CustomButton>

        <CustomButton
          dataTestId={"dropdown"}
          variant={variant}
          onClickFn={handleOpen}
          disabled={disabled}
          sx={{
            borderRadius: ` 0 ${pxToRem(5)} ${pxToRem(4)} 0`,
            height: `${pxToRem(42.5)}`,
            pr: "0",
            pl: "0",
            minWidth: `${pxToRem(25)} !important`,
            ...lightBackgroundStyle,
          }}
        >
          <Icon>arrow_drop_down</Icon>
        </CustomButton>
      </ButtonGroup>
      <Menu
        data-testid={"options-menu"}
        id="options-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleOpenOrCloseOptions}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options?.map((option) => (
          <MenuItem
            key={option.name}
            onClick={(e) => handleClick(e, option.actionFn)}
            id={option.name}
            sx={{fontSize: pxToRem(14)}}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ButtonWithDropdown;
