import {TaxesData} from "../TaxesListing.types";

import {
  MRT_ShowHideColumnsButton,
  MRT_TableInstance,
  MRT_RowSelectionState,
} from "material-react-table";
import {Stack} from "@mui/material";

import {ToolbarButton} from "components/Tables";
import {CsvTableDownloader} from "components";

interface CustomToolbarProps {
  onRequestSaveTableView: () => void;
  currentRateSelected: MRT_RowSelectionState;
  onRequestOpenModalComposition: () => void;
  table: MRT_TableInstance<TaxesData>;
}

const CustomToolbar = ({
  onRequestSaveTableView,
  onRequestOpenModalComposition,
  table,
}: CustomToolbarProps) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={(theme) => ({color: theme.palette.text.secondary})}
    >
      <ToolbarButton
        tooltipText="Salvar configuração de visualização atual"
        iconName="save"
        onClick={onRequestSaveTableView}
      />

      <MRT_ShowHideColumnsButton color="inherit" table={table} />
      <CsvTableDownloader table={table} />
      {/* <ExportTableData /> */}

      {/* <Exhibition /> */}

      <ToolbarButton
        tooltipText="Ver Composição"
        iconName="read_more"
        onClick={onRequestOpenModalComposition}
        disabled
        // disabled={Object.keys(currentRateSelected).length === 0}
      />
    </Stack>
  );
};

export default CustomToolbar;
