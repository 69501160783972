import {useDispatch} from "react-redux";
import {Types as toastTypes} from "store/reducers/toastReducer";

type ToastSeverity = "error" | "success" | "warning";

type UseToastReturn = ((message: string, severity?: ToastSeverity) => void)[];

const useToast = (): UseToastReturn => {
  const dispatch = useDispatch();

  const showToast = (message: string, severity: ToastSeverity = "success") => {
    dispatch({
      type: toastTypes.SHOW_TOAST,
      open: true,
      message: message,
      severity: severity,
    });
  };

  return [showToast];
};

export default useToast;
