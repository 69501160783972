import {createActions, createReducer} from "reduxsauce";

type initial = {
  dataModal: any;
  dataConfirmModal: any;
  openModal: boolean;
  openConfirmModal: boolean;
  operationalInstructionsModal: boolean;
  insuranceWarning: boolean;
};

const INITIAL_STATE: initial = {
  dataModal: {},
  dataConfirmModal: {},
  openModal: false,
  openConfirmModal: false,
  operationalInstructionsModal: false,
  insuranceWarning: false,
};

export const {Types, Creators} = createActions({
  showModal: ["openModal"],
  setDataConfirmModal: ["dataConfirmModal"],
  showConfirmModal: ["openConfirmModal"],
  openChooseModal: ["dataModal", "operationalInstructionsModal"],
});

const showConfirmModal = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  openConfirmModal: action.openConfirmModal,
});

const setDataConfirmModal = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  dataConfirmModal: action.dataConfirmModal,
});

const showModal = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  openModal: action.openModal,
});

const openChooseModal = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  dataModal: action.dataModal || {},
  operationalInstructionsModal: action.operationalInstructionsModal || false,
  insuranceWarning: action.insuranceWarning || false,
});

export default createReducer(INITIAL_STATE, {
  [Types.SHOW_MODAL]: showModal,
  [Types.SET_DATA_CONFIRM_MODAL]: setDataConfirmModal,
  [Types.SHOW_CONFIRM_MODAL]: showConfirmModal,
  [Types.OPEN_CHOOSE_MODAL]: openChooseModal,
});
