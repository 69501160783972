import useAlertMessage from "hooks/useAlertMessage";
import {Dispatch, SetStateAction, useState} from "react";
import {PersonTypes} from "services/api_v2/Persons/Persons.service";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";

const useHandleFieldLists = (
  getListFn: any,
  formatOptions?: null | ((data: any) => {label: string; id: number}[]),
  persons?: Array<PersonTypes> | string,
) => {
  const [showAlert] = useAlertMessage();

  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [pageState, setPageState] = useState<{
    currentPage: number;
    lastPage: number;
  }>(
    {} as {
      currentPage: number;
      lastPage: number;
    },
  );
  const formatOp = (data) => {
    return data.data?.map((client) => ({
      label: `${client?.name} - ${client?.id}`,
      id: client.id,
    }));
  };

  const getOptions = async (
    setStateFn: Dispatch<SetStateAction<AutoCompleteData[]>>,
    page?: number,
    search?: string,
    useCargoPlayPagination?: boolean,
  ) => {
    const PAGE_LIMIT = 50;
    try {
      setIsLoadingOptions(true);

      const {data} = await getListFn(page, PAGE_LIMIT, search, persons);

      if (!formatOptions) {
        formatOptions = formatOp;
      }
      const newOptions = formatOptions(data);

      setPageState({
        lastPage: data.meta?.lastPage,
        currentPage: data.meta?.currentPage,
      });

      //condição para funcionar com os endpoints do CP
      if (useCargoPlayPagination) {
        setPageState({
          lastPage: Math.ceil(data?.total_items / PAGE_LIMIT),
          currentPage: data.page,
        });
      }

      if (search !== "" && page === 1) {
        setStateFn(newOptions);
      } else {
        setStateFn((state) => [...state, ...newOptions]);
      }
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    } finally {
      setIsLoadingOptions(false);
    }
  };

  const onBottomScroll = (
    setStateFn: Dispatch<SetStateAction<AutoCompleteData[]>>,
    search?: string,
  ) => {
    if (pageState.currentPage < pageState.lastPage) {
      getOptions(setStateFn, pageState.currentPage + 1, search);
      return true;
    }
  };

  const onBottomScrollCP = (
    setStateFn: Dispatch<SetStateAction<AutoCompleteData[]>>,
    search?: string,
  ) => {
    if (pageState.currentPage < pageState.lastPage) {
      getOptions(setStateFn, pageState.currentPage + 1, search, true);
      return true;
    }
  };

  return {
    getOptions,
    onBottomScroll,
    isLoadingOptions,
    pageState,
    onBottomScrollCP,
  };
};

export default useHandleFieldLists;
