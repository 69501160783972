import {useMemo} from "react";
import {RateCompositionData} from "./Composition.types";

import {MRT_ColumnDef} from "material-react-table";

const CompositionData = () => {
  const columnsData = useMemo<MRT_ColumnDef<RateCompositionData>[]>(
    () => [
      {
        accessorKey: "rate",
        header: "Taxa",
        enableHiding: false,
      },
      {
        accessorKey: "profit",
        header: "Profit",
      },
      {
        accessorKey: "percentageCalcBase",
        header: "% Base Cálculo",
      },
      {
        accessorKey: "calcBase",
        header: "Base Cálculo",
      },
      {
        accessorKey: "commissionPercentage",
        header: "% Comissão",
      },
      {
        accessorKey: "commissionAmount",
        header: "Valor Comissão",
      },
    ],
    [],
  );

  const ratesData: RateCompositionData[] = [
    {
      id: "1",
      rate: "Equipment Maintenance Insura...",
      profit: "0,00",
      percentageCalcBase: "100,00%",
      calcBase: "0,00",
      commissionPercentage: "50,00%",
      commissionAmount: "0,00",
    },
    {
      id: "2",
      rate: "Handling",
      profit: "0,00",
      percentageCalcBase: "100,00%",
      calcBase: "0,00",
      commissionPercentage: "50,00%",
      commissionAmount: "0,00",
    },
    {
      id: "3",
      rate: "TRS BL",
      profit: "20,80",
      percentageCalcBase: "100,00%",
      calcBase: "20,80",
      commissionPercentage: "50,00%",
      commissionAmount: "10,40",
    },
    {
      id: "4",
      rate: "Comissão Vendedor",
      profit: "0,00",
      percentageCalcBase: "100,00%",
      calcBase: "0,00",
      commissionPercentage: "50,00%",
      commissionAmount: "0,00",
    },
  ];

  return {columnsData, ratesData};
};

export default CompositionData;
