import {MenuItem, MenuRoute} from "../Sidebar.types";

const removeAllAfterSlashInString = (path: string) => {
  if (!path) return;
  return path.substring(0, path.lastIndexOf("/") + 1);
};

export const retrieveBestMatchRouter = (
  menu: MenuItem[],
  activePath: string,
) => {
  let found: MenuRoute = {} as MenuRoute;

  menu.forEach((menuItem) => {
    menuItem.routes.forEach((route) => {
      if (route.path === activePath) {
        found = route;
      }
    });

    if (menuItem.subCollection) {
      menuItem.subCollection?.forEach((subCol) => {
        subCol.routes?.forEach((route) => {
          if (route.path === activePath) {
            found = route;
          }
        });
      });
    }

    if (Object.keys(found).length === 0) {
      menuItem.routes.forEach((route) => {
        const routeWithOutLastString = removeAllAfterSlashInString(route.path);
        const activePathWithoutLastString =
          removeAllAfterSlashInString(activePath);

        if (routeWithOutLastString === activePathWithoutLastString) {
          found = route;
        }
      });

      if (Object.keys(found).length === 0) {
        menuItem.subCollection?.forEach((subCol) => {
          subCol.routes?.forEach((route) => {
            const routeWithOutLastString = removeAllAfterSlashInString(
              route.path,
            );
            const activePathWithoutLastString =
              removeAllAfterSlashInString(activePath);

            if (routeWithOutLastString === activePathWithoutLastString) {
              found = route;
            }
          });
        });
      }
    }
  });
  return found;
};
