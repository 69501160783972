export enum OnlineReleaseFiles {
  "LO HBL" = process.env.REACT_APP_LO_HBL ? +process.env.REACT_APP_LO_HBL : 170,
  "LO DEMURRAGE" = process.env.REACT_APP_LO_DEMURRAGE
    ? +process.env.REACT_APP_LO_DEMURRAGE
    : 171,
  "LO PROCURAÇÃO" = process.env.REACT_APP_LO_PROCURACAO
    ? +process.env.REACT_APP_LO_PROCURACAO
    : 174,
  "LO AUTORIZAÇÃO" = process.env.REACT_APP_AUTORIZACAO
    ? +process.env.REACT_APP_AUTORIZACAO
    : 173,
  "LO TERMO ANUAL" = process.env.REACT_APP_TERMO_ANUAL
    ? +process.env.REACT_APP_TERMO_ANUAL
    : 172,
}

export type FileDescription =
  | "LO HBL"
  | "LO DEMURRAGE"
  | "LO PROCURAÇÃO"
  | "LO AUTORIZAÇÃO"
  | "LO TERMO ANUAL";

export type DocumentsContent = {
  status: string;
  category: "onlineRelease";
  createdAt: string;
  description: FileDescription;
  guid: null;
  id: number;
  idGroupFile: number;
  name: string;
  originalName: string;
  type: string;
  updatedAt: string;
  url: string;
};

export type ReleaseDocuments = {
  hbl?: DocumentsContent;
  demurrage?: DocumentsContent;
  annualTerm?: DocumentsContent;
  authorization?: DocumentsContent;
  procuration?: DocumentsContent;
};

export interface ReleaseDocumentsTableData extends Partial<DocumentsContent> {
  documentName: string;
}
