/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Badge,
  Box,
  DialogContent,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {format} from "date-fns";
import pxToRem from "hooks/usePxToRem";
import {Dispatch, SetStateAction} from "react";
import ModalContentData from "./ModalContentData";

interface ModalContentProps {
  versionUpdatesData: any[];
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
}

const ModalContent = (props: ModalContentProps) => {
  const {versionUpdatesData, page, setPage} = props;
  const {currentUpdate, handlePageChange} = ModalContentData({
    versionUpdatesData,
    page,
    setPage,
  });

  const theme = useTheme();

  return (
    <DialogContent
      sx={{
        height: "68%",
        padding: `0 ${pxToRem(20)}`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack gap={pxToRem(24)} sx={{height: "90%"}}>
        <Stack
          sx={{
            height: "71%",
            position: "relative",
          }}
        >
          <Box
            component="img"
            src={currentUpdate?.file}
            sx={{height: pxToRem(278), width: pxToRem(508)}}
          />
          <Badge
            sx={{
              position: "absolute",
              bottom: pxToRem(-12),
              left: "40%",
              backgroundColor: "background.default",
              color: "primary.main",
              fontSize: pxToRem(12),
              fontWeight: 500,
              borderRadius: pxToRem(30),
              padding: `${pxToRem(3)} ${pxToRem(15)}`,
              lineHeight: pxToRem(18),
            }}
          >
            {currentUpdate &&
              format(new Date(currentUpdate?.createdAt), "dd/MM/yyyy")}
          </Badge>
        </Stack>
        <Stack
          alignItems={"center"}
          gap={pxToRem(4)}
          sx={{padding: `0 ${pxToRem(22)}`}}
        >
          <Typography
            variant="h6"
            align="center"
            sx={{
              fontWeight: 600,
              lineHeight: pxToRem(30),
            }}
          >
            {currentUpdate?.title}
          </Typography>

          {currentUpdate?.splittedDescription ? (
            <Typography
              variant="body2"
              align="center"
              sx={{lineHeight: pxToRem(21)}}
            >
              {currentUpdate?.splittedDescription[1]}
              {currentUpdate?.styledKeyword}
              {currentUpdate?.splittedDescription[2]}
            </Typography>
          ) : (
            <Typography
              variant="body2"
              align="center"
              sx={{lineHeight: pxToRem(21)}}
            >
              {currentUpdate?.description}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Pagination
        count={versionUpdatesData.length}
        hideNextButton
        hidePrevButton
        color="primary"
        page={page}
        onChange={handlePageChange}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            page={""}
            size={"small"}
            sx={{
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "background.default"
                  : "primary.300",
              width: pxToRem(9),
              height: pxToRem(9),
              minWidth: pxToRem(9),
              padding: 0,
              marginLeft: pxToRem(5),
            }}
          />
        )}
      />
    </DialogContent>
  );
};

export default ModalContent;
