import {AxiosResponse} from "axios";
import {newBackendAPI} from "services/apiConsumer";
import {
  Params,
  PostPendingEmailContent,
  PendingEmailReturn,
  GetPendingEmailContent,
} from "./PendingEmails.types";

export const GetListOfPendingEmails = async (
  params: Partial<Params>,
): Promise<AxiosResponse<PendingEmailReturn>> => {
  return await newBackendAPI.get(`/email-batches`, {params});
};

export const GetPendingEmailById = async (
  emailId: number,
): Promise<AxiosResponse<GetPendingEmailContent>> => {
  return await newBackendAPI.get(`/email-batches/${emailId}`);
};

export const SendPendingEmail = async (
  emailData: PostPendingEmailContent,
): Promise<AxiosResponse<unknown>> => {
  return await newBackendAPI.post(`send-email-from-activity`, emailData);
};
