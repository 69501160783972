import {OutlinedInput, OutlinedInputProps} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {forwardRef} from "react";

type Props = OutlinedInputProps & {test?: any};

const ProcessInput = forwardRef((props: Props, ref) => {
  const {size = "small"} = props;
  return (
    <OutlinedInput
      ref={ref}
      size={size}
      inputProps={{
        style: {
          fontSize: pxToRem(14),
        },
        ...props.inputProps,
      }}
      {...props}
    />
  );
});

ProcessInput.displayName = "ProcessInput";

export default ProcessInput;
