/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {buildURL, newDataAPI} from "../apiConsumer";

export const RetrieveNewUserInformation = () => {
  const user = localStorage.getItem("user_info");
  if (user) return JSON.parse(user);

  return undefined;
};

export const RetrieveUserInformation = () => {
  const user = localStorage.getItem("userinfo");
  if (user) return JSON.parse(user);

  return undefined;
};

export const MobileTokenInformation = () => {
  const token = localStorage.getItem("mobileToken");
  if (token) return token;

  return undefined;
};

export const GetRoles = () => {
  return [
    {name: "Administrador Cargo Play", value: "Administrator"},
    {name: "Administrador da Empresa", value: "CompanyAdministrator"},
    {name: "Comercial", value: "Commercial"},
    {name: "Analista", value: "Analyst"},
    {name: "Convidado", value: "Guest"},
    {name: "Financeiro", value: "Financial"},
    {name: "Financeiro da Empresa", value: "CompanyFinancial"},
    {name: "Operacional", value: "Operational"},
    {name: "Despachante", value: "Broker"},
    {name: "Liberação Online", value: "DocumentRelease"},
  ];
};

export const getListUser = async (
  query: any,
  sort?: string,
  page?: number,
  limit?: number,
) => {
  return await newDataAPI.get(
    `/users/${buildURL(query, sort, page ?? 1, limit ?? 99)}`,
  );
};

export const getUserById = async (id: string) => {
  return await newDataAPI.get(`/users/${id}`);
};
export const updateUser = async (id: string, data: any) => {
  return await newDataAPI.patch(`/users/${id}`, data);
};
export const createAccount = async (data) => {
  return await newDataAPI.post("/users", data);
};
