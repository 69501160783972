import {createActions, createReducer} from "reduxsauce";

type initial = {
  isLoading: boolean;
  entirePageLoading: boolean;
};

const INITIAL_STATE: initial = {
  isLoading: false,
  entirePageLoading: false,
};

export const {Types, Creators} = createActions({
  changeLoading: ["isLoading"],
  changeEntirePageLoading: ["entirePageLoading"],
});

const changeLoading = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  isLoading: action.isLoading,
});

const changeEntirePageLoading = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  entirePageLoading: action.entirePageLoading,
});

export default createReducer(INITIAL_STATE, {
  [Types.CHANGE_LOADING]: changeLoading,
  [Types.CHANGE_ENTIRE_PAGE_LOADING]: changeEntirePageLoading,
});
