import {Icon, Menu, MenuItem} from "@mui/material";
import {EnumKeyStatus} from "components/ModalCompleteActivity/CompleteActivity.types";
import pxToRem from "hooks/usePxToRem";
import React, {useState} from "react";
import {CustomButton} from "ui";

type Props = {
  children: string;
  handleUpdateActivityStatus: (e, status: EnumKeyStatus) => void;
  isSelect: boolean;
  formRef: React.RefObject<HTMLFormElement>;
  disabled?: boolean;
};
const SelectButton = (props: Props) => {
  const {
    children,
    handleUpdateActivityStatus,
    isSelect,
    formRef,
    disabled = false,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleClick = (e, status: EnumKeyStatus) => {
    formRef.current?.reportValidity();
    setIsOpen(false);
    handleUpdateActivityStatus(e, status);
    setAnchorEl(null);
  };

  return (
    <>
      <CustomButton
        type="submit"
        disabled={disabled}
        sx={{
          borderRadius: isSelect
            ? `${pxToRem(5)} 0 0 ${pxToRem(4)}`
            : pxToRem(5),
          height: `${pxToRem(42.5)}`,
        }}
      >
        {children}
      </CustomButton>
      {isSelect && (
        <>
          <CustomButton
            sx={{
              borderRadius: ` 0 ${pxToRem(5)} ${pxToRem(4)} 0`,
              height: `${pxToRem(42.5)}`,
              pr: "0",
              pl: "0",
              minWidth: `${pxToRem(25)} !important`,
            }}
            onClickFn={handleOpen}
          >
            <Icon>arrow_drop_down</Icon>
          </CustomButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={(e) => handleClick(e, "Cancelada")}
              id="Cancelada"
            >
              Cancelada
            </MenuItem>
            <MenuItem
              onClick={(e) => handleClick(e, "Paralisada")}
              id="Paralisada"
            >
              Paralisada
            </MenuItem>
            <MenuItem
              onClick={(e) => handleClick(e, "NaoRealizada")}
              id="Não realizada"
            >
              Não realizada
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
};

export default SelectButton;
