import {AxiosResponse} from "axios";
import {newBackendAPI} from "services/apiConsumer";
import {QueryPagination} from "types/GenericTypes";
import {
  ClientTaskWithGroups,
  CompaniesResponse,
  LegalPersonType,
  PostLegalPersonType,
} from "views/Companies/Companies.types";
import {ClientArchivesType} from "views/Companies/CompanyDetails/components/ClientArchives/ClientArchives.types";

type LegalPersonPaginated = {
  data: Array<LegalPersonType>;
  meta: {
    currentPage: number;
    firstPage: number;
    firstPageUrl: string;
    lastPage: number;
    lastPageUrl: string;
    nextPageUrl: string;
    perPage: number;
    previousPageUrl: string;
    total: number;
  };
};

export type QueryFilterPaginatePerson = {
  isClient?: boolean;
  isConsignee?: boolean;
  isShipper?: boolean;
  isBroker?: boolean;
  isRepresentative?: boolean;
  isRepresentativeFinal?: boolean;
  isSeller?: boolean;
  isOriginAgent?: boolean;
  isNotify?: boolean;
  clientName?: string;
  page?: number;
  limit?: number;
};

export type NewPaginatedPersons = Partial<
  (
    | "cargoAgent"
    | "agenciesLogistic"
    | "client"
    | "containerTerminal"
    | "customBroker"
    | "employer"
    | "internationalAssociation"
    | "representative"
    | "transportCompany"
  )[]
>;

export const GetLegalPersons = async (
  filter: QueryPagination,
): Promise<AxiosResponse<CompaniesResponse>> => {
  const params = {...filter};
  return await newBackendAPI.get(`/legal-persons`, {params});
};

export const GetLegalPersonById = async (
  personId: number,
): Promise<AxiosResponse<LegalPersonType>> => {
  return await newBackendAPI.get(`/legal-persons/${personId}`);
};

export const PostLegalPerson = async (newLegalPerson: PostLegalPersonType) => {
  return await newBackendAPI.post(`/legal-persons`, newLegalPerson);
};
export const PatchLegalPerson = async (
  personId: number,
  modifiedPerson: PostLegalPersonType,
) => {
  return await newBackendAPI.patch(
    `/legal-persons/${personId}`,
    modifiedPerson,
  );
};

export const UploadLegalPersonFile = async (dataAndFile: FormData) => {
  return await newBackendAPI.post(`/legal-person-files`, dataAndFile);
};

export const GetFilesByLegalPersonId = async (
  personId: number,
): Promise<AxiosResponse<ClientArchivesType[]>> => {
  return await newBackendAPI.get(`/legal-person-files/${personId}`);
};

export const PatchLegalPersonFile = async (
  legalPersonId: number,
  legalPersonFileData: ClientArchivesType,
) => {
  return await newBackendAPI.patch(
    `/legal-person-files/${legalPersonId}`,
    legalPersonFileData,
  );
};

export const GetFilteredLegalPersons = async (
  filter: QueryFilterPaginatePerson,
): Promise<AxiosResponse<LegalPersonPaginated>> => {
  const {
    isClient = false,
    isConsignee = false,
    isShipper = false,
    isNotify = false,
    clientName = "",
    page = 1,
    limit = 50,
  } = filter;
  return await newBackendAPI.get(
    `/legal-person-by-types?isClient=${isClient}&isConsignee=${isConsignee}&isShipper=${isShipper}&isNotify=${isNotify}&name=${clientName}&page=${page}&limit=${limit}`,
  );
};

export const PostClientTaskGroup = async (data: {
  idClient: number;
  idTaskGroup: number;
}): Promise<AxiosResponse<{message: string}>> => {
  return await newBackendAPI.post(`/client-task-groups`, data);
};

export const GetClientTaskGroupsById = async (
  clientId: number,
): Promise<AxiosResponse<ClientTaskWithGroups[]>> => {
  return await newBackendAPI.get(`/client-task-groups/${clientId}`);
};

export const PatchClientTaskGroup = async (
  clientId: number,
  data: {
    idTaskGroup: number;
    isActive: boolean;
  },
): Promise<AxiosResponse<{data: {message: string}}>> => {
  return await newBackendAPI.patch(`/client-task-groups/${clientId}`, data);
};

export const DeleteClientTaskGroup = async (
  clientId: number,
  data: {idTaskGroup: number},
): Promise<AxiosResponse<{message: string}>> => {
  return await newBackendAPI.delete(`/client-task-groups/${clientId}`, {
    data,
  });
};

export const GetLegalPersonsByType = async (
  filter: NewPaginatedPersons,
  page: number,
  personName = "",
): Promise<AxiosResponse<LegalPersonPaginated>> => {
  return await newBackendAPI.get(
    `/persons-by-type?filters=["${filter}"]&name=${personName}&page=${page}`,
  );
};

export const GetAddresses = async (page = 1, limit = 10, name?: string) => {
  const params = {
    page,
    limit,
    name,
  };

  return await newBackendAPI.get(`/addresses`, {params});
};
