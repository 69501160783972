import {Typography, Chip} from "@mui/material";
import {Stack} from "@mui/system";
import pxToRem from "hooks/usePxToRem";
import HeaderFilters from "./components/HeaderFilters";
import {CustomIconButton} from "ui";
import {useSelector, useDispatch} from "react-redux";
import {Types as dashboardFiltersTypes} from "store/reducers/dashboardFiltersReducer";

type HeaderProps = {
  total: number;
  listButton?: boolean;
  getActivities: (
    startDate?: string,
    endDate?: string,
    status?: string,
    idClient?: number,
    refNext?: string,
  ) => Promise<void>;
};

const ActivitiesHeader = (props: HeaderProps) => {
  const {total, getActivities, listButton = false} = props;
  const dispatch = useDispatch();

  const {dashboardListView} = useSelector(
    (state: any) => state.stateDashboardFilter,
  );
  const handleChangeDashboardViewFormat = () => {
    dispatch({
      type: dashboardFiltersTypes.SET_DASHBOARD_VIEW,
      dashboardListView: !dashboardListView,
    });
  };

  return (
    <Stack alignItems={"flex-start"}>
      <Stack direction="row" justifyContent="space-between" width={"100%"}>
        <Stack direction="row" alignItems="center" spacing={pxToRem(8)}>
          <Typography
            variant="h4"
            component="h2"
            sx={(theme) => ({color: theme.palette.text.primary})}
          >
            Total
          </Typography>

          <Chip
            label={total > 99 ? `${total} +` : `${total}`}
            size="small"
            sx={(theme) => ({
              color: theme.palette.primary[700],
              backgroundColor: theme.palette.primary[50],
              fontWeight: 500,
              m: `${pxToRem(0)} ${pxToRem(3)}`,
            })}
          />
        </Stack>
        {listButton && (
          <CustomIconButton
            dataTestid="toggleListView"
            iconName={"view_list"}
            tooltipText="Visualizar em lista"
            onClick={handleChangeDashboardViewFormat}
            sx={{
              backgroundColor: dashboardListView
                ? "primary.300"
                : "transparent",
              marginTop: 0,
            }}
          />
        )}

        <HeaderFilters getActivities={getActivities} />
      </Stack>
    </Stack>
  );
};

export default ActivitiesHeader;
