import pxToRem from "hooks/usePxToRem";

import {FormControlLabel, FormGroup} from "@mui/material";
import StyledSwitch from "./StyledSwitch";

type CustomToggleProps = {
  label?: string;
  isChecked?: boolean;
  size?: "small" | "medium";
  color?: "primary" | "secondary" | "success" | "warning" | "error";
  labelPosition?: "top" | "end" | "bottom" | "start";
  name: string;
  isDisabled?: boolean;
  onToggleSwitch?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const CustomToggle = ({
  label,
  isChecked,
  size = "medium",
  color = "primary",
  labelPosition = "start",
  name,
  isDisabled,
  onToggleSwitch,
}: CustomToggleProps) => {
  return (
    <FormGroup
      row
      sx={{
        ".MuiFormControlLabel-root": {
          marginRight: 0,
        },
      }}
    >
      <FormControlLabel
        sx={{
          ".MuiFormControlLabel-label": {
            fontSize: pxToRem(13),
            fontWeight: 500,
          },
        }}
        label={label ?? ""}
        labelPlacement={labelPosition}
        disabled={isDisabled}
        control={
          <StyledSwitch
            color={color}
            size={size}
            name={name}
            disableRipple
            checked={isChecked}
            onChange={onToggleSwitch}
          />
        }
      />
    </FormGroup>
  );
};

export default CustomToggle;
