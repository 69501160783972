import pxToRem from "hooks/usePxToRem";

import {Box, styled} from "@mui/material";

interface StyledBoxProps {
  expandedMenu: boolean;
  isDestructive?: boolean;
  isActive?: boolean;
}

const forwardProps = {
  shouldForwardProp: (prop) => prop !== "props",
};

// prettier-ignore
const StyledBox = styled(
  Box,
  forwardProps,
)<{props: StyledBoxProps}>(
  ({theme, props}) => `
  .nav-item {
    position: relative;
    justify-content: space-between;

    padding: ${pxToRem(4)} 0;
    color: ${
      props.isDestructive && theme.palette.mode !== "dark"
        ? theme.palette.error[700]
        : theme.palette.text.secondary
    };
    width: 100%;

    transition: color 280ms ease-in-out;

    &:hover {
      color: ${
        props.isDestructive
          ? theme.palette.error[600]
          : theme.palette.primary[600]
      };
      background-color: transparent;

      &.dropdown {
        color: unset;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: ${props.expandedMenu ? pxToRem(-18) : pxToRem(-28)};
      translate: 0 -50%;

      width: ${pxToRem(4)};
      height: ${pxToRem(22)};
      background-color: ${
        theme.palette.mode === "dark"
          ? theme.palette.primary[600]
          : theme.palette.primary[700]
      };
      border-radius: 4px 0 0 4px;
      opacity: 0;
      visibility: hidden;

      transition: opacity 280ms ease-in-out, right 300ms ease-in-out;
    }

    &.active {
      color: ${
        theme.palette.mode === "dark"
          ? theme.palette.primary[600]
          : theme.palette.primary[700]
      };

      &::after {
        opacity: 1;
        visibility: visible;
      }
    }

    &__icon {
      font-size: ${pxToRem(21)};
      
    }

    &__text {
      opacity: ${props.expandedMenu ? "1" : 0};
      width: ${props.expandedMenu ? "100%" : 0};
      visibility: ${props.expandedMenu ? "visible" : "hidden"};
      text-transform: capitalize;
      white-space: nowrap;

      transition: opacity 300ms 100ms ease-in-out;

       color: ${props.isActive &&( 
         theme.palette.mode === "dark"
           ? theme.palette.primary[600]
           : theme.palette.primary[700])
       };
    }

    &__badge {
      position: ${props.expandedMenu ? "unset" : "absolute"};
      top: 0;
      right: ${pxToRem(-4)};

      background-color: ${theme.palette.primary[50]};
      color: ${theme.palette.primary[700]};
      padding: ${props.expandedMenu ? pxToRem(1) : pxToRem(2)} ${
    props.expandedMenu ? pxToRem(12) : pxToRem(2)
  };
      height: ${props.expandedMenu ? pxToRem(20) : pxToRem(16)};
      cursor: pointer;

      transition: right 300ms ease-in-out;
      
      .MuiChip-label {
        padding: 0;
        font-weight: 600;
      }
    }

    &__dropdown-content {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: ${pxToRem(6)};
        left: 4%;
        translate: -4% 0;

        background-color: ${theme.palette.divider};
        width: ${pxToRem(1)};
        height: calc(100% - 0.875rem); // 0.875rem = 14px
      }
    }

    &__dropdown-item {
      padding: ${pxToRem(8)} 0 0 ${pxToRem(26)};
    }
  }
`,
);

export default StyledBox;
