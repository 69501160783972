import React from "react";
import {useSelector} from "react-redux";
import {Avatar} from "@mui/material";

const CustomAvatar: React.FC = ({children}) => {
  const {
    userInfo: {
      settings: {darkMode},
    },
  } = useSelector((state: any) => state.stateUserInfo);
  return (
    <Avatar
      sx={{
        bgcolor: darkMode ? "#00b2af" : "#6f2c91",
        width: "48px",
        height: "48px",
      }}
    >
      {children}
    </Avatar>
  );
};

export default CustomAvatar;
