/* eslint-disable @typescript-eslint/no-explicit-any */
import {useDispatch, useSelector} from "react-redux";
import {Types as sidebarTypes} from "store/reducers/sidebarReducer";
import pxToRem from "hooks/usePxToRem";

import {Menu, MenuItem, Stack, Icon, Typography} from "@mui/material";
import {NavLink} from "react-router-dom";

interface SidebarDropdownMenuProps {
  onExpandedDropdownChange?: (arg: string | null) => void;
  dropdownContent?: Array<{
    id?: number;
    name: string;
    icon: string;
    route: string;
    path?: string;
  }>;
}

const SidebarDropdownMenu = ({
  onExpandedDropdownChange,
  dropdownContent,
}: SidebarDropdownMenuProps) => {
  const {currentAnchorElement} = useSelector(
    (state: any) => state.stateSidebar,
  );
  const dispatch = useDispatch();

  const isAnchorElementDifferentFromNullThenMenuIsOpen: boolean =
    currentAnchorElement !== null;

  const handleCloseDropdownMenu = () => {
    dispatch({
      type: sidebarTypes.CHANGE_ANCHOR_ELEMENT,
      currentAnchorElement: null,
    });

    if (onExpandedDropdownChange) onExpandedDropdownChange("");
  };

  return (
    <Menu
      anchorEl={currentAnchorElement}
      open={isAnchorElementDifferentFromNullThenMenuIsOpen}
      onClose={handleCloseDropdownMenu}
      elevation={0}
      sx={(theme) => ({
        "& .MuiPaper-root": {
          position: "relative",

          boxShadow: "-0.5px 0.5px 3px rgba(0 0 0 / 10%)",
          width: pxToRem(240),

          "&::before": {
            content: '""',
            position: "absolute",
            top: pxToRem(12),
            left: "4%",
            translate: "-4% 0",

            backgroundColor: theme.palette.divider,
            width: pxToRem(1),
            height: `calc(100% - ${pxToRem(28)})`,
          },
        },

        "& .MuiMenu-list": {
          display: "grid",
          gap: pxToRem(8),
          padding: `${pxToRem(12)} 0`,
        },

        "& .MuiMenuItem-root": {
          display: "flex",
          gap: pxToRem(6),
          color: theme.palette.text.secondary,
          padding: `${pxToRem(4)} ${pxToRem(24)} ${pxToRem(4)} ${pxToRem(28)}`,

          transition: "color 280ms ease-in-out",

          "&::after": {
            content: '""',
            position: "absolute",
            top: "50%",
            right: 0,
            translate: "0 -50%",

            width: pxToRem(4),
            height: pxToRem(22),
            backgroundColor: theme.palette.primary[700],
            borderRadius: "4px 0 0 4px",
            opacity: 0,
            visibility: "hidden",

            transition: "opacity 280ms ease-in-out, right 300ms ease-in-out",
          },

          "&:hover, &.active": {
            backgroundColor: "transparent",
            color: theme.palette.primary[700],
          },

          "&.active::after": {
            opacity: 1,
            visibility: "visible",
          },
        },

        ".MuiIcon-root": {
          fontSize: pxToRem(21),
        },
      })}
    >
      {dropdownContent?.map((item) => (
        <MenuItem
          key={item.name}
          disableRipple
          component={NavLink}
          to={item?.path ?? ""}
          onClick={handleCloseDropdownMenu}
        >
          <Stack direction="row" alignItems="center" spacing={pxToRem(6)}>
            <Icon>{item.icon}</Icon>

            <Typography variant="subtitle2" component="span">
              {item.name}
            </Typography>
          </Stack>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default SidebarDropdownMenu;
