/* eslint-disable react-hooks/exhaustive-deps */
import ConfirmModal from "components/ConfirmModal";
import {readLocalStorage, saveLocalStorage} from "hooks/useLocalStorage";
import pxToRem from "hooks/usePxToRem";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {KeyCloakRefreshTokenAuth} from "services/api_v2/Auth/auth.service";
import {Types as userInfoTypes} from "store/reducers/userInfoReducer";
import useAlertMessage from "hooks/useAlertMessage";
import {CircularProgress, Stack, useTheme} from "@mui/material";
import jwtDecode from "jwt-decode";
import {add, formatDistanceToNowStrict, isEqual, sub} from "date-fns";
import useLogout from "hooks/useLogout";
import {GetAccessTokenByRefreshToken} from "services/Auth/auth.service";
import {CustomButton} from "ui";

interface ModalTokenExpirationProps {
  isUnauthorized: boolean;
  setIsUnauthorized: Dispatch<SetStateAction<boolean>>;
  handleRedirectWarning: () => void;
}

const ModalTokenExpiration = (props: ModalTokenExpirationProps) => {
  const {isUnauthorized, setIsUnauthorized, handleRedirectWarning} = props;
  const {handleLogoutUser} = useLogout();
  const dispatch = useDispatch();
  const theme = useTheme();

  const {userInfo, currentDateTime} = useSelector(
    (state: any) => state.stateUserInfo,
  );

  const userCPData = readLocalStorage<{refreshToken?: string}>("userinfo");

  const tokenInfo: any = jwtDecode(userInfo.access_token);
  const tokenExpirationDate = new Date(tokenInfo?.exp * 1000);
  const expPlusThreeHours = add(tokenExpirationDate, {hours: 3});
  const currentDateTimeInDateFormat = new Date(currentDateTime);

  const [showAlert] = useAlertMessage();

  const [tokenExpirationCountDown, setTokenExpirationCountDown] = useState(30);
  const [isLoading, setIsLoading] = useState(false);

  const handleDateTimeUpdate = () => {
    dispatch({
      type: userInfoTypes.SET_CURRENT_DATE_TIME,
      currentDateTime: add(currentDateTimeInDateFormat, {
        minutes: 1,
      }).toString(),
    });
  };
  setTimeout(() => {
    if (!isUnauthorized) handleDateTimeUpdate();
  }, 60 * 1000);

  const handleStopCounting = () => {
    setTokenExpirationCountDown(30);
  };

  const redirectToLogin = () => {
    handleStopCounting();
    handleLogoutUser(userInfo);
  };

  const handleCheckTimeToOpenModal = () => {
    if (
      isEqual(currentDateTimeInDateFormat, sub(expPlusThreeHours, {minutes: 5}))
    ) {
      handleRedirectWarning();
    }
  };
  useEffect(() => {
    handleCheckTimeToOpenModal();
  }, [currentDateTimeInDateFormat]);

  const handleReplaceDateTimeWithNow = () => {
    const formatedToNow = formatDistanceToNowStrict(
      currentDateTimeInDateFormat,
      {
        addSuffix: true,
        unit: "minute",
      },
    );

    const minutes = formatedToNow
      ?.split(" ")
      ?.find((item) => typeof +item === "number");

    if (formatedToNow?.includes("ago")) {
      dispatch({
        type: userInfoTypes.SET_CURRENT_DATE_TIME,
        currentDateTime: add(currentDateTimeInDateFormat, {
          minutes: Number(minutes ?? 0),
        })?.toString(),
      });
    }
  };
  useEffect(() => {
    handleReplaceDateTimeWithNow();
  }, []);

  const handleLogoutIfTokenIsExpired = () => {
    if (
      formatDistanceToNowStrict(expPlusThreeHours, {
        addSuffix: true,
        unit: "minute",
      })?.includes("ago")
    ) {
      handleLogoutUser(userInfo);
    }
  };
  useEffect(() => {
    handleLogoutIfTokenIsExpired();
  }, [currentDateTime]);

  const handleStartCountDown = () => {
    if (isUnauthorized) {
      setInterval(() => {
        setTokenExpirationCountDown((state) => {
          if (state > 0) {
            return state - 1;
          }
          return 0;
        });
      }, 1000);
    }
  };
  useEffect(() => {
    handleStartCountDown();
  }, [isUnauthorized]);

  const handleRedirectWhenRefreshTokenWasNotUsed = () => {
    if (isUnauthorized && tokenExpirationCountDown === 0) {
      redirectToLogin();
    }
  };
  useEffect(() => {
    handleRedirectWhenRefreshTokenWasNotUsed();
  }, [isUnauthorized, tokenExpirationCountDown]);

  const refreshToken = async () => {
    try {
      setIsLoading(true);
      const {data} = await KeyCloakRefreshTokenAuth(userInfo.refresh_token);
      const {data: cpRefreshTokenData} = await GetAccessTokenByRefreshToken(
        userCPData?.refreshToken,
      );

      saveLocalStorage("userinfo", {
        ...userCPData,
        accessToken: cpRefreshTokenData?.accessToken,
        refreshToken: cpRefreshTokenData?.refreshToken,
      });

      saveLocalStorage("ownerProfile", {...data});
      const loggedUser = data;

      dispatch({
        type: userInfoTypes.SET_USER_INFO,
        userInfo: {
          ...userInfo,
          ...loggedUser,
        },
      });
      dispatch({
        type: userInfoTypes.SET_CURRENT_DATE_TIME,
        currentDateTime: new Date(tokenInfo?.iat * 1000).toString(),
      });
      handleStopCounting();
      setIsUnauthorized(false);
    } catch (error: any) {
      console.error(error);
      showAlert(error?.response?.data?.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ConfirmModal
      isOpen={isUnauthorized}
      title="Sessão expirada!"
      subtitle={
        "Sua sessão expirou, você será redirecionado para a tela de login."
      }
      confirmButtonFn={refreshToken}
      onClose={redirectToLogin}
      titleIcon="refresh"
      hasOnlyConfirmButton
      confirmButtonText={`Manter-se logado (${tokenExpirationCountDown} s)`}
      confirmButtonIconStart=""
      confirmButtonTextTransformNone
      color="warning"
      dataTestId={"modal-token"}
      customSecondaryButton={
        <CustomButton
          variant="outlined"
          disabled={tokenExpirationCountDown === 0}
          onClickFn={redirectToLogin}
          fullWidth
          sx={{
            height: pxToRem(40.5),
            color: theme.palette.warning[600],
            borderColor: theme.palette.warning[700],
            "&:hover": {borderColor: theme.palette.text.disabled},
          }}
        >
          Confirmar
        </CustomButton>
      }
    >
      {isLoading && (
        <Stack
          sx={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={pxToRem(18)} color="warning" />
        </Stack>
      )}
    </ConfirmModal>
  );
};

export default ModalTokenExpiration;
