import pxToRem from "hooks/usePxToRem";

import {Grid, Typography} from "@mui/material";

import {CustomInputWrapper, CustomInput, CustomAutocomplete} from "ui";
import {ConfirmModal, CustomModal, DefaultCardStructure} from "components";
import {Controller, useFieldArray, useFormContext} from "react-hook-form";
import {useState} from "react";
import {ModalNewEquipment} from "./components";
import {
  DefaultProcessDetails,
  ProcessEquipment,
} from "views/ProcessDetails/Process.types";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";
import {
  DeleteLogisticEquipment,
  GetEquipmentsList,
  PostNewLogisticEquipment,
} from "services/api_v2/Processes/Processes.service";
import useChangeDAteAndAutocomplete from "views/ProcessDetails/hooks/useChangeDAteAndAutocomplete";
import useHandleFieldLists from "views/ProcessDetails/hooks/useHandleFieldLists";
import {getTooltipText} from "views/ProcessDetails/utils/getTooltipText";
import {useParams} from "react-router-dom";
import useLoading from "hooks/useLoading";
import useToast from "hooks/useToast";
import useAlertMessage from "hooks/useAlertMessage";

type Props = {
  refetchProcess: () => Promise<DefaultProcessDetails | undefined>;
};

const LoadEquipment = (props: Props) => {
  const {refetchProcess} = props;
  const {control, setValue, getValues} = useFormContext();
  const {id: processId} = useParams<{id: string}>();
  const {fields} = useFieldArray({
    name: "processEquipments",
    keyName: "equipmentFormId",
  });
  const [setLoading] = useLoading();
  const [showToast] = useToast();
  const [showAlert] = useAlertMessage();

  const [equipmentToRemove, setEquipmentToRemove] =
    useState<ProcessEquipment | null>(null);

  const handleCreateProcessEquipments = async (data: ProcessEquipment) => {
    try {
      setLoading(true);
      await PostNewLogisticEquipment({
        idLogisticProcess: +processId,
        idEquipment: data.equipment?.id,
        quantity: data.quantity,
        freeTimeHouseDestination: data.freeTimeHouseDestination,
        freeTimeHouseOrigin: data.freeTimeHouseOrigin,
        freeTimeMasterDestination: data.freeTimeMasterDestination,
        freeTimeMasterOrigin: data.freeTimeMasterOrigin,
      });
      showToast("Equipamento vinculado com sucesso!", "success");
      await refetchProcess();
    } catch (error: any) {
      console.error(error);
      showAlert(error?.response?.data?.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveEquipment = (field: ProcessEquipment) => {
    setEquipmentToRemove(field);
    handleOpenConfirmModal();
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleOpenNewEquipmentModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseNewEquipmentModal = () => {
    setModalIsOpen(false);
  };

  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);

  const handleOpenConfirmModal = () => {
    setConfirmModalIsOpen(true);
  };

  const handleCloseConfirmModal = () => {
    setConfirmModalIsOpen(false);
    setEquipmentToRemove(null);
  };

  const handleConfirmEquipmentDeletion = async () => {
    if (!equipmentToRemove?.id) {
      showToast("Algo deu errado, tente novamente!", "error");
      return;
    }

    try {
      setLoading(true);
      await DeleteLogisticEquipment(equipmentToRemove?.id);
      await refetchProcess();
    } catch (error: any) {
      console.error(error);
      showAlert(error?.response?.data?.message, "error");
    } finally {
      setLoading(false);
    }
    handleCloseConfirmModal();
  };

  const [equipmentsOptions, setEquipmentsOptions] = useState(
    [] as AutoCompleteData[],
  );
  const [search, setSearch] = useState<string>();

  const {
    getOptions: getEquipmentsOptions,
    onBottomScroll: onBottomScrollEquipments,
    isLoadingOptions: isLoadingEquipmentsOptions,
  } = useHandleFieldLists(GetEquipmentsList);

  const {handleChangeAutocomplete, mountOptions} =
    useChangeDAteAndAutocomplete();

  return (
    <DefaultCardStructure
      title="Equipamentos"
      hasDivider
      isSubcard
      //Não teremos adição de equipamentos por motivos de alteração de taxas.
      // button={
      //   <CustomButton
      //     onClickFn={handleOpenNewEquipmentModal}
      //     startIcon="add"
      //     size="small">
      //     Novo equipamento
      //   </CustomButton>
      // }
    >
      {confirmModalIsOpen && (
        <ConfirmModal
          isOpen={confirmModalIsOpen}
          title={`Tem certeza de que deseja excluir o equipamento ${equipmentToRemove?.nameEquipment}? `}
          subtitle={"Essa ação é definitiva e não pode ser revertida!"}
          confirmButtonFn={handleConfirmEquipmentDeletion}
          onClose={handleCloseConfirmModal}
        />
      )}
      {modalIsOpen && (
        <CustomModal
          isOpen={modalIsOpen}
          onClose={handleCloseNewEquipmentModal}
          title={"Inserir novo equipamento"}
          titleIcon={"add"}
        >
          <ModalNewEquipment
            handleCreateProcessEquipments={handleCreateProcessEquipments}
            onClose={handleCloseNewEquipmentModal}
          />
        </CustomModal>
      )}
      {fields.length === 0 && (
        <Typography>Não existem equipamentos no processo.</Typography>
      )}
      {fields.map((field, index) => (
        <Grid
          container
          rowSpacing={pxToRem(20)}
          columnSpacing={pxToRem(8)}
          alignItems="flex-end"
          mb={fields.length > 1 ? pxToRem(20) : 0}
          key={field.equipmentFormId}
        >
          <CustomInputWrapper md={3} title="Tipo" isDisabled>
            <Controller
              name={`processEquipments[${index}].equipment`}
              control={control}
              render={({field: {value}}) => (
                <CustomAutocomplete
                  name={`processEquipments[${index}].equipment`}
                  placeholder="Equipamento"
                  disabled
                  tooltipText={getTooltipText(value?.name)}
                  loading={isLoadingEquipmentsOptions}
                  options={mountOptions(value, equipmentsOptions)}
                  value={value?.name ?? ""}
                  onOpen={
                    equipmentsOptions.length > 0
                      ? undefined
                      : () =>
                          getEquipmentsOptions(setEquipmentsOptions, 1, search)
                  }
                  onInputChange={(e, value) => {
                    if (e !== null) {
                      setSearch(value);
                      getEquipmentsOptions(setEquipmentsOptions, 1, search);
                    }
                  }}
                  getNextPage={() => {
                    onBottomScrollEquipments(setEquipmentsOptions, search);
                  }}
                  onChange={(e, value) => {
                    handleChangeAutocomplete(
                      e,
                      value,
                      `processEquipments[${index}].equipment`,
                    );
                  }}
                  isOptionEqualToValue={(option, value) =>
                    value === option?.label
                  }
                />
              )}
            />
          </CustomInputWrapper>
          <CustomInputWrapper md={1} title="Quantidade">
            <Controller
              name={`processEquipments[${index}].quantity`}
              control={control}
              render={({field: {value}}) => (
                <CustomInput
                  name={`processEquipments[${index}].quantity`}
                  type="number"
                  placeholder="Quantidade"
                  value={value}
                  onChange={(e) => {
                    setValue(
                      `processEquipments[${index}].quantity`,
                      +e.target.value,
                      {
                        shouldDirty: true,
                      },
                    );
                    setValue(
                      `totalOfEquipments`,
                      getValues(`totalOfEquipments`) + Number(e.target.value),
                    );
                  }}
                  size="small"
                />
              )}
            />
          </CustomInputWrapper>
          <CustomInputWrapper md={2} title="FT Master Origem" isDisabled>
            <Controller
              name={`processEquipments[${index}].freeTimeMasterOrigin`}
              control={control}
              render={({field: {value}}) => (
                <CustomInput
                  name={`processEquipments[${index}].freeTimeMasterOrigin`}
                  type="text"
                  disabled
                  placeholder="Origem"
                  value={value}
                  onChange={(e) => {
                    setValue(
                      `processEquipments[${index}].freeTimeMasterOrigin`,
                      +e.target.value,
                      {
                        shouldDirty: true,
                      },
                    );
                  }}
                  size="small"
                />
              )}
            />
          </CustomInputWrapper>
          <CustomInputWrapper md={2} title="FT House Origem" isDisabled>
            <Controller
              name={`processEquipments[${index}].freeTimeHouseOrigin`}
              control={control}
              render={({field: {value}}) => (
                <CustomInput
                  name={`processEquipments[${index}].freeTimeHouseOrigin`}
                  type="text"
                  disabled
                  placeholder="Origem"
                  value={value}
                  onChange={(e) => {
                    setValue(
                      `processEquipments[${index}].freeTimeHouseOrigin`,
                      +e.target.value,
                      {
                        shouldDirty: true,
                      },
                    );
                  }}
                  size="small"
                />
              )}
            />
          </CustomInputWrapper>
          <CustomInputWrapper md={2} title="FT Master Destino" isDisabled>
            <Controller
              name={`processEquipments[${index}].freeTimeMasterDestination`}
              control={control}
              render={({field: {value}}) => (
                <CustomInput
                  name={`processEquipments[${index}].freeTimeMasterDestination`}
                  type="text"
                  disabled
                  placeholder="Destino"
                  value={value}
                  onChange={(e) => {
                    setValue(
                      `processEquipments[${index}].freeTimeMasterDestination`,
                      +e.target.value,
                      {
                        shouldDirty: true,
                      },
                    );
                  }}
                  size="small"
                />
              )}
            />
          </CustomInputWrapper>
          <CustomInputWrapper md={2} title="FT House Destino" isDisabled>
            <Controller
              name={`processEquipments[${index}].freeTimeHouseDestination`}
              control={control}
              render={({field: {value}}) => (
                <CustomInput
                  name={`processEquipments[${index}].freeTimeHouseDestination`}
                  type="text"
                  disabled
                  placeholder="Destino"
                  value={value}
                  onChange={(e) => {
                    setValue(
                      `processEquipments[${index}].freeTimeHouseDestination`,
                      +e.target.value,
                      {
                        shouldDirty: true,
                      },
                    );
                  }}
                  size="small"
                />
              )}
            />
          </CustomInputWrapper>
          {/*
          Não teremos remoção de equipamentos por motivos de alteração de
          taxas.

           <Grid item md={1} display="flex" justifyContent="center">
            <CustomIconButton
              iconName="delete"
              color="error"
              tooltipText="Remover Equipamento"
              onClick={() =>
                handleRemoveEquipment(field as unknown as ProcessEquipment)
              }
            />
          </Grid> */}
        </Grid>
      ))}
    </DefaultCardStructure>
  );
};

export default LoadEquipment;
