/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {Fragment, useState} from "react";

import {Divider, Grid} from "@mui/material";

import {
  CargoItems,
  GeneralLoadInfo,
  HouseInfo,
  HouseMeasurements,
  LoadEquipment,
  MasterInfo,
  ModalAddNcm,
  NcmList,
} from "./components";
import pxToRem from "hooks/usePxToRem";
import {useFormContext} from "react-hook-form";
import {
  DefaultProcessDetails,
  ProcessBillLadingNcm,
} from "views/ProcessDetails/Process.types";
import ProcessPackages from "./components/ProcessPackages";

type Props = {
  refetchProcess: () => Promise<DefaultProcessDetails | undefined>;
};

const ProcessLoad = (props: Props) => {
  const {refetchProcess} = props;
  const [isModalAddNcmOpen, setIsModalAddNcmOpen] = useState(false);

  const handleOpenModalAddNcm = () => setIsModalAddNcmOpen(true);
  const handleCloseModalAddNcm = () => setIsModalAddNcmOpen(false);

  const {watch, getValues} = useFormContext();

  const [ncmsTableData, setNcmsTableData] = useState<ProcessBillLadingNcm[]>(
    watch("processBillLadingNcms"),
  );

  // Considerando que sempre teremos apenas 1 carga no projeto.
  const LOAD_INDEX = 0;

  return (
    <Fragment>
      {isModalAddNcmOpen && (
        <ModalAddNcm
          isModalOpen={isModalAddNcmOpen}
          onRequestCloseModal={handleCloseModalAddNcm}
          refetchProcess={refetchProcess}
          setNcmsTableData={setNcmsTableData}
        />
      )}
      <GeneralLoadInfo />

      {watch("processLoads.0.typeLoad") === "FCL" && (
        <LoadEquipment refetchProcess={refetchProcess} />
      )}

      <Grid container columnSpacing={pxToRem(20)}>
        <Grid item xs={12}>
          <MasterInfo />
        </Grid>

        {!getValues("isDirectMaster") && (
          <Grid item xs={12}>
            <HouseInfo />
          </Grid>
        )}
        <Grid item xs={12}>
          <HouseMeasurements loadIndex={LOAD_INDEX} />
        </Grid>
      </Grid>

      <Grid item xs={12} mt={pxToRem(8)} mb={pxToRem(8)}>
        <Divider />
      </Grid>
      {watch("processLoads.0.typeLoad") === "FCL" && (
        // <CargoItems_Table />
        <CargoItems loadIndex={LOAD_INDEX} />
      )}
      {watch("processLoads.0.typeLoad") === "LCL" && (
        <ProcessPackages loadIndex={LOAD_INDEX} />
      )}

      <NcmList
        onRequestOpenModalAddNcm={handleOpenModalAddNcm}
        refetchProcess={refetchProcess}
        ncmsTableData={ncmsTableData}
        setNcmsTableData={setNcmsTableData}
      />
    </Fragment>
  );
};

export default React.memo(ProcessLoad);
