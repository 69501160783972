import {
  Box,
  Icon,
  IconButton,
  IconButtonProps,
  SxProps,
  Theme,
  Tooltip,
} from "@mui/material";
import pxToRem from "hooks/usePxToRem";

interface CustomIconButtonProps extends IconButtonProps {
  sx?: SxProps<Theme>;
  tooltipText?: string;
  tooltipPlacement?: "top" | "right" | "bottom" | "left";
  iconName: string;
  iconSize?: number;
  dataTestid?: string;
}

const CustomIconButton = ({
  tooltipText,
  tooltipPlacement,
  iconName,
  iconSize,
  dataTestid,
  ...rest
}: CustomIconButtonProps) => {
  return (
    <Tooltip
      title={tooltipText ?? ""}
      placement={tooltipPlacement ?? "top"}
      arrow
    >
      <Box>
        <IconButton
          disableRipple
          data-testid={dataTestid}
          sx={{
            ...rest.sx,

            "&:hover": {
              background: "transparent",
            },
          }}
          {...rest}
        >
          <Icon
            aria-hidden={false}
            sx={{fontSize: iconSize && pxToRem(iconSize)}}
          >
            {iconName}
          </Icon>
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default CustomIconButton;
