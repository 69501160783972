import {createActions, createReducer} from "reduxsauce";

type initial = {
  //isSidebarOpen: boolean;
  currentAnchorElement: HTMLElement | null;
};

const sidebarStateStoredInLocalStorage = localStorage.getItem("isSidebarOpen");

const INITIAL_STATE: initial = {
  //isSidebarOpen: sidebarStateStoredInLocalStorage
  //  ? JSON.parse(sidebarStateStoredInLocalStorage)
  //  : false,
  currentAnchorElement: null,
};

export const {Types, Creators} = createActions({
  //toggleSidebarMode: ["isSidebarOpen"],
  changeAnchorElement: ["currentAnchorElement"],
});

const toggleSidebarMode = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  //isSidebarOpen: action.isSidebarOpen,
});

const changeAnchorElement = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  currentAnchorElement: action.currentAnchorElement,
});

export default createReducer(INITIAL_STATE, {
  // [Types.TOGGLE_SIDEBAR_MODE]: toggleSidebarMode,
  [Types.CHANGE_ANCHOR_ELEMENT]: changeAnchorElement,
});
