import {ExpandMore} from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import pxToRem from "hooks/usePxToRem";

type Props = {
  title: string;
  children?: React.ReactNode;
  mt?: string;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  onChange?: (event: React.SyntheticEvent<Element, Event>) => void | undefined;
  expanded?: boolean;
  titleStyle?: SxProps<Theme>;
};

const CustomAccordion = (props: Props) => {
  const {
    title,
    mt,
    sx,
    disabled,
    onChange,
    expanded,
    children,
    titleStyle = {fontSize: pxToRem(14), fontWeight: 500},
  } = props;

  return (
    <Accordion
      disabled={disabled}
      expanded={expanded}
      onChange={onChange}
      sx={{...sx, marginTop: mt ?? 0}}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={title}
        id={title}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography sx={{...titleStyle}}>{title}</Typography>
      </AccordionSummary>
      {children}
    </Accordion>
  );
};

export default CustomAccordion;
