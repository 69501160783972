import {Voyage} from "../Process.types";
import {ID_TRANSHIPMENT_TYPE_VOYAGE, ID_INITIAL_VOYAGE} from "./constants";

export const getDestinationVoyageIndex = (
  idBoardingDestination: number | undefined,
  voyages: Voyage[],
) => {
  const hasTranshipmentVoyage =
    voyages.findIndex(
      (voyage) => voyage.typeVoyage.id === ID_TRANSHIPMENT_TYPE_VOYAGE,
    ) > 0;

  if (hasTranshipmentVoyage) {
    const voyageIndex = voyages.findIndex(
      (voyage) =>
        voyage?.typeVoyage?.id === ID_TRANSHIPMENT_TYPE_VOYAGE &&
        voyage?.destination?.id === idBoardingDestination,
    );
    return voyageIndex;
  } else {
    const voyageIndex = voyages.findIndex(
      (voyage) =>
        voyage?.typeVoyage?.id === ID_INITIAL_VOYAGE &&
        voyage?.destination?.id === idBoardingDestination,
    );
    return voyageIndex;
  }
};

export const getInitialVoyageIndex = (voyages: Voyage[]) => {
  const voyageIndex = voyages.findIndex(
    (voyage) => voyage.typeVoyage.id === ID_INITIAL_VOYAGE,
  );
  return voyageIndex;
};
