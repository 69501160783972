import {Box, Tooltip, Typography, useTheme} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {SyntheticEvent} from "react";
import {CustomSearchSelect} from "ui";
import {ProcessFieldOptions} from "./AutocompleteWithPermanentPlaceholder.types";

interface AutocompleteWithPermanentPlaceholderProps {
  permanentPlaceholder: string;
  options?: ProcessFieldOptions[] | {label: string; id: string}[];
  value?: any;

  onChange?: (event: SyntheticEvent<Element, Event>, newValue: any) => void;
  onOpen?: () => void;
  groupBy?: (options: any) => string;
  name: string;
  disabled?: boolean;
  required?: boolean;
  sizeOfPlaceholder?: number;
  loading?: boolean;
  onBottomScroll?: () => void | boolean;
  hasMorePagesToLoad?: boolean;
  fnOnInputChange?: (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => void;
  multiple?: boolean;
  limitTags?: number;
  tooltipOff?: boolean;
  placeholder?: string;
  inputValue?: string;
  optionSecondaryContent?: boolean;
}

const AutocompleteWithPermanentPlaceholder = (
  props: AutocompleteWithPermanentPlaceholderProps,
) => {
  const theme = useTheme();
  const {
    permanentPlaceholder,
    sizeOfPlaceholder = 2,
    options = [],
    value,
    onChange,
    onOpen,
    groupBy,
    name,
    disabled,
    required = true,
    loading = false,
    onBottomScroll,
    hasMorePagesToLoad,
    fnOnInputChange,
    multiple,
    limitTags,
    tooltipOff,
    placeholder,
    inputValue,
    optionSecondaryContent,
  } = props;

  return (
    <Tooltip title={tooltipOff ? "" : value?.label} arrow placement="top">
      <Box position="relative">
        <Box
          position="absolute"
          sx={{
            color: theme.palette.grey[500],
            left: pxToRem(14),
            top: pxToRem(8.5),
            maxWidth: "70%",
          }}
        >
          <Typography
            sx={{
              fontSize: pxToRem(14),
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {permanentPlaceholder}
          </Typography>
        </Box>
        <CustomSearchSelect
          inputValue={inputValue}
          required={required}
          disabled={disabled}
          name={name}
          options={options}
          size="small"
          value={value}
          loading={loading}
          fnOnChange={onChange}
          fnOnOpen={onOpen}
          fullWidth
          groupBy={groupBy}
          placeholder={placeholder ?? "Selecione"}
          sizeOfPlaceholder={sizeOfPlaceholder}
          permanentPlaceholder
          fontSize={pxToRem(14)}
          fontWeight={500}
          onBottomScroll={onBottomScroll}
          hasMorePagesToLoad={hasMorePagesToLoad}
          fnOnInputChange={fnOnInputChange}
          multiple={multiple}
          limitTags={limitTags}
          optionSecondaryContent={optionSecondaryContent}
        />
      </Box>
    </Tooltip>
  );
};

export default AutocompleteWithPermanentPlaceholder;
