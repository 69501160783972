/* eslint-disable @typescript-eslint/no-explicit-any */
import {createActions, createReducer} from "reduxsauce";

type initial = {
  company: any;
};

const INITIAL_STATE: initial = {
  company: {},
};

export const {Types, Creators} = createActions({
  getCompany: ["company"],
});

const getCompany = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  company: action.company,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_COMPANY]: getCompany,
});
