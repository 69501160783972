import {Grid2} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {useFormContext} from "react-hook-form";
import {ActivityHouse, ActivityMaster} from "./components";

const ActivityKnowledge = () => {
  const {getValues} = useFormContext();

  return (
    <Grid2 container columnSpacing={pxToRem(20)}>
      <Grid2 size={{xs: 12}}>
        <ActivityMaster />
      </Grid2>

      {!getValues("isDirectMaster") && getValues("houseBL")?.length > 0 && (
        <Grid2 size={{xs: 12}}>
          <ActivityHouse />
        </Grid2>
      )}
    </Grid2>
  );
};

export default ActivityKnowledge;
