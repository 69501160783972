/* eslint-disable react-hooks/exhaustive-deps */
import pxToRem from "hooks/usePxToRem";

import {
  Box,
  DialogContent,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import {CustomInputWrapper, CustomButton, CustomAutoComplete} from "ui";

import {ModalWrapperFooter} from "views/Tasks/components/GenericTasksModal/components";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Fragment, useEffect, useState} from "react";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";
import useHandleFieldLists from "views/ProcessDetails/hooks/useHandleFieldLists";
import {GetNcms, LinkNcms} from "services/api_v2/Processes/Processes.service";
import {useParams} from "react-router-dom";
import {ConfirmModal, CustomModal} from "components";
import useToast from "hooks/useToast";
import useAlertMessage from "hooks/useAlertMessage";
import {boolean, number, object, string} from "yup";
import {LinkOptions} from "./components";
import {Controller, useForm} from "react-hook-form";
import {handleRemoveCharFromList} from "./util/removeCharFromList";
import {
  DefaultProcessDetails,
  ProcessBillLadingNcm,
} from "views/ProcessDetails/Process.types";

interface ModalAddNcmProps {
  isModalOpen: boolean;
  onRequestCloseModal: () => void;
  refetchProcess: () => Promise<DefaultProcessDetails | undefined>;
  setNcmsTableData: React.Dispatch<
    React.SetStateAction<ProcessBillLadingNcm[]>
  >;
  processId?: number;
}

interface NcmsModal {
  isBatchInsertion: boolean;
  charToRemove?: string;
  ncmsList?: string;
  linkTo?: string;
  selectedNcm?: {label: string; id: number};
}

interface NcmsPayload {
  idNcm?: number;
  idLogisticProcess?: number;
  ncms?: Array<string>;
}

const ModalAddNcm = (props: ModalAddNcmProps) => {
  const {isModalOpen, onRequestCloseModal, refetchProcess, setNcmsTableData} =
    props;
  const theme = useTheme();

  const [showAlert] = useAlertMessage();

  const defaultValues = {
    isBatchInsertion: false,
    selectedNcm: {label: "", id: 0},
    charToRemove: "",
    ncmsList: "",
  };

  const methods = useForm<NcmsModal>({
    defaultValues: defaultValues,
  });

  const {control, watch, setValue, getValues, resetField} = methods;

  const [selectedProcess, setSelectedProcess] = useState<
    undefined | {idLogisticProcess: number}
  >(undefined);

  const {id: processId} = useParams<{id: string}>();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showToast] = useToast();

  const [ncmOptions, setNcmOptions] = useState([] as AutoCompleteData[]);
  const [searchNcm, setSearchNcm] = useState("");

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [formData, setFormData] = useState<NcmsModal>({} as NcmsModal);

  const schema = object({
    isBatchInsertion: boolean(),
    characterToRemove: string().when("isBatchInsertion", {
      is: true,
      then: (schema) => schema.min(1).required(),
      otherwise: (schema) => schema.optional(),
    }),
    ncmsListToInclude: string().when("isBatchInsertion", {
      is: true,
      then: (schema) => schema.min(3).required(),
      otherwise: (schema) => schema.optional(),
    }),
    ncmSingleItem: object().when("isBatchInsertion", {
      is: false,
      then: (schema) => schema.shape({id: number().not([0])}),
      otherwise: (schema) => schema.optional(),
    }),
  });

  const formatOptionsFn = (data) => {
    return data?.data?.map((item) => ({
      label: `${item?.code}: ${item?.description}`,
      id: item?.id,
    }));
  };

  const {
    getOptions: getNcmsOptions,
    onBottomScroll: onBottomScrollNcms,
    isLoadingOptions: isLoadingNcmsOptions,
    pageState: ncmsPageState,
  } = useHandleFieldLists(GetNcms, formatOptionsFn);

  const handleChangeLocalAutocomplete = (
    _: React.SyntheticEvent<Element, Event>,
    nv: any,
  ) => {
    setValue("selectedNcm", nv);
  };

  const handleLinkNcmRequest = async (payload: NcmsPayload) => {
    try {
      const response = await LinkNcms(payload);
      showToast(response.data.message, "success");
      if (response.data.ncmsNotFound) {
        showAlert(
          `Ncm's não encontrados: ${response.data.ncmsNotFound}`,
          "warning",
        );
      }
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    } finally {
      onRequestCloseModal();
      const result = await refetchProcess();
      setNcmsTableData(result?.processBillLadingNcms ?? []);
    }
  };

  const handleLinkNcm = async (data: NcmsModal) => {
    if (data.isBatchInsertion && data.ncmsList && data.charToRemove) {
      const objToSend = {
        idLogisticProcess: selectedProcess?.idLogisticProcess,
        ncms: handleRemoveCharFromList(data.ncmsList, data.charToRemove),
      };
      handleLinkNcmRequest(objToSend);
    } else {
      const objToSend = {
        idNcm: getValues("selectedNcm")?.id ?? 0,
        idLogisticProcess: selectedProcess?.idLogisticProcess ?? 0,
      };
      handleLinkNcmRequest(objToSend);
    }
  };

  useEffect(() => {
    setSelectedProcess({idLogisticProcess: props.processId ?? +processId});
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await schema.validate({
          isBatchInsertion: watch("isBatchInsertion"),
          characterToRemove: watch("charToRemove"),
          ncmsListToInclude: watch("ncmsList"),
          ncmSingleItem: watch("selectedNcm"),
        });
        setIsButtonDisabled(false);
      } catch (e) {
        setIsButtonDisabled(true);
      }
    })();
  }, [watch()]);

  const onSubmit = (data: NcmsModal) => {
    if (data.isBatchInsertion) {
      setFormData(data);
      setIsConfirmModalOpen(true);
      return;
    }

    handleLinkNcm(data);
  };

  const handleToggleIsBatchInsertion = () => {
    setValue("isBatchInsertion", !getValues("isBatchInsertion"));

    resetField("charToRemove");
    resetField("ncmsList");
    resetField("selectedNcm");
  };

  const handleChangeChar = (_: unknown, nv: any) => {
    if (nv === null) return;
    setValue("charToRemove", nv.label);
  };

  return (
    <Fragment>
      <CustomModal
        isOpen={isModalOpen}
        onClose={onRequestCloseModal}
        title="Adicionar NCM/Descrição"
        titleIcon="note_add"
        sx={{zIndex: 1201}}
      >
        <DialogContent>
          <Grid container rowGap={pxToRem(8)}>
            <CustomInputWrapper title="Tipo de inserção" xs={6}>
              <Controller
                name="isBatchInsertion"
                control={control}
                render={({field}) => (
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          role="switch"
                          data-testid="isBatchInsertion"
                          checked={field.value}
                          onChange={handleToggleIsBatchInsertion}
                        />
                      }
                      label="Em lote"
                      labelPlacement="start"
                    />
                  </FormGroup>
                )}
              />
            </CustomInputWrapper>
            {watch("isBatchInsertion") && (
              <Fragment>
                <CustomInputWrapper title="Caractere para remover" xs={6}>
                  <Controller
                    name="charToRemove"
                    control={control}
                    render={({field}) => (
                      <CustomAutoComplete
                        size="small"
                        name="charToRemove"
                        placeholder="NCM"
                        freeSolo
                        onInputChange={(e, v) => {
                          if (v === null) {
                            return;
                          }
                          setValue("charToRemove", v);
                        }}
                        value={field.value}
                        onChangeFn={handleChangeChar}
                        options={["/", ",", "_", "-", "|"].map((item, idx) => ({
                          id: idx,
                          label: item,
                        }))}
                      />
                    )}
                  />
                </CustomInputWrapper>

                <CustomInputWrapper title="Lista">
                  <Controller
                    name="ncmsList"
                    control={control}
                    render={({field}) => (
                      <TextField
                        multiline
                        rows={8}
                        data-testid="ncmsList"
                        name="ncmsList"
                        placeholder="lista de NCM's"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </CustomInputWrapper>
              </Fragment>
            )}

            {!watch("isBatchInsertion") && (
              <Fragment>
                <CustomInputWrapper title="NCM" xs={12}>
                  <Controller
                    name="selectedNcm"
                    control={control}
                    render={({field}) => (
                      <CustomAutoComplete
                        size="small"
                        name="selectedNcm"
                        placeholder="NCM"
                        isLoading={isLoadingNcmsOptions}
                        value={field.value}
                        onChangeFn={(e, value) =>
                          handleChangeLocalAutocomplete(e, value)
                        }
                        options={ncmOptions}
                        onOpen={
                          ncmOptions.length > 0
                            ? undefined
                            : () => getNcmsOptions(setNcmOptions, 1, "")
                        }
                        onInputChange={(e, value) => {
                          if (e !== null) {
                            setSearchNcm(value);
                            getNcmsOptions(setNcmOptions, 1, value);
                          }
                        }}
                        onBottomScroll={() =>
                          onBottomScrollNcms(setNcmOptions, searchNcm)
                        }
                        hasMorePagesToLoad={
                          ncmsPageState.currentPage < ncmsPageState.lastPage
                        }
                      />
                    )}
                  />
                </CustomInputWrapper>

                <CustomInputWrapper title="Descrição Da Mercadoria">
                  <Box
                    sx={{
                      overflowY: "scroll",
                      maxHeight: pxToRem(200),
                      [theme.breakpoints.up("md")]: {maxHeight: pxToRem(300)},
                      mb: pxToRem(15),
                    }}
                  >
                    <Typography component="pre" whiteSpace="pre-wrap">
                      {watch("selectedNcm")?.label}
                    </Typography>
                  </Box>
                </CustomInputWrapper>
              </Fragment>
            )}
          </Grid>
          <LinkOptions />
        </DialogContent>
        <ModalWrapperFooter>
          <Grid
            item
            xs={12}
            display="flex"
            columnGap={pxToRem(8)}
            justifyContent="flex-end"
          >
            <CustomButton
              size="medium"
              variant="outlined"
              onClickFn={onRequestCloseModal}
            >
              Cancelar
            </CustomButton>

            <CustomButton
              size="medium"
              disabled={isButtonDisabled}
              /*
              Alterei o método tradicional com o form pois, apesar do modal ser renderizado via "Portals",
              o form ainda fica aninhado, então ao submeter o form do modal, ele submete, também, o form principal.
              Nos testes preliminares, esse teste funcionou normalmente para este caso.
              */
              onClickFn={methods.handleSubmit(onSubmit)}
            >
              Concluir
            </CustomButton>
          </Grid>
        </ModalWrapperFooter>
      </CustomModal>
      {isConfirmModalOpen && (
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          title="Inserir NCM's em lote"
          subtitle="Essa ação removerá todos os NCM's adicionados anteriormente. Esta ação não pode ser desfeita!"
          titleIcon="warning"
          confirmButtonText="Confirmar"
          confirmButtonFn={() => {
            setIsConfirmModalOpen(false);
            handleLinkNcm(formData);
          }}
          onClose={() => setIsConfirmModalOpen(false)}
        />
      )}
    </Fragment>
  );
};

export default ModalAddNcm;
