import {Grid} from "@mui/material";
import DefaultCardStructure from "components/DefaultCardStructure";
import pxToRem from "hooks/usePxToRem";
import {useState} from "react";
import {useFormContext, Controller} from "react-hook-form";
import {CustomInputWrapper, CustomInput} from "ui";
import {calcConsideredWeight} from "views/ProcessDetails/components/ProcessLoad/utils/calculateConsideredWeight";

const ActivityHouseMeasurements = () => {
  const loadIndex = 0;
  const {watch, control, setValue} = useFormContext();
  const [temporaryValue, setTemporaryValue] = useState<{
    consideredWeight: number;
    cubeWeight: number;
  } | null>(null);

  const handleCalcWeights = () => {
    const loadData = calcConsideredWeight(
      watch(`processLoads[${loadIndex}].cubicMeters`) ?? 0,
      watch(`processLoads[${loadIndex}].grossWeight`) ?? 0,
    );

    setTemporaryValue(loadData);
    setValue(
      `processLoads[${loadIndex}].consideredWeight`,
      loadData.consideredWeight,
      {shouldDirty: true},
    );
    setValue(`processLoads[${loadIndex}].cubeWeight`, loadData.cubeWeight, {
      shouldDirty: true,
    });
  };

  return (
    <DefaultCardStructure
      title="Medidas House"
      hasDivider={false}
      isSubcard={false}
    >
      <Grid container mb={pxToRem(20)} columnSpacing={pxToRem(8)}>
        <CustomInputWrapper title="Peso Bruto - kg" xs={3}>
          <Controller
            name={`processLoads[${loadIndex}].grossWeight`}
            control={control}
            render={({field: {value}}) => (
              <CustomInput
                value={value ?? 0}
                name={`processLoads[${loadIndex}].grossWeight`}
                placeholder="Peso bruto"
                onChange={(e) => {
                  setValue(
                    `processLoads[${loadIndex}].grossWeight`,
                    +e.target.value,
                    {shouldDirty: true},
                  );
                  handleCalcWeights();
                }}
                size="small"
                type="number"
                inputProps={{
                  style: {
                    fontSize: pxToRem(14),
                  },
                  min: 0.0,
                  step: 0.001,
                }}
              />
            )}
          />
        </CustomInputWrapper>

        <CustomInputWrapper title="Metros Cúbicos" xs={3}>
          <Controller
            name={`processLoads[${loadIndex}].cubicMeters`}
            control={control}
            render={({field: {value}}) => (
              <CustomInput
                value={value ?? 0}
                name={`processLoads[${loadIndex}].cubicMeters`}
                placeholder="m³"
                onChange={(e) => {
                  setValue(
                    `processLoads[${loadIndex}].cubicMeters`,
                    +e.target.value,
                    {shouldDirty: true},
                  );
                  handleCalcWeights();
                }}
                size="small"
                type="number"
                inputProps={{
                  style: {
                    fontSize: pxToRem(14),
                  },
                  min: 0.0,
                  step: 0.001,
                }}
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Quant." xs={2} required>
          <Controller
            name={`processLoads[${loadIndex}].quantities`}
            control={control}
            render={({field: {value}}) => (
              <CustomInput
                value={value}
                name={`processLoads[${loadIndex}].quantities`}
                placeholder="m³"
                onChange={(e) => {
                  setValue(
                    `processLoads[${loadIndex}].quantities`,
                    +e.target.value,
                    {shouldDirty: true},
                  );
                }}
                size="small"
                type="number"
                inputProps={{
                  style: {
                    fontSize: pxToRem(14),
                  },
                  min: 0.0,
                  step: 0.001,
                }}
                required
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Peso Cubado - kg" xs={2} isDisabled>
          <Controller
            name={`processLoads[${loadIndex}].cubeWeight`}
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                disabled
                value={temporaryValue?.cubeWeight ?? value}
                name={`processLoads[${loadIndex}].cubeWeight`}
                placeholder="Peso cubado"
                onChange={onChange}
                size="small"
                type="number"
                inputProps={{
                  style: {
                    fontSize: pxToRem(14),
                  },
                  min: 0,
                  step: 0.001,
                }}
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Peso Considerado - kg" xs={2} isDisabled>
          <Controller
            name={`processLoads[${loadIndex}].consideredWeight`}
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                value={temporaryValue?.consideredWeight ?? value}
                disabled
                name={`processLoads[${loadIndex}].consideredWeight`}
                placeholder="Peso considerado"
                onChange={onChange}
                size="small"
                type="number"
                inputProps={{
                  style: {
                    fontSize: pxToRem(14),
                  },
                  min: 0,
                  step: 0.001,
                }}
              />
            )}
          />
        </CustomInputWrapper>
      </Grid>
    </DefaultCardStructure>
  );
};

export default ActivityHouseMeasurements;
