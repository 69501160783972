import {DialogContent} from "@mui/material";

import Composition from "./Composition";

import {CustomModal} from "components";

interface ModalCompositionProps {
  isModalOpen: boolean;
  onRequestCloseModal: () => void;
}

const ModalComposition = ({
  isModalOpen,
  onRequestCloseModal,
}: ModalCompositionProps) => {
  return (
    <CustomModal
      isOpen={isModalOpen}
      onClose={onRequestCloseModal}
      title="Composição Da Taxa"
      titleIcon="list_alt_add"
    >
      <DialogContent>
        <Composition />
      </DialogContent>
    </CustomModal>
  );
};

export default ModalComposition;
