import {combineReducers} from "redux";
import {loadingBarReducer} from "react-redux-loading-bar";

import toastReducer from "./toastReducer";
import tabPanelReducer from "./tabPanelReducer";
import loadingReducer from "./loadingReducer";
import drawerNotify from "./drawerNotify";
import chatReducer from "./chatReducer";
import demurrageReducer from "./demurrageReducer";
import processSelectedReducer from "./processSelectedReducer";
import companyReducer from "./companyReducer";
import filterReducer from "./filterReducer";
import alertReducer from "./alertReducer";
import loginReducer from "./loginReducer";
import courierReducer from "./courierReducer";
import deconsolidationReducer from "./deconsolidationReducer";
import modalReducer from "./modalReducer";
import processesReducer from "./processesReducer";
import apiFilterReducer from "./apiFilterReducer";
import sidebarReducer from "./sidebarReducer";
import loginFlowReducer from "./loginFlowReducer";
import userInfoReducer from "./userInfoReducer";
import alertMessageReducer from "./alertMessageReducer";
import modalDeleteSpecialRuleReducer from "./modalDeleteSpecialRuleReducer";
import messagesReducer from "./messagesReducer";
import dashboardFiltersReducer from "./dashboardFiltersReducer";
import tasksProjectReducer from "./tasksProjectReducer";
import activitiesReducer from "./activitiesReducer";

const rootReducer = combineReducers({
  stateToast: toastReducer,
  stateTabPanel: tabPanelReducer,
  stateLoading: loadingReducer,
  stateDrawerNotify: drawerNotify,
  stateChat: chatReducer,
  stateDemurrage: demurrageReducer,
  stateProcessSelected: processSelectedReducer,
  stateCompany: companyReducer,
  stateFilter: filterReducer,
  stateAlert: alertReducer,
  stateLogin: loginReducer,
  stateCourier: courierReducer,
  stateDeconsolidation: deconsolidationReducer,
  stateModal: modalReducer,
  stateProcessesReducer: processesReducer,
  stateApiFilterReducer: apiFilterReducer,
  stateSidebar: sidebarReducer,
  stateLoginFlowData: loginFlowReducer,
  stateUserInfo: userInfoReducer,
  stateAlertMessage: alertMessageReducer,
  loadingBar: loadingBarReducer,
  stateModalDeleteSpecialRuleReducer: modalDeleteSpecialRuleReducer,
  stateMessages: messagesReducer,
  stateDashboardFilter: dashboardFiltersReducer,
  stateTasksProjectReducer: tasksProjectReducer,
  stateActivitiesReducer: activitiesReducer,
});

export default rootReducer;
