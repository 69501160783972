import {ReactNode} from "react";
import {
  Box,
  Breakpoint,
  Chip,
  Dialog,
  Divider,
  Grid,
  Icon,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";

import {CustomIconButton} from "ui";
import pxToRem from "hooks/usePxToRem";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  titleIcon: string;
  children?: ReactNode;
  hideDivider?: boolean;
  maxWidth?: false | Breakpoint;
  subtitle?: string;
  dividerBadge?: string | ReactNode;
  paperWidth?: string;
  titleEndIcon?: ReactNode;
  dataTestId?: string;
  sx?: SxProps<Theme>;
  paperOverflow?: string;
};

const CustomModal = (props: Props) => {
  const {
    isOpen,
    onClose,
    title,
    titleIcon,
    children,
    hideDivider = false,
    maxWidth,
    subtitle,
    dividerBadge,
    paperWidth,
    titleEndIcon,
    dataTestId,
    sx,
    paperOverflow,
  } = props;

  const theme = useTheme();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      scroll="paper"
      maxWidth={maxWidth}
      data-testid={dataTestId}
      PaperProps={{
        sx: {width: paperWidth ?? "100%", overflow: paperOverflow ?? "auto"},
      }}
      sx={sx}
    >
      <Grid container p={pxToRem(20)}>
        <Grid
          item
          xs={10}
          display="flex"
          alignItems={"center"}
          gap={pxToRem(12)}
        >
          <Box
            sx={{
              height: pxToRem(36),
              width: pxToRem(36),
              borderRadius: pxToRem(5),
              backgroundColor: theme.palette.primary[50],
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon sx={{color: theme.palette.primary[700]}}>{titleIcon}</Icon>
          </Box>
          <Stack alignItems="flex-start">
            <Typography variant="h4">{title}</Typography>
            <Typography
              variant="subtitle2"
              sx={{color: theme.palette.text.secondary}}
            >
              {subtitle}
            </Typography>
          </Stack>
          {titleEndIcon && (
            <Stack
              alignItems="flex-start"
              alignSelf="flex-start"
              ml={pxToRem(-8)}
            >
              {titleEndIcon}
            </Stack>
          )}
        </Grid>
        <Grid
          item
          xs={2}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <CustomIconButton iconName="close" onClick={onClose} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Divider sx={{display: hideDivider ? "none" : ""}}>
            {dividerBadge && typeof dividerBadge === "string" ? (
              <Chip label={dividerBadge} color="primary" />
            ) : (
              dividerBadge
            )}
          </Divider>
        </Grid>
      </Grid>

      {children}
    </Dialog>
  );
};

export default CustomModal;
