export const mountEmailHtml = (editorText = "", originalMail = "") => {
  const regexToGetOriginalBodyTag = /<body(?:\s+[^>]+)?>/gi;

  const emailEditorHtml = editorText;
  const splitHtml = emailEditorHtml?.split("</style>");
  const bodyContentHtml = splitHtml?.pop();

  const doctype = `<!doctype html>`;

  const htmlOpenTag = `<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">`;

  const headOpenTag = `<head>`;
  const headContent = splitHtml?.join(`</style>`) + `</style>`;
  const headCloseTag = `</head>`;

  const bodyInitTag = originalMail?.match(regexToGetOriginalBodyTag)?.[0] ?? "";
  const bodyContent = bodyContentHtml;
  const bodyCloseTag = `</body>`;

  const htmlCloseTag = `</html>`;

  const htmlToSend =
    doctype +
    htmlOpenTag +
    headOpenTag +
    headContent +
    headCloseTag +
    bodyInitTag +
    bodyContent +
    bodyCloseTag +
    htmlCloseTag;

  return htmlToSend;
};
