const useValueOrEmpty = () => {
  const valueOrEmptyString = (value: string | undefined) => {
    return value ?? "";
  };

  const listOrEmptyArray = <T>(list: T[] | undefined): T[] => {
    return list ?? [];
  };

  return {valueOrEmptyString, listOrEmptyArray};
};

export default useValueOrEmpty;
