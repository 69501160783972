/* eslint-disable @typescript-eslint/no-explicit-any */
import {useSelector} from "react-redux";
import {useState} from "react";
import pxToRem from "hooks/usePxToRem";
import formatNameToInitialLetters from "util/formatNameToInitialLetters";

import {Avatar, Box, Stack, Tooltip, Typography} from "@mui/material";

import {CustomIconButton} from "ui";
import UserMenu from "./UserMenu";

import StyledPaper from "./UserCard.styles";

const UserCard = () => {
  const {
    userInfo: {user},
  } = useSelector((state: any) => state.stateUserInfo);
  const {name, avatar} = user;

  const {userInfo} = useSelector((state: any) => state.stateUserInfo);
  const {expandedMenu} = userInfo.settings;

  const [currentAnchorElement, setCurrentAnchorElement] =
    useState<HTMLElement | null>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCurrentAnchorElement(event.currentTarget);
  };

  const handleCloseUserMenu = () => setCurrentAnchorElement(null);

  return (
    <StyledPaper elevation={0} isSidebarOpen={expandedMenu}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="card__container"
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={pxToRem(8)}
          className="card__user-info"
        >
          {/* <Link to={`${expandedMenu ? `/user-details/${id}` : "#"}`}> */}
          <Avatar src={avatar} className="card__avatar">
            {formatNameToInitialLetters(name)}
          </Avatar>
          {/* </Link> */}

          {expandedMenu && (
            <Stack className="card__text" spacing={pxToRem(-2)}>
              <Tooltip title={name} placement="top" arrow>
                <Typography
                  variant="subtitle3"
                  component="strong"
                  className="card__username"
                >
                  {name}
                </Typography>
              </Tooltip>

              <Typography variant="placeholder2" className="card__role">
                Operacional
              </Typography>
            </Stack>
          )}
        </Stack>

        <Box className="card__menu-container">
          <CustomIconButton
            iconName="more_vert"
            className="card__icon-button"
            size="small"
            onClick={handleOpenUserMenu}
            disableRipple
          />

          <UserMenu
            currentAnchorElement={currentAnchorElement}
            onCloseMenuRequested={handleCloseUserMenu}
          />
        </Box>
      </Stack>
    </StyledPaper>
  );
};

export default UserCard;
