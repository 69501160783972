import {Grid, OutlinedInput} from "@mui/material";
import {DefaultModalFooter} from "components/CustomModal/components";
import pxToRem from "hooks/usePxToRem";
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {Fragment} from "react/jsx-runtime";
import {GetEquipmentsList} from "services/api_v2/Processes/Processes.service";
import {CustomInputWrapper, CustomButton, CustomAutocomplete} from "ui";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";
import useChangeDAteAndAutocomplete from "views/ProcessDetails/hooks/useChangeDAteAndAutocomplete";
import useHandleFieldLists from "views/ProcessDetails/hooks/useHandleFieldLists";
import {getTooltipText} from "views/ProcessDetails/utils/getTooltipText";

type Props = {
  handleCreateProcessEquipments: (data: any) => void;
  onClose: () => void;
};

const ModalNewEquipment = (props: Props) => {
  const {register, handleSubmit, control, setValue} = useForm({
    shouldUseNativeValidation: true,
  });
  const onSubmit = async (data) => {
    props.handleCreateProcessEquipments(data);
  };

  const [equipmentsOptions, setEquipmentsOptions] = useState(
    [] as AutoCompleteData[],
  );
  const [search, setSearch] = useState<string>();
  const formatOptionsFn = (data) => {
    return data?.data?.map((item) => ({
      label: `${item?.name} - ${item?.id}`,
      id: item?.id,
    }));
  };
  const {
    getOptions: getEquipmentsOptions,
    onBottomScroll: onBottomScrollEquipments,
    isLoadingOptions: isLoadingEquipmentsOptions,
  } = useHandleFieldLists(GetEquipmentsList, formatOptionsFn);

  const {mountOptions} = useChangeDAteAndAutocomplete();

  const handleChangeAutocomplete = (
    _: React.SyntheticEvent<Element, Event>,
    value: any,
    name: string,
  ) => {
    if (value === null) {
      setValue(name, {id: undefined, name: undefined}, {shouldDirty: true});
      return;
    }
    setValue(name, {id: value?.id, name: value?.label}, {shouldDirty: true});
  };

  return (
    <Fragment>
      <Grid
        container
        rowSpacing={pxToRem(20)}
        columnSpacing={pxToRem(8)}
        alignItems="flex-end"
        p={pxToRem(20)}
      >
        <CustomInputWrapper md={10} title="Tipo">
          <Controller
            name={`equipment`}
            control={control}
            render={({field: {value}}) => (
              <CustomAutocomplete
                name={`equipment`}
                placeholder="Equipamento"
                tooltipText={getTooltipText(value?.name)}
                loading={isLoadingEquipmentsOptions}
                options={mountOptions(value, equipmentsOptions)}
                value={value?.name ?? null}
                onOpen={
                  equipmentsOptions.length > 0
                    ? undefined
                    : () =>
                        getEquipmentsOptions(setEquipmentsOptions, 1, search)
                }
                onInputChange={(e, value) => {
                  if (e !== null) {
                    setSearch(value);
                    getEquipmentsOptions(setEquipmentsOptions, 1, search);
                  }
                }}
                getNextPage={() => {
                  onBottomScrollEquipments(setEquipmentsOptions, search);
                }}
                onChange={(e, value) => {
                  handleChangeAutocomplete(e, value, `equipment`);
                }}
                isOptionEqualToValue={(option, value) =>
                  value === option?.label
                }
              />
            )}
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={2} title="Quantidade">
          <OutlinedInput
            {...register("quantity", {
              required: "Insira a quantidade",
              min: 1,
            })}
            type="number"
            size="small"
            placeholder="Quantidade"
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={3} title="FT Master Origem">
          <OutlinedInput
            {...register("freeTimeMasterOrigin")}
            size="small"
            type="number"
            placeholder="FT Master Origem"
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={3} title="FT House Origem">
          <OutlinedInput
            {...register("freeTimeHouseOrigin")}
            size="small"
            type="number"
            placeholder="FT House Origem"
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={3} title="FT Master Destino">
          <OutlinedInput
            {...register("freeTimeMasterDestination")}
            size="small"
            type="number"
            placeholder="FT Master Destino"
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={3} title="FT House Destino">
          <OutlinedInput
            {...register("freeTimeHouseDestination")}
            size="small"
            type="number"
            placeholder="FT House Destino"
          />
        </CustomInputWrapper>
      </Grid>
      <DefaultModalFooter>
        <Grid item xs></Grid>
        <Grid item>
          <CustomButton onClickFn={props.onClose} variant="outlined">
            Cancelar
          </CustomButton>
        </Grid>
        <Grid item>
          <CustomButton onClickFn={() => handleSubmit(onSubmit)()}>
            Confirmar
          </CustomButton>
        </Grid>
      </DefaultModalFooter>
    </Fragment>
  );
};

export default ModalNewEquipment;
