import {
  Box,
  Chip,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
  Icon,
} from "@mui/material";
import {Icon as Iconify} from "@iconify/react";
import pxToRem from "hooks/usePxToRem";
import DOMPurify from "dompurify";
import {FormatedFollowUp, PostFollowUpType} from "../../ProcessFollowUpTypes";
import {format} from "date-fns";
import {CustomButton} from "ui";
import {Dispatch, SetStateAction, useState} from "react";
import {ConfirmModal} from "components";
import {useFormContext} from "react-hook-form";

type PropsFollowUpCard = {
  followUp: FormatedFollowUp;
  handleOpenModal: () => void;
  setCurrentFollowUp: Dispatch<SetStateAction<FormatedFollowUp>>;
  handleDeleteFollow: (
    id: number,
    followUpData: Partial<PostFollowUpType>,
  ) => Promise<void>;
};

const FollowUpCard = (props: PropsFollowUpCard) => {
  const theme = useTheme();
  const {watch} = useFormContext();
  const {followUp, handleOpenModal, setCurrentFollowUp, handleDeleteFollow} =
    props;

  const cancelledProcess = watch("agencyingSituation") === "Cancelado";

  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);

  const handleModalOpenAndData = () => {
    handleOpenModal();
    setCurrentFollowUp(followUp);
  };

  return (
    <>
      {confirmModalIsOpen && (
        <ConfirmModal
          isOpen={confirmModalIsOpen}
          title="Apagar Follow-up"
          subtitle="Tem certeza que deseja apagar o follow-up?"
          titleIcon="delete"
          confirmButtonText="Apagar"
          confirmButtonFn={() => {
            handleDeleteFollow(followUp.id, {isActive: false});
            setConfirmModalIsOpen(false);
          }}
          onClose={() => setConfirmModalIsOpen(false)}
        />
      )}
      <Paper
        sx={{
          padding: `${pxToRem(22)} ${pxToRem(24)}`,
          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: `${pxToRem(5)}`,
          borderLeft: `${pxToRem(5)} solid ${theme.palette.primary.main}`,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          component="header"
          sx={{marginBottom: pxToRem(20)}}
        >
          <Stack direction="row" alignItems="center" spacing={pxToRem(12)}>
            <Box
              sx={{
                padding: pxToRem(8),
                backgroundColor: theme.palette.primary[50],
                color: theme.palette.primary[700],
                borderRadius: "50%",
              }}
            >
              <Tooltip
                placement="top"
                arrow
                title={followUp?.isPrivate ? "Privado" : "Público"}
              >
                <Icon sx={{fontSize: pxToRem(20)}}>
                  {followUp?.isPrivate ? "lock" : "lock_open"}
                </Icon>
              </Tooltip>
            </Box>

            <Typography
              component="strong"
              variant="subtitle1"
              sx={{fontWeight: 600}}
            >
              Atualização
            </Typography>
          </Stack>

          <Stack direction="row" spacing={pxToRem(8)}>
            <Chip
              label={format(new Date(followUp?.createdAt), "dd/MM/yyyy")}
              icon={<Iconify icon="ci:calendar" fontSize={pxToRem(16)} />}
              color="secondary"
              size="small"
              sx={{
                "& .MuiChip-icon": {
                  margin: 0,
                },

                gap: pxToRem(4),
              }}
            />

            <Chip
              label={format(new Date(followUp?.createdAt), "HH:mm")}
              icon={
                <Iconify
                  icon="heroicons-outline:clock"
                  fontSize={pxToRem(16)}
                />
              }
              color="secondary"
              size="small"
              sx={{
                "& .MuiChip-icon": {
                  margin: 0,
                },

                gap: pxToRem(4),
              }}
            />
          </Stack>
        </Stack>

        <Box
          component="pre"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(followUp?.content),
          }}
          sx={{
            whiteSpace: "pre-wrap",
            fontFamily: "poppins, sans-serif",
            fontSize: pxToRem(14),
          }}
        />
        <Stack direction="row" spacing={pxToRem(8)} justifyContent="flex-end">
          <CustomButton
            variant="outlined"
            destructive
            size="small"
            onClickFn={() => setConfirmModalIsOpen(true)}
            disabled={cancelledProcess}
            sx={{alignSelf: "flex-end"}}
          >
            Apagar
          </CustomButton>
          <CustomButton
            variant="outlined"
            size="small"
            onClickFn={handleModalOpenAndData}
            disabled={cancelledProcess}
            sx={{alignSelf: "flex-end"}}
          >
            Editar
          </CustomButton>
        </Stack>
      </Paper>
    </>
  );
};

export default FollowUpCard;
