import {Dialog, Stack} from "@mui/material";
import pxToRem from "hooks/usePxToRem";

import {ModalActions, ModalContent, ModalHeader} from "./components";
import ModalVersionUpdatesData from "./ModalVersionUpdatesData";

const ModalVersionUpdates = () => {
  const {versionUpdates, page, setPage, open, handleClose} =
    ModalVersionUpdatesData();

  return (
    <Dialog open={open}>
      <Stack
        gap={pxToRem(24)}
        sx={{
          width: pxToRem(548),
          height: pxToRem(641),
        }}
      >
        <ModalHeader handleClose={handleClose} />
        <ModalContent
          versionUpdatesData={versionUpdates}
          page={page}
          setPage={setPage}
        />
        <ModalActions
          versionUpdatesData={versionUpdates}
          page={page}
          setPage={setPage}
          handleClose={handleClose}
        />
      </Stack>
    </Dialog>
  );
};

export default ModalVersionUpdates;
