import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ptBR from "date-fns/locale/pt-BR";
import pxToRem from "hooks/usePxToRem";
import {useState} from "react";
import {useFormContext, UseFormSetValue} from "react-hook-form";
import {
  getDateMinusTimezoneAdapted,
  getFormattedDateFromValue,
} from "util/getISODate";
("date-fns/locale/de");

type Props = {
  value: any;
  name: string;
  title?: string;
  disabled?: boolean;
  required?: boolean;
  errorName?: string;
  setValue?: UseFormSetValue<any>;
};

const ProcessDatePicker = (props: Props) => {
  const {
    value,
    name,
    title = name,
    disabled = false,
    required = false,
    errorName,
    setValue: customSetValue,
  } = props;
  const {setValue, setError, clearErrors} = useFormContext();
  const [hasError, setHasError] = useState(false);
  const ERROR_NAME = "processDate";
  const [helperTextOnInvalid, setHelperTextOnInvalid] = useState(false);

  const conditionalSetValue = (
    name: string,
    value: any,
    options?: Partial<{
      shouldValidate: boolean;
      shouldDirty: boolean;
      shouldTouch: boolean;
    }>,
  ) => {
    if (customSetValue) {
      customSetValue(name, value, options);
    } else {
      setValue(name, value, options);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <DatePicker
        slotProps={{
          field: {
            clearable: true,
            onClear: () => conditionalSetValue(name, null, {shouldDirty: true}),
          },
          textField: {
            size: "small",
            error: hasError,
            inputProps: {
              style: {
                fontSize: pxToRem(14),
              },
            },
            onBlur: () => setHelperTextOnInvalid(false),
            required: required,
            helperText: helperTextOnInvalid && "Preencha este campo.",
            onInvalid: () => setHelperTextOnInvalid(true),
          },
        }}
        value={
          !!value && value?.toString() !== "Invalid Date"
            ? getDateMinusTimezoneAdapted(new Date(value)?.toISOString())
            : null
        }
        format="dd/MM/yyyy"
        onError={(value) => {
          if (value?.toString() === "invalidDate") {
            setHasError(true);
            setError(`${ERROR_NAME}.${errorName ?? name}`, {
              message: title,
            });
          } else {
            setHasError(false);
          }
        }}
        onChange={(newValue) => {
          if (
            newValue &&
            typeof newValue !== "string" &&
            newValue?.toString() !== "Invalid Date"
          ) {
            const date =
              !!newValue &&
              getFormattedDateFromValue(newValue?.toISOString(), "yyyy-MM-dd");
            conditionalSetValue(name, date, {shouldDirty: true});
            clearErrors(`${ERROR_NAME}.${errorName ?? name}`);
          } else if (newValue === null) {
            conditionalSetValue(name, null, {shouldDirty: true});
            clearErrors(`${ERROR_NAME}.${errorName ?? name}`);
          }
        }}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
};

export default ProcessDatePicker;
