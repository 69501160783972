import useAlertMessage from "hooks/useAlertMessage";
import {Dispatch, SetStateAction, useState} from "react";
import {getPorts} from "services/api_v2/Tasks/tasks.service";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";

const useHandleBoardingOptions = () => {
  const [showAlert] = useAlertMessage();

  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [pageState, setPageState] = useState<{
    currentPage: number;
    lastPage: number;
  }>({currentPage: 1, lastPage: 1});

  const getOptions = async (
    setStateFn: Dispatch<SetStateAction<AutoCompleteData[]>>,
    search = "",
    page = 1,
    limit = 50,
  ) => {
    try {
      setIsLoadingOptions(true);

      const {data} = await getPorts(page, limit, search);
      const newOptions = data.data?.map((item) => ({
        label: `${item?.name} - ${item?.abbreviation ?? ""}`,
        id: item.id,
        additional: item?.typeLocation,
      }));
      setPageState((state) => ({
        ...state,
        lastPage: data.meta?.lastPage,
        currentPage: data.meta?.currentPage,
      }));

      if (search !== "" && page === 1) {
        setStateFn([...newOptions]);
      } else {
        setStateFn((state) => {
          return [...state, ...newOptions];
        });
      }
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    } finally {
      setIsLoadingOptions(false);
    }
  };

  const onBottomScroll = (
    setStateFn: Dispatch<SetStateAction<AutoCompleteData[]>>,
    search = "",
  ) => {
    if (pageState.currentPage <= pageState.lastPage) {
      getOptions(setStateFn, search, pageState.currentPage + 1);
      return true;
    }
  };

  return {getOptions, onBottomScroll, isLoadingOptions, pageState};
};

export default useHandleBoardingOptions;
