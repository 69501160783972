import {memo} from "react";
import pxToRem from "hooks/usePxToRem";

import {Stack} from "@mui/material";

import {TaxesListing} from "./components";
import {DefaultCardStructure} from "components";
import {CustomInput, CustomInputWrapper} from "ui";
import {useFormContext} from "react-hook-form";

const ActivityOriginAgentTaxes = () => {
  const {watch} = useFormContext();

  const originAgentTaxes = watch("processCharges")?.filter(
    (item) => item?.paymentPerson?.id === watch("originAgent")?.id,
  );

  const totalPayment = originAgentTaxes
    .reduce(
      (sum, item) => sum + Number(item.valuePaymentTotal?.replace(",", ".")),
      0,
    )
    ?.toFixed(2);

  const totalReceipt = originAgentTaxes
    .reduce(
      (sum, item) => sum + Number(item.valueReceiptTotal?.replace(",", ".")),
      0,
    )
    ?.toFixed(2);

  return (
    <DefaultCardStructure
      title="Taxas Agente de Origem"
      linkId="taxes"
      hasDivider={false}
      titleMarginBottom={5}
      sx={{mt: pxToRem(30)}}
    >
      <Stack sx={{gap: pxToRem(20)}}>
        <TaxesListing />
      </Stack>
      <DefaultCardStructure title="Totais" hasDivider={false} isSubcard>
        <Stack direction="row" gap={pxToRem(12)}>
          <CustomInputWrapper title="Pagamento total">
            <CustomInput disabled size="small" value={totalPayment} />
          </CustomInputWrapper>
          <CustomInputWrapper title="Recebimento total">
            <CustomInput disabled size="small" value={totalReceipt} />
          </CustomInputWrapper>
        </Stack>
      </DefaultCardStructure>
    </DefaultCardStructure>
  );
};

export default memo(ActivityOriginAgentTaxes);
