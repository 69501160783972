import {Typography} from "@mui/material";

import StyledNavLink from "./NavLink.styles";

interface NavLinkProps {
  name: string;
  linkTo: string;
}

const NavLink = ({name, linkTo}: NavLinkProps) => {
  return (
    <StyledNavLink to={`#${linkTo}`} smooth activeClassName="active">
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 500,
          textTransform: "capitalize",
        }}
      >
        {name}
      </Typography>
    </StyledNavLink>
  );
};

export default NavLink;
