import {AutocompleteWithPermanentPlaceholder} from "components";
import useMessageGroups from "./useMessageGroups";
import {SyntheticEvent} from "react";

interface MessageGroupsAutocompleteProps {
  value: any;
  handleMessageGroupChange: (
    event: SyntheticEvent<Element, Event>,
    newValue: any,
  ) => void;
  disabled?: boolean;
  required?: boolean;
  name?: string;
}

const MessageGroupsAutocomplete = (props: MessageGroupsAutocompleteProps) => {
  const {
    value,
    handleMessageGroupChange,
    disabled,
    required = value?.length === 0,
    name = "defaultMessageGroup",
  } = props;

  const {
    messageGroups,
    getAllMessageGroups,
    onMessageGroupBottomScroll,
    messageGroupsPagination,
    messageGroupsInput,
    onTypeMessageGroups,
  } = useMessageGroups();

  return (
    <AutocompleteWithPermanentPlaceholder
      name={name}
      permanentPlaceholder={"Grupos de contato"}
      sizeOfPlaceholder={12}
      multiple
      value={value}
      onChange={handleMessageGroupChange}
      disabled={disabled}
      onOpen={
        messageGroups.length > 0 ? undefined : () => getAllMessageGroups(1)
      }
      options={messageGroups}
      onBottomScroll={onMessageGroupBottomScroll}
      hasMorePagesToLoad={
        messageGroupsPagination?.currentPage < messageGroupsPagination?.lastPage
      }
      required={required}
      inputValue={messageGroupsInput}
      fnOnInputChange={onTypeMessageGroups}
      tooltipOff
    />
  );
};

export default MessageGroupsAutocomplete;
