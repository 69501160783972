import pxToRem from "hooks/usePxToRem";
import {useExternalContacts, useResponsibleNextContacts} from "./hooks";

import {Stack} from "@mui/material";

import {CustomAccordion, CustomButton, CustomIconButton} from "ui";
import ProcessContactTable from "./ProcessContactTable";
import {ConfirmModal, DefaultCardStructure} from "components";
import {useFormContext} from "react-hook-form";
import {
  ExternalContactData,
  ResponsibleNextContactData,
} from "./Contacts.types";
import {useState} from "react";
import {ModalLinkContactToProcess} from "./components";
import {
  DeleteMovimentationContacts,
  DeleteResponsibles,
} from "services/api_v2/Contacts/Contacts.service";
import useToast from "hooks/useToast";
import useAlertMessage from "hooks/useAlertMessage";
import {AxiosError} from "axios";
import {DefaultProcessDetails} from "views/ProcessDetails/Process.types";

type Props = {
  refetchProcess: () => Promise<DefaultProcessDetails | undefined>;
};

const Contacts = (props: Props) => {
  const {columnsData} = useExternalContacts();
  const {responsibleNextContactsTableColumnsData} =
    useResponsibleNextContacts();
  const [showAlert] = useAlertMessage();
  const [showToast] = useToast();

  const {watch} = useFormContext();
  const cancelledProcess = watch("agencyingSituation") === "Cancelado";

  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [responsiblesTableData, setResponsiblesTableData] = useState(
    watch("responsibles"),
  );
  const [contactsTableData, setContactsTableData] = useState(
    watch("externalContacts"),
  );

  const [responsibleToDeleteId, setResponsibleToDeleteId] = useState<number>();
  const [contactToDeleteId, setContactToDeleteId] = useState<number>();
  const [objectToDelete, setObjectToDelete] = useState<
    "responsible" | "externaContact" | null
  >(null);

  const handleDeleteExternalContact = (contact: ExternalContactData) => {
    setContactToDeleteId(+contact.id);
    setObjectToDelete("externaContact");
    setConfirmModalIsOpen(true);
  };

  const deleteResponsible = async () => {
    try {
      if (objectToDelete === "externaContact" && contactToDeleteId) {
        await DeleteMovimentationContacts(contactToDeleteId);
      }

      if (objectToDelete === "responsible" && responsibleToDeleteId) {
        await DeleteResponsibles(responsibleToDeleteId);
      }
      const result = await props.refetchProcess();
      setResponsiblesTableData(result?.responsibles ?? []);
      setContactsTableData(result?.externalContacts ?? []);
      setConfirmModalIsOpen(false);
      showToast("Contato excluído com sucesso!", "success");
    } catch (err) {
      const error = err as AxiosError;
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    }
  };

  const handleDeleteResponsible = async (
    responsibleId: ResponsibleNextContactData,
  ) => {
    setObjectToDelete("responsible");
    setResponsibleToDeleteId(+responsibleId.id);
    setConfirmModalIsOpen(true);
  };

  const handleConfirmDeleteResponsible = async () => {
    await deleteResponsible();
  };

  const [modalToLinkContactIsOpen, setModalToLinkIsOpen] = useState(false);

  const handleOpenModal = () => {
    setModalToLinkIsOpen(true);
  };

  const handleCloseModal = async (refetch = false) => {
    setModalToLinkIsOpen(false);
    if (refetch) {
      const result = await props.refetchProcess();
      setResponsiblesTableData(result?.responsibles ?? []);
      setContactsTableData(result?.externalContacts ?? []);
    }
  };

  return (
    <>
      {modalToLinkContactIsOpen && (
        <ModalLinkContactToProcess
          modalIsOpen={modalToLinkContactIsOpen}
          handleCloseModal={handleCloseModal}
        />
      )}
      {confirmModalIsOpen && (
        <ConfirmModal
          isOpen={confirmModalIsOpen}
          title="Remover contato do processo"
          subtitle="Essa ação não pode ser desfeita!"
          confirmButtonFn={() => handleConfirmDeleteResponsible()}
          onClose={() => setConfirmModalIsOpen(false)}
        />
      )}
      <DefaultCardStructure
        title="Contatos do processo"
        isSubcard
        hasDivider={false}
        button={
          <CustomButton onClickFn={handleOpenModal} disabled={cancelledProcess}>
            Incluir Contato
          </CustomButton>
        }
      >
        <Stack px={pxToRem(1)}>
          <CustomAccordion
            title="Responsáveis Next"
            titleStyle={{fontSize: pxToRem(16), fontWeight: 400}}
          >
            <ProcessContactTable
              columns={responsibleNextContactsTableColumnsData}
              data={
                responsiblesTableData as unknown as ResponsibleNextContactData[]
              }
              // Inclusão de Responsáveis Next não será habilitado neste momento.
              //enableRowActions
              // renderRowActions={({row}) => (
              //   <CustomIconButton
              //     color="error"
              //     size="small"
              //     iconName="delete"
              //     tooltipText="Excluir responsável Next"
              //     onClick={() => handleDeleteResponsible(row.original)}
              //   />
              // )}
            />
          </CustomAccordion>

          <CustomAccordion
            title="Contatos externos"
            titleStyle={{fontSize: pxToRem(16), fontWeight: 400}}
          >
            <ProcessContactTable
              columns={columnsData}
              data={contactsTableData as unknown as ExternalContactData[]}
              enableRowActions
              renderRowActions={({row}) => (
                <CustomIconButton
                  disabled={cancelledProcess}
                  color="error"
                  size="small"
                  iconName="delete"
                  tooltipText="Excluir contato externo"
                  onClick={() => handleDeleteExternalContact(row.original)}
                />
              )}
            />
          </CustomAccordion>
        </Stack>
      </DefaultCardStructure>
    </>
  );
};

export default Contacts;
