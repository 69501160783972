import {Controller, useFieldArray, useFormContext} from "react-hook-form";
import {
  CustomAutocomplete,
  CustomInput,
  CustomInputWrapper,
  CustomSelect,
} from "ui";
import {getTooltipText} from "views/ProcessDetails/utils/getTooltipText";
import {useState} from "react";
import useChangeDAteAndAutocomplete from "views/ProcessDetails/hooks/useChangeDAteAndAutocomplete";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";
import useHandleFieldLists from "views/ProcessDetails/hooks/useHandleFieldLists";
import {
  GetIncotermsList,
  GetTransportCompaniesList,
} from "services/api_v2/Processes/Processes.service";
import {
  TypePath as TypePathEnum,
  ModalityPickup as ModalityPickupEnum,
  TypeLoad as TypeLoadEnum,
  ModalityDelivery as ModalityDeliveryEnum,
} from "util/globalEnums";
import {getPorts} from "services/api_v2/Tasks/tasks.service";
import useHandleBoardingOptions from "./components/ProcessBoarding/useHandleBoardingOptions";
import {
  GetPersonsByTypes,
  PersonTypes,
} from "services/api_v2/Persons/Persons.service";
import {DefaultProcessDetails} from "./Process.types";
import {
  getDestinationVoyageIndex,
  getInitialVoyageIndex,
} from "./utils/getVoyageIndex";
import {ProcessDatePicker} from "./components";

type Props = {
  xs?: number;
  title?: string;
};

const formatTransportCompanyFn = (data) => {
  return data?.data?.map((item) => ({
    label: `${item?.person?.name} - ${item?.idPerson}`,
    id: item?.person?.id,
  }));
};
const formatOptionsFn = (data) => {
  return data?.data?.map((item) => ({
    label: `${item?.name} - ${item?.id}`,
    id: item?.id,
  }));
};
const Origin = (props: Props) => {
  const {xs = 2, title = "Porto de origem"} = props;
  const {control} = useFormContext();
  const [boardingOptions, setBoardingOptions] = useState(
    [] as AutoCompleteData[],
  );
  const [search, setSearch] = useState("");

  const {getOptions, onBottomScroll, isLoadingOptions} =
    useHandleBoardingOptions();
  const {handleChangeAutocomplete, mountOptions} =
    useChangeDAteAndAutocomplete();

  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name="origin"
        control={control}
        render={({field: {value}}) => (
          <CustomAutocomplete
            optionSecondaryContent
            name={`origin`}
            placeholder="Porto de origem"
            tooltipText={getTooltipText(value?.name)}
            loading={isLoadingOptions}
            options={mountOptions(value, boardingOptions)}
            value={value?.name ?? ""}
            onOpen={
              boardingOptions.length > 0
                ? undefined
                : () => getOptions(setBoardingOptions, search)
            }
            onInputChange={(e, value) => {
              if (e !== null) {
                setSearch(value);
                getOptions(setBoardingOptions, value);
              }
            }}
            getNextPage={() => {
              onBottomScroll(setBoardingOptions, search);
            }}
            onChange={(e, value) => {
              handleChangeAutocomplete(e, value, "origin");
            }}
            isOptionEqualToValue={(option, value) => value === option?.label}
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const Destination = (props: Props) => {
  const {xs = 2, title = "Destino"} = props;
  const {control} = useFormContext();
  const [boardingOptions, setBoardingOptions] = useState(
    [] as AutoCompleteData[],
  );
  const [search, setSearch] = useState("");

  const {getOptions, onBottomScroll, isLoadingOptions} =
    useHandleBoardingOptions();
  const {handleChangeAutocomplete, mountOptions} =
    useChangeDAteAndAutocomplete();
  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name="destination"
        control={control}
        render={({field: {value}}) => (
          <CustomAutocomplete
            optionSecondaryContent
            name="destination"
            placeholder="Destino"
            tooltipText={getTooltipText(value?.name)}
            loading={isLoadingOptions}
            options={mountOptions(value, boardingOptions)}
            value={value?.name ?? ""}
            onOpen={
              boardingOptions.length > 0
                ? undefined
                : () => getOptions(setBoardingOptions, search)
            }
            onInputChange={(e, value) => {
              if (e !== null) {
                setSearch(value);
                getOptions(setBoardingOptions, value);
              }
            }}
            getNextPage={() => {
              onBottomScroll(setBoardingOptions, search);
            }}
            onChange={(e, value) => {
              handleChangeAutocomplete(e, value, "destination");
            }}
            isOptionEqualToValue={(option, value) => value === option?.label}
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const FinalDestination = (props: Props) => {
  const {xs = 2, title = "Destino Final"} = props;
  const {control} = useFormContext();
  const [boardingOptions, setBoardingOptions] = useState(
    [] as AutoCompleteData[],
  );
  const [search, setSearch] = useState("");

  const {getOptions, onBottomScroll, isLoadingOptions} =
    useHandleBoardingOptions();
  const {handleChangeAutocomplete, mountOptions} =
    useChangeDAteAndAutocomplete();
  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name="finalDestination"
        control={control}
        render={({field: {value}}) => (
          <CustomAutocomplete
            optionSecondaryContent
            name="finalDestination"
            placeholder="Destino"
            tooltipText={getTooltipText(value?.name)}
            loading={isLoadingOptions}
            options={mountOptions(value, boardingOptions)}
            value={value?.name ?? ""}
            onOpen={
              boardingOptions.length > 0
                ? undefined
                : () => getOptions(setBoardingOptions, search)
            }
            onInputChange={(e, value) => {
              if (e !== null) {
                setSearch(value);
                getOptions(setBoardingOptions, value);
              }
            }}
            getNextPage={() => {
              onBottomScroll(setBoardingOptions, search);
            }}
            onChange={(e, value) => {
              handleChangeAutocomplete(e, value, "finalDestination");
            }}
            isOptionEqualToValue={(option, value) => value === option?.label}
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const TT = (props: Props) => {
  const {xs = 2, title = "TT"} = props;
  const {control, setValue} = useFormContext();

  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name="transitTime"
        control={control}
        render={({field: {value}}) => (
          <CustomInput
            name="transitTime"
            placeholder="TT"
            value={value}
            onChange={(e) =>
              setValue(`transitTime`, +e.target.value, {
                shouldDirty: true,
              })
            }
            size="small"
            type="number"
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const PlacePickup = (props: Props) => {
  const {xs = 2, title = "Local de Coleta Origem"} = props;
  const {control, watch} = useFormContext();
  const mainVoyageIndex = watch("voyages")?.findIndex(
    (item) => item?.typeVoyage?.name === "Embarque inicial",
  );

  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name={`voyages[${mainVoyageIndex}].placePickup`}
        control={control}
        render={({field: {onChange, value}}) => (
          <CustomInput
            name={`voyages[${mainVoyageIndex}].placePickup`}
            placeholder="Local coleta"
            value={value ?? ""}
            onChange={onChange}
            size="small"
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const BookingNumber = (props: Props) => {
  const {xs = 2, title = "N° Booking"} = props;
  const {control} = useFormContext();
  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name="bookingNumber"
        control={control}
        render={({field: {onChange, value}}) => (
          <CustomInput
            name="bookingNumber"
            placeholder="N° booking"
            value={value}
            onChange={onChange}
            size="small"
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const Incoterm = (props: Props) => {
  const {xs = 2, title = "Incoterm"} = props;
  const {control} = useFormContext();
  const [incotermOptions, setIncotermOptions] = useState(
    [] as AutoCompleteData[],
  );
  const [search, setSearch] = useState("");

  const {
    getOptions: getIncotermOptions,
    onBottomScroll: onBottomScrollInconterm,
    isLoadingOptions: isLoadingIncotermOptions,
  } = useHandleFieldLists(GetIncotermsList);

  const {handleChangeAutocomplete, mountOptions} =
    useChangeDAteAndAutocomplete();

  return (
    <CustomInputWrapper title={title} xs={xs} isDisabled>
      <Controller
        name="incoterm"
        control={control}
        render={({field: {value}}) => (
          <CustomAutocomplete
            optionSecondaryContent
            name="incoterm"
            placeholder="Destino"
            tooltipText={getTooltipText(value?.name)}
            loading={isLoadingIncotermOptions}
            options={mountOptions(value, incotermOptions)}
            value={value?.name ?? ""}
            onOpen={
              incotermOptions.length > 0
                ? undefined
                : () => getIncotermOptions(setIncotermOptions, 1, search)
            }
            onInputChange={(e, value) => {
              if (e !== null) {
                setSearch(value);
                getIncotermOptions(setIncotermOptions, 1, search);
              }
            }}
            getNextPage={() => {
              onBottomScrollInconterm(setIncotermOptions, search);
            }}
            onChange={(e, value) => {
              handleChangeAutocomplete(e, value, "incoterm");
            }}
            isOptionEqualToValue={(option, value) => value === option?.label}
            disabled
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const TransportCompany = (props: Props) => {
  const {xs = 2, title = "Companhia de Transporte"} = props;
  const {control} = useFormContext();
  const [transportCompanyOptions, setTransportCompanyOptions] = useState(
    [] as AutoCompleteData[],
  );

  const [search, setSearch] = useState("");

  const {
    getOptions: getTransportCompanyOptions,
    onBottomScroll: onBottomScrollTransportCompany,
    isLoadingOptions: isLoadingTransportCompanyOptions,
  } = useHandleFieldLists(GetTransportCompaniesList, formatTransportCompanyFn);

  const {handleChangeAutocomplete, mountOptions} =
    useChangeDAteAndAutocomplete();
  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name="transportCompany"
        control={control}
        render={({field: {value}}) => (
          <CustomAutocomplete
            optionSecondaryContent
            name="transportCompany"
            placeholder="Companhia"
            tooltipText={getTooltipText(value?.name)}
            loading={isLoadingTransportCompanyOptions}
            options={mountOptions(value, transportCompanyOptions)}
            value={value?.name ?? null}
            onOpen={
              transportCompanyOptions.length > 0
                ? undefined
                : () =>
                    getTransportCompanyOptions(
                      setTransportCompanyOptions,
                      1,
                      search,
                    )
            }
            onInputChange={(e, value) => {
              if (e !== null) {
                setSearch(value);
                getTransportCompanyOptions(
                  setTransportCompanyOptions,
                  1,
                  value,
                );
              }
            }}
            getNextPage={() => {
              onBottomScrollTransportCompany(
                setTransportCompanyOptions,
                search,
              );
            }}
            onChange={(e, value) => {
              handleChangeAutocomplete(e, value, "transportCompany");
            }}
            isOptionEqualToValue={(option, value) => value === option?.label}
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const TypePath = (props: Props) => {
  const {xs = 2, title = "Tipo de Rota"} = props;
  const {control} = useFormContext();

  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name="typePath"
        control={control}
        render={({field: {onChange, value}}) => (
          <CustomSelect
            content={Object.values(TypePathEnum)}
            value={value}
            name="typePath"
            onChange={onChange}
            required
            size="small"
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const TypeLoad = (props: Props) => {
  const {xs = 2, title = "Modalidade"} = props;
  const {control} = useFormContext();

  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name={`processLoads[0].typeLoad`}
        control={control}
        render={({field: {onChange, value}}) => (
          <CustomSelect
            name={`processLoads[0].typeLoad`}
            value={value}
            onChange={onChange}
            content={Object.values(TypeLoadEnum)}
            size="small"
            disabled
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const Path = (props: Props) => {
  const {xs = 2, title = "Rota"} = props;
  const {control} = useFormContext();

  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name="path"
        control={control}
        render={({field: {onChange, value}}) => (
          <CustomInput
            size="small"
            name="path"
            placeholder="Rota"
            value={value}
            onChange={onChange}
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const DateEstimatedReadinessCargo = (props: Props) => {
  const {xs = 2, title = "Previsão de prontidão"} = props;
  const {control} = useFormContext();

  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name="dateEstimatedReadinessCargo"
        control={control}
        render={({field: {value}}) => (
          <ProcessDatePicker value={value} name="dateEstimatedReadinessCargo" />
        )}
      />
    </CustomInputWrapper>
  );
};

const DateReadinessCargo = (props: Props) => {
  const {xs = 2, title = "Confirmação de prontidão"} = props;
  const {control} = useFormContext();

  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name="dateReadinessCargo"
        control={control}
        render={({field: {value}}) => (
          <ProcessDatePicker
            value={value}
            name="dateReadinessCargo"
            title="Confirmação de prontidão"
          />
        )}
      />
    </CustomInputWrapper>
  );
};

/*
  O ETD do boarding é o campo ETA da viagem do 
  tipo "Embarque inicial"
*/
const ETD = (props: Props) => {
  const {xs = 2, title = "ETD"} = props;
  const {control} = useFormContext<DefaultProcessDetails>();

  const {fields} = useFieldArray({
    name: "voyages",
    keyName: "formId",
    control: control,
  });

  const getVoyageIndex = () => {
    return getInitialVoyageIndex(fields);
  };

  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name={`voyages[${getVoyageIndex()}].estimatedCargoLoad` as any}
        control={control}
        render={({field: {value}}) => (
          <ProcessDatePicker
            value={value}
            name={`voyages[${getVoyageIndex()}].estimatedCargoLoad` as any}
            title="ETD"
          />
        )}
      />
    </CustomInputWrapper>
  );
};

/*
  O ETA do boarding é o campo ETA da viagem do 
  tipo "Transbordo/Escala" com destino = destino do boarding
*/
const ETA = (props: Props) => {
  const {xs = 2, title = "ETA"} = props;
  const {control, getValues} = useFormContext<DefaultProcessDetails>();

  const {fields} = useFieldArray({
    name: "voyages",
    keyName: "formId",
    control: control,
  });
  const idBoardingDestination = getValues("destination")?.id;

  const getVoyageIndex = () => {
    return getDestinationVoyageIndex(idBoardingDestination, fields);
  };

  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name={`voyages[${getVoyageIndex()}].estimatedCargoUnload` as any}
        control={control}
        render={({field: {value}}) => (
          <ProcessDatePicker
            value={value}
            name={`voyages[${getVoyageIndex()}].estimatedCargoUnload` as any}
            title="ETA"
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const ModalityPickup = (props: Props) => {
  const {xs = 2, title = "Modalidade de coleta"} = props;
  const {control} = useFormContext();

  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name="modalityPickup"
        control={control}
        render={({field: {onChange, value}}) => (
          <CustomSelect
            content={Object.values(ModalityPickupEnum)}
            value={value}
            name="modalityPickup"
            onChange={onChange}
            required
            size="small"
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const ModalityDelivery = (props: Props) => {
  const {xs = 2, title = "Modalidade de entrega"} = props;
  const {control} = useFormContext();

  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name="modalityDelivery"
        control={control}
        render={({field: {onChange, value}}) => (
          <CustomSelect
            content={Object.values(ModalityDeliveryEnum)}
            value={value}
            name="modalityDelivery"
            onChange={onChange}
            required
            size="small"
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const DateEstimatedDelivery = (props: Props) => {
  const {xs = 2, title = "Data est. de Entrega"} = props;
  const {control} = useFormContext();

  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name="dateEstimatedDelivery"
        control={control}
        render={({field: {value}}) => (
          <ProcessDatePicker
            value={value}
            name="dateEstimatedDelivery"
            title="Data est. de Entrega"
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const DateDelivery = (props: Props) => {
  const {xs = 2, title = "Data de entrega"} = props;
  const {control} = useFormContext();

  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name="dateDelivery"
        control={control}
        render={({field: {value}}) => (
          <ProcessDatePicker
            value={value}
            name="dateDelivery"
            title="Data de entrega"
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const DateDeliveryOrigin = (props: Props) => {
  const {xs = 2, title = "Data de entrega na origem"} = props;
  const {control} = useFormContext();

  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name="dateDeliveryOrigin"
        control={control}
        render={({field: {value}}) => (
          <ProcessDatePicker
            value={value}
            name="dateDeliveryOrigin"
            title="Data de entrega na origem"
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const PlaceDeliveryOrigin = (props: Props) => {
  const {xs = 2, title = "Local de recebimento Origem"} = props;
  const {control} = useFormContext();

  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name="placeDeliveryOrigin"
        control={control}
        render={({field: {value, onChange}}) => (
          <CustomInput
            size="small"
            name="placeDeliveryOrigin"
            placeholder="Local de recebimento Origem"
            value={value}
            onChange={onChange}
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const ReceivingLocation = (props: Props) => {
  const {xs = 2, title = "Local de recebimento"} = props;
  const {control} = useFormContext();
  const [placeDeliveryDestinationOptions, setPlaceDeliveryDestinationOptions] =
    useState([] as AutoCompleteData[]);

  const [searchFields, setSearchFields] = useState({
    placeDeliveryOrigin: "",
    placeDeliveryDestination: "",
    transportCompany: "",
  });

  const {
    getOptions: getPlaceDeliveryDestinationOptions,
    onBottomScroll: onBottomScrollPlaceDeliveryDestination,
    isLoadingOptions: isLoadingPlaceDeliveryDestinationOptions,
    pageState: placeDeliveryDestinationPageState,
  } = useHandleFieldLists(getPorts, formatOptionsFn);

  const {handleChangeAutocomplete} = useChangeDAteAndAutocomplete();
  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name="receivingLocation"
        control={control}
        render={({field: {value}}) => (
          <CustomAutocomplete
            optionSecondaryContent
            size="small"
            name="receivingLocation"
            placeholder="Local de recebimento"
            loading={isLoadingPlaceDeliveryDestinationOptions}
            value={value?.name ?? ""}
            tooltipText={getTooltipText(value?.name)}
            options={placeDeliveryDestinationOptions}
            onChange={(e, value) => {
              handleChangeAutocomplete(e, value, "receivingLocation");
            }}
            onOpen={
              placeDeliveryDestinationOptions.length > 0
                ? undefined
                : () =>
                    getPlaceDeliveryDestinationOptions(
                      setPlaceDeliveryDestinationOptions,
                    )
            }
            getNextPage={() => {
              onBottomScrollPlaceDeliveryDestination(
                setPlaceDeliveryDestinationOptions,
                searchFields.placeDeliveryDestination,
              );
            }}
            isOptionEqualToValue={(option, value) => value === option?.label}
            onInputChange={(e, value) => {
              //condição pra não fazer a requisição na primeira atualização do input
              if (e !== null) {
                setSearchFields((prev) => ({
                  ...prev,
                  placeDeliveryDestination: value,
                }));
                getPlaceDeliveryDestinationOptions(
                  setPlaceDeliveryDestinationOptions,
                  1,
                  value,
                );
              }
            }}
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const DateCargoLoad = (props: Props) => {
  const {xs = 2, title = "Confirmação de embarque"} = props;
  const {control} = useFormContext<DefaultProcessDetails>();

  const {fields} = useFieldArray({
    name: "voyages",
    keyName: "formId",
    control: control,
  });

  const getVoyageIndex = () => {
    return getInitialVoyageIndex(fields);
  };

  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name={`voyages[${getVoyageIndex()}].cargoLoad` as any}
        control={control}
        render={({field: {value}}) => (
          <ProcessDatePicker
            value={value}
            name={`voyages[${getVoyageIndex()}].cargoLoad` as any}
            title="Confirmação de embarque"
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const DateCargoUnload = (props: Props) => {
  const {xs = 2, title = "Confirmação de desembarque"} = props;
  const {control, getValues} = useFormContext<DefaultProcessDetails>();

  const {fields} = useFieldArray({
    name: "voyages",
    keyName: "formId",
    control: control,
  });
  const idBoardingDestination = getValues("destination")?.id;

  const getVoyageIndex = () => {
    return getDestinationVoyageIndex(idBoardingDestination, fields);
  };

  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name={`voyages[${getVoyageIndex()}].cargoUnload` as any}
        control={control}
        render={({field: {value}}) => (
          <ProcessDatePicker
            value={value}
            name={`voyages[${getVoyageIndex()}].cargoUnload` as any}
            title="Confirmação de desembarque"
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const DateEstimatedPickup = (props: Props) => {
  const {xs = 2, title = "Data estimada de coleta"} = props;
  const {control} = useFormContext();

  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name="dateEstimatedPickup"
        control={control}
        render={({field: {value}}) => (
          <ProcessDatePicker
            value={value}
            name="dateEstimatedPickup"
            title="Data estimada de coleta"
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const DatePickup = (props: Props) => {
  const {xs = 2, title = "Confirmação de coleta"} = props;
  const {control} = useFormContext();

  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name="datePickup"
        control={control}
        render={({field: {value}}) => (
          <ProcessDatePicker
            value={value}
            name="datePickup"
            title="Confirmação de coleta"
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const TerminalMooring = (props: Props) => {
  const {xs = 2, title = "Terminal de chegada"} = props;
  const {control} = useFormContext();
  const [mooringOptions, setMooringOptions] = useState<AutoCompleteData[]>(
    [] as AutoCompleteData[],
  );

  const [search, setSearch] = useState("");

  const {
    getOptions: getMooringOptions,
    onBottomScroll: onBottomScrollMooring,
    isLoadingOptions: isLoadingMooringOptions,
  } = useHandleFieldLists(GetPersonsByTypes, null, [
    PersonTypes.containerTerminal,
  ]);

  const {handleChangeAutocomplete, mountOptions} =
    useChangeDAteAndAutocomplete();
  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name="terminalMooring"
        control={control}
        render={({field: {value}}) => (
          <CustomAutocomplete
            optionSecondaryContent
            name={`terminalMooring`}
            placeholder="Terminal de chegada"
            tooltipText={getTooltipText(value?.name)}
            loading={isLoadingMooringOptions}
            options={mountOptions(value, mooringOptions)}
            value={value?.name ?? ""}
            onOpen={
              mooringOptions.length > 0
                ? undefined
                : () => getMooringOptions(setMooringOptions, 1, search)
            }
            onInputChange={(e, value) => {
              if (e !== null) {
                setSearch(value);
                getMooringOptions(setMooringOptions, 1, value);
              }
            }}
            getNextPage={() => {
              onBottomScrollMooring(setMooringOptions, search);
            }}
            onChange={(e, value) => {
              handleChangeAutocomplete(e, value, "terminalMooring");
            }}
            isOptionEqualToValue={(option, value) => value === option?.label}
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const TerminalRedestination = (props: Props) => {
  const {xs = 2, title = "Terminal de redestinação"} = props;
  const {control} = useFormContext();
  const [redestinationOptions, setRedestinationOptions] = useState<
    AutoCompleteData[]
  >([] as AutoCompleteData[]);
  const [search, setSearch] = useState("");
  const {
    getOptions: getRedestinationOptions,
    onBottomScroll: onBottomScrollRedestination,
    isLoadingOptions: isLoadingRedestinationOptions,
  } = useHandleFieldLists(GetPersonsByTypes, null, [
    PersonTypes.containerTerminal,
  ]);

  const {handleChangeAutocomplete, mountOptions} =
    useChangeDAteAndAutocomplete();
  return (
    <CustomInputWrapper title={title} xs={xs}>
      <Controller
        name="terminalRedestination"
        control={control}
        render={({field: {value}}) => (
          <CustomAutocomplete
            optionSecondaryContent
            name={`terminalRedestination`}
            placeholder="Terminal de chegada"
            tooltipText={getTooltipText(value?.name)}
            loading={isLoadingRedestinationOptions}
            options={mountOptions(value, redestinationOptions)}
            value={value?.name ?? ""}
            onOpen={
              redestinationOptions.length > 0
                ? undefined
                : () =>
                    getRedestinationOptions(setRedestinationOptions, 1, search)
            }
            onInputChange={(e, value) => {
              if (e !== null) {
                setSearch(value);
                getRedestinationOptions(setRedestinationOptions, 1, value);
              }
            }}
            getNextPage={() => {
              onBottomScrollRedestination(setRedestinationOptions, search);
            }}
            onChange={(e, value) => {
              handleChangeAutocomplete(e, value, "terminalRedestination");
            }}
            isOptionEqualToValue={(option, value) => value === option?.label}
          />
        )}
      />
    </CustomInputWrapper>
  );
};

const fclCase = [
  {item: Origin, xs: 2.4},
  {item: Destination, xs: 2.4},
  {item: FinalDestination, xs: 2.4},
  {item: TypePath, xs: 2.4},
  {item: TypeLoad, xs: 2.4}, //Modalidade
  {item: Incoterm, xs: 2},
  {item: BookingNumber, xs: 1.7},
  {item: TT, xs: 1.1},
  {item: TransportCompany, xs: 2.4},
  {item: PlacePickup, xs: 2.4},
  {item: PlaceDeliveryOrigin, xs: 2.4},
  {item: DateEstimatedReadinessCargo, xs: 2.4},
  {item: DateReadinessCargo, xs: 2.4},
  {item: DateEstimatedPickup, xs: 2.4},
  {item: DatePickup, xs: 2.4},
  {item: ModalityPickup, xs: 2.4},
  {item: ETD, xs: 2.4},
  {item: DateCargoLoad, xs: 2.4},
  {item: ETA, xs: 2.4},
  {item: DateCargoUnload, xs: 2.4},
  {item: TerminalMooring, xs: 4},
  {item: TerminalRedestination, xs: 4},
];

const mainCase = [
  {item: Origin, xs: 2.4},
  {item: Destination, xs: 2.4},
  {item: FinalDestination, xs: 2.4},
  {item: TT, xs: 1},
  {item: BookingNumber, xs: 1.8},
  {item: Incoterm, xs: 2},
  {item: TransportCompany, xs: 2.4},
  {item: TypePath, xs: 2.4},
  {item: Path, xs: 2.4},
  {item: DateEstimatedReadinessCargo, xs: 2.4},
  {item: DateReadinessCargo, xs: 2.4},
  {item: ETD, xs: 2.4},
  {item: DateCargoLoad, xs: 2.4},
  {item: ETA, xs: 2.4},
  {item: DateCargoUnload, xs: 2.4},
  {item: ModalityPickup, xs: 2.4},
  {item: ModalityDelivery, xs: 2.4},
  {item: DateEstimatedDelivery, xs: 2.4},
  {item: DateDelivery, xs: 2.4},
  {item: DateDeliveryOrigin, xs: 2.4},
  {item: PlaceDeliveryOrigin, xs: 2.4},
  {item: ReceivingLocation, xs: 2.4},
  {item: DateEstimatedPickup, xs: 2.4}, //caso exw
  {item: PlacePickup, xs: 2.4},
  {item: DatePickup, xs: 2.4}, //caso exw
  {item: TypeLoad, xs: 2.4}, //Modalidade
  {item: TerminalMooring, xs: 4},
  {item: TerminalRedestination, xs: 4},
];

const useHandleBoardingFields = () => {
  return {fclCase, mainCase};
};

export default useHandleBoardingFields;
