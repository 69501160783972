import pxToRem from "hooks/usePxToRem";

import {Box, Button, Divider} from "@mui/material";

import {DefaultCardStructure} from "components";
import {
  BasicInfo,
  Contacts,
  CustomerInstructionsModal,
  Transactioners,
} from "./components";
import React, {useState} from "react";
import useAlertMessage from "hooks/useAlertMessage";
import {ProcessInstructions} from "./components/CustomerInstructionsModal/CustomerInstructions.types";
import {useFormContext} from "react-hook-form";
import {DefaultProcessDetails} from "views/ProcessDetails/Process.types";

type Props = {
  patchInstructions: (
    instructions: Partial<ProcessInstructions>,
  ) => Promise<DefaultProcessDetails | undefined>;
  refetchProcess: () => Promise<DefaultProcessDetails | undefined>;
};

const ProcessInfo = (props: Props) => {
  const [showAlert] = useAlertMessage();
  const {watch, reset} = useFormContext();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleCloseModal = () => {
    setModalIsOpen(false);
  };
  const cancelledProcess = watch("agencyingSituation") === "Cancelado";

  const handleSubmitInstructions = async (
    instructions: Partial<ProcessInstructions>,
  ) => {
    try {
      const result = await props.patchInstructions(instructions);
      reset(result, {keepDirtyValues: false, keepValues: true});
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    }
  };

  return (
    <DefaultCardStructure
      linkId="process"
      title="Processo"
      button={
        <Button
          variant="contained"
          onClick={() => setModalIsOpen(true)}
          sx={(theme) => ({fontSize: theme.typography.button3.fontSize})}
        >
          Instruções gerais
        </Button>
      }
      hasDivider
      badgeText={watch("processStatus.name")}
    >
      <BasicInfo />
      <CustomerInstructionsModal
        onCloseModal={handleCloseModal}
        isOpen={modalIsOpen}
        handleSubmitInstructions={handleSubmitInstructions}
      />
      <Transactioners />

      <Box pt={pxToRem(24)}>
        <Divider />
      </Box>

      <Contacts refetchProcess={props.refetchProcess} />
    </DefaultCardStructure>
  );
};

export default React.memo(ProcessInfo);
