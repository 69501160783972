import {Grid, Typography} from "@mui/material";
import {CustomSearchSelect} from "ui";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";

type Props = {
  isLoadingOptions: boolean;
  handleChangeCategory: (
    _: React.SyntheticEvent<Element, Event>,
    value: {
      id: number;
      label: string;
    },
  ) => void;
  documentOptions: AutoCompleteData[];
  getOptions: (
    page?: number,
    itemsPerPage?: number,
    search?: string,
  ) => Promise<void>;
  pageState: {currentPage: number; lastPage: number};
  categorySearch?: string;
  getFilteredCategory: (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
  ) => void;
  helperText: boolean;
};

const TypeDocumentAutocomplete = (props: Props) => {
  const {
    isLoadingOptions,
    handleChangeCategory,
    documentOptions,
    getOptions,
    pageState,
    getFilteredCategory,
    helperText,
    categorySearch,
  } = props;

  const FIRST_PAGE = 1;
  const PAGE_LIMIT = 30;

  return (
    <Grid item xs={12}>
      <CustomSearchSelect
        size="small"
        name="item"
        fullWidth
        placeholder="Tipo de documento"
        loading={isLoadingOptions}
        fnOnChange={(e, value) => handleChangeCategory(e, value)}
        options={documentOptions}
        fnOnOpen={() => getOptions(FIRST_PAGE, PAGE_LIMIT)}
        onBottomScroll={() => {
          getOptions(pageState.currentPage + 1, PAGE_LIMIT, categorySearch);
          return true;
        }}
        hasMorePagesToLoad={pageState.currentPage < pageState.lastPage}
        fnOnInputChange={getFilteredCategory}
      />
      {helperText && (
        <Typography variant="subtitle3">
          Digite 3 caracteres para a busca
        </Typography>
      )}
    </Grid>
  );
};
export default TypeDocumentAutocomplete;
