import {Types as loadingTypes} from "store/reducers/loadingReducer";
import {useDispatch} from "react-redux";

const useLoading = () => {
  const dispatch = useDispatch();

  const setLoading = (isLoading: boolean) => {
    dispatch({
      type: loadingTypes.CHANGE_LOADING,
      isLoading: isLoading,
    });
  };

  return [setLoading];
};

export default useLoading;
