/* eslint-disable @typescript-eslint/no-explicit-any */
import {useState} from "react";
import {useSelector} from "react-redux";

import {
  Box,
  Divider,
  IconButton,
  List,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import {SidebarNavigation, SidebarNavItem, UserCard} from "./components";

import inextLogo from "assets/images/new_logo.svg";
import StartIcon from "@mui/icons-material/Start";

import StyledPaper from "./Sidebar.styles";
import useUserSettings from "hooks/useUserSettings";
import SidebarMenuData from "./SidebarMenuData";
import useLogout from "hooks/useLogout";
import {NavLink} from "react-router-dom";
import pxToRem from "hooks/usePxToRem";

const Sidebar = () => {
  const {handleLogoutUser} = useLogout();

  const {initialExpandedMenu} = SidebarMenuData();

  const [currentDropdownExpanded, setCurrentDropdownExpanded] = useState<
    string | null
  >(initialExpandedMenu);

  const {userInfo} = useSelector((state: any) => state.stateUserInfo);
  const {expandedMenu} = userInfo.settings;

  const {saveUserSettings} = useUserSettings();

  const handleToggleSidebarMode = () => {
    saveUserSettings({
      expandedMenu: !expandedMenu,
    });

    setCurrentDropdownExpanded("");
  };

  const handleEnvironmentText = () => {
    if (location.hostname.includes("hom")) {
      if (expandedMenu) {
        return "HOMOLOG";
      } else {
        return "HOM";
      }
    } else if (location.hostname.includes("dev")) {
      if (expandedMenu) {
        return "DEVELOP";
      } else {
        return "DEV";
      }
    }
  };

  return (
    <StyledPaper elevation={0} isSidebarOpen={expandedMenu}>
      <Box>
        <Stack
          direction="row"
          justifyContent={expandedMenu ? "space-between" : "center"}
          alignItems="center"
          className="sidebar__header"
          component="header"
        >
          <Box className="sidebar__logo--box" component={NavLink} to="/">
            <Box
              className="sidebar__logo"
              component="img"
              src={inextLogo}
              alt="iNext"
            />
          </Box>
          <Tooltip
            title={expandedMenu ? "Retrair menu" : "Expandir menu"}
            placement="right"
            arrow={expandedMenu}
          >
            <Box>
              <IconButton
                disableRipple
                className="sidebar__button"
                onClick={handleToggleSidebarMode}
              >
                <StartIcon className="sidebar__button--icon" />
              </IconButton>
            </Box>
          </Tooltip>
        </Stack>

        <Divider />
      </Box>
      <Stack
        className="sidebar__user-card"
        justifyContent={"center"}
        alignItems={"center"}
        gap={pxToRem(12)}
      >
        <Typography
          component="strong"
          color="red"
          sx={{
            textTransform: "uppercase",
            fontSize: pxToRem(24),
            fontWeight: 700,
            lineHeight: 1.3,
          }}
        >
          {handleEnvironmentText()}
        </Typography>
        <UserCard />
      </Stack>

      <Stack className="sidebar__nav" component="nav" sx={{flex: 1}}>
        <SidebarNavigation
          currentDropdownExpanded={currentDropdownExpanded}
          onCurrentDropdownExpandedChange={setCurrentDropdownExpanded}
        />
      </Stack>

      <Stack className="sidebar__footer" component="footer">
        <Divider />

        <List className="sidebar__footer--list">
          <SidebarNavItem
            name="Sair"
            icon="logout"
            isDestructive
            onClickRequest={() => handleLogoutUser(userInfo)}
          />
        </List>
      </Stack>
    </StyledPaper>
  );
};

export default Sidebar;
