import {Button, Icon, useTheme} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import React, {forwardRef} from "react";
import {useSelector} from "react-redux";

type SelectDate = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  value?: unknown;
  fullWidth?: boolean;
};

const SelectDateButton = forwardRef<HTMLButtonElement | null, SelectDate>(
  function SelectDateButton(props, ref) {
    const {onClick, value, fullWidth} = props;
    const {userInfo} = useSelector((state: any) => state.stateUserInfo);
    const {darkMode} = userInfo.settings;
    const theme = useTheme();
    return (
      <Button
        variant="outlined"
        sx={{
          width: fullWidth ? "100%" : "auto",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          height: pxToRem(40),
          color: darkMode
            ? theme.palette.text.primary
            : theme.palette.grey[700],
          borderColor: darkMode
            ? theme.palette.grey[700]
            : theme.palette.grey[300],
          textTransform: "capitalize",
          ":hover": {
            borderColor: darkMode
              ? theme.palette.grey[300]
              : theme.palette.grey[700],
            backgroundColor: "transparent",
            color: darkMode
              ? theme.palette.text.primary
              : theme.palette.grey[700],
          },
        }}
        ref={ref}
        endIcon={<Icon>calendar_month</Icon>}
        onClick={onClick}
      >
        {value === "" ? "Selecionar Data" : value}
      </Button>
    );
  },
);

export default SelectDateButton;
