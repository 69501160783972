import pxToRem from "hooks/usePxToRem";
import {Box, Divider} from "@mui/material";

import {DocumentsList} from "./components";
import {DefaultCardStructure} from "components";

import "./processDocumentsStyle.scss";
import {FieldValues, UseFormSetValue} from "react-hook-form";
import {memo} from "react";

interface Props {
  refetchFiles: (setRhfValues: UseFormSetValue<FieldValues>) => Promise<void>;
}

const ProcessDocuments = (props: Props) => {
  return (
    <DefaultCardStructure
      title="Documentos"
      hasDivider={false}
      linkId="documents"
      titleMarginBottom={10}
    >
      <Box>
        <DocumentsList refetchFiles={props.refetchFiles} />

        <Box pt={pxToRem(24)}>
          <Divider />
        </Box>

        {/* <IncludeNewCourier />

        <ArrivalCourier /> */}
      </Box>
    </DefaultCardStructure>
  );
};

export default memo(ProcessDocuments);
