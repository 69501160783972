import pxToRem from "hooks/usePxToRem";

import {Divider, Grid, Typography} from "@mui/material";

import {
  CustomInputWrapper,
  CustomInput,
  CustomAutocomplete,
  CustomButton,
  CustomIconButton,
} from "ui";
import {ConfirmModal, DefaultCardStructure} from "components";
import {useState} from "react";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";
import {Controller, useFieldArray, useFormContext} from "react-hook-form";
import useChangeDAteAndAutocomplete from "views/ProcessDetails/hooks/useChangeDAteAndAutocomplete";
import {
  DeleteLogisticVoyage,
  GetVesselsList,
} from "services/api_v2/Processes/Processes.service";
import useHandleFieldLists from "views/ProcessDetails/hooks/useHandleFieldLists";
import useHandleBoardingOptions from "../../useHandleBoardingOptions";
import {getTooltipText} from "views/ProcessDetails/utils/getTooltipText";
import useVoyageTypes from "./useVoyageTypes";
import VoyagesTable from "./VoyagesTable";
import AddOrEditVoyageModal from "./AddOrEditVoyageModal";
import ProcessDatePicker from "views/ProcessDetails/components/ProcessDatePicker";
import useToast from "hooks/useToast";
import useAlertMessage from "hooks/useAlertMessage";
import {
  DefaultProcessDetails,
  Voyage,
} from "views/ProcessDetails/Process.types";
import {useParams} from "react-router";
import {getUpdatedFields} from "util/getDirtyFieldsFromRHF";
import useLoading from "hooks/useLoading";
import {AddOrEditVoyageData} from "./AddOrEditVoyageModal/AddOrEditVoyageModal";

type Props = {
  addNewVoyage: (
    id: number,
    voyages: Partial<Voyage>[],
  ) => Promise<DefaultProcessDetails | undefined>;
  refetchProcess: () => Promise<DefaultProcessDetails | undefined>;
};

const Voyages = (props: Props) => {
  const {control, resetField, getValues, formState, watch, reset} =
    useFormContext();

  const {fields: voyages, remove} = useFieldArray({
    control,
    keyName: "idForm",
    name: "voyages",
  });

  const cancelledProcess = watch("agencyingSituation") === "Cancelado";

  const [showToast] = useToast();
  const [showAlert] = useAlertMessage();
  const [setLoading] = useLoading();

  const {handleChangeAutocomplete, mountOptions} =
    useChangeDAteAndAutocomplete();

  const [boardingOptions, setBoardingOptions] = useState(
    [] as AutoCompleteData[],
  );
  const [vesselsOptions, setVesselsOptions] = useState(
    [] as AutoCompleteData[],
  );

  const [voyageTypes, setVoyageTypes] = useState([] as AutoCompleteData[]);

  const [openVoyageModal, setOpenVoyageModal] = useState(false);
  const [voyageIndex, setVoyageIndex] = useState<null | number>(null);
  const [newVoyage, setNewVoyage] = useState<"" | "newVoyage">("");

  const {getVoyageTypes, loading: loadingVoyageTypes} = useVoyageTypes();

  const {
    getOptions: getBoardingOptions,
    onBottomScroll: onBottomScrollBoarding,
    isLoadingOptions: isLoadingBoardingOptions,
  } = useHandleBoardingOptions();

  const {
    getOptions: getVesselsOptions,
    onBottomScroll: onBottomScrollVessels,
    isLoadingOptions: isLoadingVesselsOptions,
  } = useHandleFieldLists(GetVesselsList);

  const [searchFields, setSearchFields] = useState({
    vessels: "",
    origin: "",
    destination: "",
  });
  const [listView, setListView] = useState(false);

  const handleChangeViewFormat = () => {
    setListView((state) => !state);
  };

  const handleInsertNewVoyage = () => {
    setNewVoyage("newVoyage");
    const processFormHasDirtyFields =
      Object.keys(formState.dirtyFields).length > 0;
    if (processFormHasDirtyFields) {
      handleOpenWarningDirtyFields();
    } else handleOpenVoyageModal(voyages?.length);
  };
  const [initialOrigin, setInitialOrigin] = useState<{
    id: number;
    name: string;
  }>();

  const handleOpenVoyageModal = (index: number | null) => {
    setOpenVoyageModal(true);
    setVoyageIndex(index);
    if (index && index > 0) {
      setInitialOrigin(getValues(`voyages[${index - 1}].destination`));
    }
  };

  const [showHelperText, setShowHelperText] = useState({
    typeVoyage: false,
    origin: false,
    destination: false,
  });

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{
    itemId?: number;
    index?: number;
  }>({});

  const handleDeleteVoyage = async (field: any, index: number) => {
    if (!field.id) {
      remove(index);
      return;
    }

    setItemToDelete({itemId: field.id as number, index: index});
    setDeleteModalIsOpen(true);
  };

  const handleClose = () => {
    setDeleteModalIsOpen(false);
  };
  const confirmDeletionVoyage = async () => {
    if (!itemToDelete?.itemId || itemToDelete?.index === undefined) {
      return;
    }
    try {
      await DeleteLogisticVoyage(itemToDelete.itemId);
      remove(itemToDelete.index);

      resetField("voyages", {
        keepDirty: false,
        defaultValue: formState?.defaultValues?.voyages.filter(
          (item) => item.id !== itemToDelete.itemId,
        ),
      });
      handleClose();

      showToast("Viagem removida com sucesso", "success");
    } catch (e: any) {
      console.error(e);
      showAlert(e?.response?.data?.message, "error");
    }
  };

  const {id: processId} = useParams<{id: string}>();

  const handleUpdateRenderAfterAddVoyage = async () => {
    try {
      const response = await props.refetchProcess();
      reset(response, {keepDirtyValues: false, keepValues: false});
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitAndResetProcess = async (receivedData, dirty) => {
    const dataToSend = getUpdatedFields(dirty, receivedData);
    const data = {
      id: dataToSend?.id ?? receivedData?.id,
      idTypeVoyage: dataToSend?.typeVoyage?.id,
      idOrigin: dataToSend?.origin?.id,
      idDestination: dataToSend?.destination?.id,
      idVessel: dataToSend?.vessel?.id,
      voyageFlight: dataToSend?.voyageFlight,
      estimatedCargoLoad: dataToSend?.estimatedCargoLoad,
      cargoLoad: dataToSend?.cargoLoad,
      estimatedCargoUnload: dataToSend?.estimatedCargoUnload,
      cargoUnload: dataToSend?.cargoUnload,
      placePickup: dataToSend?.placePickup,
    };

    try {
      setLoading(true);
      await props.addNewVoyage(+processId, data as any);
      await handleUpdateRenderAfterAddVoyage();
      setOpenVoyageModal(false);
      setModalData({});
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const [modalData, setModalData] = useState<AddOrEditVoyageData>(
    {} as AddOrEditVoyageData,
  );

  const handleEditVoyage = (data: Voyage) => {
    const dataToEdit: AddOrEditVoyageData = {
      id: data.id,
      typeVoyage: {id: data?.typeVoyage?.id, name: data?.typeVoyage?.name},
      origin: {id: data?.origin?.id, name: data?.origin?.name},
      destination: {id: data?.destination?.id, name: data?.destination?.name},
      vessel: {id: data?.vessel?.id, name: data?.vessel?.name},
      voyageFlight: data?.voyageFlight,
      estimatedCargoLoad: data?.estimatedCargoLoad,
      cargoLoad: data?.cargoLoad,
      estimatedCargoUnload: data?.estimatedCargoUnload,
      cargoUnload: data?.cargoLoad,
      placePickup: data?.placePickup,
    };
    setNewVoyage("");
    setModalData(dataToEdit);
    setOpenVoyageModal(true);
  };

  const handleCloseModal = () => {
    setOpenVoyageModal(false);
    setModalData({});
    setInitialOrigin(undefined);
  };

  const [openWarningDirtyFields, setOpenWarningDirtyFields] =
    useState<boolean>(false);

  const handleOpenWarningDirtyFields = () => {
    setOpenWarningDirtyFields(true);
  };

  const handleCloseWarningDirtyFields = () => {
    setOpenWarningDirtyFields(false);
  };

  const handleConfirmWarningDirtyFields = () => {
    handleCloseWarningDirtyFields();
    handleOpenVoyageModal(voyages?.length);
  };

  return (
    <>
      {openWarningDirtyFields && (
        <ConfirmModal
          isOpen={openWarningDirtyFields}
          title={"Campos alterados sem salvar"}
          subtitle={
            <Typography>
              Há campos do processo que foram alterados mas não foram salvos.
              Deseja continuar e{" "}
              <Typography sx={{fontWeight: 600, display: "inline-block"}}>
                perder as alterações
              </Typography>{" "}
              ou cancelar para poder salvar?
            </Typography>
          }
          confirmButtonFn={handleConfirmWarningDirtyFields}
          onClose={handleCloseWarningDirtyFields}
          color="warning"
          confirmButtonIconIsDisabled
          confirmButtonText="Continuar"
          titleIcon="warning"
        />
      )}
      {deleteModalIsOpen && (
        <ConfirmModal
          isOpen={deleteModalIsOpen}
          title={"Deletar viagem"}
          subtitle={`Esta ação não pode ser revertida!`}
          confirmButtonFn={() => confirmDeletionVoyage()}
          onClose={handleClose}
        />
      )}
      {openVoyageModal && (
        <AddOrEditVoyageModal
          isOpen={openVoyageModal}
          index={voyageIndex}
          handleSubmitVoyage={handleSubmitAndResetProcess}
          handleCloseModal={handleCloseModal}
          newVoyage={newVoyage}
          modalData={modalData}
          initialOrigin={initialOrigin}
        />
      )}
      <DefaultCardStructure
        title="Dados de Booking - Viagens"
        sx={{width: "100%"}}
        isSubcard
        hasDivider={false}
        titleMarginBottom={listView ? 0 : 24}
        button={
          <>
            <CustomIconButton
              iconName={"reorder"}
              onClick={handleChangeViewFormat}
              tooltipText="Visualizar em lista/campos"
              disabled={cancelledProcess}
              sx={{
                backgroundColor: listView ? "primary.300" : "background.paper",
                color: listView ? "text.primary" : "primary.main",
                border: "1px solid",
                borderColor: "primary.main",
                marginTop: 0,
              }}
            />
            <CustomButton
              onClickFn={handleInsertNewVoyage}
              startIcon="add"
              size="small"
              disabled={cancelledProcess}
            >
              Nova viagem
            </CustomButton>
          </>
        }
      >
        {voyages.length === 0 && (
          <Typography>Não existem viagens no processo.</Typography>
        )}

        {listView ? (
          <VoyagesTable
            voyages={voyages}
            setOpenVoyageModal={setOpenVoyageModal}
            setVoyageIndex={setVoyageIndex}
            handleEditVoyage={handleEditVoyage}
          />
        ) : (
          voyages?.map((voyage, index) => {
            return (
              <Grid
                container
                columnSpacing={pxToRem(8)}
                rowSpacing={pxToRem(12)}
                mb={pxToRem(8)}
                key={`${voyage.idForm}voyages[${index}]`}
              >
                <Grid item xs={12}>
                  <Typography
                    fontSize={pxToRem(15)}
                    fontWeight={600}
                  >{`Viagem ${index + 1}`}</Typography>
                </Grid>
                <CustomInputWrapper title="Tipo de viagem" xs={3}>
                  <Controller
                    name={`voyages[${index}].typeVoyage`}
                    render={({field: {value}}) => (
                      <CustomAutocomplete
                        value={value?.name}
                        name={`voyages[${index}].typeVoyage`}
                        options={voyageTypes}
                        onOpen={
                          voyageTypes?.length > 0
                            ? undefined
                            : () => getVoyageTypes(setVoyageTypes)
                        }
                        onChange={(e, value) =>
                          handleChangeAutocomplete(
                            e,
                            value,
                            `voyages[${index}].typeVoyage`,
                          )
                        }
                        placeholder="Tipo viagem"
                        loading={loadingVoyageTypes}
                        required
                        onBlur={() =>
                          setShowHelperText((prev) => ({
                            ...prev,
                            typeVoyage: false,
                          }))
                        }
                        textFieldProps={{
                          required: true,
                          helperText:
                            showHelperText.typeVoyage && "Preencha este campo.",
                          onInvalid: () =>
                            setShowHelperText((prev) => ({
                              ...prev,
                              typeVoyage: true,
                            })),
                        }}
                      />
                    )}
                  />
                </CustomInputWrapper>
                <CustomInputWrapper title="Origem" xs={3}>
                  <Controller
                    name={`voyages[${index}].origin`}
                    control={control}
                    render={({field: {value}}) => (
                      <CustomAutocomplete
                        optionSecondaryContent
                        name={`voyages[${index}].origin`}
                        placeholder="Origem"
                        tooltipText={getTooltipText(value?.name)}
                        loading={isLoadingBoardingOptions}
                        options={mountOptions(value, boardingOptions)}
                        value={value?.name ?? ""}
                        onOpen={() =>
                          getBoardingOptions(setBoardingOptions, "")
                        }
                        onInputChange={(e, value) => {
                          if (e !== null) {
                            setSearchFields((prev) => ({
                              ...prev,
                              origin: value,
                            }));
                            getBoardingOptions(setBoardingOptions, value);
                          }
                        }}
                        getNextPage={() => {
                          onBottomScrollBoarding(
                            setBoardingOptions,
                            searchFields.origin,
                          );
                        }}
                        onChange={(e, value) => {
                          handleChangeAutocomplete(
                            e,
                            value,
                            `voyages[${index}].origin`,
                          );
                        }}
                        isOptionEqualToValue={(option, value) =>
                          value === option?.label
                        }
                      />
                    )}
                  />
                </CustomInputWrapper>
                <CustomInputWrapper title="Destino" xs={3}>
                  <Controller
                    name={`voyages[${index}].destination`}
                    control={control}
                    render={({field: {value}}) => (
                      <CustomAutocomplete
                        optionSecondaryContent
                        name={`voyages[${index}].destination`}
                        placeholder="Destino"
                        tooltipText={getTooltipText(value?.name)}
                        loading={isLoadingBoardingOptions}
                        options={mountOptions(value, boardingOptions)}
                        value={value?.name ?? ""}
                        onOpen={() =>
                          getBoardingOptions(setBoardingOptions, "")
                        }
                        onInputChange={(e, value) => {
                          if (e !== null) {
                            setSearchFields((prev) => ({
                              ...prev,
                              destination: value,
                            }));
                            getBoardingOptions(setBoardingOptions, value);
                          }
                        }}
                        getNextPage={() => {
                          onBottomScrollBoarding(
                            setBoardingOptions,
                            searchFields.destination,
                          );
                        }}
                        onChange={(e, value) => {
                          handleChangeAutocomplete(
                            e,
                            value,
                            `voyages[${index}].destination`,
                          );
                        }}
                        isOptionEqualToValue={(option, value) =>
                          value === option?.label
                        }
                      />
                    )}
                  />
                </CustomInputWrapper>
                <CustomInputWrapper title="Navio" xs={3}>
                  <Controller
                    name={`voyages[${index}].vessel`}
                    control={control}
                    render={({field: {value}}) => (
                      <CustomAutocomplete
                        name={`voyages[${index}].vessel`}
                        placeholder="Navio"
                        tooltipText={getTooltipText(value?.name)}
                        loading={isLoadingVesselsOptions}
                        options={mountOptions(value, vesselsOptions)}
                        value={value?.name ?? ""}
                        onOpen={() =>
                          getVesselsOptions(setVesselsOptions, 1, "")
                        }
                        onInputChange={(e, value) => {
                          if (e !== null) {
                            setSearchFields((prev) => ({
                              ...prev,
                              vessels: value,
                            }));
                            getVesselsOptions(setVesselsOptions, 1, value);
                          }
                        }}
                        getNextPage={() => {
                          onBottomScrollVessels(
                            setVesselsOptions,
                            searchFields.vessels,
                          );
                        }}
                        onChange={(e, value) => {
                          handleChangeAutocomplete(
                            e,
                            value,
                            `voyages[${index}].vessel`,
                          );
                        }}
                        isOptionEqualToValue={(option, value) =>
                          value === option?.label
                        }
                      />
                    )}
                  />
                </CustomInputWrapper>
                <CustomInputWrapper title="Viagem" xs={3}>
                  <Controller
                    name={`voyages[${index}].voyageFlight`}
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <CustomInput
                        name={`voyages[${index}].voyageFlight`}
                        placeholder="Viagem"
                        value={value ?? ""}
                        onChange={onChange}
                        size="small"
                      />
                    )}
                  />
                </CustomInputWrapper>
                <CustomInputWrapper title="ETD" xs={3}>
                  <Controller
                    name={`voyages[${index}].estimatedCargoLoad`}
                    control={control}
                    render={({field: {value}}) => (
                      <ProcessDatePicker
                        value={value}
                        name={`voyages[${index}].estimatedCargoLoad`}
                        title="ETD"
                      />
                    )}
                  />
                </CustomInputWrapper>
                <CustomInputWrapper title="Embarque" xs={3}>
                  <Controller
                    name={`voyages[${index}].cargoLoad`}
                    control={control}
                    render={({field: {value}}) => (
                      <ProcessDatePicker
                        value={value}
                        name={`voyages[${index}].cargoLoad`}
                        title="Embarque"
                      />
                    )}
                  />
                </CustomInputWrapper>
                <CustomInputWrapper title="ETA" xs={3}>
                  <Controller
                    name={`voyages[${index}].estimatedCargoUnload`}
                    control={control}
                    render={({field: {value}}) => (
                      <ProcessDatePicker
                        value={value}
                        name={`voyages[${index}].estimatedCargoUnload`}
                        title="ETA"
                      />
                    )}
                  />
                </CustomInputWrapper>
                <CustomInputWrapper title="Desembarque" xs={3}>
                  <Controller
                    name={`voyages[${index}].cargoUnload`}
                    control={control}
                    render={({field: {value}}) => (
                      <ProcessDatePicker
                        value={value}
                        name={`voyages[${index}].cargoUnload`}
                        title="Desembarque"
                      />
                    )}
                  />
                </CustomInputWrapper>
                <CustomInputWrapper title="Local de coleta" xs={3}>
                  <Controller
                    name={`voyages[${index}].placePickup`}
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <CustomInput
                        name={`voyages[${index}].placePickup`}
                        placeholder="Local de coleta"
                        value={value ?? ""}
                        onChange={onChange}
                        size="small"
                      />
                    )}
                  />
                </CustomInputWrapper>
                <Grid
                  item
                  xs={1}
                  display="flex"
                  alignItems="end"
                  justifyContent="flex-start"
                >
                  <CustomIconButton
                    tooltipText="Apagar viagem"
                    iconName="delete"
                    disabled={cancelledProcess}
                    sx={{
                      pr: 0,
                      ":hover": {
                        color: "red",
                      },
                    }}
                    onClick={() => {
                      handleDeleteVoyage(voyage, index);
                    }}
                  />
                </Grid>
                {index < voyages?.length - 1 && (
                  <Grid item xs={12}>
                    <Divider
                      sx={{
                        width: "85%",
                        margin: `${pxToRem(22)} auto`,
                        display: index < voyages?.length - 1 ? "block" : "none",
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            );
          })
        )}
      </DefaultCardStructure>
    </>
  );
};

export default Voyages;
