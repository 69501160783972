import {
  Box,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {Icon as Iconify} from "@iconify/react";

interface InfoCardProps {
  iconify?: boolean;
  title: string;
  content: string | string[];
  icon?: string;
}

const InfoCard = (props: InfoCardProps) => {
  const theme = useTheme();
  const {iconify, title, content, icon} = props;

  const handleIconCaseIconfyIsFalse = () => {
    switch (typeof icon) {
      case "undefined":
        if (title === "Nome") {
          return "description";
        } else {
          return "article";
        }
      case "string":
        return icon;
      default:
        return "article";
    }
  };
  return (
    <Stack direction="row" gap={pxToRem(16)} sx={{width: "100%"}}>
      <Box
        sx={{
          height: pxToRem(44),
          width: pxToRem(44),
          padding: pxToRem(10),
          backgroundColor: theme.palette.background.default,
          borderRadius: pxToRem(4),
        }}
      >
        {iconify ? (
          <Iconify
            icon={
              title === "Responsável"
                ? "fluent:person-board-28-filled"
                : "fluent:person-tag-24-filled"
            }
            color={theme.palette.primary.main}
            width={pxToRem(24)}
            height={pxToRem(24)}
          />
        ) : (
          <Icon color="primary">{handleIconCaseIconfyIsFalse()}</Icon>
        )}
      </Box>
      <Stack justifyContent={"center"}>
        <Typography variant="subtitle1" fontSize={pxToRem(14)}>
          {title}
        </Typography>
        {typeof content === "string" ? (
          <Typography
            variant="body2"
            sx={{
              textTransform: "capitalize",
              fontStyle: content?.length > 0 ? "normal" : "italic",
            }}
          >
            {content?.length > 0 ? content?.toLowerCase() : "Sem informação"}
          </Typography>
        ) : (
          <List sx={{paddingLeft: pxToRem(0), width: "85%"}}>
            {content?.map((item) => (
              <ListItem key={item} disablePadding sx={{gap: pxToRem(8)}}>
                <ListItemIcon sx={{minWidth: pxToRem(10)}}>
                  <Icon sx={{fontSize: pxToRem(10)}}>
                    radio_button_unchecked
                  </Icon>
                </ListItemIcon>
                <ListItemText
                  sx={{width: "70%"}}
                  primaryTypographyProps={{
                    textTransform: "capitalize",
                    variant: "body2",
                  }}
                >
                  {item.toLowerCase()}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        )}
      </Stack>
    </Stack>
  );
};

export default InfoCard;
