/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {Suspense, useEffect, useState} from "react";
import {matchRoutes, renderRoutes} from "react-router-config";
import {ThemeProvider} from "@mui/material/styles";
import {Container, Box, Paper, CircularProgress, Stack} from "@mui/material";
import {useSelector} from "react-redux";

import {
  MyToast,
  Sidebar,
  NotificationsSidebar,
  ConfirmModal,
  ModalTokenExpiration,
} from "components";
import {CustomLoading, CustomAlertMessage, CustomEntirePageLoading} from "ui";
import lightTheme from "global/themes/lightTheme";
import darkTheme from "global/themes/darkTheme";

import pxToRem from "hooks/usePxToRem";
import {
  handleRequestInterceptor,
  handleResponseInterceptor,
} from "services/apiConsumer";
import {useHistory, useLocation} from "react-router-dom";
import {differenceInMinutes} from "date-fns";
import jwtDecode from "jwt-decode";
import {readLocalStorage} from "hooks/useLocalStorage";
import useLogout from "hooks/useLogout";
import useToast from "hooks/useToast";
import {Types as tasksProjectTypes} from "store/reducers/tasksProjectReducer";
import {Types as processesTypes} from "store/reducers/processesReducer";
import {Types as activitiesTypes} from "store/reducers/activitiesReducer";
import useChangeMrtReduxPagination from "hooks/useChangeMrtReduxPagination";

const Private = (props: any) => {
  const {route} = props;
  const {userInfo} = useSelector((state: any) => state.stateUserInfo);
  const {tasksOfProjectPagination, projectTasksPagination} = useSelector(
    (state: any) => state.stateTasksProjectReducer,
  );
  const {processesPagination} = useSelector(
    (state: any) => state.stateProcessesReducer,
  );
  const {activitiesPagination} = useSelector(
    (state: any) => state.stateActivitiesReducer,
  );

  const {resetPagination} = useChangeMrtReduxPagination();

  const history = useHistory();
  const location = useLocation();
  const [showToast] = useToast();

  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [isLogged, setIsLogged] = React.useState(false);
  const [isDeniedAccessModalOpen, setIsDeniedAccessModalOpen] = useState(false);

  const handleToggleDeniedAccessModal = () => {
    setIsDeniedAccessModalOpen(!isDeniedAccessModalOpen);
  };

  const handleRedirectWarning = () => {
    setIsUnauthorized(true);
  };

  handleRequestInterceptor(userInfo?.access_token);
  handleResponseInterceptor(
    history,
    handleToggleDeniedAccessModal,
    handleRedirectWarning,
  );
  const userCPData = readLocalStorage<{refreshToken?: string}>("userinfo");
  const {handleLogoutUser} = useLogout();

  // TasksOfProject
  useEffect(() => {
    resetPagination(
      tasksProjectTypes.SET_TASKS_OF_PROJECT_PAGINATION,
      "tasksOfProjectPagination",
      tasksOfProjectPagination,
      ["/tasks/", "/task-details/"],
    );
  }, [location]);

  //ProjectTasks
  useEffect(() => {
    resetPagination(
      tasksProjectTypes.SET_PROJECT_TASKS_PAGINATION,
      "projectTasksPagination",
      projectTasksPagination,
      ["/project-tasks", "/tasks/", "/task-details/"],
    );
  }, [location]);

  //Processes
  useEffect(() => {
    resetPagination(
      processesTypes.SET_PROCESSES_PAGINATION,
      "processesPagination",
      processesPagination,
      ["/search-processes", "/process-details/"],
    );
  }, [location]);

  //Activities
  useEffect(() => {
    resetPagination(
      activitiesTypes.SET_ACTIVITIES_PAGINATION,
      "activitiesPagination",
      activitiesPagination,
      ["/activity-details/", "/process-details/"],
    );
  }, [location]);

  /*
    `logoutCaseCPRefreshTokenIsExpired` Faz o logout do usuário caso o 
    REFRESH token do CP esteja ha 120 minutos de expirar.
    Para isso acontecer, o usuário precisaria ficar 1 semana se mantendo 
    logado, que é a duração do REFRESH token do CP.
  */
  const logoutCaseCPRefreshTokenIsExpired = () => {
    try {
      const tokenExpirationStamp =
        jwtDecode<any>(userCPData?.refreshToken ?? "").exp * 1000;

      const difference = differenceInMinutes(
        new Date(tokenExpirationStamp),
        new Date(),
      );
      if (difference < 120) {
        handleLogoutUser(userInfo);
        showToast("Por Favor, faça seu login novamente.");
      }
    } catch (e) {
      console.error(e);
    }
  };
  logoutCaseCPRefreshTokenIsExpired();

  useEffect(() => {
    if (userInfo.access_token) {
      setIsLogged(true);
    } else {
      history.push("/login");
    }
  }, [userInfo.access_token]);

  useEffect(() => {
    const routesFound = matchRoutes(route.routes, location.pathname);
    if (routesFound.length === 0) {
      history.push(`/page-not-found`);
    }
  }, [userInfo, history.location.pathname]);

  return (
    <ThemeProvider
      theme={userInfo?.settings?.darkMode ? darkTheme : lightTheme}
    >
      <Container maxWidth="xl" disableGutters>
        {isLogged && (
          <Box sx={{height: "100vh"}}>
            <ConfirmModal
              isOpen={isDeniedAccessModalOpen}
              title="Ação negada"
              subtitle={
                "Você não possui permissão para realizar esta ação, entre em contato com o seu Gestor."
              }
              confirmButtonFn={handleToggleDeniedAccessModal}
              onClose={handleToggleDeniedAccessModal}
              titleIcon="lock"
              hasOnlyConfirmButton
              confirmButtonText="Confirmar"
              confirmButtonIconStart="check"
            />
            <ModalTokenExpiration
              isUnauthorized={isUnauthorized}
              setIsUnauthorized={setIsUnauthorized}
              handleRedirectWarning={handleRedirectWarning}
            />
            <Stack direction="row">
              <Box component="aside">
                <Sidebar />
              </Box>
              {!isUnauthorized && (
                <Paper
                  elevation={0}
                  sx={{
                    flex: 1,
                    borderRadius: 0,
                    width: "calc(100% - 100vw)",
                  }}
                  color="primary"
                >
                  <Stack
                    component="main"
                    sx={{
                      height: "100vh",
                      overflowY: "scroll",
                      padding: `0 ${pxToRem(24)} ${pxToRem(8)}`,
                      scrollbarWidth: "none", //remover a barra de scroll para firefox
                      overflow: "-moz-scrollbars-none", //remover a barra de scroll para firefox

                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                  >
                    <Suspense fallback={<CircularProgress color="primary" />}>
                      {renderRoutes(route.routes)}
                    </Suspense>

                    <CustomLoading />
                    <CustomEntirePageLoading />
                    <NotificationsSidebar />
                  </Stack>
                  <CustomAlertMessage />
                </Paper>
              )}
              <MyToast />
            </Stack>
          </Box>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default Private;
