import {Tooltip, IconButton, Icon} from "@mui/material";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import pxToRem from "hooks/usePxToRem";

interface CopyToClipboardIconProps {
  textToBeCopied: string;
  tooltipTitle?: string;
  iconButtonSize?: "small" | "medium" | "large";
  color?: string;
  toastText?: string;
  iconSize?: number;
  tooltipPlacement?:
    | "top"
    | "right"
    | "bottom"
    | "left"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start";
}

const CopyToClipboardIcon = (props: CopyToClipboardIconProps) => {
  const {copyToClipboard} = useCopyToClipboard();

  const {
    tooltipTitle = "Copiar referência cliente",
    tooltipPlacement = "right",
    textToBeCopied,
    iconButtonSize = "medium",
    toastText = "Referência copiada",
    iconSize = 14,
    color,
  } = props;

  return (
    <Tooltip title={tooltipTitle} placement={tooltipPlacement} arrow>
      <IconButton
        onClick={() => copyToClipboard(textToBeCopied, toastText)}
        size={iconButtonSize}
      >
        <Icon
          sx={{
            fontSize: pxToRem(iconSize),
            cursor: "pointer",
            color: color,
          }}
        >
          content_copy
        </Icon>
      </IconButton>
    </Tooltip>
  );
};

export default CopyToClipboardIcon;
