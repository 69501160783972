import {useState} from "react";

const usePostponePopper = () => {
  const [postponePopperIsOpen, setPostponePopperIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [processNumberToPostpone, setProcessNumberToPostpone] = useState("");
  const [activityIdToPostpone, setActivityIdToPostpone] = useState<
    number | null
  >(null);

  const handlePostponeActivity = (
    activityId: number,
    processNumber: string,
    e: React.MouseEvent<HTMLElement | HTMLButtonElement, MouseEvent>,
  ) => {
    setProcessNumberToPostpone(processNumber);
    setActivityIdToPostpone(activityId);
    setAnchorEl(e.currentTarget);
    setPostponePopperIsOpen(true);
  };

  const handleClosePostponeActivityPopper = () => {
    setPostponePopperIsOpen(false);
  };

  return {
    postponePopperIsOpen,
    anchorEl,
    processNumberToPostpone,
    activityIdToPostpone,
    handleClosePostponeActivityPopper,
    handlePostponeActivity,
  };
};

export default usePostponePopper;
