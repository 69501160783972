export enum Gender {
  Masculino = "Masculino",
  Feminino = "Feminino",
  Outros = "Outros",
}

export enum CivilStatus {
  Solteiro = "Solteiro",
  Casado = "Casado",
  Divorciado = "Divorciado",
  Viuvo = "Viúvo",
  Separado = "Separado",
}
export enum Indicator {
  PagamentoAVista = "Pagamento a vista",
  PagamentoAPrazo = "Pagamento a prazo",
  Outros = "Outros",
}
export enum Situation {
  Ativo = "Ativo",
  Inativo = "Inativo",
  Revalidar = "Revalidar",
  EmAnalise = "Em analise",
}
export enum InstallmentType {
  SemParcelamento = "Sem parcelamento",
  Especifico = "Especifico",
  Diario = "Diario",
  Semanal = "Semanal",
  Mensal = "Mensal",
  Bimestral = "Bimestral",
  Trimestral = "Trimestral",
  Quadrimestral = "Quadrimestral",
  Semestral = "Semestral",
  Anual = "Anual",
}
export enum FinancialContractType {
  Pagamento = "Pagamento",
  Recebimento = "Recebimento",
}

export enum FinancialContractSituation {
  Ativo = "Ativo",
  Inativo = "Inativo",
  Revalidar = "Revalidar",
  EmAnalise = "Em analise",
}
export enum InterestPeriod {
  Diario = "Diario",
  Mensal = "Mensal",
  Anual = "Anual",
}

export enum InterestType {
  Simples = "Simples",
  Composto = "Compostos",
}
export enum GenerateInvoiceOption {
  Nao = "Não",
  PorRemessa = "Por remessa",
  Automaticamente = "Automaticamente",
}
export enum BacenBoletim {
  SemBoletim = "Sem boletim",
  Abertura = "Abertura",
  FechamentoPTAXAnterior = "Fechamento PTAX-Anterior",
  FechamentoPTAXAtual = "Fechamento PTAX-Atual",
}

export enum BacenOrigin {
  SemOrigem = "Sem origem",
  Compra = "Compra",
  Venda = "Venda",
}
export enum UseContract {
  Normal = "Normal",
  CiaTransporte = "Cia transporte",
}

export enum ReferenceDateOption {
  DataAtual = "Data atual",
  DataDoProcesso = "Data do processo",
  DataDeEmbarque = "Data de embarque",
  DataDeDesembarque = "Data de desembarque",
  ExecutedOnHAWB = "Executed On (HAWB)",
  ExecutedOnMAWB = "Executed On (MAWB)",
  ManterDataDaFatura = "Manter data da fatura",
}
export enum ConversionDateOption {
  DataAtual = "Data atual",
  DataDeReferencia = "Data de referencia",
  DataDoProcesso = "Data do processo",
  DataDeEmbarque = "Data de embarque",
  DataDeDesembarque = "Data de desembarque",
  DataDePagamento = "Data de pagamento",
  DataDeRecebimento = "Data de recebimento",
  DataDoAcertoComAgente = "Data do acerto com agente",
  DataLiberadoFaturamento = "Data liberado faturamento",
  AjusteManual = "Ajuste manual",
  DataPrimeiraFinalizacao = "Data primeira finalizacao",
}

export enum ActivityStatus {
  NaoIniciada = "Não iniciada",
  EmAndamento = "Em andamento",
  Paralisada = "Paralisada",
  Concluida = "Concluída",
  NaoRealizada = "Não realizada",
  Cancelada = "Cancelada",
  NaoCriar = "Não criar",
}

export enum GenerateActivity {
  AoIniciar = "Ao iniciar",
  AoConcluir = "Ao concluir",
  AoIniciarConcluir = "Ao iniciar/concluir",
  Desativado = "Desativado",
}

//enums provisórios:
export enum TypeKnowledge {
  knowledge1 = "Conhecimento 1",
  knowledge2 = "Conhecimento 2",
  knowledge3 = "Conhecimento 3",
}

export enum Modality {
  "Aérea" = "Aérea",
  "Marítima" = "Marítima",
  "Terrestre" = "Terrestre",
}

export enum Operation {
  "Exportação" = "Exportação",
  "Importação" = "Importação",
  "Nacional" = "Nacional",
}

export enum DashboardStatus {
  "Nenhum" = "Nenhum",
  "Não iniciada" = "Não iniciada",
  "Em andamento" = "Em andamento",
  "Concluída" = "Concluída",
  "Paralisada" = "Paralisada",
  "Cancelada" = "Cancelada",
  "Não realizada" = "Não realizada",
}

export enum ModalityPayment {
  "Prepaid" = "Prepaid",
  "Collect" = "Collect",
  "Collect/Prepaid" = "Collect/Prepaid",
  "Prepaid abroad" = "Prepaid abroad",
}

export enum TypeLoad {
  "(Não especificado)" = "(Não especificado)",
  "Aéreo" = "Aéreo",
  "Break-Bulk" = "Break-Bulk",
  "FCL" = "FCL",
  "LCL" = "LCL",
  "RO-RO" = "RO-RO",
  "Rodoviário" = "Rodoviário",
}

export enum TypePath {
  "transbordo" = "Transbordo",
  "direto" = "Direto",
  "(Não informado)" = "(Não informado)",
}

export enum WoodPackage {
  "Treated and certified" = "Treated and certified",
  "Not treated" = "Not treated",
  "Processed" = "Processed",
  "Do not apply" = "Do not apply",
}

export enum PlaceIssue {
  Destino = "Destino",
  ExpressRelease = "Express release",
  Origem = "Origem",
  SeaWaybill = "Sea waybill",
  Outro = "Outro",
}

export enum TypeEquipment {
  Container20 = "Container 20'",
  Container40 = "Container 40'",
  Carreta = "Carreta",
}
export enum TypeItemLoad {
  "Container" = "Container",
  "Carga solta" = "Carga solta",
  "Granel" = "Granel",
  "Veículo" = "Veículo",
}

export enum ReferredBy {
  Empresa = "Empresa",
  AgenteLocal = "Agente local",
  AgenteExterior = "Agente exterior",
  AssociacaoInternacional = "Associação internacional",
  Despachante = "Despachante",
  Commercial = "Comercial",
  ContaChave = "Conta chave",
}
export enum ServiceType {
  NaoInformado = "(Nao informado)",
  DoorToDoor = "Door to door",
  DoorToPort = "Door to port",
  PortToDoor = "Port to door",
  PortToPort = "Port to port",
  PortToRamp = "Port to ramp",
}
export enum DeliveryPickupType {
  NaoInformado = "(Não informado)",
  Dedicado = "Dedicado",
  Rodizio = "Rodizio",
}
export enum DeliveryPickupDestinationType {
  NaoInformado = "(Não informado)",
  Dedicado = "Dedicado",
  Rodizio = "Rodizio",
}
export enum ProjectType {
  Normal = "Normal",
  AirAir = "Air-Air",
  SeaAir = "Sea-Air",
  EmbarqueTriangular = "Embarque triangular",
}
export enum ReleaseCanal {
  Nenhum = "(Nenhum)",
  CanalVerde = "Canal verde",
  CanalAmarelo = "Canal amarelo",
  CanalVermelho = "Canal vermelho",
  CanalCinza = "Canal cinza",
}
export enum AgencyingSituation {
  ProcessoAberto = "Processo aberto",
  EmAndamento = "Em andamento",
  LiberadoFaturamento = "Liberado faturamento",
  Faturado = "Faturado",
  Auditado = "Auditado",
  Finalizado = "Finalizado",
  Cancelado = "Cancelado",
}
export enum AgentPaymentStatus {
  SemAcertoComAgente = "Sem acerto com agente",
  EmAberto = "Em aberto",
  ParcialmenteQuitado = "Parcialmente quitado",
  Quitado = "Quitado",
}

export enum ModalityPickup {
  NaoInformado = "(Não informado)",
  RailTruck = "Rail/Truck",
  AllRail = "All rail",
  AllTruck = "All truck",
}
export enum ModalityDelivery {
  NaoInformado = "(Não informado)",
  RailTruck = "Rail/Truck",
  AllRail = "All rail",
  AllTruck = "All truck",
}

export enum EmailTypes {
  Atividade = "Atividade",
  Outro = "Outro",
}

export enum EmailStatus {
  Enviado = "Enviado",
  Pendente = "Pendente",
  Erro = "Erro",
}
