import {DialogActions, Grid} from "@mui/material";
import {CustomModal} from "components";
import pxToRem from "hooks/usePxToRem";
import {useState} from "react";
import {GetPackagesList} from "services/api_v2/Processes/Processes.service";
import {
  CustomInputWrapper,
  CustomInput,
  CustomAutocomplete,
  CustomButton,
} from "ui";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";
import useHandleFieldLists from "views/ProcessDetails/hooks/useHandleFieldLists";
import {LogisticPackages} from "views/ProcessDetails/Process.types";
import {getTooltipText} from "views/ProcessDetails/utils/getTooltipText";
import {calcConsideredWeight} from "../../utils/calculateConsideredWeight";

type Props = {
  isOpen: boolean;
  handleCloseModalAfterSave: (payload: Partial<LogisticPackages>) => void;
  handleCloseModal: () => void;
};

/*
  Componente inacabado e não utilizado por mudança de planos.
  Mas ainda consta aqui pois será utilizado em breve
*/

const ModalNewPackage = (props: Props) => {
  const {isOpen, handleCloseModalAfterSave, handleCloseModal} = props;
  const defaultValues: LogisticPackages = {
    consideredWeight: 0,
    cubedWeight: 0,
    grossWeight: 0,
    height: 0,
    length: 0,
    measurement: 0,
    netWeight: 0,
    quantity: 0,
    width: 0,
  } as LogisticPackages;
  const [formValues, setFormValues] = useState(defaultValues);

  const [packagesOptions, setPackagesOptions] = useState(
    [] as AutoCompleteData[],
  );
  const [search, setSearch] = useState<string>();

  const {
    getOptions: getPackagesOptions,
    onBottomScroll: onBottomScrollPackages,
    isLoadingOptions: isLoadingPackagesOptions,
  } = useHandleFieldLists(GetPackagesList);

  const setValue = (name: string, value: any) => {
    setFormValues((prev) => ({...prev, [name]: value}));
  };

  const handleChangeAutocomplete = (
    _: React.SyntheticEvent<Element, Event>,
    value: any,
    name: string,
  ) => {
    if (value === null) {
      setValue(name, {id: null, name: null});
      return;
    }
    setValue(name, {id: value?.id, name: value?.label});
  };

  const handleSubmitForm = async () => {
    try {
      const payload = {
        idPackage: formValues?.idPackage,
        quantity: formValues?.quantity,
        length: formValues?.length,
        height: formValues?.height,
        width: formValues?.width,
        seal: formValues?.seal,
        netWeight: formValues?.netWeight,
        grossWeight: formValues?.grossWeight,
        cubedWeight: formValues?.cubedWeight,
        consideredWeight: formValues?.consideredWeight,
        measurement: formValues?.measurement,
      };

      //   const result = PostNewLogisticLogisticPackage(payload);
      handleCloseModalAfterSave(payload);
    } catch (e) {
      console.error(e);
    }
  };

  const calculateVolume = () => {
    const widthInMeters = (formValues?.width ?? 0) / 100;
    const lengthInMeters = (formValues?.length ?? 0) / 100;
    const heightInMeters = (formValues?.height ?? 0) / 100;

    const volume = +(widthInMeters * lengthInMeters * heightInMeters).toFixed(
      3,
    );
    // const newTempValue = handleChangeTempMeasureValue(volume, index);
    // setTempData(newTempValue);
    setValue(`measurement`, volume);
    calculateWeight();
  };

  const calculateWeight = () => {
    const loadData = calcConsideredWeight(
      formValues?.measurement ?? 0,
      formValues?.grossWeight ?? 0,
      //   watch(`processPackages[${index}].measurement`),
      //   watch(`processPackages[${index}].grossWeight`),
    );

    // const newTempValue = handleChangeTempLoadsValue(loadData, index);
    // setTempData(newTempValue);
    setValue(`consideredWeight`, loadData.consideredWeight);
    setValue(`cubedWeight`, loadData.cubeWeight);
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={handleCloseModal}
      title="Incluir item de carga"
      titleIcon="add"
    >
      <Grid
        container
        columnSpacing={pxToRem(8)}
        rowSpacing={pxToRem(20)}
        sx={{
          padding: pxToRem(20),
        }}
        component="form"
        id="newPackage"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <CustomInputWrapper md={3} title="Pacote">
          <CustomAutocomplete
            name={`package`}
            placeholder="Pacote"
            tooltipText={getTooltipText(formValues.package?.name ?? "")}
            loading={isLoadingPackagesOptions}
            options={packagesOptions}
            value={formValues.package?.name ?? null}
            onOpen={
              packagesOptions.length > 0
                ? undefined
                : () => getPackagesOptions(setPackagesOptions, 1, search)
            }
            onInputChange={(e, value) => {
              if (e !== null) {
                setSearch(value);
                getPackagesOptions(setPackagesOptions, 1, search);
              }
            }}
            getNextPage={() => {
              onBottomScrollPackages(setPackagesOptions, search);
            }}
            onChange={(e, value) => {
              handleChangeAutocomplete(e, value, `package`);
            }}
            isOptionEqualToValue={(option, value) => value === option?.label}
          />
        </CustomInputWrapper>
        <CustomInputWrapper md={1} title="Quantidade">
          <CustomInput
            name={`quantity`}
            type="number"
            placeholder="Quantidade"
            value={formValues.quantity ?? ""}
            onChange={(e) => {
              setValue(`quantity`, +e.target.value);
            }}
            inputProps={{
              style: {
                fontSize: pxToRem(14),
              },
              min: 0.0,
              step: 0.001,
            }}
            size="small"
          />
        </CustomInputWrapper>
        <CustomInputWrapper md={2} title="Comprimento (cm)">
          <CustomInput
            name={`length`}
            type="number"
            placeholder="Altura"
            value={formValues.length ?? ""}
            onChange={(e) => {
              setValue(`length`, +e.target.value);
              calculateVolume();
            }}
            inputProps={{
              style: {
                fontSize: pxToRem(14),
              },
              min: 0.0,
              step: 0.001,
            }}
            size="small"
          />
        </CustomInputWrapper>
        <CustomInputWrapper md={2} title="Altura (cm)">
          <CustomInput
            name={`height`}
            type="number"
            placeholder="Altura"
            value={formValues.height ?? ""}
            onChange={(e) => {
              setValue(`height`, +e.target.value);
              calculateVolume();
            }}
            inputProps={{
              style: {
                fontSize: pxToRem(14),
              },
              min: 0.0,
              step: 0.001,
            }}
            size="small"
          />
        </CustomInputWrapper>
        <CustomInputWrapper md={2} title="Largura (cm)">
          <CustomInput
            name={`width`}
            type="number"
            placeholder="Largura"
            value={formValues.width ?? ""}
            onChange={(e) => {
              setValue(`width`, +e.target.value);
              calculateVolume();
            }}
            inputProps={{
              style: {
                fontSize: pxToRem(14),
              },
              min: 0.0,
              step: 0.001,
            }}
            size="small"
          />
        </CustomInputWrapper>
        <CustomInputWrapper md={2} title="Medidas (m³)">
          <CustomInput
            name={`measurement`}
            type="number"
            placeholder="Medidas"
            // value={tempData?.[index]?.measurement ?? value ?? ""}
            value={formValues.measurement ?? ""}
            onChange={(e) => {
              setValue(`measurement`, +e.target.value);

              //   const newValue = handleChangeTempLoadsValue(
              //     {measurement: undefined},
              //     index,
              //   );

              calculateWeight();
            }}
            inputProps={{
              style: {
                fontSize: pxToRem(14),
              },
              min: 0.0,
              step: 0.001,
            }}
            size="small"
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={2} title="Peso líquido (kg)">
          <CustomInput
            name={`netWeight`}
            type="number"
            placeholder="Peso"
            value={formValues.netWeight ?? ""}
            onChange={(e) => setValue(`netWeight`, +e.target.value)}
            inputProps={{
              style: {
                fontSize: pxToRem(14),
              },
              min: 0.0,
              step: 0.001,
            }}
            size="small"
          />
        </CustomInputWrapper>
        <CustomInputWrapper md={2} title="Peso bruto (kg)">
          <CustomInput
            name={`grossWeight`}
            type="number"
            placeholder="Peso"
            value={formValues.grossWeight ?? ""}
            onChange={(e) => {
              setValue(`grossWeight`, +e.target.value);
              calculateWeight();
            }}
            inputProps={{
              style: {
                fontSize: pxToRem(14),
              },
              min: 0.0,
              step: 0.001,
            }}
            size="small"
          />
        </CustomInputWrapper>
        <CustomInputWrapper md={2} title="Peso cubado (kg)">
          <CustomInput
            name={`cubedWeight`}
            type="number"
            placeholder="Peso"
            // value={tempData?.[index]?.cubeWeight ?? value ?? ""}
            value={formValues.cubedWeight ?? ""}
            onChange={(e) => {
              setValue(`cubedWeight`, +e.target.value);
              //   const newValue = handleChangeTempLoadsValue(
              //     {cubeWeight: undefined},
              //     index,
              //   );
              calculateWeight();
              //   setTempData(newValue);
            }}
            inputProps={{
              style: {
                fontSize: pxToRem(14),
              },
              min: 0.0,
              step: 0.001,
            }}
            size="small"
          />
        </CustomInputWrapper>
        <CustomInputWrapper md={2} title="Peso considerado (kg)">
          <CustomInput
            name={`consideredWeight`}
            type="number"
            placeholder="Peso"
            value={formValues.consideredWeight ?? ""}
            onChange={(e) => {
              setValue(`consideredWeight`, +e.target.value);
              //   const newValue = handleChangeTempLoadsValue(
              //     {consideredWeight: undefined},
              //     index,
              //   );
              //   setTempData(newValue);
            }}
            inputProps={{
              style: {
                fontSize: pxToRem(14),
              },
              min: 0.0,
              step: 0.001,
            }}
            size="small"
          />
        </CustomInputWrapper>
      </Grid>
      <DialogActions sx={{padding: `0 ${pxToRem(20)} ${pxToRem(20)} 0`}}>
        <CustomButton
          size="medium"
          variant="outlined"
          onClickFn={handleCloseModal}
        >
          Cancelar
        </CustomButton>
        <CustomButton
          size="medium"
          type="submit"
          endIcon="save"
          onClickFn={handleSubmitForm}
        >
          Salvar
        </CustomButton>
      </DialogActions>
    </CustomModal>
  );
};

export default ModalNewPackage;
