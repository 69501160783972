import useAlertMessage from "hooks/useAlertMessage";
import useLoading from "hooks/useLoading";
import {
  readLocalStorage,
  removeLocalStorage,
  saveLocalStorage,
} from "hooks/useLocalStorage";
import useToast from "hooks/useToast";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {Types as userInfoTypes} from "store/reducers/userInfoReducer";

const useLogout = () => {
  const [showToast] = useToast();
  const [setLoading] = useLoading();
  const [showAlert] = useAlertMessage();

  const dispatch = useDispatch();
  const history = useHistory();

  const [successfulLogout, setSuccessfulLogout] = useState(false);

  const handleClearReduxUserInfo = () => {
    dispatch({
      type: userInfoTypes.SET_USER_INFO,
      userInfo: {
        access_token: "",
        id_token: "",
        user: {
          id: 0,
          avatar: "",
          name: "",
          email: "",
          logged_at: "",
        },
        settings: {
          acceptPrivacy: false,
          darkMode: true,
          expandedMenu: true,
          showNotifications: true,
        },
        menu: [],
      },
    });
    dispatch({
      type: userInfoTypes.SET_CURRENT_DATE_TIME,
      currentDateTime: "",
    });
  };

  const handleLogoutUser = (userInfo: any) => {
    try {
      setLoading(true);
      const routeBeforeLogout = history.location.pathname;
      window.location.href = `${
        process.env.REACT_APP_KEYCLOAK_URL
      }/auth/realms/inext/protocol/openid-connect/logout?id_token_hint=${
        userInfo.id_token
      }&client_id=${
        process.env.REACT_APP_KEYCLOAK_CLIENT_ID
      }&post_logout_redirect_uri=${
        window.location.host === "localhost:3000"
          ? "http://localhost:3000"
          : process.env.REACT_APP_URL
      }/login`;
      const localTheme = readLocalStorage("theme");
      /**
       * Itens abaixo são referentes ao login do usuário
       */
      removeLocalStorage("userinfo");
      removeLocalStorage("user_info");
      removeLocalStorage("persist:root");
      removeLocalStorage("ownerProfile");
      removeLocalStorage("theme");
      removeLocalStorage("tabPanel");

      saveLocalStorage("theme", localTheme);
      saveLocalStorage("route-before-logout", routeBeforeLogout);
      showToast("Logout realizado com sucesso");
      setSuccessfulLogout(true);
    } catch (error) {
      console.error(error);
      showAlert("Erro ao fazer logout", "error");
    } finally {
      if (successfulLogout) {
        handleClearReduxUserInfo();
      }
      setLoading(false);
    }
  };

  return {handleLogoutUser};
};

export default useLogout;
