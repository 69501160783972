import {
  Box,
  Icon,
  IconButton,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {useFormContext} from "react-hook-form";

type Status = "Concluída" | "Não iniciada" | "Outras";

type Props = {
  name: string;
  status: Status;
  date: string;
  handleConcludeActivity: () => void;
  handleRestoreActivity: () => void;
};

const ActivityListItem = (props: Props) => {
  const {name, status, date, handleConcludeActivity, handleRestoreActivity} =
    props;

  const {watch} = useFormContext();

  const cancelledProcess = watch("agencyingSituation") === "Cancelado";

  const getInfoByStatus = (status: Status) => {
    switch (status) {
      case "Concluída":
        return {text: "Concluído em", color: "success"};

      case "Não iniciada":
        return {text: "Vence em", color: "warning"};
      case "Outras":
        return {text: "Alterado em", color: "info"};

      default:
        return {text: "Alterado em", color: "info"};
    }
  };

  return (
    <ListItem
      sx={(theme) => ({
        borderLeft: `${pxToRem(3)} solid ${
          theme.palette[getInfoByStatus(status).color][500]
        }`,
        mb: pxToRem(3),
      })}
      secondaryAction={
        status === "Não iniciada" || status === "Outras" ? (
          <Tooltip
            title={!cancelledProcess && "Concluir atividade"}
            placement="top"
            arrow
          >
            <Box>
              <IconButton
                onClick={handleConcludeActivity}
                disabled={cancelledProcess}
              >
                <Icon>check</Icon>
              </IconButton>
            </Box>
          </Tooltip>
        ) : (
          <Tooltip
            title={!cancelledProcess && "Restaurar atividade"}
            placement="top"
            arrow
          >
            <Box>
              <IconButton
                onClick={handleRestoreActivity}
                disabled={cancelledProcess}
              >
                <Icon>history</Icon>
              </IconButton>
            </Box>
          </Tooltip>
        )
      }
    >
      <ListItemText
        primary={<Typography variant="subtitle2">{name}</Typography>}
        secondary={
          <Typography
            variant="subtitle3"
            sx={(theme) => ({color: theme.palette.grey[400]})}
          >{`${getInfoByStatus(status).text} ${date}`}</Typography>
        }
        sx={{maxWidth: "95%"}}
      />
    </ListItem>
  );
};

export default ActivityListItem;
