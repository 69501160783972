import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import {AxiosError} from "axios";
import useAlertMessage from "hooks/useAlertMessage";
import pxToRem from "hooks/usePxToRem";
import {Dispatch, SetStateAction, useState} from "react";
import {
  GetProcessById,
  GetProcessFiles,
  PostProcessFile,
} from "services/api_v2/Processes/Processes.service";
import {CustomButton, CustomUploader} from "ui";

interface AttachFilesProps {
  emailData: {
    subject: string;
    to: string[];
    cc: string[];
    bcc: string[];
    attachment: {name: string; url: string; id: string}[];
  };
  setEmailData: Dispatch<
    SetStateAction<{
      subject: string;
      to: string[];
      cc: string[];
      bcc: string[];
      attachment: {name: string; url: string; id: string}[];
    }>
  >;
  processId: number;
}

const AttachFiles = (props: AttachFilesProps) => {
  const ID_OTHER_DOCUMENTS = 50; //id para "outros documentos"
  const [showAlert] = useAlertMessage();

  const [showUploader, setShowUploader] = useState(false);

  const handleShowUploader = () => {
    setShowUploader((state) => !state);
    setShowFilesList(false);
  };

  const getFilesFromProcess = async () => {
    try {
      const {data} = await GetProcessFiles(props.processId, ID_OTHER_DOCUMENTS);
      return Array.isArray(data.data) ? data.data : [];
    } catch (error) {
      console.error(error);
      showAlert("Erro ao trazer documentos do processo");
      return [];
    }
  };

  const uploadFilesToProcess = async (file) => {
    const formData = new FormData();

    formData.append("file", file);
    formData.append(
      "process",
      JSON.stringify({
        description: file?.name,
        idGroupFile: ID_OTHER_DOCUMENTS,
        idProcess: props.processId,
      }),
    );
    try {
      await PostProcessFile(formData);
    } catch (error: any) {
      const err = error as AxiosError;
      showAlert(err?.response?.data?.message);
      console.error(error);
    }
  };

  const handleAttachFileToEmail = async (acceptedFiles) => {
    await uploadFilesToProcess(acceptedFiles[0]);

    const processAttachments = await getFilesFromProcess();
    const lastIndex = processAttachments?.length - 1;
    const lastAttachedFileData = {
      name: processAttachments[lastIndex]?.file?.originalName,
      url: processAttachments[lastIndex]?.file?.url,
      id: processAttachments[lastIndex]?.file?.id,
    };

    props.setEmailData((state) => ({
      ...state,
      attachment: [...state.attachment, lastAttachedFileData],
    }));

    handleShowUploader();
  };

  const [processFilesList, setProcessFilesList] = useState(
    [] as {originalName: string; url: string; id: string}[],
  );
  const [showFilesList, setShowFilesList] = useState(false);

  const getAllFilesFromProcess = async () => {
    try {
      const response = await GetProcessById(props.processId);

      const filesNotAttached = response.data.process.files.filter(
        (item) => !props.emailData.attachment?.some((i) => i.id === item.id),
      );

      setProcessFilesList(filesNotAttached);
    } catch (error: any) {
      console.error(error);
      showAlert(error?.response?.data?.message);
    }
  };

  const handleShowFilesList = () => {
    setShowFilesList((state) => !state);
    setShowUploader(false);

    if (processFilesList.length > 1) {
      setProcessFilesList([]);
    } else {
      getAllFilesFromProcess();
    }
  };

  const handleAddProcessFileToEmailAttachments = (selectedFile: {
    name: string;
    url: string;
    id: string;
  }) => {
    props.setEmailData((state) => ({
      ...state,
      attachment: [...state.attachment, selectedFile],
    }));

    handleShowFilesList();
  };

  return (
    <Grid
      container
      sx={{justifyContent: "space-between", marginBottom: pxToRem(8)}}
    >
      <Grid item xs={6.5}>
        <CustomButton
          dataTestId="btn-attach-from-process"
          variant="outlined"
          size="small"
          onClickFn={handleShowFilesList}
          sx={{fontSize: "12px", width: "100%"}}
        >
          Anexar do processo
        </CustomButton>
      </Grid>
      <Grid item xs={5}>
        <CustomButton
          dataTestId="btn-attach-new"
          variant="outlined"
          size="small"
          onClickFn={handleShowUploader}
          sx={{fontSize: "12px", width: "100%"}}
        >
          Anexar novo
        </CustomButton>
      </Grid>

      {showFilesList && processFilesList.length > 0 && (
        <RadioGroup
          data-testid="files-options"
          aria-labelledby="radio-buttons-group"
          name="radio-buttons-group"
        >
          {processFilesList.map((item) => (
            <FormControlLabel
              key={item.id}
              value={{id: item.id, name: item.originalName, url: item.url}}
              control={<Radio size="small" />}
              label={
                <Typography sx={{fontSize: "11px"}}>
                  {item.originalName}
                </Typography>
              }
              onClick={() =>
                handleAddProcessFileToEmailAttachments({
                  id: item.id,
                  name: item.originalName,
                  url: item.url,
                })
              }
            />
          ))}
        </RadioGroup>
      )}
      {showFilesList && !processFilesList.length && (
        <Typography
          data-testid="info-no-available-files"
          sx={{
            width: "100%",
            fontSize: "11px",
            textAlign: "center",
            marginTop: pxToRem(8),
          }}
        >
          Nenhum documento do processo disponível
        </Typography>
      )}
      {showUploader && (
        <Grid data-testid="file-uploader" item xs={12}>
          <CustomUploader
            singleFile
            handleSetDocuments={(acceptedFiles) =>
              handleAttachFileToEmail(acceptedFiles)
            }
          />
        </Grid>
      )}
    </Grid>
  );
};

export default AttachFiles;
