import {AxiosResponse} from "axios";
import {newBackendAPI} from "services/apiConsumer";
import {QueryPagination} from "types/GenericTypes";
import {ClientContactsType} from "views/Companies/CompanyDetails/components/ClientContacts/ClientContacts.types";
import {GetContactsResponse} from "./Contacts.types";

export const GetContactsByPersonId = async (
  personId: number,
): Promise<AxiosResponse<ClientContactsType[]>> => {
  return await newBackendAPI.get(`/contacts/${personId}`);
};

export const PostContacts = async (newContact: ClientContactsType) => {
  return await newBackendAPI.post(`/contacts`, newContact);
};

export const PatchContacts = async (
  personId: number,
  contact: ClientContactsType,
) => {
  return await newBackendAPI.patch(`/contacts/${personId}`, contact);
};

export const GetContacts = async (
  query: QueryPagination & {idProcess?: number; idMessageGroup?: number},
): Promise<AxiosResponse<GetContactsResponse>> => {
  const params = {...query};

  return await newBackendAPI.get(`/contacts`, {params});
};

export const PostMovimentationContacts = async (
  idLegalPersonContact: number,
  sendMessageGroups: {idSendMessageGroup: number}[],
  idGroupActivity: number,
) => {
  return await newBackendAPI.post(`/movimentation-contacts`, {
    idLegalPersonContact,
    sendMessageGroups,
    idGroupActivity,
  });
};

export const DeleteMovimentationContacts = async (
  idContact: number,
  idSendMessageGroup?: number,
) => {
  return await newBackendAPI.delete(`/movimentation-contacts/${idContact}`);
};

export const PostLogisticResponsibles = async (
  idTaskRole: number,
  idEmployee: number,
  idLogisticProcess: number,
) => {
  return await newBackendAPI.post(`/logistic-responsibles`, {
    idTaskRole,
    idEmployee,
    idLogisticProcess,
  });
};

export const DeleteResponsibles = async (idResponsible: number) => {
  return await newBackendAPI.delete(`/logistic-responsibles/${idResponsible}`);
};
