import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteRenderOptionState,
  CircularProgress,
  FilterOptionsState,
  FormControl,
  FormHelperText,
  Icon,
  OutlinedTextFieldProps,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import useMasks from "hooks/useMasks";
import pxToRem from "hooks/usePxToRem";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

export type AutoCompleteData = {
  id: number;
  label: string;
  additional?: string | {cnpj: string; vatNumber: string};
};

interface AutoCompleteTypeProps extends Partial<OutlinedTextFieldProps> {
  freeSolo?: boolean;
  onChangeFn?: (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason?: AutocompleteChangeReason,
  ) => void;
  options: Array<string | number> | Array<AutoCompleteData>;
  width?: string;
  warning?: boolean;
  warningText?: string;
  helperText?: string;
  onOpen?: (event: React.SyntheticEvent<Element, Event>) => void;
  isLoading?: boolean;
  loadingText?: string;
  onBottomScroll?: () => boolean | void;
  filterOptions?: (
    options: unknown[],
    state: FilterOptionsState<unknown>,
  ) => unknown[];
  onInputChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => void;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode;
  getOptionLabel?: (option: any) => string;
  isDisabled?: boolean;
  multiple?: boolean;
  hasMorePagesToLoad?: boolean;
  tooltipText?: string;
  optionSecondaryContent?: boolean;
}

const CustomAutoComplete = (props: AutoCompleteTypeProps) => {
  const {
    freeSolo = true,
    onChangeFn,
    error,
    warning,
    options,
    name,
    helperText,
    warningText = "Campo divergente",
    isLoading = false,
    loadingText = "Carregando...",
    onOpen,
    onBottomScroll = () => false,
    filterOptions,
    onInputChange,
    renderOption,
    getOptionLabel,
    isDisabled = false,
    multiple = false,
    hasMorePagesToLoad,
    tooltipText,
    ...rest
  } = props;

  const {cnpjMask} = useMasks();

  const theme = useTheme();

  const ListboxComponent = React.forwardRef<
    HTMLUListElement,
    React.HTMLAttributes<HTMLElement>
  >(function ListboxComponent(props, ref) {
    return (
      <ul
        {...props}
        ref={ref}
        id="scrollableUl"
        style={{
          minHeight: pxToRem(200),
          overflow: hasMorePagesToLoad ? "scroll" : "auto",
        }}
      >
        {hasMorePagesToLoad ? (
          <InfiniteScroll
            dataLength={options?.length}
            next={onBottomScroll}
            hasMore={!!hasMorePagesToLoad}
            loader={<CircularProgress />}
            scrollableTarget="scrollableUl"
            style={{overflow: hasMorePagesToLoad ? "scroll" : "auto"}}
          >
            {props.children}
          </InfiniteScroll>
        ) : (
          props.children
        )}
      </ul>
    );
  });

  const optionSecondaryText = (option) => {
    if (typeof option?.additional === "string") {
      return option?.additional;
    }
    if (option?.additional?.cnpj) {
      return `CNPJ: ${cnpjMask(option?.additional?.cnpj)}`;
    } else if (option?.additional?.vatNumber) {
      return `VAT Number: ${option?.additional?.vatNumber}`;
    }
    return "-";
  };

  return (
    <FormControl
      sx={{
        width: props.width ? props.width : "100%",
      }}
    >
      <Tooltip title={tooltipText} placement="top" arrow>
        <Autocomplete
          ListboxComponent={ListboxComponent}
          options={options}
          data-testid={name}
          id={name}
          freeSolo={freeSolo}
          selectOnFocus
          multiple={multiple}
          onOpen={onOpen}
          onInputChange={onInputChange}
          loading={isLoading}
          loadingText={loadingText}
          clearOnBlur
          filterOptions={filterOptions}
          value={rest.value}
          onChange={onChangeFn}
          disabled={isDisabled}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={(value: any, option: any) =>
            value.id === option.id
          }
          renderOption={(props, option: any, state) => {
            return (
              renderOption ?? (
                <li
                  {...props}
                  key={`${option?.id}.${state?.index}`}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography style={{fontSize: pxToRem(14)}}>
                    {option?.label}
                  </Typography>
                  {rest.optionSecondaryContent && (
                    <Typography style={{fontSize: pxToRem(12)}}>
                      {optionSecondaryText(option)}
                    </Typography>
                  )}
                </li>
              )
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              {...rest}
              error={error}
              color={warning ? "warning" : undefined}
              focused={warning}
              name={name}
              InputProps={{
                style: {fontSize: rest?.sx?.["fontSize"] ?? pxToRem(14)},
                ...params.InputProps,
              }}
            />
          )}
        />
      </Tooltip>
      {(error || warning) && (
        <FormHelperText
          sx={{
            m: pxToRem(3),
            color: theme.palette[error ? "error" : "warning"][700],
            display: "flex",
            alignItems: "center",
          }}
        >
          <Icon sx={{fontSize: pxToRem(20), mr: pxToRem(2)}}>info</Icon>
          <Typography variant="body3" component="span">
            {error && helperText}
            {warning && warningText}
          </Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomAutoComplete;
