import {createActions, createReducer} from "reduxsauce";

type initial = {
  dataModal: {
    title: string;
    subtitle: string;
    onClose?: () => void;
    confirmButtonFn?: Promise<void>;
  };
  openModal: boolean;
};

const INITIAL_STATE: initial = {
  dataModal: {
    title: "",
    subtitle: "",
  },
  openModal: false,
};

export const {Types, Creators} = createActions({
  setDataModal: ["dataModal"],
  showModal: ["openModal"],
});

const showModal = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  openModal: action.openModal,
});

const setDataModal = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  dataModal: action.dataModal,
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_DATA_MODAL]: setDataModal,
  [Types.SHOW_MODAL]: showModal,
});
