/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {ThemeProvider} from "@mui/material/styles";
import {Container, Box} from "@mui/material";
import {renderRoutes} from "react-router-config";
import {MyToast} from "components";
import {CustomLoading} from "ui";
import lightTheme from "global/themes/lightTheme";
import darkTheme from "global/themes/darkTheme";
import {readLocalStorage} from "hooks/useLocalStorage";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import useWindowDimensions from "hooks/useWindowDimensions";

const Public = (props: any) => {
  const {route} = props;
  const {userInfo} = useSelector((state: any) => state.stateUserInfo);
  const {screenWidth} = useWindowDimensions();

  const history = useHistory();
  const theme = readLocalStorage("theme");

  useEffect(() => {
    if (userInfo.access_token && screenWidth > 768) {
      history.push("/dashboards");
    }
  }, [userInfo.access_token]);

  return (
    <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
      <Container
        maxWidth="xl"
        disableGutters
        sx={(theme) => ({
          backgroundColor: theme.palette.background.default,
        })}
      >
        <Box sx={{height: "100vh"}}>{renderRoutes(route.routes)}</Box>
        <MyToast />
        <CustomLoading />
      </Container>
    </ThemeProvider>
  );
};

export default Public;
