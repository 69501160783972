/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, SxProps, Theme} from "@mui/material";
import Dropzone from "react-dropzone";

type UploaderIcon = {
  children: JSX.Element | JSX.Element[];
  onDrop: <T extends File>(acceptedFiles: T[]) => void;
  sx?: SxProps<Theme>;
  alignItems?: string;
  accept?: string | string[];
};

const BOUploaderIcon = ({
  children,
  onDrop,
  sx,
  alignItems,
  accept,
}: UploaderIcon) => {
  return (
    <Dropzone onDrop={onDrop} accept={accept}>
      {({getRootProps, getInputProps}) => (
        <section>
          <div {...getRootProps()} style={{position: "relative"}}>
            <input {...getInputProps()} />
            <Box
              sx={{
                ...sx,
                display: "flex",
                flexDirection: "column",
                alignItems: alignItems ?? "center",
                cursor: "pointer",
              }}
            >
              {children}
            </Box>
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default BOUploaderIcon;
