/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Stack, Typography, useTheme} from "@mui/material";
import {ReactNode} from "react";
import pxToRem from "hooks/usePxToRem";
import {SectionContainer} from "components";
import {CustomButton, CustomIconButton} from "ui";

interface ConditionGroupContainerProps {
  title: string;
  defaultRuleInputs: ReactNode;
  children: ReactNode;
  specialRulesList: any[];
  handleAddSpecialRule: () => void;
  disableAddSpecialRuleButton?: boolean;
  hideButtons?: boolean;
  isSubCard?: boolean;
  deleteBigRule?: () => void;
}

const ConditionGroupContainer = (props: ConditionGroupContainerProps) => {
  const {
    title,
    defaultRuleInputs,
    children,
    specialRulesList,
    handleAddSpecialRule,
    disableAddSpecialRuleButton,
    hideButtons,
    isSubCard,
    deleteBigRule,
  } = props;

  const theme = useTheme();

  return (
    <SectionContainer
      title={title}
      titleSize={isSubCard ? 14 : 16}
      isSubcard={isSubCard}
      actions={
        deleteBigRule && (
          <Box sx={{paddingRight: pxToRem(16)}}>
            <CustomIconButton
              iconName={"delete"}
              onClick={deleteBigRule}
              tooltipText={`Deletar ${title}`}
              iconSize={21}
              sx={{
                boxShadow: "0.75px 0.75px 3px 1px rgba(0, 0, 0, 0.1)",
                borderRadius: pxToRem(4),
                border: "1px solid transparent",
                height: pxToRem(38),
                "&:hover": {
                  boxShadow: "border-box",
                  border: `1px solid ${theme.palette.error[600]}`,
                  color: theme.palette.error[600],
                  transition: "0.6s",
                },
              }}
            />
          </Box>
        )
      }
    >
      <Stack
        alignItems={"flex-end"}
        sx={{width: "99%", padding: `${pxToRem(12)} ${pxToRem(8)} 0 0`}}
      >
        <Stack
          direction="row"
          sx={{
            position: "relative",
            gap: pxToRem(8),
            paddingBottom: `${pxToRem(22)}`,
            width: "100%",
            borderRadius: `${pxToRem(4)} ${pxToRem(4)} 0 0`,
          }}
        >
          {defaultRuleInputs}
        </Stack>
        {!hideButtons &&
          (specialRulesList?.length <= 0 ? (
            <CustomButton
              startIcon={"add"}
              variant="text"
              onClickFn={handleAddSpecialRule}
              disabled={disableAddSpecialRuleButton}
              sx={{paddingRight: 0, justifyContent: "flex-end"}}
            >
              Adicionar regras especiais
            </CustomButton>
          ) : (
            <Stack
              sx={{
                position: "relative",
                padding: `${pxToRem(16)}`,
                width: "100%",
                border: `1px solid ${theme.palette.grey[100]}`,
                borderRadius: `${pxToRem(4)} ${pxToRem(4)}`,
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  marginBottom: pxToRem(12),
                }}
              >
                Regras especiais para {title?.toLowerCase()}
              </Typography>
              {children}
            </Stack>
          ))}
      </Stack>
    </SectionContainer>
  );
};
export default ConditionGroupContainer;
