import {Grid} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {useState} from "react";
import {useFormContext} from "react-hook-form";
import {getTaskRoles} from "services/api_v2/Tasks/tasks.service";
import {GetOnlyEmployees} from "services/api_v2/User/user.service";
import {CustomInputWrapper, CustomAutocomplete} from "ui";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";
import useHandleFieldLists from "views/ProcessDetails/hooks/useHandleFieldLists";

type Props = {
  selectedResponsible: AutoCompleteData | null;
  setSelectedResponsible: React.Dispatch<
    React.SetStateAction<AutoCompleteData | null>
  >;
  selectedTaskRole: AutoCompleteData | null;
  setSelectedTaskRole: React.Dispatch<
    React.SetStateAction<AutoCompleteData | null>
  >;
};

const NextResponsibles = (props: Props) => {
  const {
    selectedResponsible,
    setSelectedResponsible,
    selectedTaskRole,
    setSelectedTaskRole,
  } = props;

  const {getValues} = useFormContext();

  const [responsibleOptions, setResponsibleOptions] = useState<
    {label: string; id: number}[]
  >([]);
  const [responsibleSearch, setResponsibleSearch] = useState<string>();

  const formatResponsiblesList = (data) => {
    const persons = getValues("responsibles");

    const result = data.data?.map((client) => ({
      label: `${client?.person?.name} - ${client?.person?.id}`,
      id: client?.person?.id,
    }));

    const filteredResult = result.filter(
      (responsible) =>
        persons.findIndex((person) => person.idPerson === responsible.id) < 0,
    );
    return filteredResult;
  };

  const {
    getOptions: getResponsibleOptions,
    onBottomScroll: onBottomScrollResponsible,
    isLoadingOptions: isLoadingResponsibleOptions,
  } = useHandleFieldLists(
    (page: number, limit: number, search: string) =>
      GetOnlyEmployees({page, name: search, limit}),
    formatResponsiblesList,
  );
  const handleSelectResponsible = (newResponsible: AutoCompleteData) => {
    setSelectedResponsible(newResponsible);
  };

  const handleSelectTaskRole = (newTaskRole: AutoCompleteData) => {
    setSelectedTaskRole(newTaskRole);
  };

  const [taskRolesOptions, setTaskRolesOptions] = useState<
    {label: string; id: number}[]
  >([]);

  const formatOp = (data) => {
    const responsibles = getValues("responsibles");

    const list: any[] = data.map((client) => ({
      label: `${client?.name} - ${client?.id}`,
      id: client.id,
    }));

    const filteredList = list.filter(
      (listItem) =>
        responsibles.findIndex(
          (contact) => contact.taskRoleId === listItem.id,
        ) < 0,
    );

    return filteredList;
  };

  const {
    getOptions: getTaskRolesOptions,
    isLoadingOptions: isLoadingTaskRolesOptions,
  } = useHandleFieldLists(() => getTaskRoles(), formatOp);

  return (
    <Grid container item xs={12} columnSpacing={pxToRem(8)}>
      <CustomInputWrapper title="Responsável" xs={8}>
        <CustomAutocomplete
          name="nextResponsible"
          placeholder="Responsável Next"
          loading={isLoadingResponsibleOptions}
          options={responsibleOptions}
          value={selectedResponsible?.label ?? null}
          onOpen={
            responsibleOptions.length > 0
              ? undefined
              : () =>
                  getResponsibleOptions(
                    setResponsibleOptions,
                    1,
                    responsibleSearch,
                  )
          }
          onInputChange={(e, value) => {
            if (e !== null) {
              setResponsibleSearch(value);
              getResponsibleOptions(
                setResponsibleOptions,
                1,
                responsibleSearch,
              );
            }
          }}
          getNextPage={() => {
            onBottomScrollResponsible(setResponsibleOptions, responsibleSearch);
          }}
          onChange={(_, value) => {
            handleSelectResponsible(value);
          }}
          isOptionEqualToValue={(option, value) => value === option?.label}
        />
      </CustomInputWrapper>
      <CustomInputWrapper title="Função" xs={4}>
        <CustomAutocomplete
          name="taskRole"
          placeholder="Função"
          loading={isLoadingTaskRolesOptions}
          options={taskRolesOptions}
          value={selectedTaskRole?.label ?? null}
          onOpen={
            taskRolesOptions?.length > 0
              ? undefined
              : () => getTaskRolesOptions(setTaskRolesOptions, 1)
          }
          onChange={(_, value) => {
            handleSelectTaskRole(value);
          }}
          isOptionEqualToValue={(option, value) => value === option?.label}
        />
      </CustomInputWrapper>
    </Grid>
  );
};

export default NextResponsibles;
