import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {Task} from "components/ModalCompleteActivity/CompleteActivity.types";
import pxToRem from "hooks/usePxToRem";
import React, {ChangeEvent, Dispatch, SetStateAction} from "react";

type Props = {
  taskData: Task;
  setTaskData: Dispatch<SetStateAction<Task>>;
};

const MultiPathList = (props: Props) => {
  const {taskData, setTaskData} = props;

  const handleListItemClick = (
    _: ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setTaskData((prev) => ({...prev, idSelectedPath: +value}));
  };

  const theme = useTheme();

  return (
    <Box sx={{width: "100%", bgcolor: "background.paper"}}>
      <FormControl>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={pxToRem(24)}
        >
          <Typography
            variant="subtitle1"
            component="strong"
            sx={{
              position: "relative",
              fontWeight: 600,
              paddingLeft: 0,

              "&::after": {
                content: '""',
                position: "absolute",
                left: 0,
                bottom: pxToRem(-4),
                height: pxToRem(4),
                width: pxToRem(36),
                backgroundColor: theme.palette.primary.main,
                borderRadius: pxToRem(30),
              },
            }}
          >
            Multi caminhos
          </Typography>
        </Stack>
        <RadioGroup defaultValue={null} onChange={handleListItemClick}>
          {taskData?.taskActivities?.map((path) => (
            <React.Fragment key={path?.idNewTask}>
              <FormControlLabel
                value={path?.idNewTask}
                control={<Radio />}
                label={path?.pathGenerateActivity}
              />
            </React.Fragment>
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default MultiPathList;
