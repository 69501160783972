/* eslint-disable @typescript-eslint/no-explicit-any */
import useAlertMessage from "hooks/useAlertMessage";
import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {AllVersionUpdates} from "services/api_v2/Dev/dev.service";
import {VersionByUser} from "services/api_v2/User/user.service";
import {Types as userInfoTypes} from "store/reducers/userInfoReducer";

const ModalVersionUpdatesData = () => {
  const [showAlert] = useAlertMessage();

  const {userInfo} = useSelector((state: any) => state.stateUserInfo);
  const versionUpdates = userInfo?.versionUpdates;

  const [open, setOpen] = useState<boolean>(
    versionUpdates != null &&
      versionUpdates?.length > 0 &&
      versionUpdates != undefined,
  );
  const [page, setPage] = useState<number>(1);
  const [allVersionUpdates, setAllVersionUpdates] = useState<any[]>([]);

  const dispatch = useDispatch();

  const getAllVersionUpdates = async () => {
    try {
      const {data} = await AllVersionUpdates();
      setAllVersionUpdates(data);
    } catch (error: any) {
      console.error(error);
      showAlert(error?.response?.data?.message, "error");
    }
  };

  const getVersionUpdateId = (versionUpdate: any) => {
    return allVersionUpdates
      .filter((update) => versionUpdate.title === update.title)
      .map((update) => update.id)[0];
  };

  const versionUpdatesId = versionUpdates?.map((update: any) =>
    getVersionUpdateId(update),
  );

  const registerReadVersionUpdates = async () => {
    try {
      const versionByUserData = {
        idPerson: userInfo.id,
        idVersionUpdate: versionUpdatesId,
      };
      await VersionByUser(versionByUserData);
    } catch (error: any) {
      console.error(error);
      showAlert(error?.response?.data?.message, "error");
    }
  };

  const handleClose = () => {
    registerReadVersionUpdates();
    setOpen(false);
    dispatch({
      type: userInfoTypes.SET_USER_INFO,
      userInfo: {...userInfo, versionUpdates: []},
    });
  };

  useEffect(() => {
    getAllVersionUpdates();
  }, [userInfo]);

  return {page, setPage, open, handleClose, versionUpdates};
};

export default ModalVersionUpdatesData;
