import {ButtonProps, Theme} from "@mui/material";
import pxToRem from "hooks/usePxToRem";

interface MuiButtonStyleOverridesRootProps {
  theme: Theme;
  ownerState: ButtonProps;
}

const defaultButton = {
  MuiButton: {
    styleOverrides: {
      root: ({ownerState, theme}: MuiButtonStyleOverridesRootProps) => ({
        ...(ownerState.size === "small" && {
          padding: `${pxToRem(6)} ${pxToRem(12)}`,
          fontSize: theme.typography.button2.fontSize,
        }),

        ...(ownerState.size === "medium" && {
          padding: `${pxToRem(8)} ${pxToRem(14)}`,
        }),

        ...(ownerState.size === "large" && {
          padding: `${pxToRem(10)} ${pxToRem(16)}`,
        }),
      }),
    },
  },
};

export default defaultButton;
