import {EmailData} from "components/EmailPreviewModal/EmailPreviewModal";
import {useState} from "react";
import {
  GenerateFollowUpActivity,
  PostTaskFinish,
  SendEmailFromActivity,
} from "services/api_v2/CompleteActivity/CompleteActivity.service";
import useLoading from "./useLoading";
import useAlertMessage from "./useAlertMessage";
import useToast from "./useToast";
import {AxiosError} from "axios";
import {mountEmailHtml} from "util/mountEmailHtml";
import {ActivityStatus} from "util/globalEnums";
import {
  GetPendingEmailById,
  SendPendingEmail,
} from "services/api_v2/PendingEmails/PendingEmails.service";

const useSendFollowUp = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [emailReturn, setEmailReturn] = useState("");

  const [activityId, setActivityId] = useState<number>();
  const [processId, setProcessId] = useState<number>(NaN);

  const [setLoading] = useLoading();
  const [showAlert] = useAlertMessage();

  const [showToast] = useToast();

  const [emailData, setEmailData] = useState<EmailData>({
    subject: "",
    to: [],
    cc: [],
    bcc: [],
    attachment: [],
  });

  const handleGetFollowUp = async (id: number) => {
    try {
      setLoading(true);
      setProcessId(id);
      const createResponse = await GenerateFollowUpActivity(id);

      const finishActivityResponse = await PostTaskFinish({
        id: createResponse?.data?.id,
        activityStatus: ActivityStatus.Concluida,
        updatedField: [],
      });

      setActivityId(createResponse?.data?.id);

      setEmailData({
        subject: finishActivityResponse?.data?.info?.subject ?? "",
        to: finishActivityResponse?.data?.info?.to ?? [],
        cc: finishActivityResponse?.data?.info?.cc ?? [],
        bcc: finishActivityResponse?.data?.info?.bcc ?? [],
        attachment:
          finishActivityResponse?.data?.attachment?.map((item) => ({
            url: item?.url ?? "",
            name: item?.name ?? "",
            id: crypto.randomUUID(),
          })) ?? [],
      });

      setEmailReturn(finishActivityResponse?.data?.email?.replace(/\\/g, ""));

      setIsOpen(true);
    } catch (err) {
      const error = err as AxiosError;
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendFollowUp = async (
    localEmailData: EmailData,
    editedEmail: string,
  ) => {
    try {
      setLoading(true);
      await SendEmailFromActivity({
        idActivity: activityId ?? 0,
        email: mountEmailHtml(editedEmail, emailReturn),
        info: {
          subject: localEmailData?.subject,
          to: localEmailData?.to,
          cc: localEmailData?.cc,
          bcc: localEmailData?.bcc,
        },
        attachment: localEmailData.attachment,
      });
      showToast("Follow up enviado com sucesso", "success");
      handleCloseModal();
    } catch (err) {
      const error = err as AxiosError;
      console.error(error);
      showAlert(error?.response?.data?.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleAbortFollowUp = async () => {
    try {
      await PostTaskFinish({
        id: activityId,
        activityStatus: "Cancelada",
      });
      showToast("Envio cancelado com sucesso!", "success");
    } catch (err) {
      const error = err as AxiosError;
      console.error(error);
      showAlert(error?.response?.data?.message, "error");
    } finally {
      handleCloseModal();
    }
  };
  const handleGetGenericEmail = async (emailId: number) => {
    try {
      setLoading(true);
      const {data: emailData} = await GetPendingEmailById(emailId);
      setEmailReturn(emailData?.email?.replace(/\\/g, ""));
      setEmailData({
        subject: emailData?.info?.subject ?? "",
        to: emailData?.info?.to ?? [],
        cc: emailData?.info?.cc ?? [],
        bcc: emailData?.info?.bcc ?? [],

        attachment:
          emailData?.attachment?.map((item) => ({
            url: item?.url ?? "",
            name: item?.name ?? "",
            id: crypto.randomUUID(),
          })) ?? [],
      });

      setIsOpen(true);
    } catch (err) {
      const error = err as AxiosError;
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendGenericEmail = async (
    localEmailData: EmailData,
    editedEmail: string,
    idActivity: number,
    idSendEmailInBatch: number,
  ) => {
    try {
      setLoading(true);
      const emailData = {
        idActivity: idActivity,
        idSendEmailInBatch: idSendEmailInBatch,
        attachment: localEmailData.attachment,
        email: mountEmailHtml(editedEmail, emailReturn),
        info: {
          subject: localEmailData.subject,
          to: localEmailData.to,
          cc: localEmailData.cc,
          bcc: localEmailData.bcc,
        },
      };
      await SendPendingEmail(emailData);
      showToast("Email enviado com sucesso", "success");

      handleCloseModal();
    } catch (err) {
      const error = err as AxiosError;
      console.error(error);
      showAlert(error?.response?.data?.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setEmailData({
      subject: "",
      to: [],
      cc: [],
      bcc: [],
      attachment: [],
    });
    setEmailReturn("");
  };

  return {
    isOpen,
    setIsOpen,
    emailReturn,
    setEmailReturn,
    handleGetFollowUp,
    handleSendFollowUp,
    emailData,
    setEmailData,
    handleCloseModal,
    handleAbortFollowUp,
    handleGetGenericEmail,
    handleSendGenericEmail,
    processId,
  };
};

export default useSendFollowUp;
