import {Tooltip, Icon} from "@mui/material";

type Props = {
  onClick: () => void;
  tooltipText?: string;
};

const HelperIcon = (props: Props) => {
  const {onClick, tooltipText = "Ver dados correspondência"} = props;
  return (
    <Tooltip title={tooltipText} arrow placement="top">
      <Icon
        data-testid={"helper-icon"}
        sx={{
          fontSize: "14px",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        markunread_box
      </Icon>
    </Tooltip>
  );
};

export default HelperIcon;
