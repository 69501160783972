import {Stack, useTheme} from "@mui/material";
import {InfoCard} from "./components";
import pxToRem from "hooks/usePxToRem";
import {CustomButton} from "ui";
import {useHistory} from "react-router-dom";
import {TaskInfoProps} from "./TaskInfo.types";

const TaskInfo = (props: TaskInfoProps) => {
  const theme = useTheme();
  const history = useHistory();

  const {infoCardData, hideButton, itemId} = props;

  const redirectToProjectTasksList = () => {
    history.goBack();
  };

  return (
    <Stack sx={{paddingTop: pxToRem(24.5)}} gap={pxToRem(29)}>
      {infoCardData.map((item) => (
        <InfoCard
          key={item.title}
          title={item.title}
          content={item.content}
          iconify={item?.iconify}
          icon={item?.icon}
        />
      ))}
      {hideButton
        ? ""
        : itemId !== "null" && (
            <CustomButton
              variant="outlined"
              endIcon={"open_in_new"}
              size="small"
              onClickFn={redirectToProjectTasksList}
              sx={{
                color: theme.palette.text.primary,
                border: `1px solid ${theme.palette.grey[300]}`,
                width: "100%",
                textTransform: "none",
                fontSize: pxToRem(16),
              }}
            >
              Ver no projeto
            </CustomButton>
          )}
    </Stack>
  );
};

export default TaskInfo;
