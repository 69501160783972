import {Grid, ToggleButtonGroup, ToggleButton} from "@mui/material";
import {CustomModal} from "components";
import {DefaultModalFooter} from "components/CustomModal/components";
import pxToRem from "hooks/usePxToRem";
import {useState} from "react";
import {CustomButton} from "ui";
import NextResponsibles from "../NextResponsibles";
import ExternalContacts from "../ExternalContacts";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";
import {
  PostLogisticResponsibles,
  PostMovimentationContacts,
} from "services/api_v2/Contacts/Contacts.service";
import useToast from "hooks/useToast";
import {AxiosError} from "axios";
import useAlertMessage from "hooks/useAlertMessage";
import {useFormContext} from "react-hook-form";

type Props = {
  modalIsOpen: boolean;
  handleCloseModal: (refetch?: boolean) => void;
};

type TypeOfContact = "nextResponsibles" | "externalContacts";

const ModalLinkContactToProcess = (props: Props) => {
  const {modalIsOpen, handleCloseModal} = props;
  const {getValues} = useFormContext();
  const [showAlert] = useAlertMessage();
  const [showToast] = useToast();

  const [typeOfContact, setTypeOfContact] =
    useState<TypeOfContact>("externalContacts");

  const handleChangeTypeOfContact = (
    _: React.MouseEvent<HTMLElement>,
    typeOfContact: TypeOfContact,
  ) => {
    if (!typeOfContact) {
      return;
    }
    setTypeOfContact(typeOfContact);
  };

  const [selectedResponsible, setSelectedResponsible] =
    useState<AutoCompleteData | null>(null);

  const [selectedTaskRole, setSelectedTaskRole] =
    useState<AutoCompleteData | null>(null);

  const [selectedContact, setSelectedContact] = useState<number>();

  const [selectedGroups, setSelectedGroups] = useState<number[]>([]);

  const handleLinkContact = async () => {
    if (typeOfContact === "nextResponsibles") {
      if (!selectedTaskRole || !selectedResponsible) return;
      try {
        await PostLogisticResponsibles(
          selectedTaskRole?.id,
          selectedResponsible?.id,
          getValues("id"),
        );
        const shouldRefetch = true;
        handleCloseModal(shouldRefetch);
        showToast("Contato incluído com sucesso", "success");
      } catch (err) {
        const error = err as AxiosError;
        showAlert(error?.response?.data?.message, "error");
        console.error(error);
      }
    }

    if (typeOfContact === "externalContacts" && selectedContact) {
      try {
        await PostMovimentationContacts(
          selectedContact,
          selectedGroups.map((group) => ({idSendMessageGroup: group})),
          getValues("idGroupActivity"),
        );
        const shouldRefetch = true;
        handleCloseModal(shouldRefetch);
        showToast("Contato incluído com sucesso", "success");
      } catch (err) {
        const error = err as AxiosError;
        showAlert(error?.response?.data?.message, "error");
        console.error(error);
      }
    }
  };

  const buttonIsDisabled = () => {
    if (
      typeOfContact === "externalContacts" &&
      selectedContact &&
      selectedGroups.length > 0
    ) {
      return false;
    }

    if (
      typeOfContact === "nextResponsibles" &&
      selectedResponsible &&
      selectedTaskRole
    ) {
      return false;
    }
    return true;
  };

  return (
    <CustomModal
      isOpen={modalIsOpen}
      onClose={() => handleCloseModal()}
      title={"Vincular novo contato ao processo"}
      titleIcon={"add"}
      maxWidth="md"
    >
      <Grid container minHeight={pxToRem(200)} p={pxToRem(20)}>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          height={pxToRem(40)}
        >
          <ToggleButtonGroup
            color="primary"
            value={typeOfContact}
            exclusive
            onChange={handleChangeTypeOfContact}
            aria-label="Platform"
            size="small"
          >
            {/* Inclusão de Responsáveis Next não será habilitado neste momento. */}
            {/* <ToggleButton value="nextResponsibles">
              Responsáveis Next
            </ToggleButton> */}
            <ToggleButton value="externalContacts">
              Contatos Externos
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        {typeOfContact === "nextResponsibles" && (
          <NextResponsibles
            selectedResponsible={selectedResponsible}
            setSelectedResponsible={setSelectedResponsible}
            selectedTaskRole={selectedTaskRole}
            setSelectedTaskRole={setSelectedTaskRole}
          />
        )}
        {typeOfContact === "externalContacts" && (
          <ExternalContacts
            setSelectedContact={setSelectedContact}
            selectedGroups={selectedGroups}
            setSelectedGroups={setSelectedGroups}
          />
        )}
      </Grid>
      <DefaultModalFooter>
        <Grid item xs></Grid>
        <Grid item>
          <CustomButton
            fullWidth
            variant="outlined"
            onClickFn={() => handleCloseModal()}
          >
            Cancelar
          </CustomButton>
        </Grid>
        <Grid item>
          <CustomButton
            dataTestId="link-button"
            fullWidth
            onClickFn={handleLinkContact}
            disabled={buttonIsDisabled()}
          >
            Vincular
          </CustomButton>
        </Grid>
      </DefaultModalFooter>
    </CustomModal>
  );
};

export default ModalLinkContactToProcess;
