import pxToRem from "hooks/usePxToRem";

import {Grid, TextField} from "@mui/material";

import {CustomInput, CustomInputWrapper, CustomSelect} from "ui";
import {DefaultCardStructure} from "components";
import {ModalityPayment, PlaceIssue} from "util/globalEnums";
import {Controller, useFieldArray, useFormContext} from "react-hook-form";
import useValueOrEmpty from "hooks/useValueOrEmpty";
import {DefaultProcessDetails} from "views/ProcessDetails/Process.types";
import ProcessDatePicker from "views/ProcessDetails/components/ProcessDatePicker";
import {useState} from "react";

const HouseInfo = () => {
  const {control, setValue, watch, getValues} =
    useFormContext<DefaultProcessDetails>();

  const {fields: houseBl} = useFieldArray({
    control,
    keyName: "formId",
    name: "houseBL",
  });

  const {valueOrEmptyString} = useValueOrEmpty();

  const [allowHouseIsRequired, setAllowHouseIsRequired] = useState(false);

  const checkIfHouseIsRequired = () => {
    if (
      getValues("houseBL")[0]?.dateDeconsolidation ||
      getValues("houseBL")[0]?.placeIssue ||
      getValues("houseBL")[0]?.logisticBillLading?.descriptionOfPackages
    ) {
      setAllowHouseIsRequired(true);
    } else {
      setAllowHouseIsRequired(false);
    }
  };

  const [
    dateDeconsolidationIsDisabled,
    setDisableDateDeconsolidationIsDisabled,
  ] = useState(!getValues("houseBL")[0].number);
  return (
    <DefaultCardStructure title="BL House" hasDivider={false} isSubcard>
      {houseBl.map((house, index) => {
        return (
          <Grid
            container
            rowSpacing={pxToRem(20)}
            columnSpacing={pxToRem(8)}
            key={`${house.formId}voyages[${index}]`}
            sx={{mb: pxToRem(8)}}
          >
            <CustomInputWrapper md={2.4} title="BL n°">
              <Controller
                name={`houseBL.${index}.number` as const}
                control={control}
                render={({field: {value}}) => (
                  <CustomInput
                    size="small"
                    placeholder="house n°"
                    name={`houseBL.${index}.number`}
                    value={valueOrEmptyString(value)}
                    onBlur={() => {
                      checkIfHouseIsRequired();
                    }}
                    required={allowHouseIsRequired}
                    onChange={(e) => {
                      setDisableDateDeconsolidationIsDisabled(false);
                      setValue(`houseBL.${index}.number`, e.target.value, {
                        shouldDirty: true,
                      });
                      setValue(`houseBL.${index}.typeKnowledge`, "House", {
                        shouldDirty: true,
                      });
                    }}
                  />
                )}
              />
            </CustomInputWrapper>

            <CustomInputWrapper md={2.4} title="Modelo De Pagamento" isDisabled>
              <Controller
                name={`houseBL.${index}.paymentMethod`}
                control={control}
                render={({field: {onChange, value}}) => (
                  <CustomSelect
                    name={`houseBL.${index}.paymentMethod`}
                    value={valueOrEmptyString(value)}
                    onChange={onChange}
                    content={Object.values(ModalityPayment)}
                    size="small"
                    disabled
                    onBlur={() => {
                      checkIfHouseIsRequired();
                    }}
                  />
                )}
              />
            </CustomInputWrapper>

            <CustomInputWrapper md={2.4} title="CE n°" isDisabled>
              <Controller
                name={`houseBL.${index}.deconsolidationNumber`}
                control={control}
                render={({field: {onChange, value}}) => (
                  <CustomInput
                    name={`houseBL.${index}.deconsolidationNumber`}
                    value={valueOrEmptyString(value)}
                    placeholder="CE n°"
                    onChange={onChange}
                    size="small"
                    disabled
                    onBlur={() => {
                      checkIfHouseIsRequired();
                    }}
                  />
                )}
              />
            </CustomInputWrapper>

            <CustomInputWrapper md={2.4} title="Data CE">
              <Controller
                name={`houseBL.${index}.dateDeconsolidation`}
                control={control}
                render={({field: {value}}) => (
                  <ProcessDatePicker
                    value={value}
                    name={`houseBL.${index}.dateDeconsolidation`}
                    title="Data CE"
                    disabled={dateDeconsolidationIsDisabled}
                  />
                )}
              />
            </CustomInputWrapper>

            <CustomInputWrapper md={2.4} title="Local Emissão">
              <Controller
                name={`houseBL.${index}.placeIssue`}
                control={control}
                render={({field: {onChange, value}}) => (
                  <CustomSelect
                    name={`houseBL.${index}.placeIssue`}
                    value={valueOrEmptyString(value)}
                    onChange={onChange}
                    content={Object.values(PlaceIssue)}
                    size="small"
                    onBlur={() => {
                      checkIfHouseIsRequired();
                    }}
                  />
                )}
              />
            </CustomInputWrapper>
            <CustomInputWrapper md={12} title="Descrição dos pacotes">
              <Controller
                name={
                  `houseBL.${index}.logisticBillLading.descriptionOfPackages` as const
                }
                control={control}
                render={({field: {value}}) => (
                  <TextField
                    multiline
                    minRows={4}
                    maxRows={8}
                    size="small"
                    name={`houseBL.${index}.logisticBillLading.descriptionOfPackages`}
                    value={valueOrEmptyString(value)}
                    placeholder="descrição"
                    onBlur={() => {
                      checkIfHouseIsRequired();
                    }}
                    onChange={(e) => {
                      if (e?.target?.value) {
                        setValue(
                          `houseBL.${index}.logisticBillLading.descriptionOfPackages` as const,
                          e.target.value,
                          {shouldDirty: true},
                        );
                      } else {
                        setValue(
                          `houseBL.${index}.logisticBillLading.descriptionOfPackages` as const,
                          undefined,
                          {shouldDirty: true},
                        );
                      }
                    }}
                    sx={{fontSize: pxToRem(14)}}
                  />
                )}
              />
            </CustomInputWrapper>
          </Grid>
        );
      })}
    </DefaultCardStructure>
  );
};

export default HouseInfo;
