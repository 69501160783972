import {Fragment, ReactNode} from "react";
import {Divider, Grid} from "@mui/material";
import pxToRem from "hooks/usePxToRem";

type Props = {
  children: ReactNode;
  hideDivider?: boolean;
};

const DefaultModalFooter = (props: Props) => {
  const {children, hideDivider = false} = props;

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Divider sx={{display: hideDivider ? "none" : ""}} />
        </Grid>
      </Grid>
      <Grid container p={pxToRem(20)} spacing={pxToRem(8)}>
        {children}
      </Grid>
    </Fragment>
  );
};
export default DefaultModalFooter;
