/* eslint-disable @typescript-eslint/no-explicit-any */
import pxToRem from "hooks/usePxToRem";

import {
  MaterialReactTable,
  MRT_ToggleGlobalFilterButton,
  MRT_ToggleFiltersButton,
  MRT_TableOptions,
} from "material-react-table";
import {MRT_Localization_PT_BR} from "material-react-table/locales/pt-BR";

import {CsvTableDownloader} from "components";

type ProcessContactTableProps<TData extends Record<string, any>> =
  MRT_TableOptions<TData>;

const ProcessContactTable = <T extends Record<string, any>>(
  props: ProcessContactTableProps<T>,
) => {
  return (
    <MaterialReactTable
      initialState={{
        density: "compact",
      }}
      localization={MRT_Localization_PT_BR}
      enableFullScreenToggle={false}
      enableDensityToggle={false}
      enableColumnResizing
      enableStickyHeader
      layoutMode="grid"
      enablePagination={false}
      enableHiding={false}
      positionToolbarAlertBanner="none"
      displayColumnDefOptions={{
        "mrt-row-actions": {
          size: 20,
        },
      }}
      muiTableContainerProps={{
        sx: (theme) => ({
          flex: 1,
          maxHeight: pxToRem(320),
          backgroundColor: theme.palette.background.paper,
        }),
      }}
      muiTablePaperProps={{
        elevation: 0,
      }}
      muiTableHeadCellProps={{
        sx: (theme) => ({
          backgroundColor: theme.palette.background.paper,
          fontSize: pxToRem(14),
          fontWeight: 600,
          padding: `${pxToRem(4)} ${pxToRem(16)} ${pxToRem(6.4)}`,

          ".Mui-TableHeadCell-Content-Wrapper": {
            whiteSpace: "nowrap",
          },

          "& .Mui-TableHeadCell-content": {
            alignItems: "center",
          },
        }),
      }}
      muiTableBodyCellProps={() => ({
        sx: (theme) => ({
          fontSize: pxToRem(14),
          color: theme.palette.text.secondary,
          padding: `${pxToRem(8)} ${pxToRem(16)}`,
        }),
      })}
      muiTableBodyRowProps={{
        hover: false,
        sx: (theme) => ({
          backgroundColor: theme.palette.background.paper,
        }),
      }}
      muiBottomToolbarProps={{
        sx: (theme) => ({
          backgroundColor: theme.palette.background.paper,
        }),
      }}
      muiTopToolbarProps={{
        sx: (theme) => ({
          backgroundColor: theme.palette.background.paper,
        }),
      }}
      muiColumnActionsButtonProps={{size: "small"}}
      renderToolbarInternalActions={({table}) => (
        <>
          <MRT_ToggleGlobalFilterButton table={table} />
          <MRT_ToggleFiltersButton table={table} />
          <CsvTableDownloader table={table} />
        </>
      )}
      {...props}
    />
  );
};

export default ProcessContactTable;
