import {useSelector} from "react-redux";
import pxToRem from "hooks/usePxToRem";
import formatNameToInitialLetters from "util/formatNameToInitialLetters";

import {
  Avatar,
  Box,
  Divider,
  Icon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import ThemeSwitcher from "./ThemeSwitcher";

interface UserMenuProps {
  currentAnchorElement: HTMLElement | null;
  onCloseMenuRequested: () => void;
}

const UserMenu = ({
  currentAnchorElement,
  onCloseMenuRequested,
}: UserMenuProps) => {
  const {userInfo} = useSelector((state: any) => state.stateUserInfo);
  const expandedMenu = userInfo?.settings?.expandedMenu;

  const isAnchorElementDifferentFromNullThenMenuIsOpen: boolean =
    currentAnchorElement !== null;

  return (
    <Menu
      anchorEl={currentAnchorElement}
      open={isAnchorElementDifferentFromNullThenMenuIsOpen}
      onClose={onCloseMenuRequested}
      elevation={0}
      sx={(theme) => ({
        "& .MuiPaper-root": {
          boxShadow: "-0.5px 0.5px 3px rgba(0 0 0 / 10%)",
          width: pxToRem(224),
        },

        "& .MuiMenu-list": {
          padding: `${pxToRem(8)} 0`,
        },

        "& .MuiMenuItem-root": {
          display: "flex",
          gap: pxToRem(6),
          color: theme.palette.text.secondary,
          padding: `${pxToRem(8)} ${pxToRem(16)}`,

          transition: "background-color 280ms ease-in-out",

          "&.active": {
            backgroundColor: theme.palette.primary[50],
            color: theme.palette.primary.main,
          },
        },

        ".MuiIcon-root": {
          fontSize: pxToRem(21),
        },
      })}
    >
      {!expandedMenu && (
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            spacing={pxToRem(8)}
            sx={{padding: `0 ${pxToRem(16)} ${pxToRem(8)}`}}
          >
            {/* <Link to={`/user-details/${userInfo?._id}`}> */}

            <Avatar
              src={userInfo?.person?.avatar}
              sx={(theme) => ({
                height: pxToRem(29),
                width: pxToRem(29),
                fontSize: pxToRem(14),
                border: `1px solid ${theme.palette.primary.main}`,
                backgroundColor: theme.palette.primary[50],
                boxSizing: "content-box",
                color: theme.palette.primary[700],
                textTransform: "uppercase",
              })}
            >
              {formatNameToInitialLetters(userInfo?.person?.name)}
            </Avatar>
            {/* </Link> */}

            <Stack spacing={pxToRem(-2)}>
              <Tooltip title={userInfo?.person?.name} placement="top" arrow>
                <Typography
                  variant="subtitle3"
                  component="strong"
                  sx={{
                    width: pxToRem(152),
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {userInfo?.name}
                </Typography>
              </Tooltip>

              <Typography variant="placeholder2" color="primary">
                Operacional
              </Typography>
            </Stack>
          </Stack>

          <Divider sx={{marginBottom: pxToRem(8)}} />
        </Box>
      )}
      {/* items de menu comentados, porque não tem funcionalidade nesse momento do projeto */}
      {/* <MenuItem
        component={NavLink}
        to={`/user-details/${userInfo?._id}`}
        onClick={onCloseMenuRequested}>
        <Icon>person</Icon>
        <Typography variant="subtitle2">Perfil</Typography>
      </MenuItem> */}
      <MenuItem
        sx={{
          cursor: "default",
          justifyContent: "space-between",

          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        disableRipple
      >
        <Stack direction="row" spacing={pxToRem(6)}>
          <Icon sx={{rotate: "180deg"}}>contrast</Icon>
          <Typography variant="subtitle2">Tema</Typography>
        </Stack>

        <ThemeSwitcher />
      </MenuItem>
      {/* <MenuItem component={NavLink} to="*" onClick={onCloseMenuRequested}>
        <Icon>settings</Icon>
        <Typography variant="subtitle2">Configurações</Typography>
      </MenuItem> */}
    </Menu>
  );
};

export default UserMenu;
