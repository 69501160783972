import {AxiosResponse} from "axios";
import {newBackendAPI} from "services/apiConsumer";
import {QueryPagination} from "types/GenericTypes";
import {TasksByGroups} from "views/Tasks/components/TaskDetails/TaskDetails.types";
import {
  Locations,
  TaskGroupsResponse,
} from "views/Tasks/components/TasksProject/TasksProjectTypes";
import {
  TasksOfProjectDataType,
  ProjectDataPostType,
  TasksUnrelatedToProjects,
  PostTask,
  AllFieldsFromProcess,
} from "views/Tasks/types/Tasks.types";

type TasksToBind = {
  idTaskGroup: number;
  tasks: Array<number>;
};

export const getAllProjects = async (
  queryPagination: QueryPagination,
  filter?: {operation: string; modality: string},
): Promise<AxiosResponse<TaskGroupsResponse>> => {
  const params = {
    page: queryPagination.page,
    limit: queryPagination.limit,
    operation: filter?.operation,
    modality: filter?.modality,
  };

  return await newBackendAPI.get(`/task-groups`, {params});
};

export const getTasksByProjectId = async (
  id: number,
  isGeneratedSeparately?: boolean,
): Promise<AxiosResponse<TasksOfProjectDataType>> => {
  const params = {isGeneratedSeparately};
  return await newBackendAPI.get(`/task-groups/${id}`, {params});
};

export const postNewTask = async (data: PostTask) => {
  return await newBackendAPI.post("/tasks", data);
};

export const updateATask = async (data: PostTask, id: number) => {
  return await newBackendAPI.patch(`/tasks/${id}?onlyHeader=true`, data);
};

export const getTaskRoles = async () => {
  return await newBackendAPI.get("/task-roles");
};

export const postNewProject = async (newProject: ProjectDataPostType) => {
  return await newBackendAPI.post("/task-groups", newProject);
};

export const updateProject = async (
  projectId: number,
  changesInProject: Partial<ProjectDataPostType>,
) => {
  return await newBackendAPI.patch(
    `/task-groups/${projectId}`,
    changesInProject,
  );
};

export const getFollowUpTemplates = async (pagination?: QueryPagination) => {
  const params = {...pagination};
  return await newBackendAPI.get("/template-follow-ups", {params});
};

export const getFollowUpTemplateById = async (id: number) => {
  return await newBackendAPI.get(`/template-follow-ups/${id}`);
};

export const getMessageTemplates = async (pagination?: QueryPagination) => {
  const params = {...pagination};
  return await newBackendAPI.get("/template-messages", {params});
};

export const getMessageTemplateById = async (id: number) => {
  return await newBackendAPI.get(`/template-messages/${id}`);
};

export const getTasksWithoutLinkedProjects = async (): Promise<
  AxiosResponse<TasksUnrelatedToProjects[]>
> => {
  return await newBackendAPI.get(`/tasks/?groupTask=null`);
};

export const bindTasksToAProject = async (
  tasks: TasksToBind,
): Promise<AxiosResponse<{message: string}>> => {
  return await newBackendAPI.patch(`/bind-tasks`, tasks);
};

export const getProcessDateFields = async (): Promise<
  AxiosResponse<AllFieldsFromProcess>
> => {
  return await newBackendAPI.get("/all-fields-from-process?type=DateTime");
};

export const getAllFieldsFromProcess = async (
  type?: string,
  search?: string,
): Promise<AxiosResponse<AllFieldsFromProcess>> => {
  return await newBackendAPI.get(
    `/all-fields-from-process?type=${type ?? ""}&search=${search ?? ""}`,
  );
};

export const addNewTaskRule = async (data) => {
  return await newBackendAPI.post(`/tasks`, data);
};

export const updateTaskRule = async (data, id: number) => {
  return await newBackendAPI.patch(`/tasks/${id}`, data);
};

export const getTask = async (id: number) => {
  return await newBackendAPI.get(`/tasks/${id}`);
};

export const deleteSpecialRule = async (id: number) => {
  return await newBackendAPI.delete(`/special-rules/${id}`);
};

export const getAllTasksByGroupId = async (
  id: number,
): Promise<AxiosResponse<TasksByGroups>> => {
  return await newBackendAPI.get(`tasks/?page=1&limit=100&idTaskGroup=${id}`);
};

export const getPorts = async (
  page = 1,
  limit = 50,
  search: string | undefined = "",
): Promise<AxiosResponse<Locations>> => {
  return await newBackendAPI.get(
    `/ports/?name=${search}&page=${page}&limit=${limit}`,
  );
};

export const GetMessageGroups = async (pagination?: QueryPagination) => {
  const params = {...pagination};

  return await newBackendAPI.get("/send-message-groups", {params});
};

export const GetFileGroups = async (pagination?: QueryPagination) => {
  const params = {...pagination};

  return await newBackendAPI.get("/group-files", {params});
};
