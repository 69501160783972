import {styled} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {NavHashLink as Link} from "react-router-hash-link";

const StyledNavLink = styled(Link)(
  ({theme}) => `
    position: relative;
    text-decoration: none;
    color: inherit;

    transition: color 280ms ease-in-out;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: ${pxToRem(-10)};

      height: ${pxToRem(2)};
      width: 100%;
      background-color: ${theme.palette.primary.main};
      border-radius: ${pxToRem(30)};
      opacity: 0;

      transition: opacity 280ms ease-in-out;
    }

    &:hover {
      color: ${theme.palette.primary.main};
    }

    &.active {
      color: ${theme.palette.primary.main};

      &::after {
        opacity: 1;
      }
    }
`,
);

export default StyledNavLink;
