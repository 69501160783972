/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Checkbox,
  FormControlLabel,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {SyntheticEvent} from "react";

type CheckboxProps = {
  checked?: boolean;
  disabled?: boolean;
  size?: "small" | "medium";
  onChange?: (event: SyntheticEvent<Element, Event>, checked: boolean) => void;
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
  name?: string;
  value?: any;
  sx?: SxProps<Theme>;
  label: string;
  labelPlacement?: "bottom" | "end" | "start" | "top";
  fontSize?: string;
  hasDisabledRipple?: boolean;
  isReadOnly?: boolean;
};

const CustomCheckbox = (props: CheckboxProps) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          size={props.size ?? "small"}
          color={props.color ?? "primary"}
          name={props.name}
          value={props.value}
          disableRipple={props.hasDisabledRipple}
          readOnly={props.isReadOnly}
        />
      }
      checked={props.checked ?? false}
      disabled={props.disabled}
      onChange={props.onChange}
      label={
        <Typography sx={{fontSize: props.fontSize ?? pxToRem(11)}}>
          {props.label}
        </Typography>
      }
      labelPlacement={props.labelPlacement ?? "end"}
      sx={{...props.sx}}
    />
  );
};

export default CustomCheckbox;
