import {SelectChangeEvent} from "@mui/material";
import {Task} from "components/ModalCompleteActivity/CompleteActivity.types";
import {formatFieldIfDate} from "components/ModalCompleteActivity/util/formatDateField";
import useAlertMessage from "hooks/useAlertMessage";
import useLoading from "hooks/useLoading";
import {readLocalStorage, saveLocalStorage} from "hooks/useLocalStorage";
import useToast from "hooks/useToast";
import {useState} from "react";
import {useFormContext} from "react-hook-form";
import {ChangeActivityStatusById} from "services/api_v2/ActivitiesHistory/activitiesHistory.service";
import {GetTaskToFinish} from "services/api_v2/CompleteActivity/CompleteActivity.service";
import {ActivityStatus} from "util/globalEnums";
import {Activity, DefaultProcessDetails} from "../Process.types";

type ActivitySelectStatus = "Tudo" | "Concluída" | "Não iniciada" | "Outras";
interface UseActivitiesSidebarProps {
  refetchProcess: () => Promise<DefaultProcessDetails | undefined>;
}

const useActivitiesSidebar = (props: UseActivitiesSidebarProps) => {
  const {
    watch,
    reset,
    formState: {dirtyFields},
  } = useFormContext();

  const [isLoadingActivities, setIsLoadingActivities] = useState(false);
  const [currentActivity, setCurrentActivity] = useState<Activity>(
    {} as Activity,
  );

  const getSidebarStatusByActivityStatus = (activityStatus: ActivityStatus) => {
    switch (activityStatus) {
      case "Não iniciada":
        return "Não iniciada";
      case "Concluída":
        return "Concluída";

      default:
        return "Outras";
    }
  };

  const activities = watch("activities")?.filter(
    (item) => item?.task?.name !== "INÍCIO PROCESSO IM",
  );

  const [statusToFilter, setStatusToFilter] = useState<ActivitySelectStatus>(
    readLocalStorage("activityStatusToFilter") ?? "Tudo",
  );

  const changeStatusToFilter = (e: SelectChangeEvent<any>) => {
    setStatusToFilter(e.target.value);
    saveLocalStorage("activityStatusToFilter", e.target.value);
  };

  const getFilteredActivities = (status: ActivitySelectStatus) => {
    if (status === "Tudo") {
      return activities;
    } else if (status === "Concluída" || status === "Não iniciada") {
      return activities.filter((item) => item.activityStatus === status);
    } else {
      return activities.filter(
        (item) =>
          item.activityStatus !== "Concluída" &&
          item.activityStatus !== "Não iniciada",
      );
    }
  };

  const currentProcessNumber = watch("processNumber");

  const [openCompleteActivityModal, setOpenCompleteActivityModal] =
    useState<boolean>(false);
  const [openWarningDirtyFields, setOpenWarningDirtyFields] =
    useState<boolean>(false);
  const [setLoading] = useLoading();
  const [showAlert] = useAlertMessage();
  const [showToast] = useToast();

  const [processAndGroupActivityId, setProcessAndGroupActivityId] = useState({
    processId: "",
    groupActivityId: "",
  });
  const [taskData, setTaskData] = useState({} as Task);

  const handleOpenWarningDirtyFields = () => {
    setOpenWarningDirtyFields(true);
  };

  const handleCloseWarningDirtyFields = () => {
    setOpenWarningDirtyFields(false);
  };

  const handleConfirmWarningDirtyFields = () => {
    handleUpdateRenderAfterProcessRefetch();
    handleCloseWarningDirtyFields();
    handleOpenCompleteActivityModal(currentActivity);
  };

  const handleOpenCompleteActivityModal = async (activity: Activity) => {
    try {
      setLoading(true);

      const response = await GetTaskToFinish(+activity.id);

      setTaskData({
        ...response?.data?.activity,
        files: [],
        monitoredFields:
          response?.data?.activity?.monitoredFields?.map((item) => ({
            ...item,
            value: {
              id: item.fieldValue?.id ?? null,
              label: formatFieldIfDate(item) ?? "",
            },
            uiComponentChecked:
              item.tableName === "ui_component" ? false : null,
          })) ?? null,
      });

      setOpenCompleteActivityModal(true);
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFinishActivity = async (activity: Activity) => {
    setCurrentActivity(activity);
    setProcessAndGroupActivityId({
      processId: watch("processNumber").toString(),
      groupActivityId: activity?.idGroupActivity?.toString(),
    });
    const processFormHasDirtyFields = Object.keys(dirtyFields).length > 0;
    if (processFormHasDirtyFields) {
      handleOpenWarningDirtyFields();
    } else {
      handleOpenCompleteActivityModal(activity);
    }
  };

  const handleUpdateRenderAfterProcessRefetch = async () => {
    try {
      setIsLoadingActivities(true);
      const response = await props.refetchProcess();
      reset(response, {keepDirtyValues: false, keepValues: false});
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingActivities(false);
    }
  };

  const handleRestoreActivity = async (activity) => {
    try {
      setLoading(true);
      await ChangeActivityStatusById(
        activity.id,
        ActivityStatus["NaoIniciada"],
      );
      await handleUpdateRenderAfterProcessRefetch();
      showToast("Atividade restaurada com sucesso!");
    } catch (error: any) {
      showAlert(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    openCompleteActivityModal,
    setOpenCompleteActivityModal,
    currentProcessNumber,
    processAndGroupActivityId,
    taskData,
    statusToFilter,
    setStatusToFilter,
    isLoadingActivities,
    getFilteredActivities,
    getSidebarStatusByActivityStatus,
    handleFinishActivity,
    changeStatusToFilter,
    handleRestoreActivity,
    handleUpdateRenderAfterProcessRefetch,
    openWarningDirtyFields,
    setOpenWarningDirtyFields,
    handleConfirmWarningDirtyFields,
    handleCloseWarningDirtyFields,
  };
};

export default useActivitiesSidebar;
