import {MRT_TableInstance} from "material-react-table";
import {CustomIconButton} from "ui";
import CsvDownloader from "react-csv-downloader";

type Props<DataType extends Record<string, any>> = {
  table: MRT_TableInstance<DataType>;
  component?: JSX.Element;
  customDownloadIcon?: string;
  fileName?: string;
};

type ColumnsToCsvType = {
  id: string;
  displayName: string | undefined;
};
const CsvTableDownloader = <T extends Record<string, any>>({
  table,
  component,
  customDownloadIcon,
  fileName,
}: Props<T>) => {
  const columnsToCSVDownloader: ColumnsToCsvType[] = table
    .getVisibleLeafColumns()
    .map((item) => ({
      id: item.id.toString(),
      displayName: item.columnDef.header?.toString(),
    }));

  //pega todos os processos, independente da página, mas respeita filtros e ordem
  const rowsToCSVDownloader = table
    .getPrePaginationRowModel()
    .rows.map((item) => ({...item.original}));

  return (
    <CsvDownloader
      filename={fileName ?? "lista-processos-filtrados"}
      columns={columnsToCSVDownloader}
      datas={rowsToCSVDownloader}
    >
      {component ?? (
        <CustomIconButton
          tooltipText="Exportar CSV"
          tooltipPlacement="bottom"
          iconName={customDownloadIcon ?? "download"}
        />
      )}
    </CsvDownloader>
  );
};

export default CsvTableDownloader;
