import {MyDrawer, MyNotifications} from "./components";
import {Types as openNotifyTypes} from "store/reducers/drawerNotify";
import {useDispatch, useSelector} from "react-redux";

const NotificationsSidebar = () => {
  const dispatch = useDispatch();
  const {open} = useSelector((state: any) => state.stateDrawerNotify);

  const handleCloseNotificationsSidebar = () => {
    dispatch({
      type: openNotifyTypes.OPEN_NOTIFY,
    });
  };

  return (
    <MyDrawer open={open} handleClose={handleCloseNotificationsSidebar}>
      <MyNotifications />
    </MyDrawer>
  );
};

export default NotificationsSidebar;
