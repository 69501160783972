import useToast from "./useToast";

const useCopyToClipboard = () => {
  const [showToast] = useToast();

  const copyToClipboard = (
    textToBeCopied: string,
    toastText = "Referência copiada!",
  ) => {
    if (!navigator.clipboard) {
      return;
    }
    showToast(toastText);
    return navigator.clipboard
      .writeText(textToBeCopied)
      .catch((error) => console.error(error));
  };

  return {copyToClipboard};
};

export default useCopyToClipboard;
