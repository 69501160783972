import {Box} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";
import {CustomButton} from "ui";
import pxToRem from "hooks/usePxToRem";
import {
  ActivityCargoItems,
  ActivityHouseMeasurements,
  ActivityKnowledge,
  ActivityNcms,
  ActivityPackages,
  ActivityOriginAgentTaxes,
} from "./components";
import {Process} from "./ProcessSectionView.types";
import {DefaultProcessDetails} from "views/ProcessDetails/Process.types";
import {Fragment} from "react/jsx-runtime";
import {getUpdatedFields} from "util/getDirtyFieldsFromRHF";
import {useRef} from "react";
import useAlertMessage from "hooks/useAlertMessage";
import {AvailableUiComponents} from "components/ModalCompleteActivity/CompleteActivity.types";

type Props = {
  defaultValues: Process | undefined;
  refetchProcess: () => Promise<Process | undefined>;
  processId: number;
  componentToRender: AvailableUiComponents | undefined;
  handleCloseDrawer: (uiComponent: AvailableUiComponents | undefined) => void;
  patchProcess: (
    processId: number,
    changedData: Partial<Process>,
    defaultValues: Process | undefined,
  ) => Promise<void>;
};

const ProcessSectionView = (props: Props) => {
  const methods = useForm({defaultValues: props.defaultValues});

  const {dirtyFields} = methods.formState;
  const [showAlert] = useAlertMessage();

  const onSubmit = async (data) => {
    const ret = getUpdatedFields(dirtyFields, data);
    try {
      await props.patchProcess(
        props.processId,
        ret,
        methods?.formState?.defaultValues as any,
      );
    } catch (e) {
      showAlert("Algo deu errado, tente novamente!");
      methods.reset(props.defaultValues);
      return;
    }
    props.handleCloseDrawer(props.componentToRender);
  };

  const formRef = useRef<HTMLFormElement | null>(null);

  const handleFormSubmissionWithValidation = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (formRef?.current) {
      const isValid = formRef?.current?.reportValidity();
      if (!isValid) {
        return;
      } else {
        methods.handleSubmit(onSubmit)(e);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form ref={formRef} id="process">
        <Box p={`${pxToRem(20)} ${pxToRem(40)} ${pxToRem(20)} ${pxToRem(20)}`}>
          {props.componentToRender === "cargoItems" &&
            props.defaultValues?.processLoads[0].typeLoad === "FCL" && (
              <Fragment>
                <ActivityHouseMeasurements />
                <ActivityCargoItems
                  refetchProcess={
                    props.refetchProcess as () => Promise<
                      DefaultProcessDetails | undefined
                    >
                  }
                />
              </Fragment>
            )}

          {props.componentToRender === "cargoItems" &&
            props.defaultValues?.processLoads[0].typeLoad === "LCL" && (
              <Fragment>
                <ActivityHouseMeasurements />
                <ActivityPackages
                  refetchProcess={
                    props.refetchProcess as () => Promise<
                      DefaultProcessDetails | undefined
                    >
                  }
                />
              </Fragment>
            )}
          {props.componentToRender === "ncm" && (
            <Box sx={{position: "relative"}}>
              <ActivityNcms
                refetchProcess={
                  props.refetchProcess as () => Promise<
                    DefaultProcessDetails | undefined
                  >
                }
                processId={props.processId}
              />
            </Box>
          )}

          {props.componentToRender === "knowledges" && <ActivityKnowledge />}
          {props.componentToRender === "taxes" && <ActivityOriginAgentTaxes />}
          <Box
            display="flex"
            width="100%"
            justifyContent="flex-end"
            mt={pxToRem(20)}
          >
            <CustomButton
              size="small"
              onClickFn={handleFormSubmissionWithValidation}
            >
              Salvar e Finalizar conferência
            </CustomButton>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

export default ProcessSectionView;
