import {TaxesData} from "../ActivityTaxesListing.types";

import {
  MRT_ShowHideColumnsButton,
  MRT_TableInstance,
  MRT_RowSelectionState,
} from "material-react-table";
import {Stack} from "@mui/material";

import {ToolbarButton} from "components/Tables";
import {CsvTableDownloader} from "components";

interface CustomToolbarProps {
  onRequestSaveTableView: () => void;
  table: MRT_TableInstance<TaxesData>;
}

const CustomToolbar = ({onRequestSaveTableView, table}: CustomToolbarProps) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={(theme) => ({color: theme.palette.text.secondary})}
    >
      <ToolbarButton
        tooltipText="Salvar configuração de visualização atual"
        iconName="save"
        onClick={onRequestSaveTableView}
      />

      <MRT_ShowHideColumnsButton color="inherit" table={table} />
      <CsvTableDownloader table={table} />
    </Stack>
  );
};

export default CustomToolbar;
