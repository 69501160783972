import {PaginationState, Updater} from "@tanstack/react-table";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";

const useChangeMrtReduxPagination = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const handleChangePagination = (
    value: Updater<PaginationState>,
    type: string,
    keyName: string,
    oldValue,
  ) => {
    if (typeof value === "function") {
      dispatch({
        type: type,
        [keyName]: value(oldValue),
      });
    } else {
      dispatch({
        type: type,
        [keyName]: value,
      });
    }
  };

  const resetPagination = (
    type: string,
    keyName: string,
    currentValue: {
      pageIndex: number;
      pageSize: number;
      totalNumberOfPages?: number;
    },
    pathnamesList: string[],
  ) => {
    const matchingPathname: string[] = [];

    for (let i = 0; i < pathnamesList.length; i++) {
      if (location.pathname.includes(pathnamesList[i])) {
        matchingPathname.push(pathnamesList[i]);
      }
    }

    if (
      matchingPathname.length === 0 &&
      (currentValue.pageIndex !== 0 || currentValue.pageSize !== 20)
    ) {
      dispatch({
        type: type,
        [keyName]: {pageIndex: 0, pageSize: 20},
      });
    }
  };

  return {handleChangePagination, resetPagination};
};

export default useChangeMrtReduxPagination;
