import {MonitoredField} from "../CompleteActivity.types";

export const formatFieldIfDate = (item: MonitoredField) => {
  if (!item?.fieldValue) return "";
  if (item?.columnType === "DateTime") {
    return item?.fieldValue?.value?.substring(0, 10);
  } else {
    return item?.fieldValue?.value;
  }
};
