//Conforme cálculos no HC
export const calcConsideredWeight = (volume: number, grossWeight: number) => {
  const COEFFICIENT_CUBED_WEIGHT = 1000.0;

  const cubeWeight = +volume * COEFFICIENT_CUBED_WEIGHT;

  const consideredWeight = Math.max(cubeWeight, +grossWeight);

  return {
    consideredWeight: Number.parseFloat(
      (consideredWeight / COEFFICIENT_CUBED_WEIGHT).toFixed(3),
    ),
    cubeWeight: cubeWeight,
  };
};
