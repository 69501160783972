/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Divider,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import {TabPanel} from "components";
import pxToRem from "hooks/usePxToRem";
import {useState} from "react";
import {TaskInfo} from "./components";

interface ViewInfoSidebarProps {
  infoCardData: {
    title: string;
    content: string | string[];
    iconify?: boolean;
    icon?: string;
  }[];
  itemId: string;
  hideSummaryTab?: boolean;
  hideInfoButton?: boolean;
}

const ViewInfoSidebar = (props: ViewInfoSidebarProps) => {
  const theme = useTheme();

  const {infoCardData, hideSummaryTab, hideInfoButton, itemId} = props;

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTabIndex(newValue);
  };

  return (
    <Paper
      sx={{
        height: `calc(100vh - ${pxToRem(80)})`,
        position: "relative",
        zIndex: 0,
        boxShadow: "none",
        borderRadius: 0,
        padding: `${pxToRem(22)} 0 0 ${pxToRem(20)}`,
        backgroundImage: "none",
      }}
    >
      <Divider
        orientation="vertical"
        sx={{
          height: `calc(100% + ${pxToRem(8)})`,
          position: "absolute",
          bottom: pxToRem(-8),
          left: 0,
        }}
      />
      <Stack sx={{overflowY: "auto", height: "100%", zIndex: 99}}>
        <Typography
          variant="h4"
          sx={{paddingBottom: pxToRem(4), fontSize: pxToRem(20)}}
        >
          Mais detalhes
        </Typography>
        {!hideSummaryTab && (
          <>
            <Tabs value={currentTabIndex} onChange={handleChangeTab}>
              <Tab
                label="Informações"
                sx={{
                  textTransform: "none",
                  fontSize: pxToRem(16),
                  paddingLeft: 0,
                }}
              />

              <Tab
                label="Resumo"
                sx={{textTransform: "none", fontSize: pxToRem(16)}}
                disabled
              />
            </Tabs>
            <Divider
              sx={{
                width: "100%",
                position: "absolute",
                top: pxToRem(97),
                height: pxToRem(2),
                backgroundColor: theme.palette.grey[100],
                borderColor: theme.palette.grey[100],
                zIndex: -1,
              }}
            />
          </>
        )}

        <TabPanel
          index={0}
          id="info"
          component={
            <TaskInfo
              infoCardData={infoCardData}
              hideButton={hideInfoButton}
              itemId={itemId}
            />
          }
          tabsValue={currentTabIndex}
        />
        <TabPanel
          index={1}
          id="summary"
          component={<></>}
          tabsValue={currentTabIndex}
        />
      </Stack>
    </Paper>
  );
};

export default ViewInfoSidebar;
