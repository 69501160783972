/* eslint-disable @typescript-eslint/no-explicit-any */
import {createActions, createReducer} from "reduxsauce";

type initial = {
  activitiesPagination: {
    pageIndex: number;
    pageSize: number;
    totalNumberOfPages: number;
  };
};

const INITIAL_STATE: initial = {
  activitiesPagination: {
    pageIndex: 0,
    pageSize: 20,
    totalNumberOfPages: 1,
  },
};

export const {Types, Creators} = createActions({
  setActivitiesPagination: ["ActivitiesPagination"],
});

const setActivitiesPagination = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  activitiesPagination: action.activitiesPagination,
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_ACTIVITIES_PAGINATION]: setActivitiesPagination,
});
