/* eslint-disable @typescript-eslint/no-explicit-any */
import {createActions, createReducer} from "reduxsauce";

type initial = {
  data: any;
};

const INITIAL_STATE: initial = {
  data: {
    status: {
      noOriginal: 0,
      infoDivergent: 0,
      shipowner: 0,
      processRelease: 0,
    },
    list: {
      noOriginal: [],
      infoDivergent: [],
      shipowner: [],
      processRelease: [],
    },
  },
};

export const {Types, Creators} = createActions({
  getDataDeconsolidation: ["data"],
});

const getDataDeconsolidation = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  data: action.data,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_DATA_DECONSOLIDATION]: getDataDeconsolidation,
});
