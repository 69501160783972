import {Box, Icon, IconButton, IconButtonProps, Tooltip} from "@mui/material";

interface ToolbarButtonProps extends IconButtonProps {
  tooltipText: string;
  iconName: string;
}

const ToolbarButton = ({
  tooltipText,
  iconName,
  ...rest
}: ToolbarButtonProps) => {
  return (
    <Tooltip title={tooltipText} arrow>
      <Box>
        <IconButton
          size="medium"
          sx={(theme) => ({color: theme.palette.text.secondary})}
          {...rest}
        >
          <Icon>{iconName}</Icon>
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default ToolbarButton;
