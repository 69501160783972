/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Typography} from "@mui/material";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";

interface ModalContentDataProps {
  versionUpdatesData: any[];
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
}

const ModalContentData = (props: ModalContentDataProps) => {
  const {versionUpdatesData, page, setPage} = props;

  const [currentUpdate, setCurrentUpdate] = useState<any>(
    versionUpdatesData[0],
  );

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    setCurrentUpdate(versionUpdatesData[page - 1]);
  }, [page]);

  const handleKeywordHighlight = () => {
    if (currentUpdate?.description.includes(currentUpdate?.keyword)) {
      const styledKeyword = (
        <Typography
          variant="body2"
          component="b"
          sx={{fontWeight: 500, color: "primary.main"}}
        >
          {currentUpdate?.keyword}
        </Typography>
      );

      const newDescription = currentUpdate?.description.replace(
        currentUpdate.keyword,
        styledKeyword,
      );

      const splittedDescription = newDescription.split(styledKeyword);

      const firstDesctiptionPart = splittedDescription[0];
      const secondDesctiptionPart = splittedDescription[1];

      setCurrentUpdate({
        ...currentUpdate,
        styledKeyword: styledKeyword,
        splittedDescription: {
          1: firstDesctiptionPart,
          2: secondDesctiptionPart,
        },
      });
    }
  };

  useEffect(() => {
    handleKeywordHighlight();
  }, [currentUpdate?.id]);

  return {currentUpdate, handlePageChange};
};

export default ModalContentData;
