import {createActions, createReducer} from "reduxsauce";

type initial = {
  value: number;
};

const INITIAL_STATE: initial = {
  value: Number(localStorage.getItem("tabPanel")) || 0,
};

export const {Types, Creators} = createActions({
  alterPanel: ["value"],
});

const alterPanel = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  value: action.value,
});

export default createReducer(INITIAL_STATE, {
  [Types.ALTER_PANEL]: alterPanel,
});
