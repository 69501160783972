export const idOfCategories = {
  originalDocs: [
    8, 65, 70, 71, 72, 78, 80, 92, 93, 113, 114, 116, 119, 122, 123, 131, 154,
  ],
  draft: [
    20, 21, 22, 68, 69, 73, 74, 81, 82, 87, 91, 102, 103, 104, 115, 117, 127,
    132, 140, 141, 142, 145,
  ],
  merchant: [23, 55],
  financial: [
    1, 2, 3, 4, 9, 10, 15, 17, 25, 28, 43, 44, 45, 46, 47, 48, 49, 54, 85, 86,
    88, 90, 97, 121, 130, 136, 152, 156, 162, 164, 165, 166,
  ],
  pricing: [
    5, 6, 14, 30, 33, 34, 38, 39, 40, 41, 56, 57, 58, 59, 75, 89, 125, 126, 167,
  ],
  onlineRelease: [170, 171, 172, 173, 174],
  allDocuments: function (): Array<number> {
    return [
      ...this.draft,
      ...this.financial,
      ...this.merchant,
      ...this.onlineRelease,
      ...this.originalDocs,
      ...this.pricing,
    ];
  },
};
