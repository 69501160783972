import {createActions, createReducer} from "reduxsauce";

type initial = {
  projectTasksPagination: {
    pageIndex: number;
    pageSize: number;
  };
  tasksOfProjectPagination: {
    pageIndex: number;
    pageSize: number;
  };
};

export const INITIAL_STATE: initial = {
  projectTasksPagination: {
    pageIndex: 0,
    pageSize: 20,
  },
  tasksOfProjectPagination: {
    pageIndex: 0,
    pageSize: 20,
  },
};

export const {Types, Creators} = createActions({
  setProjectTasksPagination: ["projectTasksPagination"],
  setTasksOfProjectPagination: ["tasksOfProjectPagination"],
});

const setProjectTasksPagination = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  projectTasksPagination: action.projectTasksPagination,
});

const setTasksOfProjectPagination = (
  state = INITIAL_STATE,
  action: initial,
) => ({
  ...state,
  tasksOfProjectPagination: action.tasksOfProjectPagination,
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_PROJECT_TASKS_PAGINATION]: setProjectTasksPagination,
  [Types.SET_TASKS_OF_PROJECT_PAGINATION]: setTasksOfProjectPagination,
});
