import {ReactNode, cloneElement} from "react";
import pxToRem from "hooks/usePxToRem";
import {
  Box,
  Chip,
  Divider,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";

interface DefaultCardStructureProps {
  title: string;
  linkId?: string;
  badgeText?: string;
  badgeColor?:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
  button?: JSX.Element;
  toggleSwitch?: JSX.Element;
  children: ReactNode;
  hasDivider: boolean;
  isSubcard?: boolean;
  sx?: SxProps<Theme>;
  titleMarginBottom?: number;
}

const DefaultCardStructure = ({
  title,
  linkId,
  badgeText,
  badgeColor = "primary",
  button,
  toggleSwitch,
  children,
  hasDivider,
  isSubcard,
  sx,
  titleMarginBottom = 24,
}: DefaultCardStructureProps) => {
  const theme = useTheme();

  return (
    <Box
      pt={pxToRem(12)}
      sx={{
        position: "relative",
        paddingLeft: isSubcard ? pxToRem(20) : 0,
        ...sx,
      }}
      component="section"
      id={linkId}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={pxToRem(titleMarginBottom)}
      >
        <Typography
          variant="subtitle1"
          component="strong"
          sx={{
            position: "relative",
            fontWeight: 600,
            paddingLeft: isSubcard ? pxToRem(14) : 0,
            color: isSubcard
              ? theme.palette.grey[500]
              : theme.palette.text.primary,

            "&::after": {
              content: '""',
              position: "absolute",
              left: 0,
              bottom: isSubcard ? pxToRem(1) : pxToRem(-4),

              height: isSubcard ? pxToRem(24) : pxToRem(4),
              width: isSubcard ? pxToRem(5) : pxToRem(36),
              backgroundColor: theme.palette.primary.main,
              borderRadius: pxToRem(30),
            },
          }}
        >
          {title}
        </Typography>

        <Stack direction="row" alignItems="center" spacing={pxToRem(16)}>
          {button && cloneElement(button, {size: "small"})}

          {badgeText && <Chip label={badgeText} color={badgeColor} />}

          {toggleSwitch && cloneElement(toggleSwitch)}
        </Stack>
      </Stack>

      {children}

      {hasDivider && (
        <Box pt={pxToRem(24)}>
          <Divider />
        </Box>
      )}
    </Box>
  );
};

export default DefaultCardStructure;
