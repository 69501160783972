import {createActions, createReducer} from "reduxsauce";

type initial = {
  definedRanges: (Date | null)[];
  dateFilter: string;
  currentActivityType: string;
  companyName: {id?: number; label: string};
  dashboardListView: boolean;
  refNext: string;
};

const INITIAL_STATE: initial = {
  definedRanges: [new Date(), null],
  dateFilter: "Hoje",
  currentActivityType: "",
  companyName: {id: undefined, label: ""},
  dashboardListView: false,
  refNext: "",
};

export const {Types, Creators} = createActions({
  setDefinedRanges: ["definedRanges"],
  setDateFilter: ["dateFilter"],
  setCurrentActivityType: ["currentActivityType"],
  setCompanyName: ["companyName"],
  setDashboardView: ["dashboardListView"],
  setRefNext: ["refNext"],
});

const setDefinedRanges = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  definedRanges: action.definedRanges,
});

const setDateFilter = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  dateFilter: action.dateFilter,
});

const setCurrentActivityType = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  currentActivityType: action.currentActivityType,
});

const setCompanyName = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  companyName: action.companyName,
});

const setDashboardView = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  dashboardListView: action.dashboardListView,
});

const setRefNext = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  refNext: action.refNext,
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_DEFINED_RANGES]: setDefinedRanges,
  [Types.SET_DATE_FILTER]: setDateFilter,
  [Types.SET_CURRENT_ACTIVITY_TYPE]: setCurrentActivityType,
  [Types.SET_COMPANY_NAME]: setCompanyName,
  [Types.SET_DASHBOARD_VIEW]: setDashboardView,
  [Types.SET_REF_NEXT]: setRefNext,
});
