/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {Button, Icon, SxProps, Theme, useTheme} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {useSelector} from "react-redux";

type Props = {
  variant?: "text" | "contained" | "outlined";
  href: string;
  onClickFn: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled: boolean;
  children: React.ReactNode;
  padding?: string;
  fullWidth: boolean;
  type?: "button" | "submit" | "reset";
  sx?: SxProps<Theme>;
  size?: "small" | "medium" | "large";
  destructive?: boolean;
  startIcon?: string | JSX.Element;
  endIcon?: string | JSX.Element;
  form?: string;
  secondary?: boolean;
  dataTestId?: string;
};

const CustomButton = (props: Partial<Props>) => {
  const theme = useTheme();
  const {userInfo} = useSelector((state: any) => state.stateUserInfo);
  const {darkMode} = userInfo.settings;

  const secondaryProps = () => {
    if (props.secondary) {
      return {
        borderColor: theme.palette.grey[300],
        color: darkMode ? theme.palette.text.primary : theme.palette.grey[700],
        textTransform: "capitalize",
        ":hover": {
          backgroundColor: "transparent",
          borderColor: theme.palette.grey[800],
          color: darkMode
            ? theme.palette.text.primary
            : theme.palette.grey[700],
        },
      } as const;
    } else {
      return;
    }
  };

  const customMinWidth = () => {
    if (props.size === "small") {
      return "71px";
    } else if (props.size === "large") {
      return "85px";
    } else {
      return "81px";
    }
  };

  return (
    <Button
      data-testid={props.dataTestId}
      disabled={props.disabled}
      onClick={props.onClickFn}
      type={props.type ?? "button"}
      sx={{
        borderRadius: "5px",
        minWidth: customMinWidth(),
        padding: props.padding,
        minHeight: pxToRem(20),
        textTransform: "none",
        ...secondaryProps(),
        ...props.sx,
      }}
      variant={props.variant ?? "contained"}
      href={props.href}
      fullWidth={props.fullWidth ?? false}
      size={props.size ?? "medium"}
      color={props.destructive ? "error" : "primary"}
      startIcon={props.startIcon && <Icon>{props.startIcon}</Icon>}
      endIcon={props.endIcon && <Icon>{props.endIcon}</Icon>}
      form={props.form}
    >
      {props.children}
    </Button>
  );
};

export default CustomButton;
