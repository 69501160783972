export const handleRemoveCharFromList = (
  list: string,
  char: string,
): string[] => {
  const partes = list.replace(/[\n\r\s]+/g, "").split(char);
  const ncmsList: string[] = [];
  partes.forEach((parte) => {
    const matches = parte.match(/\d{3,8}/g);
    if (matches) {
      matches.forEach((match) => {
        ncmsList.push(match);
      });
    }
  });
  return [...new Set(ncmsList)];
};
