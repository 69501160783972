import {useState} from "react";
import pxToRem from "hooks/usePxToRem";
import CompositionData from "./CompositionData";

import {SelectChangeEvent, Stack} from "@mui/material";
import {MaterialReactTable, MRT_RowSelectionState} from "material-react-table";
import {MRT_Localization_PT_BR} from "material-react-table/locales/pt-BR";

import {CustomSelect} from "ui";

const Composition = () => {
  const {columnsData, ratesData} = CompositionData();

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [currentFilterSelected, setCurrentFilterSelected] = useState(
    "Apenas selecionados",
  );
  const selectFilterAvailableValues = ["Mostrar todos", "Apenas selecionados"];

  const handleChangeCurrentFilter = (event: SelectChangeEvent) =>
    setCurrentFilterSelected(event.target.value);

  const showRateCompositionBasedOnCurrentFilter = () => {
    const selectedRates = ratesData.filter((rate) => rowSelection[rate.id]);

    if (currentFilterSelected === "Apenas selecionados") return selectedRates;

    return ratesData;
  };

  return (
    <Stack spacing={4}>
      <MaterialReactTable
        state={{rowSelection}}
        columns={columnsData}
        data={showRateCompositionBasedOnCurrentFilter()}
        localization={MRT_Localization_PT_BR}
        enableBottomToolbar={false}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableFilters={false}
        enableSorting={false}
        enableHiding={false}
        enableRowSelection
        enableStickyHeader
        layoutMode="grid"
        enableColumnResizing
        columnResizeMode="onEnd"
        onRowSelectionChange={setRowSelection}
        getRowId={(row) => row.id}
        renderToolbarInternalActions={() => (
          <Stack justifyContent="flex-end">
            <CustomSelect
              size="small"
              content={selectFilterAvailableValues}
              value={currentFilterSelected}
              onChange={handleChangeCurrentFilter}
            />
          </Stack>
        )}
        muiTableContainerProps={{
          sx: (theme) => ({
            height: pxToRem(354),
            backgroundColor: theme.palette.background.paper,
          }),
        }}
        muiTablePaperProps={{
          elevation: 0,
        }}
        muiTopToolbarProps={{
          sx: (theme) => ({backgroundColor: theme.palette.background.paper}),
        }}
        muiTableHeadCellProps={{
          sx: (theme) => ({
            backgroundColor: theme.palette.background.paper,

            ".Mui-TableHeadCell-Content-Wrapper": {
              whiteSpace: "nowrap",
            },

            "& .Mui-TableHeadCell-content": {
              alignItems: "center",
            },
          }),
        }}
        muiTableBodyRowProps={({row}) => ({
          onClick: row.getToggleSelectedHandler(),
          sx: (theme) => ({
            cursor: "pointer",
            backgroundColor: theme.palette.background.paper,

            ".MuiTableCell-root": {
              whiteSpace: "nowrap",
            },
          }),
        })}
      />
    </Stack>
  );
};

export default Composition;
