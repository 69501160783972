export const getDirtyFieldsFromRHF = (
  dirtyFields: object | boolean,
  allValues: object,
): object => {
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      getDirtyFieldsFromRHF(dirtyFields[key], allValues[key]),
    ]),
  );
};

export const getDirtyFieldsFromRHF_v2 = (
  dirtyFields: object | boolean,
  allValues: object,
): object => {
  if (dirtyFields === true) {
    return allValues;
  }

  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      getDirtyFieldsFromRHF(dirtyFields[key], allValues[key]),
    ]),
  );
};

export function getUpdatedFields(dirtyFields, allFields) {
  const updatedFields: any = {};

  for (const key in dirtyFields) {
    if (
      typeof dirtyFields[key] === "object" &&
      !Array.isArray(dirtyFields[key])
    ) {
      // Trata objetos aninhados
      const nestedFields = getUpdatedFields(dirtyFields[key], allFields[key]);

      if (Object.keys(nestedFields).length > 0) {
        // Inclui o ID do objeto simples alterado
        if (allFields[key].id !== undefined) {
          nestedFields.id = allFields[key].id;
        }
        updatedFields[key] = nestedFields;
      }
    } else if (Array.isArray(dirtyFields[key])) {
      // Trata arrays
      updatedFields[key] = [];

      dirtyFields[key].forEach((field, index) => {
        const updatedField = getUpdatedFields(field, allFields[key][index]);

        if (Object.keys(updatedField).length > 0) {
          // Inclui o ID do objeto alterado
          if (allFields[key][index]?.id !== undefined) {
            updatedField.id = allFields[key][index]?.id;
          }
          updatedFields[key].push(updatedField);
        }
      });
    } else if (dirtyFields[key]) {
      // Aqui, retornamos o valor alterado de allFields
      updatedFields[key] = allFields?.[key];

      // Inclui o ID caso exista
      if (allFields[key]?.id !== undefined) {
        updatedFields[key].id = allFields[key]?.id;
      }
    }
  }

  return updatedFields;
}
