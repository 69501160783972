import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {Dispatch, SetStateAction, useMemo} from "react";
import {CustomIconButton} from "ui";
import {getDefaultMRTOptions} from "util/getDefaultMRTOptions";
import {Voyage} from "views/ProcessDetails/Process.types";

interface VoyagesTableProps {
  voyages: any[];
  setOpenVoyageModal: Dispatch<SetStateAction<boolean>>;
  setVoyageIndex: Dispatch<SetStateAction<number | null>>;
  handleEditVoyage: (data: Voyage) => void;
}

const VoyagesTable = (props: VoyagesTableProps) => {
  const {voyages, handleEditVoyage} = props;
  const defaultMrtOptions = getDefaultMRTOptions<Voyage>();

  const columns = useMemo<MRT_ColumnDef<Voyage>[]>(
    () => [
      {
        accessorFn: (voyage) => voyage?.typeVoyage?.name,
        header: "Tipo de viagem",
        size: 200,
      },
      {
        accessorFn: (voyage) => voyage?.origin?.name,
        header: "Origem",
        size: 250,
      },

      {
        accessorFn: (voyage) => voyage?.destination?.name,
        header: "Destino",
        size: 250,
      },
      {
        accessorFn: (voyage) => voyage?.vessel?.name,
        header: "Navio",
        size: 190,
      },
      {
        accessorKey: "voyageFlight",
        header: "Viagem",
        size: 170,
      },
      {
        accessorKey: "estimatedCargoLoad",
        header: "ETD",
        size: 120,
      },
      {
        accessorKey: "cargoLoad",
        header: "Embarque",
        size: 120,
      },
      {
        accessorKey: "estimatedCargoUnload",
        header: "ETA",
        size: 120,
      },
      {
        accessorKey: "cargoUnload",
        header: "Desembarque",
        size: 170,
      },
      {
        accessorKey: "placePickup",
        header: "Local de coleta",
        size: 170,
      },
    ],
    [],
  );

  const data = voyages;

  const table = useMaterialReactTable({
    ...defaultMrtOptions,
    columns,
    data,
    renderRowActions: ({row}) => (
      <CustomIconButton
        iconName={"edit"}
        onClick={() => handleEditVoyage(row.original)}
      />
    ),
  });

  return <MaterialReactTable table={table} />;
};

export default VoyagesTable;
