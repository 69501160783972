import {Icon, ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";
import {SelectFontSize} from "./components";
import pxToRem from "hooks/usePxToRem";

const TextEditingButtons = () => {
  const buttonsData = [
    {command: "undo", iconName: "undo", tooltipText: "Desfazer"},
    {command: "bold", iconName: "format_bold", tooltipText: "Negrito"},
    {command: "italic", iconName: "format_italic", tooltipText: "Itálico"},
    {
      command: "underline",
      iconName: "format_underline",
      tooltipText: "Sublinhar",
    },
    {
      command: "strikeThrough",
      iconName: "strikethrough_s",
      tooltipText: "Taxar",
    },
    {
      command: "hiliteColor",
      iconName: "border_color",
      tooltipText: "Destacar",
      value: "yellow",
    },
  ];

  return (
    <ToggleButtonGroup
      size="small"
      orientation="vertical"
      sx={{
        margin: `auto ${pxToRem(24)} auto ${pxToRem(16)}`,
        justifySelf: "flex-end",
      }}
    >
      <SelectFontSize />
      {buttonsData.map((item) => (
        <Tooltip
          title={item.tooltipText}
          placement="left"
          arrow
          key={item.command}
        >
          <ToggleButton
            key={item.command}
            value={item.command}
            onMouseDown={(evt) => {
              evt.preventDefault();
              document.execCommand(item.command, false, item?.value);
            }}
          >
            <Icon sx={{fontSize: pxToRem(18), margin: pxToRem(7)}}>
              {item.iconName}
            </Icon>
          </ToggleButton>
        </Tooltip>
      ))}
    </ToggleButtonGroup>
  );
};

export default TextEditingButtons;
