import {useState} from "react";
import TaxesListingData from "./TaxesListingData";

import {Stack} from "@mui/material";
import {
  MaterialReactTable,
  MRT_RowSelectionState,
  MRT_ColumnSizingState,
  MRT_ColumnOrderState,
  MRT_VisibilityState,
} from "material-react-table";
import {MRT_Localization_PT_BR} from "material-react-table/locales/pt-BR";

import CustomToolbar from "./CustomToolbar";
import {useFormContext} from "react-hook-form";
import useToast from "hooks/useToast";
import {TaxesData} from "./TaxesListing.types";
import pxToRem from "hooks/usePxToRem";

interface TaxesListingProps {
  onRequestOpenModalComposition: () => void;
  setSelectedChargeId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const TaxesListing = (props: TaxesListingProps) => {
  const {onRequestOpenModalComposition, setSelectedChargeId} = props;
  const [showToast] = useToast();

  const {watch} = useFormContext();

  const {columnsData} = TaxesListingData();

  const columnsMapping = [
    "mrt-row-select",
    columnsData
      .flatMap((column) => column.columns)
      .map((item) => item?.accessorKey),
  ];

  const checkIfThereAreValuesInBrowserStorageIfHasSyncIt = (
    storageKey: string,
    nullReturnType: object | [],
  ) => {
    const storedValue = localStorage.getItem(storageKey);

    if (storedValue) return JSON.parse(storedValue);

    return nullReturnType;
  };

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({
    [watch("processCharges")[0]?.id]: true,
  });

  const [columnSizing, setColumnSizing] = useState<MRT_ColumnSizingState>(
    checkIfThereAreValuesInBrowserStorageIfHasSyncIt("columnSizing", {}),
  );
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>(
    checkIfThereAreValuesInBrowserStorageIfHasSyncIt(
      "columnOrder",
      columnsMapping,
    ),
  );
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    checkIfThereAreValuesInBrowserStorageIfHasSyncIt("columnVisibility", {
      equipment: false,
      payTo: false,
      paymentPersonName: false,
      typePayment: false,
      billingPayment: false,
      quantityPayment: false,
      receiptPersonName: false,
      typeReceipt: false,
      receiveFrom: false,
      quantityReceipt: false,
      modalityReceipt: false,
      dateExpiry: false,
      originCharge: false,
      displayCharge: false,
    }),
  );

  const handleSaveTableView = () => {
    localStorage.setItem("columnSizing", JSON.stringify(columnSizing));
    localStorage.setItem("columnOrder", JSON.stringify(columnOrder));
    localStorage.setItem("columnVisibility", JSON.stringify(columnVisibility));

    showToast(
      "Configurações de visualização da tabela salvas com sucesso!",
      "success",
    );
  };

  return (
    <Stack spacing={4}>
      <MaterialReactTable
        initialState={{rowSelection, pagination: {pageSize: 100, pageIndex: 0}}}
        state={{
          rowSelection,
          columnSizing,
          columnOrder,
          columnVisibility,
          density: "compact",
        }}
        onColumnSizingChange={setColumnSizing}
        onColumnOrderChange={setColumnOrder}
        onColumnVisibilityChange={setColumnVisibility}
        columns={columnsData}
        data={watch("processCharges") as TaxesData[]}
        localization={MRT_Localization_PT_BR}
        enableFullScreenToggle={false}
        enableBottomToolbar={false}
        enableDensityToggle={false}
        enableFilters={false}
        enableSorting={false}
        enableRowSelection={true}
        enableMultiRowSelection={false}
        enableStickyHeader
        layoutMode="grid"
        enableColumnDragging
        enableColumnResizing
        enableColumnOrdering
        columnResizeMode="onEnd"
        positionToolbarAlertBanner="none"
        onRowSelectionChange={setRowSelection}
        renderToolbarInternalActions={({table}) => (
          <CustomToolbar
            onRequestSaveTableView={handleSaveTableView}
            onRequestOpenModalComposition={onRequestOpenModalComposition}
            currentRateSelected={rowSelection}
            table={table}
          />
        )}
        displayColumnDefOptions={{
          "mrt-row-select": {
            Header: "Selec.",
          },
        }}
        getRowId={(row) => row?.id}
        muiTableContainerProps={{
          sx: (theme) => ({
            maxHeight: pxToRem(600),
            backgroundColor: theme.palette.background.paper,
          }),
        }}
        muiTablePaperProps={{
          elevation: 0,
        }}
        muiTopToolbarProps={{
          sx: (theme) => ({backgroundColor: theme.palette.background.paper}),
        }}
        muiTableHeadCellProps={{
          sx: (theme) => ({
            backgroundColor: theme.palette.background.paper,

            ".Mui-TableHeadCell-Content-Wrapper": {
              whiteSpace: "nowrap",
            },
          }),
        }}
        muiTableBodyRowProps={({row}) => ({
          onClick: () => {
            setRowSelection((prev) => ({
              [row?.id]: !prev[row?.id],
            }));
            setSelectedChargeId(+row?.id);
          },
          selected: rowSelection[row?.id],
          sx: (theme) => ({
            cursor: "pointer",
            backgroundColor: theme.palette.background.paper,
          }),
        })}
        muiTableBodyCellProps={() => ({
          sx: (theme) => ({
            color: theme.palette.text.secondary,
            borderRight: `1px solid ${theme.palette.grey[300]}`,
          }),
        })}
      />
    </Stack>
  );
};

export default TaxesListing;
