import {Box, Divider, Stack, Typography} from "@mui/material";

import {Icon as Iconify} from "@iconify/react";

import StyledPaper from "./StatCard.styles";
import pxToRem from "hooks/usePxToRem";

interface StatCard {
  numbersOf: number;
  title: string;
  iconName: string;
  isActive?: boolean;
  onClickRequest?: (event: React.MouseEvent<HTMLElement>) => void;
}

const StatCard = ({
  numbersOf = 0,
  title,
  iconName,
  isActive,
  onClickRequest,
}: StatCard) => {
  return (
    <StyledPaper elevation={0} isActive={isActive} onClick={onClickRequest}>
      <Stack
        className="card__content"
        justifyContent="space-between"
        gap={pxToRem(3)}
      >
        <Stack
          className="card__text"
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography className="card__title" variant="h4" component="strong">
            {numbersOf}
          </Typography>

          <Stack
            className="card__icon--box"
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Iconify className="card__icon" icon={iconName}>
              {iconName}
            </Iconify>
          </Stack>
        </Stack>

        <Typography
          className="card__subtitle"
          color="text.secondary"
          variant="subtitle2"
          component="p"
        >
          {title}
        </Typography>
      </Stack>

      <Box>
        <Divider />

        <Stack
          className="card__footer"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography className="card__footer--title" variant="subtitle3">
            Detalhes
          </Typography>

          <Iconify
            className="card__footer--icon"
            icon="mdi:arrow-bottom-right-thin-circle-outline"
          />
        </Stack>
      </Box>
    </StyledPaper>
  );
};

export default StatCard;
