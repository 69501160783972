import {Grid} from "@mui/material";

import {DefaultCardStructure} from "components";
import pxToRem from "hooks/usePxToRem";
import {useState} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {CustomInput, CustomInputWrapper} from "ui";
import {calcConsideredWeight} from "../../utils/calculateConsideredWeight";

interface Props {
  loadIndex: number;
}

const HouseMeasurements = (props: Props) => {
  const {watch, control, setValue} = useFormContext();
  const [temporaryValue, setTemporaryValue] = useState<{
    consideredWeight: number;
    cubeWeight: number;
  } | null>(null);

  const handleCalcWeights = () => {
    const loadData = calcConsideredWeight(
      watch(`processLoads[${props.loadIndex}].cubicMeters`) ?? 0,
      watch(`processLoads[${props.loadIndex}].grossWeight`) ?? 0,
    );

    setTemporaryValue(loadData);
    setValue(
      `processLoads[${props.loadIndex}].consideredWeight`,
      loadData.consideredWeight,
      {shouldDirty: true},
    );
    setValue(
      `processLoads[${props.loadIndex}].cubeWeight`,
      loadData.cubeWeight,
      {shouldDirty: true},
    );
  };

  return (
    <DefaultCardStructure title="Medidas House" hasDivider={false} isSubcard>
      <Grid container spacing={pxToRem(8)} mb={pxToRem(20)}>
        <CustomInputWrapper title="Peso Bruto - kg" xs={3}>
          <Controller
            name={`processLoads[${props.loadIndex}].grossWeight`}
            control={control}
            render={({field: {value}}) => (
              <CustomInput
                value={value ?? 0}
                name={`processLoads[${props.loadIndex}].grossWeight`}
                placeholder="Peso bruto"
                onChange={(e) => {
                  setValue(
                    `processLoads[${props.loadIndex}].grossWeight`,
                    +e.target.value,
                    {shouldDirty: true},
                  );
                  handleCalcWeights();
                }}
                size="small"
                type="number"
                inputProps={{
                  style: {
                    fontSize: pxToRem(14),
                  },
                  min: 0.0,
                  step: 0.001,
                }}
              />
            )}
          />
        </CustomInputWrapper>

        <CustomInputWrapper title="Metros Cúbicos" xs={3}>
          <Controller
            name={`processLoads[${props.loadIndex}].cubicMeters`}
            control={control}
            render={({field: {value}}) => (
              <CustomInput
                value={value ?? 0}
                name={`processLoads[${props.loadIndex}].cubicMeters`}
                placeholder="m³"
                onChange={(e) => {
                  setValue(
                    `processLoads[${props.loadIndex}].cubicMeters`,
                    +e.target.value,
                    {shouldDirty: true},
                  );
                  handleCalcWeights();
                }}
                size="small"
                type="number"
                inputProps={{
                  style: {
                    fontSize: pxToRem(14),
                  },
                  min: 0.0,
                  step: 0.001,
                }}
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Quant." xs={2}>
          <Controller
            name={`processLoads[${props.loadIndex}].quantities`}
            control={control}
            render={({field: {value}}) => (
              <CustomInput
                value={value ?? 0}
                name={`processLoads[${props.loadIndex}].quantities`}
                placeholder="m³"
                onChange={(e) => {
                  setValue(
                    `processLoads[${props.loadIndex}].quantities`,
                    +e.target.value,
                    {shouldDirty: true},
                  );
                }}
                size="small"
                type="number"
                inputProps={{
                  style: {
                    fontSize: pxToRem(14),
                  },
                  min: 0.0,
                  step: 0.001,
                }}
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Peso Cubado - kg" xs={2} isDisabled>
          <Controller
            name={`processLoads[${props.loadIndex}].cubeWeight`}
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                disabled
                value={temporaryValue?.cubeWeight ?? value}
                name={`processLoads[${props.loadIndex}].cubeWeight`}
                placeholder="Peso cubado"
                onChange={onChange}
                size="small"
                type="number"
                inputProps={{
                  style: {
                    fontSize: pxToRem(14),
                  },
                  min: 0,
                  step: 0.001,
                }}
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Peso Considerado - kg" xs={2} isDisabled>
          <Controller
            name={`processLoads[${props.loadIndex}].consideredWeight`}
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                value={temporaryValue?.consideredWeight ?? value}
                disabled
                name={`processLoads[${props.loadIndex}].consideredWeight`}
                placeholder="Peso considerado"
                onChange={onChange}
                size="small"
                type="number"
                inputProps={{
                  style: {
                    fontSize: pxToRem(14),
                  },
                  min: 0,
                  step: 0.001,
                }}
              />
            )}
          />
        </CustomInputWrapper>
      </Grid>
    </DefaultCardStructure>
  );
};

export default HouseMeasurements;
