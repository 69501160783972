import {Stack} from "@mui/material";
import {ReactNode} from "react";

interface TabPanelProps {
  index: number;
  tabsValue: number;
  id: string;
  component: ReactNode;
}

const TabPanel = (props: TabPanelProps) => {
  const {index, id, tabsValue, component} = props;

  return (
    <Stack role="tabpanel" hidden={tabsValue !== index} id={id}>
      {tabsValue === index && component}
    </Stack>
  );
};

export default TabPanel;
