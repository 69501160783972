import {Theme, ChipProps} from "@mui/material";
import pxToRem from "hooks/usePxToRem";

interface MuiChipStyleOverridesRootProps {
  theme: Theme;
  ownerState: ChipProps;
}

interface CustomChipColorData {
  backgroundColor: string;
  color: string;
}

declare module "@mui/material/Chip" {
  interface ChipPropsSizeOverrides {
    large: true;
  }
}

const customFilledChipColors = (theme: Theme) => ({
  primary: {
    backgroundColor: theme.palette.primary[50],
    color:
      theme.palette.mode === "dark"
        ? theme.palette.primary[600]
        : theme.palette.primary[700],
  },

  secondary: {
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.grey[700]
        : theme.palette.grey[50],

    color:
      theme.palette.mode === "dark"
        ? theme.palette.grey[50]
        : theme.palette.grey[700],
  },

  info: {
    backgroundColor: theme.palette.info[50],
    color: theme.palette.info[700],
  },

  success: {
    backgroundColor: theme.palette.success[50],
    color: theme.palette.success[700],
  },

  warning: {
    backgroundColor: theme.palette.warning[50],
    color: theme.palette.warning[700],
  },

  error: {
    backgroundColor: theme.palette.error[50],
    color: theme.palette.error[700],
  },
});

const defaultBadgeStyles = {
  MuiChip: {
    defaultProps: {
      color: "secondary" as const,
    },

    styleOverrides: {
      sizeLarge: {
        padding: `${pxToRem(6)} ${pxToRem(16)}`,
      },

      root: ({ownerState, theme}: MuiChipStyleOverridesRootProps) => {
        const customChipColor: CustomChipColorData =
          customFilledChipColors(theme)[
            ownerState.color as keyof typeof customFilledChipColors
          ];

        return {
          fontWeight: theme.typography.fontWeightMedium,
          fontSize: theme.typography.subtitle2.fontSize,

          backgroundColor: customChipColor?.backgroundColor,
          color: customChipColor?.color,

          ...(ownerState.size === "small" && {
            padding: `${pxToRem(2)} ${pxToRem(12)}`,
          }),

          ...(ownerState.size === "medium" && {
            padding: `${pxToRem(4)} ${pxToRem(14)}`,
          }),

          "& .MuiChip-label": {
            padding: 0,
          },
          "& .MuiChip-deleteIcon": {
            margin: 0,
            fontSize: pxToRem(16),
            color:
              theme.palette.mode === "dark"
                ? theme.palette.grey[50]
                : theme.palette.grey[700],
            "&:hover": {
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.grey[300]
                  : theme.palette.grey[500],
            },
          },
        };
      },
    },
  },
};

export default defaultBadgeStyles;
