import {Chip} from "@mui/material";
import {Stack, Box, SxProps, Theme} from "@mui/system";
import HeaderWithNavigation from "components/HeaderWithNavigation";
import pxToRem from "hooks/usePxToRem";
import {useState, useEffect, MutableRefObject, LegacyRef} from "react";
import Editor from "react-simple-code-editor";

type Props = {
  variablesList: string[];
  onClick: (lastRef: LegacyRef<Editor> | undefined, field: string) => void;
  editorRef: MutableRefObject<LegacyRef<Editor> | undefined>;
  sx?: SxProps<Theme>;
};

const VariablesList = (props: Props) => {
  const {variablesList, onClick, editorRef} = props;

  const [lastRef, setLastRef] =
    useState<MutableRefObject<LegacyRef<Editor> | undefined>>();

  useEffect(() => {
    if (editorRef) {
      setLastRef(editorRef);
    }
  }, [editorRef]);

  const orderArray = (list: string[]) => {
    return list.sort((a, b) => a.localeCompare(b));
  };

  return (
    <Stack
      sx={{
        width: "30%",
        gap: pxToRem(8),
        height: `calc(100vh - ${pxToRem(188)})`,
        overflowY: "auto",
        paddingLeft: pxToRem(24),
        ...props?.sx,
      }}
    >
      <HeaderWithNavigation
        title={"Variáveis"}
        sx={{marginBottom: pxToRem(8)}}
      />
      {orderArray(variablesList).map((field) => (
        <Box
          key={field}
          sx={{cursor: "pointer"}}
          onClick={() => onClick(lastRef?.current, field)}
        >
          <Chip label={field} />
        </Box>
      ))}
    </Stack>
  );
};

export default VariablesList;
