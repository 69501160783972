import {AxiosResponse} from "axios";
import {keyCloakAPI, newBackendAPI} from "../../apiConsumer";

type LoginDataType = {
  email: string;
  password: string;
};

type LoginReturn<T> = {
  data: {
    menu: Array<T>;
    token: string;
    user: {
      avatar: string;
      id: string;
      email: string;
      name: string;
      logged_at: string;
    };
  };
};

type KeycloakLoginReturn<T> = {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  token_type: string;
  session_state: string;
  scope: string;
};

export const Authenticate = async <T>(
  loginData: LoginDataType,
): Promise<AxiosResponse<LoginReturn<T>>> => {
  return await newBackendAPI.post("/authenticate", loginData);
};

export const RequestPasswordReset = async (mail: string) => {
  return await newBackendAPI.post("/forgot-password", {email: mail});
};

export const ValidatePasswordResetCode = async (passwordResetCode: {
  email: string;
  code: string;
}) => {
  return await newBackendAPI.post("/validate-code", passwordResetCode);
};

export const UpdatePassword = async (newPassword: {
  email: string;
  password: string;
}) => {
  return await newBackendAPI.post("/update-password", newPassword);
};

export const KeyCloakAuthenticate = async <T>(
  loginData: LoginDataType,
): Promise<AxiosResponse<KeycloakLoginReturn<T>>> => {
  return await keyCloakAPI.post(
    "/auth/realms/inext/protocol/openid-connect/token",
    FormatKeyCloakAuthInfo(loginData),
  );
};

const FormatKeyCloakAuthInfo = (loginData: LoginDataType) => {
  const {innerWidth: screenWidth} = window;

  const params = new URLSearchParams();

  params.append("grant_type", "password");
  params.append(
    "client_id",
    screenWidth > 768
      ? (process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? "")
      : (process.env.REACT_APP_KEYCLOAK_MOBILE_ID ?? ""),
  );
  params.append("scope", "openid");
  params.append("username", loginData.email);
  params.append("password", loginData.password);

  return params;
};

export const KeyCloakRefreshTokenAuth = async <T>(
  refreshToken: string,
): Promise<AxiosResponse<KeycloakLoginReturn<T>>> => {
  return await keyCloakAPI.post(
    "/auth/realms/inext/protocol/openid-connect/token",
    FormatKeyCloakRefreshTokenReqInfo(refreshToken),
  );
};

const FormatKeyCloakRefreshTokenReqInfo = (refreshToken: string) => {
  const {innerWidth: screenWidth} = window;
  const params = new URLSearchParams();

  params.append("grant_type", "refresh_token");
  params.append(
    "client_id",
    screenWidth > 768
      ? (process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? "")
      : (process.env.REACT_APP_KEYCLOAK_MOBILE_ID ?? ""),
  );
  params.append("refresh_token", refreshToken);

  return params;
};
