import {ConfirmModal} from "components";
import {DeleteProcessFile} from "services/api_v2/Processes/Processes.service";
import useLoading from "hooks/useLoading";
import useToast from "hooks/useToast";
import {Dispatch, SetStateAction} from "react";
import useAlertMessage from "hooks/useAlertMessage";

interface DeleteDocumentModalProps {
  openRemoveSingleDocumentModal: boolean;
  setOpenRemoveSingleDocumentModal: Dispatch<SetStateAction<boolean>>;
  getProcessDetails: () => Promise<void>;
  fileInfo: {id: number; name: string};
}

const DeleteDocumentModal = (props: DeleteDocumentModalProps) => {
  const {
    openRemoveSingleDocumentModal,
    setOpenRemoveSingleDocumentModal,
    getProcessDetails,
    fileInfo,
  } = props;

  const [showAlert] = useAlertMessage();
  const [setLoading] = useLoading();
  const [showToast] = useToast();

  const handleClose = () => {
    setOpenRemoveSingleDocumentModal(false);
  };

  const deleteOnlineReleaseSingleDocument = async () => {
    try {
      setLoading(true);
      await DeleteProcessFile(fileInfo?.id);
      showToast("Documento deletado com sucesso");
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    } finally {
      getProcessDetails();
      setLoading(false);
      handleClose();
    }
  };

  return (
    <ConfirmModal
      isOpen={openRemoveSingleDocumentModal}
      title={"Deletar documento anexado"}
      subtitle={`Deseja deletar o documento de ${fileInfo?.name} que já foi anexado?`}
      confirmButtonFn={() => deleteOnlineReleaseSingleDocument()}
      onClose={handleClose}
    />
  );
};

export default DeleteDocumentModal;
