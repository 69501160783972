import {createActions, createReducer} from "reduxsauce";

type initial = {
  message: string;
};

const INITIAL_STATE: initial = {
  message: "",
};

export const {Types, Creators} = createActions({
  setMessage: ["message"],
});

const setMessage = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  message: action.message,
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_MESSAGE]: setMessage,
});
