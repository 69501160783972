import pxToRem from "hooks/usePxToRem";

import {Grid} from "@mui/material";

import {
  CustomInputWrapper,
  CustomInput,
  CustomInputToSelectDate,
  CustomSelect,
} from "ui";
import {memo, useEffect} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {CustomDatePicker} from "components";
import useChangeDAteAndAutocomplete from "views/ProcessDetails/hooks/useChangeDAteAndAutocomplete";

const BasicRateInfo = (props: {chargeId?: number}) => {
  const {watch, control, setValue} = useFormContext();

  const {handleChangeDateField} = useChangeDAteAndAutocomplete();

  useEffect(() => {
    setValue(
      "charges",
      watch("processCharges").find((item) => item?.id === props?.chargeId),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chargeId]);

  return (
    <Grid
      container
      rowSpacing={pxToRem(20)}
      columnSpacing={pxToRem(8)}
      mt={pxToRem(20)}
    >
      <CustomInputWrapper md={3} title="Taxa">
        <Controller
          name="charges.rate"
          control={control}
          render={({field: {onChange, value}}) => (
            <CustomInput
              name="charges.rate"
              placeholder="Taxa"
              value={value}
              onChange={onChange}
              size="small"
              disabled
            />
          )}
        />
      </CustomInputWrapper>

      <CustomInputWrapper md={3} title="Origem Taxa">
        <Controller
          name="charges.originCharge"
          control={control}
          render={({field: {onChange, value}}) => (
            <CustomSelect
              name="charges.originCharge"
              value={value ?? ""}
              onChange={onChange}
              content={["Frete", "Destino", "Outros"]}
              size="small"
              disabled
            />
          )}
        />
      </CustomInputWrapper>

      <CustomInputWrapper md={3} title="Data Validade">
        <Controller
          name="charges.dateExpiry"
          control={control}
          render={({field: {value}}) => (
            <CustomDatePicker
              customInput={<CustomInputToSelectDate />}
              name="charges.dateExpiry"
              fullWidth
              getDatePickerValuesOnClose={(dates) =>
                handleChangeDateField(dates, "dateEstimatedReadinessCargo")
              }
              value={value}
              showDateHeader={false}
              disabled
            />
          )}
        />
      </CustomInputWrapper>

      <CustomInputWrapper md={3} title="Tipo Serviço">
        <Controller
          name="charges.typeService"
          control={control}
          render={({field: {onChange, value}}) => (
            <CustomInput
              name="charges.typeService"
              value={value}
              placeholder="Tipo"
              onChange={onChange}
              size="small"
              disabled
            />
          )}
        />
      </CustomInputWrapper>
    </Grid>
  );
};

export default memo(BasicRateInfo);
