import {Box, Divider, Stack} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {useHistory} from "react-router-dom";
import {CustomButton} from "ui";

interface FooterProps {
  form: string;
  bottom?: string;
  disabledSaveButton?: boolean;
}

const Footer = (props: FooterProps) => {
  const {bottom, disabledSaveButton, form} = props;
  const history = useHistory();

  const handleGoToLastUrl = () => {
    history.goBack();
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      gap={pxToRem(12)}
      sx={{
        width: "100%",
        height: pxToRem(55.94),
        justifySelf: "flex-end",
        boxShadow: "none",
        position: "absolute",
        bottom: bottom ?? 0,
        padding: `${pxToRem(8)} 0 0 0 `,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: pxToRem(0),
          width: `calc(100% + ${pxToRem(50)})`,
        }}
      >
        <Divider />
      </Box>
      <CustomButton
        variant="outlined"
        size="small"
        sx={{textTransform: "capitalize"}}
        onClickFn={handleGoToLastUrl}
      >
        Cancelar
      </CustomButton>
      <CustomButton
        endIcon={"save"}
        size="small"
        type="submit"
        disabled={disabledSaveButton}
        form={form}
        sx={{
          textTransform: "capitalize",
          margin: `0 ${pxToRem(24)} 0 0`,
        }}
      >
        Salvar
      </CustomButton>
    </Stack>
  );
};

export default Footer;
