import {Dispatch, SetStateAction, useEffect} from "react";

interface TablesSearchFunctionProps {
  globalFilter: string;
  currentList: any[];
  setCurrentList: Dispatch<SetStateAction<any[]>>;
  getFn: () => Promise<void> | void;
  objKeyToCompare?: string;
  //prop abaixo pode ser removida assim que todos os endpoints de tabela forem ajustados pra aceitar pesquisa por nome
  noApiSearch?: boolean;
}

const TablesSearchFunction = (props: TablesSearchFunctionProps) => {
  const {
    globalFilter,
    currentList,
    setCurrentList,
    getFn,
    objKeyToCompare = "name",
    noApiSearch,
  } = props;

  useEffect(() => {
    const keys = objKeyToCompare?.split(".");

    const foundElements = currentList?.filter((item) => {
      let itemWithKeys = item;

      for (const k in keys) {
        itemWithKeys = itemWithKeys[keys[k]];
      }
      return itemWithKeys?.toLowerCase()?.includes(globalFilter?.toLowerCase());
    });

    if (noApiSearch) {
      if (globalFilter) {
        setCurrentList(foundElements);
      } else {
        getFn();
      }
    } else if (globalFilter?.length > 3 || !globalFilter) {
      getFn();
    }
  }, [globalFilter]);
};
export default TablesSearchFunction;
