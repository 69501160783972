/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Stack, useTheme} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import LoadingBar, {hideLoading, showLoading} from "react-redux-loading-bar";

const CustomEntirePageLoading = () => {
  const {entirePageLoading} = useSelector((state: any) => state.stateLoading);

  const dispatch = useDispatch();

  const theme = useTheme();

  const handleLoadingBarSatus = () => {
    if (entirePageLoading) {
      dispatch(showLoading());
    } else {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    handleLoadingBarSatus();
  }, [entirePageLoading]);

  return (
    <React.Fragment>
      {entirePageLoading && (
        <Stack
          sx={{
            height: "100vh",
            backgroundColor: "background.paper",
            width: "100vw",
            zIndex: 1,
            position: "absolute",
          }}
        >
          <LoadingBar
            updateTime={10}
            maxProgress={100}
            progressIncrease={10}
            showFastActions
            style={{
              backgroundColor: theme.palette.primary.main,
              height: pxToRem(5),
              position: "absolute",
              top: 0,
            }}
          />
        </Stack>
      )}
    </React.Fragment>
  );
};

export default CustomEntirePageLoading;
