export const splitAndRemoveSpacesFromString = (
  text: string,
  regexToSplit?: RegExp | string,
) => {
  const regex = regexToSplit ?? /;|,/;

  const arrayFromString = text?.trim().split(regex);
  const arrayWithoutSpaces = arrayFromString?.map((item) => item.trim());
  return arrayWithoutSpaces?.filter((item) => item !== "");
};
