import {MonitoredField} from "components/ModalCompleteActivity/CompleteActivity.types";
import useHandlePaginatedAutocomplete from "components/ModalCompleteActivity/useHandleAutocomplete";
import {CustomAutocomplete} from "ui";

type Props = {
  field: MonitoredField;
  handleChangeFields: (
    e: React.ChangeEvent<HTMLInputElement> | null,
    field: MonitoredField,
    newValue?: any,
  ) => void;
  disabled?: boolean;
};

const AutocompleteField = (props: Props) => {
  const {field, handleChangeFields, disabled} = props;

  const {
    isLoadingOptions,
    options,
    onBottomScroll,
    onInputChange,
    onOpen,
    mountOptions,
  } = useHandlePaginatedAutocomplete({
    path: field?.endpoint,
  });
  const handleAutoCompleteFields = (
    field: MonitoredField,
    newValue: string,
  ) => {
    handleChangeFields(null, field, newValue);
  };

  const locationsOrLegalPersons =
    field?.endpoint === "/ports" ||
    field?.endpoint?.includes("/legal-person-by-types") ||
    field?.endpoint?.includes("/persons-by-type");

  return (
    <CustomAutocomplete
      optionSecondaryContent={locationsOrLegalPersons}
      name="item"
      placeholder={field?.label ?? ""}
      loading={isLoadingOptions}
      required
      value={field?.value?.id ? field?.value : null}
      onChange={(_, v) => handleAutoCompleteFields(field, v)}
      options={mountOptions(field?.value, options)}
      onOpen={onOpen}
      getNextPage={() => {
        onBottomScroll();
      }}
      onInputChange={(e, value) => onInputChange(e, value)}
      disabled={disabled}
    />
  );
};

export default AutocompleteField;
