import {createTheme} from "@mui/material/styles";

import {breakpointValues, priorityColors, typographyConfigs} from "../base";
import {defaultBadgeStyles, defaultButton} from "global/components";

const darkTheme = createTheme({
  breakpoints: {
    values: {
      ...breakpointValues,
    },
  },

  typography: {
    ...typographyConfigs,
  },

  components: {
    ...defaultButton,
    ...defaultBadgeStyles,
  },

  palette: {
    mode: "dark",
    ...priorityColors,

    primary: {
      main: "#14B8A6",
      50: "#F0FDFA",
      100: "#CCFBF1",
      200: "#99F6E4",
      300: "#5EEAD4",
      400: "#2DD4BF",
      500: "#14B8A6",
      600: "#0D9488",
      700: "#0F766E",
      800: "#115E59",
      900: "#134E4A",
    },

    secondary: {
      main: "#32C4A9",
    },

    grey: {
      50: "#f8fafc",
      100: "#f1f5f9",
      200: "#e2e8f0",
      300: "#cbd5e1",
      400: "#94a3b8",
      500: "#64748b",
      600: "#475569",
      700: "#334155",
      800: "#1e293b",
      900: "#0f172a",
      A400: "#404F64",
    },

    background: {
      default: "#1a2035",
      paper: "#1f283e",
    },

    text: {
      primary: "#ffffff",
      secondary: "#ffffff",
    },
  },
});

export default darkTheme;
