import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {Dispatch, SetStateAction} from "react";
import {CustomButton} from "ui";
import completedActivity from "assets/images/activityCompleted.svg";

interface ModalActivityCompletionInfoProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  processNumber: string;
  hasNextActivity: boolean;
  handleFinishTask: () => void;
  onCloseFinishTask: () => void;
  activityName?: string;
  nextActivityName?: string;
}

const ModalActivityCompletionInfo = (
  props: ModalActivityCompletionInfoProps,
) => {
  const theme = useTheme();

  const {
    isOpen,
    setIsOpen,
    processNumber,
    hasNextActivity,
    handleFinishTask,
    onCloseFinishTask,
    activityName = "",
    nextActivityName = "",
  } = props;

  const handleCloseActivityCompletionInfoModal = () => {
    setIsOpen(false);
    onCloseFinishTask();
  };

  const handleGoToNextActivity = () => {
    setIsOpen(false);
    handleFinishTask();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseActivityCompletionInfoModal}
      PaperProps={{sx: {width: pxToRem(450)}}}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: pxToRem(28),
        }}
      >
        <Box component="img" src={completedActivity} />
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: pxToRem(12),
          paddingTop: pxToRem(7),
          padding: `0 ${pxToRem(20)} ${pxToRem(24)} ${pxToRem(20)}`,
        }}
      >
        <Typography variant="h4">Atividade concluída!</Typography>
        <Typography variant="body2" sx={{textAlign: "center", fontWeight: 500}}>
          A atividade de{" "}
          <Typography
            variant="body2"
            component="span"
            sx={{
              display: "inline-flex",
              color: "primary.main",
              fontWeight: 500,
            }}
          >
            {activityName}
          </Typography>{" "}
          foi concluída com sucesso para o processo de número{" "}
          <Typography
            variant="body2"
            component="span"
            sx={{
              display: "inline-flex",
              color: "primary.main",
              fontWeight: 500,
            }}
          >
            {processNumber}
          </Typography>
          .{" "}
          {hasNextActivity
            ? `Deseja prosseguir para a próxima atividade (${nextActivityName}) que precisa ser concluída nesse processo?`
            : "No momento não há outras atividades desse processo para concluir."}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          padding: pxToRem(20),
          display: "flex",
          justifyContent: "flex-end",
          flexWrap: "nowrap",
          borderTop: `${pxToRem(1)} solid ${theme.palette.grey[100]}`,
        }}
      >
        <CustomButton
          variant="outlined"
          onClickFn={handleCloseActivityCompletionInfoModal}
          sx={{width: hasNextActivity ? "50%" : "100%"}}
        >
          Fechar
        </CustomButton>
        {hasNextActivity && (
          <CustomButton
            endIcon={"arrow_forward"}
            onClickFn={handleGoToNextActivity}
            sx={{width: "50%", marginLeft: 0}}
          >
            Próxima atividade
          </CustomButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ModalActivityCompletionInfo;
