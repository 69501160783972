import {useSelector} from "react-redux";

import useUserSettings from "hooks/useUserSettings";

import StyledSwitch from "./ThemeSwitcher.styles";

const ThemeSwitcher = () => {
  const {
    userInfo: {
      settings: {darkMode},
    },
  } = useSelector((state: any) => state.stateUserInfo);

  const {saveUserSettings} = useUserSettings();

  const handleChangeTheme = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(
      "theme",
      JSON.stringify(event.target.checked ? "dark" : "light"),
    );

    saveUserSettings({
      darkMode: event.target.checked,
    });
  };

  return <StyledSwitch checked={darkMode} onChange={handleChangeTheme} />;
};

export default ThemeSwitcher;
