import {Stack} from "@mui/material";
import {ReactNode} from "react";

interface TwoColumnsViewLayoutProps {
  firstColumn: {width: string; content: ReactNode; minWidth?: string};
  secondColumn: {width: string; content: ReactNode; minWidth?: string};
  containerHeight?: string;
}

const TwoColumnsViewLayout = (props: TwoColumnsViewLayoutProps) => {
  const {firstColumn, secondColumn, containerHeight} = props;
  return (
    <Stack
      direction="row"
      sx={{
        height: containerHeight ?? "100%",
        width: "100%",
      }}
    >
      <Stack
        sx={{
          height: "100%",
          width: firstColumn.width,
          minWidth: firstColumn?.minWidth ?? undefined,
        }}
      >
        {firstColumn.content}
      </Stack>
      <Stack
        sx={{
          height: "100%",
          width: secondColumn.width,
          minWidth: secondColumn?.minWidth ?? undefined,
        }}
      >
        {secondColumn.content}
      </Stack>
    </Stack>
  );
};

export default TwoColumnsViewLayout;
