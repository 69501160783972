import {Typography, Stack, Grid} from "@mui/material";
// import {Stack, Grid} from "@mui/system";
import ConfirmModal from "components/ConfirmModal";
import DefaultCardStructure from "components/DefaultCardStructure";
import useAlertMessage from "hooks/useAlertMessage";
import {useDebounceCallback} from "hooks/useDebounceCallback";
import pxToRem from "hooks/usePxToRem";
import useToast from "hooks/useToast";
import {useState} from "react";
import {useFormContext, useFieldArray, Controller} from "react-hook-form";
import {
  GetPackagesList,
  DeleteLogisticPackage,
} from "services/api_v2/Processes/Processes.service";
import {
  CustomButton,
  CustomInputWrapper,
  CustomAutocomplete,
  CustomInput,
  CustomIconButton,
  CustomSelect,
} from "ui";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";
import {WoodPackage} from "util/globalEnums";
import {ModalNewPackage} from "views/ProcessDetails/components/ProcessLoad/components";
import {calcConsideredWeight} from "views/ProcessDetails/components/ProcessLoad/utils/calculateConsideredWeight";
import useChangeDAteAndAutocomplete from "views/ProcessDetails/hooks/useChangeDAteAndAutocomplete";
import useHandleFieldLists from "views/ProcessDetails/hooks/useHandleFieldLists";
import {
  DefaultProcessDetails,
  LogisticPackages,
} from "views/ProcessDetails/Process.types";
import {getTooltipText} from "views/ProcessDetails/utils/getTooltipText";

type Props = {
  refetchProcess: () => Promise<DefaultProcessDetails | undefined>;
};

const ActivityPackages = (props: Props) => {
  const {refetchProcess} = props;

  const loadIndex = 0;
  const {control, setValue, watch, reset, formState} = useFormContext();
  const {fields, append, remove} = useFieldArray({
    name: "processPackages",
    keyName: "idForm",
  });

  const [packagesOptions, setPackagesOptions] = useState(
    [] as AutoCompleteData[],
  );
  const [search, setSearch] = useState<string>();

  const {
    getOptions: getPackagesOptions,
    onBottomScroll: onBottomScrollPackages,
    isLoadingOptions: isLoadingPackagesOptions,
  } = useHandleFieldLists(GetPackagesList);

  const {handleChangeAutocomplete, mountOptions} =
    useChangeDAteAndAutocomplete();

  const handleAppendItem = () => {
    append({
      consideredWeight: 0,
      cubedWeight: 0,
      grossWeight: 0,
      height: 0,
      length: 0,
      cubicMeters: 0,
      netWeight: 0,
      package: 0,
      quantity: 0,
      width: 0,
    });
  };

  const [tempData, setTempData] = useState<
    | {
        cubicMeters?: number;
        cubeWeight?: number;
        consideredWeight?: number;
      }[]
    | null
    | undefined
  >(null);

  const handleChangeTempMeasureValue = (
    value: number | undefined,
    index: number,
  ) => {
    const newTempValue = tempData?.map((item, i) => {
      if (i === index) {
        return {...item, cubicMeters: value};
      } else {
        return item;
      }
    });
    return newTempValue;
  };

  const handleChangeTempLoadsValue = (
    data: {[key: string]: number | undefined},
    index: number,
  ) => {
    const newTempValue = tempData?.map((item, i) => {
      if (i === index) {
        return {...item, ...data};
      } else {
        return item;
      }
    });
    return newTempValue ?? [data];
  };

  const calculateVolume = (index: number) => {
    const widthInMeters = (watch(`processPackages.${index}.width`) ?? 0) / 100;
    const lengthInMeters =
      (watch(`processPackages.${index}.length`) ?? 0) / 100;
    const heightInMeters =
      (watch(`processPackages.${index}.height`) ?? 0) / 100;

    const volume = +(widthInMeters * lengthInMeters * heightInMeters).toFixed(
      3,
    );
    const newTempValue = handleChangeTempMeasureValue(volume, index);
    setTempData(newTempValue);
    setValue(`processPackages.${index}.cubicMeters`, volume, {
      shouldDirty: true,
    });
    calculateWeight(index);
  };

  const calculateVolumeDebounced = useDebounceCallback(calculateVolume, 1000);

  const calculateWeight = (index: number) => {
    const loadData = calcConsideredWeight(
      watch(`processPackages[${index}].cubicMeters`) ?? 0,
      watch(`processPackages[${index}].grossWeight`) ?? 0,
    );

    const newTempValue = handleChangeTempLoadsValue(loadData, index);
    setTempData(newTempValue);
    setValue(
      `processPackages[${index}].consideredWeight`,
      loadData.consideredWeight,
      {shouldDirty: true},
    );
    setValue(`processPackages[${index}].cubedWeight`, loadData.cubeWeight, {
      shouldDirty: true,
    });
    setHouseMeasurementsDebounced();
  };

  const setHouseMeasurements = () => {
    const packages = watch("processPackages");
    const totalGrossWeight =
      packages.reduce((acc, prev) => acc + prev.grossWeight, 0) ?? 0;
    setValue(`processLoads[${loadIndex}].grossWeight`, totalGrossWeight, {
      shouldDirty: true,
    });

    const totalMeasurements =
      packages.reduce((acc, prev) => acc + prev.cubicMeters, 0) ?? 0;

    setValue(`processLoads[${loadIndex}].cubicMeters`, totalMeasurements, {
      shouldDirty: true,
    });
    const totalQuantities =
      packages.reduce((acc, prev) => acc + prev.quantity, 0) ?? 0;
    setValue(`processLoads[${loadIndex}].quantities`, totalQuantities, {
      shouldDirty: true,
    });

    //calcular peso considerado da carga
    const loadData = calcConsideredWeight(
      totalMeasurements ?? 0,
      totalGrossWeight ?? 0,
    );
    setValue(
      `processLoads[${loadIndex}].consideredWeight`,
      loadData.consideredWeight,
      {shouldDirty: true},
    );
    setValue(`processLoads[${loadIndex}].cubeWeight`, loadData.cubeWeight, {
      shouldDirty: true,
    });
  };
  const setHouseMeasurementsDebounced = useDebounceCallback(
    setHouseMeasurements,
    1000,
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleCloseModalAfterSave = (payload: Partial<LogisticPackages>) => {
    reset(
      {
        ...formState.defaultValues,
        processPackages: [
          ...formState.defaultValues?.processPackages,
          {...payload},
        ],
      },
      {keepDefaultValues: false},
    );

    setModalIsOpen(false);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{
    itemId?: number;
    index?: number;
  }>({});

  const handleDeleteLogisticPackage = async (field: any, index: number) => {
    if (!field.id) {
      remove(index);
      return;
    }

    setItemToDelete({itemId: field.id as number, index: index});
    setConfirmModalIsOpen(true);
  };

  const [showToast] = useToast();
  const [showAlert] = useAlertMessage();
  const handleClose = () => {
    setConfirmModalIsOpen(false);
  };

  const confirmDeletionPackage = async () => {
    if (!itemToDelete?.itemId || itemToDelete?.index === undefined) {
      return;
    }
    try {
      const prevProcessPackages = formState.defaultValues?.processPackages;

      prevProcessPackages.splice(itemToDelete?.index, 1);

      await DeleteLogisticPackage(itemToDelete.itemId);
      remove(itemToDelete.index);

      await refetchProcess();
      handleClose();

      const newDefault = {
        ...formState.defaultValues,
        processPackages: prevProcessPackages,
      };

      reset(newDefault, {
        keepDirtyValues: false,
        keepValues: true,
      });
      showToast("Item removido com sucesso", "success");
    } catch (e: any) {
      console.error(e);
      showAlert(e?.response?.data?.message, "error");
    }
  };

  return (
    <>
      {modalIsOpen && (
        <ModalNewPackage
          isOpen={modalIsOpen}
          handleCloseModalAfterSave={handleCloseModalAfterSave}
          handleCloseModal={handleCloseModal}
        />
      )}

      {confirmModalIsOpen && (
        <ConfirmModal
          isOpen={confirmModalIsOpen}
          title={"Deletar item de carga/pacote"}
          subtitle={`Esta ação não pode ser revertida!`}
          confirmButtonFn={() => confirmDeletionPackage()}
          onClose={handleClose}
        />
      )}
      <DefaultCardStructure
        title="Itens Da Carga - LCL"
        hasDivider={true}
        isSubcard
        button={
          <CustomButton startIcon="add" onClickFn={handleAppendItem}>
            Incluir item
          </CustomButton>
        }
      >
        <Stack sx={{gap: pxToRem(16)}}>
          {!fields?.length ? (
            <Typography>Não existem itens de carga no processo.</Typography>
          ) : (
            <CustomInputWrapper md={2.4} title="Wood Package">
              <Controller
                name={`processLoads[0].woodPackage`}
                control={control}
                render={({field: {onChange, value}}) => (
                  <CustomSelect
                    name={`processLoads[0].woodPackage`}
                    value={value}
                    onChange={onChange}
                    content={Object.values(WoodPackage)}
                    size="small"
                  />
                )}
              />
            </CustomInputWrapper>
          )}
          {fields?.map((field, index) => (
            <Grid key={field.idForm} container columnSpacing={pxToRem(2)}>
              <CustomInputWrapper md={2} title={`Pacote ${index + 1}`} required>
                <Controller
                  name={`processPackages.${index}.package`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomAutocomplete
                      name={`processPackages.${index}.package`}
                      placeholder="Pacote"
                      tooltipText={getTooltipText(value?.name)}
                      loading={isLoadingPackagesOptions}
                      options={mountOptions(value, packagesOptions)}
                      value={value?.name ?? null}
                      onOpen={
                        packagesOptions.length > 0
                          ? undefined
                          : () =>
                              getPackagesOptions(setPackagesOptions, 1, search)
                      }
                      onInputChange={(e, value) => {
                        if (e !== null) {
                          setSearch(value);
                          getPackagesOptions(setPackagesOptions, 1, search);
                        }
                      }}
                      getNextPage={() => {
                        onBottomScrollPackages(setPackagesOptions, search);
                      }}
                      onChange={(e, value) => {
                        handleChangeAutocomplete(
                          e,
                          value,
                          `processPackages.${index}.package`,
                        );
                      }}
                      isOptionEqualToValue={(option, value) =>
                        value === option?.label
                      }
                      required
                    />
                  )}
                />
              </CustomInputWrapper>
              <CustomInputWrapper
                md={1}
                title="Quant."
                tooltip="Quantidade"
                required
              >
                <Controller
                  name={`processPackages.${index}.quantity`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomInput
                      name={`processPackages.${index}.quantity`}
                      type="number"
                      placeholder="Quantidade"
                      value={value ?? 0}
                      onChange={(e) => {
                        setValue(
                          `processPackages.${index}.quantity`,
                          +e.target.value,
                          {shouldDirty: true},
                        );
                        setHouseMeasurementsDebounced();
                      }}
                      inputProps={{
                        style: {
                          fontSize: pxToRem(14),
                        },
                        min: 0.0,
                        step: 0.001,
                        onClick: (e) => value === 0 && e.currentTarget.select(),
                      }}
                      size="small"
                      required
                    />
                  )}
                />
              </CustomInputWrapper>
              <CustomInputWrapper
                md={1}
                title="Comprimento (cm)"
                tooltip="Comprimento (cm)"
              >
                <Controller
                  name={`processPackages.${index}.length`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomInput
                      name={`processPackages.${index}.length`}
                      type="number"
                      placeholder="Altura"
                      value={value ?? 0}
                      onChange={(e) => {
                        setValue(
                          `processPackages.${index}.length`,
                          +e.target.value,
                          {shouldDirty: true},
                        );
                        calculateVolumeDebounced(index);
                      }}
                      inputProps={{
                        style: {
                          fontSize: pxToRem(14),
                        },
                        min: 0.0,
                        step: 0.001,
                        onClick: (e) => value === 0 && e.currentTarget.select(),
                      }}
                      size="small"
                    />
                  )}
                />
              </CustomInputWrapper>
              <CustomInputWrapper
                md={1}
                title="Altura (cm)"
                tooltip="Altura (cm)"
              >
                <Controller
                  name={`processPackages.${index}.height`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomInput
                      name={`processPackages.${index}.height`}
                      type="number"
                      placeholder="Altura"
                      value={value ?? 0}
                      onChange={(e) => {
                        setValue(
                          `processPackages.${index}.height`,
                          +e.target.value,
                          {shouldDirty: true},
                        );
                        calculateVolumeDebounced(index);
                      }}
                      inputProps={{
                        style: {
                          fontSize: pxToRem(14),
                        },
                        min: 0.0,
                        step: 0.001,
                        onClick: (e) => value === 0 && e.currentTarget.select(),
                      }}
                      size="small"
                    />
                  )}
                />
              </CustomInputWrapper>
              <CustomInputWrapper
                md={1}
                title="Largura (cm)"
                tooltip="Largura (cm)"
              >
                <Controller
                  name={`processPackages.${index}.width`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomInput
                      name={`processPackages.${index}.width`}
                      type="number"
                      placeholder="Largura"
                      value={value ?? 0}
                      onChange={(e) => {
                        setValue(
                          `processPackages.${index}.width`,
                          +e.target.value,
                          {shouldDirty: true},
                        );
                        calculateVolumeDebounced(index);
                      }}
                      inputProps={{
                        style: {
                          fontSize: pxToRem(14),
                        },
                        min: 0.0,
                        step: 0.001,
                        onClick: (e) => value === 0 && e.currentTarget.select(),
                      }}
                      size="small"
                    />
                  )}
                />
              </CustomInputWrapper>
              <CustomInputWrapper
                md={1}
                title="Medidas (m³)"
                tooltip="Medidas (m³)"
              >
                <Controller
                  name={`processPackages.${index}.cubicMeters`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomInput
                      name={`processPackages.${index}.cubicMeters`}
                      type="number"
                      placeholder="Medidas"
                      value={tempData?.[index]?.cubicMeters ?? value ?? 0}
                      onChange={(e) => {
                        setValue(
                          `processPackages.${index}.cubicMeters`,
                          +e.target.value,
                          {shouldDirty: true},
                        );
                        calculateWeight(index);
                      }}
                      inputProps={{
                        style: {
                          fontSize: pxToRem(14),
                        },
                        min: 0.0,
                        step: 0.001,
                        onClick: (e) => value === 0 && e.currentTarget.select(),
                      }}
                      size="small"
                    />
                  )}
                />
              </CustomInputWrapper>

              <CustomInputWrapper
                md={1}
                title="Peso líquido (kg)"
                tooltip="Peso líquido (kg)"
              >
                <Controller
                  name={`processPackages.${index}.netWeight`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomInput
                      name={`processPackages.${index}.netWeight`}
                      type="number"
                      placeholder="Peso"
                      value={value ?? 0}
                      onChange={(e) =>
                        setValue(
                          `processPackages.${index}.netWeight`,
                          +e.target.value,
                          {shouldDirty: true},
                        )
                      }
                      inputProps={{
                        style: {
                          fontSize: pxToRem(14),
                        },
                        min: 0.0,
                        step: 0.001,
                        onClick: (e) => value === 0 && e.currentTarget.select(),
                      }}
                      size="small"
                    />
                  )}
                />
              </CustomInputWrapper>
              <CustomInputWrapper
                md={1}
                title="Peso bruto (kg)"
                tooltip="Peso bruto (kg)"
              >
                <Controller
                  name={`processPackages.${index}.grossWeight`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomInput
                      name={`processPackages.${index}.grossWeight`}
                      type="number"
                      placeholder="Peso"
                      value={value ?? 0}
                      onChange={(e) => {
                        setValue(
                          `processPackages.${index}.grossWeight`,
                          +e.target.value,
                          {shouldDirty: true},
                        );
                        calculateWeight(index);
                      }}
                      inputProps={{
                        style: {
                          fontSize: pxToRem(14),
                        },
                        min: 0.0,
                        step: 0.001,
                        onClick: (e) => value === 0 && e.currentTarget.select(),
                      }}
                      size="small"
                    />
                  )}
                />
              </CustomInputWrapper>
              <CustomInputWrapper
                md={1.5}
                title="Peso cubado (kg)"
                tooltip="Peso cubado (kg)"
              >
                <Controller
                  name={`processPackages.${index}.cubedWeight`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomInput
                      name={`processPackages.${index}.cubedWeight`}
                      type="number"
                      placeholder="Peso"
                      value={tempData?.[index]?.cubeWeight ?? value ?? 0}
                      onChange={(e) => {
                        setValue(
                          `processPackages.${index}.cubedWeight`,
                          +e.target.value,
                          {shouldDirty: true},
                        );
                        const newValue = handleChangeTempLoadsValue(
                          {cubeWeight: undefined},
                          index,
                        );
                        calculateWeight(index);
                        setTempData(newValue);
                      }}
                      inputProps={{
                        style: {
                          fontSize: pxToRem(14),
                        },
                        min: 0.0,
                        step: 0.001,
                        onClick: (e) => value === 0 && e.currentTarget.select(),
                      }}
                      size="small"
                    />
                  )}
                />
              </CustomInputWrapper>
              <CustomInputWrapper
                md={1}
                title="Peso considerado (kg)"
                tooltip="Peso considerado (kg)"
              >
                <Controller
                  name={`processPackages.${index}.consideredWeight`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomInput
                      name={`processPackages.${index}.consideredWeight`}
                      type="number"
                      placeholder="Peso"
                      value={tempData?.[index]?.consideredWeight ?? value ?? 0}
                      onChange={(e) => {
                        setValue(
                          `processPackages.${index}.consideredWeight`,
                          +e.target.value,
                          {shouldDirty: true},
                        );
                        const newValue = handleChangeTempLoadsValue(
                          {consideredWeight: undefined},
                          index,
                        );
                        calculateWeight(index);
                        setTempData(newValue);
                      }}
                      inputProps={{
                        style: {
                          fontSize: pxToRem(14),
                        },
                        min: 0.0,
                        step: 0.001,
                        onClick: (e) => value === 0 && e.currentTarget.select(),
                      }}
                      size="small"
                    />
                  )}
                />
              </CustomInputWrapper>
              <Grid
                item
                xs={0.5}
                display="flex"
                alignItems="end"
                justifyContent="end"
              >
                <CustomIconButton
                  iconName="delete"
                  onClick={() => handleDeleteLogisticPackage(field, index)}
                />
              </Grid>
            </Grid>
          ))}
        </Stack>
      </DefaultCardStructure>
    </>
  );
};

export default ActivityPackages;
