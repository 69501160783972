import {AxiosError} from "axios";
import useAlertMessage from "hooks/useAlertMessage";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMaterialReactTable,
} from "material-react-table";
import {useState, useMemo} from "react";
import {useParams} from "react-router-dom";
import {GetContacts} from "services/api_v2/Contacts/Contacts.service";
import {getDefaultMRTOptions} from "util/getDefaultMRTOptions";
import {LinkExternalContactsTable} from "../Contacts.types";

const useLinkExternalContacts = () => {
  const {id: processId} = useParams<{id: string}>();

  const [data, setData] = useState<LinkExternalContactsTable[]>([]);

  const [showAlert] = useAlertMessage();

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [rowCount, setRowCount] = useState(0);

  const [contactsSearch, setContactSearch] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const columns = useMemo<MRT_ColumnDef<LinkExternalContactsTable>[]>(
    () => [
      {
        accessorKey: "person.name",
        header: "Contato",
        size: 80,
      },
      {
        accessorFn: (row) => row?.legalPerson?.fantasyName,
        id: "legalPerson",
        header: "Empresa",
      },
      {
        accessorKey: "person.email",
        header: "E-mail",
      },
      {
        accessorKey: "area",
        header: "Área",
        size: 80,
      },
    ],
    [],
  );
  const defaultMrtOptions = getDefaultMRTOptions<LinkExternalContactsTable>();

  const table = useMaterialReactTable({
    ...defaultMrtOptions,
    columns,
    data,
    enableMultiRowSelection: false,
    enableRowSelection: (row) => !row.getIsGrouped(),
    enableRowActions: false,
    enableSelectAll: false,
    enableGrouping: true,
    positionToolbarAlertBanner: "none",
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id?.toString(),
    initialState: {
      ...defaultMrtOptions.initialState,
      density: "compact",
      expanded: true,
      grouping: ["legalPerson"],
    },
    state: {
      ...defaultMrtOptions.state,
      isLoading: tableIsLoading,
      rowSelection: rowSelection,
      pagination,
    },
    manualPagination: true,
    enableGlobalFilter: true,
    rowCount: rowCount,
    onGlobalFilterChange: setContactSearch,
    onPaginationChange: setPagination,
    groupedColumnMode: "remove",
    displayColumnDefOptions: {
      "mrt-row-expand": {
        GroupedCell: ({row, table}) => {
          const {grouping} = table.getState();
          return row.getValue(grouping[grouping.length - 1]);
        },
        size: 130,
      },
    },
  });

  const mrtOptionsProps: typeof defaultMrtOptions = {};
  const getContacts = async (idMessageGroup?: number) => {
    try {
      setTableIsLoading(true);
      const response = await GetContacts({
        page: pagination.pageIndex + 1,
        limit: pagination.pageSize,
        name: contactsSearch,
        idProcess: +processId,
        idMessageGroup: idMessageGroup,
      });
      setRowCount(response.data.meta.total);

      const data = response.data?.data?.map((item) => ({
        id: item.id,
        person: {
          name: item?.personContact?.name,
          email: item?.personContact?.email,
          id: item?.personContact?.id,
        },
        area: item?.area,
        contactGroups: item?.contactGroups?.map((group) => group?.groupName),
        legalPerson: {
          fantasyName: item?.legalPerson?.fantasyName,
          idPerson: item?.legalPerson?.idPerson,
        },
      }));
      setData(data);
    } catch (err) {
      const error = err as AxiosError;
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    } finally {
      setTableIsLoading(false);
    }
  };

  return {
    table,
    data,
    columns,
    getContacts,
    rowCount,
    setPagination,
    pagination,
    contactsSearch,
    setContactSearch,
    mrtOptionsProps,
    rowSelection,
  };
};

export default useLinkExternalContacts;
