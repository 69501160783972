import React, {useState} from "react";
import pxToRem from "hooks/usePxToRem";

import {Divider, Grid} from "@mui/material";

import {
  CustomInputWrapper,
  CustomInput,
  CustomInputToSelectDate,
  CustomAutoComplete,
  CustomSelect,
} from "ui";
import {CustomDatePicker} from "components";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";
import {ModalityDelivery, ModalityPickup, TypePath} from "util/globalEnums";
import useHandleBoardingOptions from "../../useHandleBoardingOptions";
import {Controller, useFormContext} from "react-hook-form";
import useChangeDAteAndAutocomplete from "views/ProcessDetails/hooks/useChangeDAteAndAutocomplete";
import {
  GetIncotermsList,
  GetTransportCompaniesList,
} from "services/api_v2/Processes/Processes.service";
import useHandleFieldLists from "views/ProcessDetails/hooks/useHandleFieldLists";
import {getFormattedDateFromValue} from "util/getISODate";
import {getPorts} from "services/api_v2/Tasks/tasks.service";
import {getTooltipText} from "views/ProcessDetails/utils/getTooltipText";
import {ClearButton} from "components/CustomDatePicker/components";

const MainBoardingInfo = () => {
  const {control, getValues, watch} = useFormContext();

  const {
    handleChangeAutocomplete,
    handleChangeDateField,
    handleDeleteDateField,
  } = useChangeDAteAndAutocomplete();

  const [incotermOptions, setIncotermOptions] = useState(
    [] as AutoCompleteData[],
  );

  const [boardingOptions, setBoardingOptions] = useState(
    [] as AutoCompleteData[],
  );

  const [transportCompanyOptions, setTransportCompanyOptions] = useState(
    [] as AutoCompleteData[],
  );

  const [placeDeliveryDestinationOptions, setPlaceDeliveryDestinationOptions] =
    useState([] as AutoCompleteData[]);

  const {getOptions, onBottomScroll, isLoadingOptions, pageState} =
    useHandleBoardingOptions();

  const [searchFields, setSearchFields] = useState({
    placeDeliveryOrigin: "",
    placeDeliveryDestination: "",
    transportCompany: "",
  });

  const {
    getOptions: getIncotermOptions,
    onBottomScroll: onBottomScrollInconterm,
    isLoadingOptions: isLoadingIncotermOptions,
    pageState: incotermPageState,
  } = useHandleFieldLists(GetIncotermsList);

  const formatOptionsFn = (data) => {
    return data?.data?.map((item) => ({
      label: `${item?.name} - ${item?.id}`,
      id: item?.id,
    }));
  };
  const formatTransportCompanyFn = (data) => {
    return data?.data?.map((item) => ({
      label: `${item?.person?.name} - ${item?.idPerson}`,
      id: item?.person?.id,
    }));
  };

  const {
    getOptions: getTransportCompanyOptions,
    onBottomScroll: onBottomScrollTransportCompany,
    isLoadingOptions: isLoadingTransportCompanyOptions,
    pageState: transportCompanyPageState,
  } = useHandleFieldLists(GetTransportCompaniesList, formatTransportCompanyFn);

  const {
    getOptions: getPlaceDeliveryDestinationOptions,
    onBottomScroll: onBottomScrollPlaceDeliveryDestination,
    isLoadingOptions: isLoadingPlaceDeliveryDestinationOptions,
    pageState: placeDeliveryDestinationPageState,
  } = useHandleFieldLists(getPorts, formatOptionsFn);

  return (
    <Grid container spacing={pxToRem(24)}>
      <Grid container item columnSpacing={pxToRem(8)} rowSpacing={pxToRem(20)}>
        <CustomInputWrapper title="Porto de origem" xs={2.4}>
          <Controller
            name="origin"
            control={control}
            render={({field: {value}}) => (
              <CustomAutoComplete
                size="small"
                name="origin"
                placeholder="Porto de origem"
                isLoading={isLoadingOptions}
                tooltipText={getTooltipText(value?.name)}
                value={value?.name ?? ""}
                onChangeFn={(e, value) =>
                  handleChangeAutocomplete(e, value, "origin")
                }
                options={boardingOptions}
                onOpen={
                  boardingOptions.length > 0
                    ? undefined
                    : () => getOptions(setBoardingOptions, "")
                }
                onInputChange={(e, value) => {
                  if (e !== null) {
                    getOptions(setBoardingOptions, value);
                  }
                }}
                onBottomScroll={() => onBottomScroll(setBoardingOptions, "")}
                hasMorePagesToLoad={pageState.currentPage < pageState.lastPage}
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Destino" xs={2.4}>
          <Controller
            name="destination"
            control={control}
            render={({field: {value}}) => (
              <CustomAutoComplete
                size="small"
                name="destination"
                placeholder="Destino"
                isLoading={isLoadingOptions}
                tooltipText={getTooltipText(value?.name)}
                value={value?.name ?? ""}
                onChangeFn={(e, value) =>
                  handleChangeAutocomplete(e, value, "destination")
                }
                options={boardingOptions}
                onOpen={
                  boardingOptions.length > 0
                    ? undefined
                    : () => getOptions(setBoardingOptions, "")
                }
                onInputChange={(e, value) => {
                  if (e !== null) {
                    getOptions(setBoardingOptions, value);
                  }
                }}
                onBottomScroll={() => onBottomScroll(setBoardingOptions, "")}
                hasMorePagesToLoad={pageState.currentPage < pageState.lastPage}
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Destino Final" xs={2.4}>
          <Controller
            name="finalDestination"
            control={control}
            render={({field: {value}}) => (
              <CustomAutoComplete
                size="small"
                name="finalDestination"
                placeholder="Destino final"
                isLoading={isLoadingOptions}
                tooltipText={getTooltipText(value?.name)}
                value={value?.name ?? ""}
                onChangeFn={(e, value) =>
                  handleChangeAutocomplete(e, value, "finalDestination")
                }
                options={boardingOptions}
                onOpen={
                  boardingOptions.length > 0
                    ? undefined
                    : () => getOptions(setBoardingOptions, "")
                }
                onInputChange={(e, value) => {
                  if (e !== null) {
                    getOptions(setBoardingOptions, value);
                  }
                }}
                onBottomScroll={() => onBottomScroll(setBoardingOptions, "")}
                hasMorePagesToLoad={pageState.currentPage < pageState.lastPage}
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="TT" xs={1}>
          <Controller
            name="transitTime"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="transitTime"
                placeholder="TT"
                value={value}
                onChange={onChange}
                size="small"
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="N° Booking" xs={1.8}>
          <Controller
            name="bookingNumber"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="bookingNumber"
                placeholder="N° booking"
                value={value}
                onChange={onChange}
                size="small"
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Incoterm" xs={2}>
          <Controller
            name="incoterm"
            control={control}
            render={({field: {value}}) => (
              <CustomAutoComplete
                size="small"
                name="incoterm"
                isLoading={isLoadingIncotermOptions}
                placeholder="Incoterm"
                value={value?.name ?? ""}
                tooltipText={getTooltipText(value?.name)}
                onChangeFn={(e, value) =>
                  handleChangeAutocomplete(e, value, "incoterm")
                }
                options={incotermOptions}
                onOpen={
                  incotermOptions.length > 0
                    ? undefined
                    : () => getIncotermOptions(setIncotermOptions, 1)
                }
                onInputChange={(e) => {
                  if (e !== null) {
                    getIncotermOptions(setIncotermOptions, 1);
                  }
                }}
                onBottomScroll={() =>
                  onBottomScrollInconterm(setIncotermOptions)
                }
                hasMorePagesToLoad={
                  incotermPageState.currentPage < incotermPageState.lastPage
                }
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Companhia de Transporte" xs={2.4} isDisabled>
          <Controller
            name="transportCompany"
            control={control}
            render={({field: {value}}) => (
              <CustomAutoComplete
                size="small"
                name="transportCompany"
                placeholder="Companhia"
                isDisabled
                isLoading={isLoadingTransportCompanyOptions}
                value={value?.name ?? ""}
                tooltipText={getTooltipText(value?.name)}
                onChangeFn={(e, value) =>
                  handleChangeAutocomplete(e, value, "transportCompany")
                }
                options={transportCompanyOptions}
                onOpen={
                  transportCompanyOptions.length > 0
                    ? undefined
                    : () =>
                        getTransportCompanyOptions(
                          setTransportCompanyOptions,
                          1,
                        )
                }
                onInputChange={(e, value) => {
                  if (e !== null) {
                    setSearchFields((prev) => ({
                      ...prev,
                      transportCompany: value,
                    }));
                    getTransportCompanyOptions(
                      setTransportCompanyOptions,
                      1,
                      value,
                    );
                  }
                }}
                onBottomScroll={() =>
                  onBottomScrollTransportCompany(
                    setTransportCompanyOptions,
                    searchFields.transportCompany,
                  )
                }
                hasMorePagesToLoad={
                  transportCompanyPageState.currentPage <
                  transportCompanyPageState.lastPage
                }
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Tipo de Rota" xs={2.4}>
          <Controller
            name="typePath"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomSelect
                content={Object.values(TypePath)}
                value={value}
                name="typePath"
                onChange={onChange}
                required
                size="small"
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Rota" xs={2.4}>
          <Controller
            name="path"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                size="small"
                name="path"
                placeholder="Rota"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Previsão de prontidão" xs={2.4}>
          <Controller
            name="dateEstimatedReadinessCargo"
            control={control}
            render={({field: {value}}) => (
              <CustomDatePicker
                customInput={<CustomInputToSelectDate />}
                shouldCloseOnSelect
                name="dateEstimatedReadinessCargo"
                fullWidth
                value={getFormattedDateFromValue(value)}
                getDatePickerValuesOnClose={(dates) =>
                  handleChangeDateField(dates, "dateEstimatedReadinessCargo")
                }
                showDateHeader={false}
                placeholderText="DD-MM-YYYY"
                customFooter={
                  <ClearButton
                    handleClear={() =>
                      handleDeleteDateField("dateEstimatedReadinessCargo")
                    }
                  />
                }
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper
          title="Confirmação de prontidão"
          xs={2.4}
          isDisabled={getValues("dateEstimatedReadinessCargo") === ""}
        >
          <Controller
            name="dateReadinessCargo"
            control={control}
            render={({field: {value}}) => (
              <CustomDatePicker
                customInput={<CustomInputToSelectDate />}
                name="dateReadinessCargo"
                shouldCloseOnSelect
                fullWidth
                value={getFormattedDateFromValue(value)}
                getDatePickerValuesOnClose={(dates) =>
                  handleChangeDateField(dates, "dateReadinessCargo")
                }
                placeholderText="DD-MM-YYYY"
                showDateHeader={false}
                disabled={getValues("dateEstimatedReadinessCargo") === ""}
                //se não tem data estimada, a data de confirmação fica desabilitada.
                customFooter={
                  <ClearButton
                    handleClear={() =>
                      handleDeleteDateField("dateReadinessCargo")
                    }
                  />
                }
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="ETD" xs={2.4}>
          <Controller
            name="dateEstimatedCargoLoad"
            control={control}
            render={({field: {value}}) => (
              <CustomDatePicker
                customInput={<CustomInputToSelectDate />}
                name="dateEstimatedCargoLoad"
                shouldCloseOnSelect
                fullWidth
                value={getFormattedDateFromValue(value)}
                getDatePickerValuesOnClose={(dates) =>
                  handleChangeDateField(dates, "dateEstimatedCargoLoad")
                }
                placeholderText="DD-MM-YYYY"
                showDateHeader={false}
                customFooter={
                  <ClearButton
                    handleClear={() =>
                      handleDeleteDateField("dateEstimatedCargoLoad")
                    }
                  />
                }
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="ETA" xs={2.4}>
          <Controller
            name="dateEstimatedCargoUnload"
            control={control}
            render={({field: {value}}) => (
              <CustomDatePicker
                customInput={<CustomInputToSelectDate />}
                name="dateEstimatedCargoUnload"
                shouldCloseOnSelect
                fullWidth
                value={getFormattedDateFromValue(value)}
                getDatePickerValuesOnClose={(dates) =>
                  handleChangeDateField(dates, "dateEstimatedCargoUnload")
                }
                placeholderText="DD-MM-YYYY"
                showDateHeader={false}
                customFooter={
                  <ClearButton
                    handleClear={() =>
                      handleDeleteDateField("dateEstimatedCargoUnload")
                    }
                  />
                }
              />
            )}
          />
        </CustomInputWrapper>
        {/* campos novos abaixo */}
        <CustomInputWrapper title="Modalidade de coleta" xs={2.4}>
          <Controller
            name="modalityPickup"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomSelect
                content={Object.values(ModalityPickup)}
                value={value}
                name="modalityPickup"
                onChange={onChange}
                required
                size="small"
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Modalidade de entrega" xs={2.4}>
          <Controller
            name="modalityDelivery"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomSelect
                content={Object.values(ModalityDelivery)}
                value={value}
                name="modalityDelivery"
                onChange={onChange}
                required
                size="small"
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Data est. de Entrega" xs={2.4}>
          <Controller
            name="dateEstimatedDelivery"
            control={control}
            render={({field: {value}}) => (
              <CustomDatePicker
                customInput={<CustomInputToSelectDate />}
                name="dateEstimatedDelivery"
                shouldCloseOnSelect
                fullWidth
                value={getFormattedDateFromValue(value)}
                getDatePickerValuesOnClose={(dates) =>
                  handleChangeDateField(dates, "dateEstimatedDelivery")
                }
                placeholderText="DD-MM-YYYY"
                showDateHeader={false}
                customFooter={
                  <ClearButton
                    handleClear={() =>
                      handleDeleteDateField("dateEstimatedDelivery")
                    }
                  />
                }
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Data de entrega" xs={2.4}>
          <Controller
            name="dateDelivery"
            control={control}
            render={({field: {value}}) => (
              <CustomDatePicker
                customInput={<CustomInputToSelectDate />}
                name="dateDelivery"
                shouldCloseOnSelect
                fullWidth
                value={getFormattedDateFromValue(value)}
                getDatePickerValuesOnClose={(dates) =>
                  handleChangeDateField(dates, "dateDelivery")
                }
                placeholderText="DD-MM-YYYY"
                showDateHeader={false}
                customFooter={
                  <ClearButton
                    handleClear={() => handleDeleteDateField("dateDelivery")}
                  />
                }
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Data estimada de coleta destino" xs={2.4}>
          <Controller
            name="dateEstimatedPickup"
            control={control}
            render={({field: {value}}) => (
              <CustomDatePicker
                customInput={<CustomInputToSelectDate />}
                name="dateEstimatedPickup"
                shouldCloseOnSelect
                fullWidth
                value={getFormattedDateFromValue(value)}
                getDatePickerValuesOnClose={(dates) =>
                  handleChangeDateField(dates, "dateEstimatedPickup")
                }
                placeholderText="DD-MM-YYYY"
                showDateHeader={false}
                customFooter={
                  <ClearButton
                    handleClear={() =>
                      handleDeleteDateField("dateEstimatedPickup")
                    }
                  />
                }
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Confirmação de coleta destino" xs={2.4}>
          <Controller
            name="datePickupDestination"
            control={control}
            render={({field: {value}}) => (
              <CustomDatePicker
                customInput={<CustomInputToSelectDate />}
                name="datePickupDestination"
                shouldCloseOnSelect
                fullWidth
                value={getFormattedDateFromValue(value)}
                getDatePickerValuesOnClose={(dates) =>
                  handleChangeDateField(dates, "datePickupDestination")
                }
                placeholderText="DD-MM-YYYY"
                showDateHeader={false}
                customFooter={
                  <ClearButton
                    handleClear={() =>
                      handleDeleteDateField("datePickupDestination")
                    }
                  />
                }
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Data est. de entrega" xs={2.4}>
          <Controller
            name="dateEstimatedDelivery"
            control={control}
            render={({field: {value}}) => (
              <CustomDatePicker
                customInput={<CustomInputToSelectDate />}
                name="dateEstimatedDelivery"
                shouldCloseOnSelect
                fullWidth
                value={getFormattedDateFromValue(value)}
                getDatePickerValuesOnClose={(dates) =>
                  handleChangeDateField(dates, "dateEstimatedDelivery")
                }
                placeholderText="DD-MM-YYYY"
                showDateHeader={false}
                customFooter={
                  <ClearButton
                    handleClear={() =>
                      handleDeleteDateField("dateEstimatedDelivery")
                    }
                  />
                }
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Data de entrega na origem" xs={2.4}>
          <Controller
            name="dateDeliveryOrigin"
            control={control}
            render={({field: {value}}) => (
              <CustomDatePicker
                customInput={<CustomInputToSelectDate />}
                name="dateDeliveryOrigin"
                shouldCloseOnSelect
                fullWidth
                value={getFormattedDateFromValue(value)}
                getDatePickerValuesOnClose={(dates) =>
                  handleChangeDateField(dates, "dateDeliveryOrigin")
                }
                placeholderText="DD-MM-YYYY"
                showDateHeader={false}
                customFooter={
                  <ClearButton
                    handleClear={() =>
                      handleDeleteDateField("dateDeliveryOrigin")
                    }
                  />
                }
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Local de recebimento Origem" xs={2.4}>
          <Controller
            name="placeDeliveryOrigin"
            control={control}
            render={({field: {value, onChange}}) => (
              <CustomInput
                size="small"
                name="placeDeliveryOrigin"
                placeholder="Local de recebimento Origem"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Local de recebimento" xs={2.4}>
          <Controller
            name="receivingLocation"
            control={control}
            render={({field: {value}}) => (
              <CustomAutoComplete
                size="small"
                name="receivingLocation"
                placeholder="Local de recebimento"
                isLoading={isLoadingPlaceDeliveryDestinationOptions}
                value={value?.name ?? ""}
                tooltipText={getTooltipText(value?.name)}
                options={placeDeliveryDestinationOptions}
                onChangeFn={(e, value) =>
                  handleChangeAutocomplete(e, value, "receivingLocation")
                }
                onOpen={
                  placeDeliveryDestinationOptions.length > 0
                    ? undefined
                    : () =>
                        getPlaceDeliveryDestinationOptions(
                          setPlaceDeliveryDestinationOptions,
                        )
                }
                onBottomScroll={() =>
                  onBottomScrollPlaceDeliveryDestination(
                    setPlaceDeliveryDestinationOptions,
                    searchFields.placeDeliveryDestination,
                  )
                }
                hasMorePagesToLoad={
                  placeDeliveryDestinationPageState.currentPage <
                  placeDeliveryDestinationPageState.lastPage
                }
                onInputChange={(e, value) => {
                  //condição pra não fazer a requisição na primeira atualização do input

                  if (e !== null) {
                    setSearchFields((prev) => ({
                      ...prev,
                      placeDeliveryDestination: value,
                    }));
                    getPlaceDeliveryDestinationOptions(
                      setPlaceDeliveryDestinationOptions,
                      1,
                      value,
                    );
                  }
                }}
              />
            )}
          />
        </CustomInputWrapper>

        {/* embarque e desembarque */}
        <CustomInputWrapper title="Confirmação de embarque" xs={2.4}>
          <Controller
            name="dateCargoLoad"
            control={control}
            render={({field: {value}}) => (
              <CustomDatePicker
                customInput={<CustomInputToSelectDate />}
                name="dateCargoLoad"
                shouldCloseOnSelect
                fullWidth
                value={getFormattedDateFromValue(value)}
                getDatePickerValuesOnClose={(dates) =>
                  handleChangeDateField(dates, "dateCargoLoad")
                }
                placeholderText="DD-MM-YYYY"
                showDateHeader={false}
                customFooter={
                  <ClearButton
                    handleClear={() => handleDeleteDateField("dateCargoLoad")}
                  />
                }
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Confirmação de chegada" xs={2.4}>
          <Controller
            name="dateCargoUnload"
            control={control}
            render={({field: {value}}) => (
              <CustomDatePicker
                customInput={<CustomInputToSelectDate />}
                name="dateCargoUnload"
                shouldCloseOnSelect
                fullWidth
                value={getFormattedDateFromValue(value)}
                getDatePickerValuesOnClose={(dates) =>
                  handleChangeDateField(dates, "dateCargoUnload")
                }
                placeholderText="DD-MM-YYYY"
                showDateHeader={false}
                customFooter={
                  <ClearButton
                    handleClear={() => handleDeleteDateField("dateCargoUnload")}
                  />
                }
              />
            )}
          />
        </CustomInputWrapper>

        {watch("incoterm.name")?.includes("EXW") && (
          <React.Fragment>
            <CustomInputWrapper title="Previsão de coleta" xs={2.4}>
              <Controller
                name="dateEstimatedPickup"
                control={control}
                render={({field: {value}}) => (
                  <CustomDatePicker
                    customInput={<CustomInputToSelectDate />}
                    name="dateEstimatedPickup"
                    shouldCloseOnSelect
                    fullWidth
                    value={getFormattedDateFromValue(value)}
                    getDatePickerValuesOnClose={(dates) =>
                      handleChangeDateField(dates, "dateEstimatedPickup")
                    }
                    placeholderText="DD-MM-YYYY"
                    showDateHeader={false}
                    customFooter={
                      <ClearButton
                        handleClear={() =>
                          handleDeleteDateField("dateEstimatedPickup")
                        }
                      />
                    }
                  />
                )}
              />
            </CustomInputWrapper>

            <CustomInputWrapper title="Confirmação de coleta" xs={2.4}>
              <Controller
                name="datePickup"
                control={control}
                render={({field: {value}}) => (
                  <CustomDatePicker
                    customInput={<CustomInputToSelectDate />}
                    name="datePickup"
                    shouldCloseOnSelect
                    fullWidth
                    value={getFormattedDateFromValue(value)}
                    getDatePickerValuesOnClose={(dates) =>
                      handleChangeDateField(dates, "datePickup")
                    }
                    placeholderText="DD-MM-YYYY"
                    showDateHeader={false}
                    customFooter={
                      <ClearButton
                        handleClear={() => handleDeleteDateField("datePickup")}
                      />
                    }
                  />
                )}
              />
            </CustomInputWrapper>
          </React.Fragment>
        )}
      </Grid>

      <Grid item xs>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default MainBoardingInfo;
