import {useState} from "react";
import {GetGenericServiceFromUrl} from "services/api_v2/Generic/Generic.service";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";

type Props = {
  path?: string;
};

const useHandleAutocomplete = (props: Props) => {
  const {path = ""} = props;

  const PAGE_LIMIT = 50;
  const FIRST_PAGE = 1;

  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [pageState, setPageState] = useState({currentPage: 1, lastPage: 1});
  const [hasMorePagesToLoad, setHasMorePagesToLoad] = useState(false);
  const [searchParam, setSearchParam] = useState<string>();
  const [options, setOptions] = useState<AutoCompleteData[]>([]);

  const locationsOrLegalPersons = () => {
    return (
      path === "/ports" ||
      path?.includes("/legal-person-by-types") ||
      path?.includes("/persons-by-type")
    );
  };

  const GetAnything = async (
    page = FIRST_PAGE,
    limit = PAGE_LIMIT,
    name?: string,
  ) => {
    try {
      setIsLoadingOptions(true);
      const params = {
        page,
        limit,
        name,
      };
      if (path === "") return;
      const response = await GetGenericServiceFromUrl(path, params);

      let formattedOptions: AutoCompleteData[] = [];
      formattedOptions = response?.data?.data?.map((option) => {
        const optionReturn = {};
        if (locationsOrLegalPersons()) {
          optionReturn["additional"] = option?.typeLocation ?? {
            cnpj: option?.legalPersons?.cnpj,
            vatNumber: option?.legalPersons?.vatNumber,
          };
        }
        optionReturn["id"] = option?.id;
        optionReturn["label"] = option?.name;

        return optionReturn;
      });

      if (name && page === FIRST_PAGE) {
        setOptions(() => [...formattedOptions]);
      } else {
        setOptions((prev) => [...prev, ...formattedOptions]);
      }

      setPageState({
        currentPage: response?.data?.meta?.currentPage,
        lastPage: response?.data?.meta?.lastPage,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingOptions(false);
    }
  };

  const onOpen = async () => {
    setOptions([]);
    await GetAnything(FIRST_PAGE, PAGE_LIMIT, "");
  };

  const onBottomScroll = () => {
    if (pageState.currentPage < pageState.lastPage) {
      setHasMorePagesToLoad(true);
      (async () => {
        await GetAnything(pageState.currentPage + 1, PAGE_LIMIT, searchParam);
      })();
    } else {
      setHasMorePagesToLoad(false);
    }

    return true;
  };

  const onInputChange = async (
    e: React.SyntheticEvent<Element, Event>,
    value: string,
  ) => {
    setSearchParam(value);
    if (!e) {
      return;
    }
    if (e?.type === "click") {
      return;
    } else {
      await GetAnything(FIRST_PAGE, PAGE_LIMIT, value);
    }
  };

  const mountOptions = (
    actualValue:
      | {
          id: number | null;
          label: string;
          additional?: string;
          cnpj?: string;
          vatNumber?: string;
        }
      | null
      | undefined,
    options: AutoCompleteData[],
  ) => {
    const filteredOptions = options.filter(
      (item) => item?.id !== actualValue?.id,
    );
    if (actualValue?.id === null) return [...filteredOptions];
    return [
      {
        id: actualValue?.id,
        label: actualValue?.label ?? "",
        additional: actualValue?.additional ?? {
          cnpj: actualValue?.cnpj,
          vatNumber: actualValue?.vatNumber,
        },
      },
      ...filteredOptions,
    ];
  };

  return {
    isLoadingOptions,
    setIsLoadingOptions,
    options,
    setOptions,
    pageState,
    setPageState,
    hasMorePagesToLoad,
    setHasMorePagesToLoad,
    searchParam,
    setSearchParam,
    onBottomScroll,
    onInputChange,
    onOpen,
    mountOptions,
  };
};

export default useHandleAutocomplete;
