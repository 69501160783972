import {ListItem, ListItemText, Typography, Skeleton} from "@mui/material";

type Props = {
  numberOfItems?: number;
};

const VerticalListSkeleton = (props: Props) => {
  const {numberOfItems = 6} = props;

  const listSize = new Array(numberOfItems).fill(0);
  return (
    <>
      {listSize.map((_, index) => (
        <ListItem key={index}>
          <ListItemText
            primary={
              <Typography variant="subtitle2">
                <Skeleton />
              </Typography>
            }
            secondary={
              <Typography variant="subtitle3">
                <Skeleton />
              </Typography>
            }
          />
        </ListItem>
      ))}
    </>
  );
};
export default VerticalListSkeleton;
