import pxToRem from "hooks/usePxToRem";

import {styled, Switch} from "@mui/material";

// prettier-ignore
const StyledSwitch = styled(Switch)(({theme}) => ({
  width: pxToRem(62),
  height: pxToRem(34),
  padding: pxToRem(7),

  "& .MuiSwitch-switchBase": {
    margin: pxToRem(3),
    padding: 0,
    translate: `${pxToRem(5)} 0`,

    "&.Mui-checked": {
      color: theme.palette.primary.contrastText,
      translate: `${pxToRem(5)} 0`,

      "& .MuiSwitch-thumb::before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          theme.palette.primary.contrastText,
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },

      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.primary[50],
      },
    },
  },

  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" 
    ? theme.palette.primary[500] 
    : theme.palette.primary[700],
    
    width: pxToRem(28),
    height: pxToRem(28),

    '&::before': {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,

      width: "100%",
      height: "100%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        theme.palette.primary.contrastText,
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },

  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.primary[200],
    borderRadius: "10px",
  },
}));

export default StyledSwitch;
