import {DialogActions, DialogContent, Divider, Stack} from "@mui/material";
import {CustomModal} from "components";
import useAlertMessage from "hooks/useAlertMessage";
import pxToRem from "hooks/usePxToRem";
import useToast from "hooks/useToast";
import {Dispatch, SetStateAction, useState} from "react";
import {useSelector} from "react-redux";
import {PostHCActivity} from "services/api_v2/Processes/Processes.service";
import {GetEmployees} from "services/api_v2/User/user.service";
import {
  CustomButton,
  CustomInput,
  CustomInputWrapper,
  CustomSearchSelect,
} from "ui";

interface ModalHCNewActivityProps {
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
  processNumber: string;
  processIdGroupActivity: number;
  button?: boolean;
  buttonConfigs?: {width?: number; variant?: "text" | "contained" | "outlined"};
}

const ModalHCNewActivity = (props: ModalHCNewActivityProps) => {
  const [showAlert] = useAlertMessage();
  const [showToast] = useToast();

  const {
    buttonConfigs,
    button,
    processNumber,
    isOpened,
    setIsOpened,
    processIdGroupActivity,
  } = props;
  const {userInfo} = useSelector((state: any) => state.stateUserInfo);

  const currentUser = {name: userInfo?.person?.name, id: userInfo?.person?.id};

  const initialActivityData: {
    responsible: {label: string; id: number};
    description: string;
  } = {
    responsible: {label: currentUser?.name, id: currentUser?.id},
    description: "",
  };

  const [activityData, setActivityData] = useState(initialActivityData);

  const [responsiblesList, setResponsiblesList] = useState<
    {label: string; id: number}[]
  >([]);
  const [responsiblesPages, setResponsiblesPages] = useState({
    currentPage: 0,
    lastPage: 1,
  });

  const getResponsibles = async (
    page = responsiblesPages?.currentPage + 1,
    name?: string,
  ) => {
    try {
      const response = await GetEmployees(page, name);
      setResponsiblesPages({
        currentPage: response?.data?.meta?.currentPage,
        lastPage: response?.data?.meta?.lastPage,
      });

      const formattedDataToRenderInAutoComplete = response.data.data.map(
        (responsible) => ({
          id: responsible?.id,
          label: responsible?.name,
        }),
      );

      setResponsiblesList(formattedDataToRenderInAutoComplete);
    } catch (error: any) {
      console.error(error);
      showAlert(error?.response?.data?.message);
    }
  };

  const postHCNewActivity = async () => {
    try {
      const reqBody = {
        idResponsible: activityData.responsible?.id,
        idGroupActivity: processIdGroupActivity,
        description: activityData.description,
      };
      await PostHCActivity(reqBody);

      showToast("Atividade HC criada com sucesso");
    } catch (error: any) {
      console.error(error);
      showAlert(error?.response?.data?.message);
    }
  };

  const onBottomScroll = () => {
    getResponsibles();
    return false;
  };

  const handleOpenOrCloseModal = () => {
    setIsOpened((state) => !state);
  };

  const handleInputsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActivityData((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
  };

  const handleResponsibleChange = (
    e: React.SyntheticEvent<Element, Event>,
    newValue: {label: string; id: number},
  ) => {
    setActivityData((state) => {
      if (newValue !== null) {
        return {
          ...state,
          responsible: newValue,
        };
      } else {
        return {...state, responsible: newValue};
      }
    });
  };

  const handleFormSubmit = () => {
    postHCNewActivity();
    handleOpenOrCloseModal();
  };

  const disableSendButton = () => {
    if (
      !activityData?.responsible ||
      activityData?.responsible?.label?.length === 0 ||
      activityData?.description?.length === 0
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      {button && (
        <CustomButton
          onClickFn={handleOpenOrCloseModal}
          variant={buttonConfigs?.variant}
          sx={{
            width: buttonConfigs?.width
              ? pxToRem(buttonConfigs?.width)
              : pxToRem(170),
          }}
        >
          Criar atividade HC
        </CustomButton>
      )}
      <CustomModal
        dividerBadge={processNumber}
        maxWidth="xs"
        isOpen={isOpened}
        onClose={handleOpenOrCloseModal}
        title={"Criar nova atividade no HC"}
        subtitle={
          "Esta funcionalidade irá criar uma nova atividade neste processo dentro do HC"
        }
        titleIcon={"cable"}
        dataTestId={"modal-hc-new-activity"}
      >
        <>
          <DialogContent>
            <Stack id="hcActivity" sx={{gap: pxToRem(12)}}>
              <CustomInputWrapper title={"Responsável"}>
                <CustomSearchSelect
                  name="responsible"
                  value={activityData.responsible}
                  options={responsiblesList}
                  fnOnOpen={
                    responsiblesList?.length <= 1
                      ? () => getResponsibles()
                      : undefined
                  }
                  fnOnChange={handleResponsibleChange}
                  fnOnInputChange={(e: unknown, value: string) => {
                    if (e !== null) {
                      getResponsibles(1, value);
                    }
                  }}
                  onBottomScroll={onBottomScroll}
                  hasMorePagesToLoad={
                    responsiblesPages?.currentPage < responsiblesPages?.lastPage
                  }
                  size="small"
                  placeholder="Pesquise e/ou selecione"
                  required
                  fontSize={pxToRem(12)}
                />
              </CustomInputWrapper>
              <CustomInputWrapper title={"Descrição da atividade"}>
                <CustomInput
                  name="description"
                  value={activityData.description}
                  onChange={handleInputsChange}
                  size="small"
                  isMultiline
                  placeholder="Descreva a atividade que precisa ser realizada dentro do HC"
                  required
                  height={pxToRem(40)}
                  sx={{fontSize: pxToRem(12)}}
                />
              </CustomInputWrapper>
            </Stack>
          </DialogContent>
          <Divider orientation="horizontal" />
          <DialogActions
            sx={{
              padding: `${pxToRem(20)} ${pxToRem(28)} ${pxToRem(20)} ${pxToRem(
                20,
              )}`,
            }}
          >
            <CustomButton onClickFn={handleOpenOrCloseModal} variant="outlined">
              Cancelar
            </CustomButton>
            <CustomButton
              onClickFn={handleFormSubmit}
              disabled={disableSendButton()}
            >
              Criar atividade HC
            </CustomButton>
          </DialogActions>
        </>
      </CustomModal>
    </>
  );
};

export default ModalHCNewActivity;
