import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Box, DialogContent, Grid, Tab, TextField} from "@mui/material";
import {CustomModal} from "components";
import pxToRem from "hooks/usePxToRem";
import {useState} from "react";
import {useFormContext} from "react-hook-form";
import {CustomButton} from "ui";
import {ModalWrapperFooter} from "views/Tasks/components/GenericTasksModal/components";
import {ProcessInstructions} from "./CustomerInstructions.types";

type Props = {
  onCloseModal: () => void;
  isOpen: boolean;
  handleSubmitInstructions: (
    instructions: Partial<ProcessInstructions>,
  ) => void;
};

const CustomerInstructionsModal = (props: Props) => {
  const {isOpen, onCloseModal, handleSubmitInstructions} = props;
  const {getValues, watch} = useFormContext();

  const cancelledProcess = watch("agencyingSituation") === "Cancelado";

  const initialValues: ProcessInstructions = {
    customerInstructions: getValues("customerInstructions"),
    agentInstructions: getValues("agentInstructions"),
    operatingInstructions: getValues("operatingInstructions"),
    operatingClientInstructions: getValues("operatingClientInstructions"),
    financialInstructions: getValues("financialInstructions"),
    observation: getValues("observation"),
  };

  const [instructions, setInstructions] =
    useState<ProcessInstructions>(initialValues);

  const submitCustomerInstructions = (values: ProcessInstructions) => {
    let changedInstructions = {};
    for (const key in values) {
      if (values[key] !== initialValues[key]) {
        changedInstructions = {...changedInstructions, [key]: values[key]};
      }
    }

    handleSubmitInstructions(changedInstructions);
    onCloseModal();
  };

  const [value, setValue] = useState("1");

  const handleChangeTabs = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleChangeFields = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setInstructions((prev) => ({...prev, [e.target.name]: e.target.value}));
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onCloseModal}
      title="Instruções gerais"
      titleIcon="description"
      maxWidth={"md"}
      paperOverflow="hidden"
    >
      <Box>
        <DialogContent>
          <TabContext value={value}>
            <Box sx={{borderBottom: 1, borderColor: "divider"}}>
              <TabList onChange={handleChangeTabs}>
                <Tab label="Instruções Cliente" value="1" />
                <Tab label="Instruções Agente" value="2" />
                <Tab label="Instruções Operacional" value="3" />
                <Tab label="Instruções Operacional cliente" value="4" />
                <Tab label="Instruções Financeiras" value="5" />
                <Tab label="Observações" value="6" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{padding: `${pxToRem(24)} 0 0 0`}}>
              <TextField
                sx={{width: "100%", height: "auto"}}
                rows={13}
                multiline
                autoComplete="on"
                variant="outlined"
                placeholder="Adicione uma ou mais instruções específicas deste processo."
                value={instructions.customerInstructions}
                name="customerInstructions"
                onChange={handleChangeFields}
              />
            </TabPanel>
            <TabPanel value="2" sx={{padding: `${pxToRem(24)} 0 0 0`}}>
              <TextField
                sx={{width: "100%", height: "auto"}}
                rows={13}
                multiline
                autoComplete="on"
                variant="outlined"
                placeholder="Adicione uma ou mais instruções específicas deste processo."
                value={instructions.agentInstructions}
                name="agentInstructions"
                onChange={handleChangeFields}
              />
            </TabPanel>
            <TabPanel value="3" sx={{padding: `${pxToRem(24)} 0 0 0`}}>
              <TextField
                sx={{width: "100%", height: "auto"}}
                rows={13}
                multiline
                autoComplete="on"
                variant="outlined"
                placeholder="Adicione uma ou mais instruções específicas deste processo."
                value={instructions.operatingInstructions}
                name="operatingInstructions"
                onChange={handleChangeFields}
              />
            </TabPanel>
            <TabPanel value="4" sx={{padding: `${pxToRem(24)} 0 0 0`}}>
              <TextField
                sx={{width: "100%", height: "auto"}}
                rows={13}
                multiline
                autoComplete="on"
                variant="outlined"
                placeholder="Adicione uma ou mais instruções específicas deste processo."
                value={instructions.operatingClientInstructions}
                name="operatingClientInstructions"
                onChange={handleChangeFields}
              />
            </TabPanel>
            <TabPanel value="5" sx={{padding: `${pxToRem(24)} 0 0 0`}}>
              <TextField
                sx={{width: "100%", height: "auto"}}
                rows={13}
                multiline
                autoComplete="on"
                variant="outlined"
                placeholder="Adicione uma ou mais instruções específicas deste processo."
                value={instructions.financialInstructions}
                name="financialInstructions"
                onChange={handleChangeFields}
              />
            </TabPanel>
            <TabPanel value="6" sx={{padding: `${pxToRem(24)} 0 0 0`}}>
              <TextField
                sx={{width: "100%", height: "auto"}}
                rows={13}
                multiline
                autoComplete="on"
                variant="outlined"
                placeholder="Adicione uma ou mais instruções específicas deste processo."
                value={instructions.observation}
                name="observation"
                onChange={handleChangeFields}
              />
            </TabPanel>
          </TabContext>
        </DialogContent>

        <ModalWrapperFooter>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="flex-end"
            columnGap={pxToRem(8)}
          >
            <CustomButton
              variant="outlined"
              onClickFn={onCloseModal}
              disabled={cancelledProcess}
            >
              Cancelar
            </CustomButton>
            <CustomButton
              variant="contained"
              onClickFn={() => submitCustomerInstructions(instructions)}
              disabled={cancelledProcess}
            >
              Adicionar instruções
            </CustomButton>
          </Grid>
        </ModalWrapperFooter>
      </Box>
    </CustomModal>
  );
};

export default CustomerInstructionsModal;
