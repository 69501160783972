/* eslint-disable react-hooks/exhaustive-deps */
import {memo, useEffect, useMemo, useState} from "react";
import useLoading from "hooks/useLoading";
import {
  DocumentsContent,
  FileDescription,
  OnlineReleaseFiles,
  ReleaseDocuments,
  ReleaseDocumentsTableData,
} from "./ProcessOnlineRelease.types";

import {Icon, IconButton, Stack, useTheme} from "@mui/material";
import {format} from "date-fns";
import {MaterialReactTable, MRT_ColumnDef} from "material-react-table";

import {DefaultCardStructure} from "components";
import BOUploaderIcon from "components/BOUploaderItem";
import pxToRem from "hooks/usePxToRem";
import {MRT_Localization_PT_BR} from "material-react-table/locales/pt-BR";
import {CustomIconButton} from "ui";
import {PostProcessFile} from "services/api_v2/Processes/Processes.service";
import useToast from "hooks/useToast";
import {useParams} from "react-router-dom";
import {DeleteDocumentModal, TableStatusCellChip} from "./components";
import {FieldValues, UseFormSetValue, useFormContext} from "react-hook-form";
import useAlertMessage from "hooks/useAlertMessage";
import {idOfCategories} from "views/ProcessDetails/utils/idOfCategories";
import tableActionColumnStyle from "util/tableActionColumnStyle";
interface Props {
  refetchFiles: (setRhfValues: UseFormSetValue<FieldValues>) => Promise<void>;
}

const ProcessOnlineRelease = (props: Props) => {
  const theme = useTheme();
  const [showAlert] = useAlertMessage();
  const {watch, setValue} = useFormContext();
  const {refetchFiles} = props;
  const onlineReleaseDocuments: DocumentsContent[] = watch("files").filter(
    (file) => idOfCategories.onlineRelease.includes(file?.idGroupFile),
  );
  const cancelledProcess = watch("agencyingSituation") === "Cancelado";

  const data = new FormData();

  const {id} = useParams<{id: string}>();

  const [documentsSent, setDocumentsSent] = useState<ReleaseDocuments>();
  const [openRemoveSingleDocumentModal, setOpenRemoveSingleDocumentModal] =
    useState<boolean>(false);
  const [fileInfo, setFileInfo] = useState<{id: number; name: string}>({
    id: NaN,
    name: "",
  });

  const [setLoading] = useLoading();
  const [showToast] = useToast();

  const addOnlineReleaseDocuments = async (acceptedFile: File, description) => {
    try {
      setLoading(true);
      handleDocumentData(acceptedFile, description);

      const response = await PostProcessFile(data);

      showToast(response?.data?.message);
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    } finally {
      await refetchFiles(setValue);
      setLoading(false);
    }
  };

  const handleDocumentData = (
    acceptedFile: File,
    description: FileDescription,
  ) => {
    data.append(
      "process",
      JSON.stringify({
        description: description,
        idGroupFile: OnlineReleaseFiles[description],
        idProcess: id,
      }),
    );

    data.append("file", acceptedFile);
  };

  const handleCreateFileLinkForPreview = (uploadedFile) => {
    const link = uploadedFile.size
      ? window.URL.createObjectURL(uploadedFile)
      : uploadedFile.url;
    return link;
  };

  const handleOpenDeleteDocumentModal = (file: ReleaseDocumentsTableData) => {
    setFileInfo({id: file?.id ?? NaN, name: file?.documentName ?? ""});
    setOpenRemoveSingleDocumentModal(true);
  };

  const handleDocumentsList = () => {
    const tempDocuments: ReleaseDocuments = {};
    onlineReleaseDocuments.forEach((doc) => {
      switch (doc?.idGroupFile) {
        case OnlineReleaseFiles["LO HBL"]:
          tempDocuments.hbl = doc;
          break;
        case OnlineReleaseFiles["LO DEMURRAGE"]:
          tempDocuments.demurrage = doc;
          break;
        case OnlineReleaseFiles["LO TERMO ANUAL"]:
          tempDocuments.annualTerm = doc;
          break;
        case OnlineReleaseFiles["LO AUTORIZAÇÃO"]:
          tempDocuments.authorization = doc;
          break;
        case OnlineReleaseFiles["LO PROCURAÇÃO"]:
          tempDocuments.procuration = doc;
          break;
      }
    });

    setDocumentsSent(tempDocuments);
  };

  useEffect(() => {
    handleDocumentsList();
  }, [watch("files").length]);

  const columnsData = useMemo<MRT_ColumnDef<ReleaseDocumentsTableData>[]>(
    () => [
      {
        accessorKey: "documentName",
        header: "Nome do documento",
        minSize: 300,
      },

      {
        accessorKey: "updatedAt",
        header: "Data último envio",
        Cell: ({row: {original}}) =>
          original?.updatedAt
            ? format(new Date(original?.updatedAt), "dd/MM/yyyy - HH:mm")
            : "-",
      },

      {
        accessorKey: "status",
        header: "Status",
        Cell: TableStatusCellChip,
      },
    ],
    [],
  );

  const releaseDocumentsData: ReleaseDocumentsTableData[] = [
    {
      documentName: "HBL",
      description: "LO HBL",
      ...documentsSent?.hbl,
    },
    {
      documentName: "Demurrage",
      description: "LO DEMURRAGE",
      ...documentsSent?.demurrage,
    },
    {
      documentName: "Termo Anual",
      description: "LO TERMO ANUAL",
      ...documentsSent?.annualTerm,
    },
    {
      documentName: "Autorização",
      description: "LO AUTORIZAÇÃO",
      ...documentsSent?.authorization,
    },
    {
      documentName: "Procuração",
      description: "LO PROCURAÇÃO",
      ...documentsSent?.procuration,
    },
  ];

  return (
    <DefaultCardStructure
      title="Liberação Online"
      hasDivider={false}
      linkId="onlineRelease"
      badgeText="Pendente"
    >
      <DeleteDocumentModal
        openRemoveSingleDocumentModal={openRemoveSingleDocumentModal}
        setOpenRemoveSingleDocumentModal={setOpenRemoveSingleDocumentModal}
        fileInfo={fileInfo}
        getProcessDetails={async () => await refetchFiles(setValue)}
      />
      <MaterialReactTable
        columns={columnsData}
        data={releaseDocumentsData}
        initialState={{
          density: "compact",
          columnPinning: {left: ["mrt-row-actions"]},
        }}
        localization={MRT_Localization_PT_BR}
        enableTopToolbar={false}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableColumnResizing
        enableRowActions
        positionActionsColumn="first"
        layoutMode="grid"
        enableStickyHeader
        enablePagination={false}
        enableHiding={false}
        displayColumnDefOptions={tableActionColumnStyle(theme)}
        renderRowActions={({row}) => {
          return (
            <Stack direction="row" justifyContent="center" alignItems="center">
              <BOUploaderIcon
                onDrop={(acceptedFiles: File[]) =>
                  addOnlineReleaseDocuments(
                    acceptedFiles[0],
                    row.original?.description,
                  )
                }
              >
                <CustomIconButton
                  iconName="upload_file_rounded"
                  tooltipText="Enviar documento"
                  disabled={!!row?.original?.updatedAt || cancelledProcess}
                />
              </BOUploaderIcon>

              <IconButton
                component="a"
                href={handleCreateFileLinkForPreview(row?.original)}
                target="_blank"
                disabled={!row?.original?.updatedAt}
              >
                <Icon>download_rounded</Icon>
              </IconButton>

              <CustomIconButton
                iconName="delete_rounded"
                tooltipText="Apagar documento"
                disabled={!row?.original?.updatedAt || cancelledProcess}
                onClick={() => handleOpenDeleteDocumentModal(row?.original)}
              />
            </Stack>
          );
        }}
        muiTableContainerProps={{
          sx: (theme) => ({
            flex: 1,
            maxHeight: pxToRem(252),
            backgroundColor: theme.palette.background.paper,
          }),
        }}
        muiTablePaperProps={{
          elevation: 0,
        }}
        muiTableHeadCellProps={{
          sx: (theme) => ({
            backgroundColor: theme.palette.background.paper,
            fontSize: pxToRem(14),
            fontWeight: 600,

            ".Mui-TableHeadCell-Content-Wrapper": {
              whiteSpace: "nowrap",
            },

            "& .Mui-TableHeadCell-content": {
              alignItems: "center",
            },
          }),
        }}
        muiTableBodyCellProps={() => ({
          sx: (theme) => ({
            fontSize: pxToRem(14),
            color: theme.palette.text.secondary,
          }),
        })}
        muiTableBodyRowProps={{
          hover: false,
          sx: (theme) => ({
            backgroundColor: theme.palette.background.paper,
          }),
        }}
        muiBottomToolbarProps={{
          sx: (theme) => ({
            backgroundColor: theme.palette.background.paper,
          }),
        }}
        muiColumnActionsButtonProps={{size: "small"}}
      />
    </DefaultCardStructure>
  );
};

export default memo(ProcessOnlineRelease);
