import {AxiosResponse} from "axios";
import {
  Status,
  Task,
} from "components/ModalCompleteActivity/CompleteActivity.types";
import {newBackendAPI} from "services/apiConsumer";

type FinishTask = {
  id?: number; //idActivity
  idNewActivity?: number;
  updatedField?: {id: number; value: string}[];
  activityStatus?: Status;
};

export interface FinishInBatch {
  activityStatus: string;
  activities: number[];
  updatedFields: [
    {
      id: number;
      value: string;
    },
  ];
}

type SendEmail = {
  idActivity: number;
  info: {
    subject: string;
    to: string[];
    cc: string[];
    bcc: string[];
  };
  email: string;
  attachment: {name: string; url: string; id: string}[];
};

type PostponeActivityReturn = {
  idTask: number;
  idGroupActivity: number;
  idResponsible: number;
  idTaskFollowUp: number;
  idTaskEmail: number;
  idTaskActivity: number;
  activityStatus: string;
  isActive: boolean;
  estimatedStart: string;
  estimatedFinish: string;
  finishedAt: string;
};

export const GetTaskToFinish = async (
  idActivity: number,
): Promise<AxiosResponse<{activity: Task}>> => {
  return await newBackendAPI.get(`/finished-activities/${idActivity}`);
};

export const PostTaskFinish = async (payload: FinishTask) => {
  const {id, ...data} = payload;
  return await newBackendAPI.patch(`/finished-activities/${id}`, data);
};

export const SendEmailFromActivity = async (payload: SendEmail) => {
  return await newBackendAPI.post(`/send-email-from-activity`, payload);
};

export const GenerateFollowUpActivity = async (processId: number) => {
  return await newBackendAPI.get(`/generate-follow-up-activity/${processId}`);
};

export const PostActivityBatchFinish = async (data: FinishInBatch) => {
  return await newBackendAPI.post(`/finished-activities-in-batch`, data);
};

export const MakeSingleActivity = async (
  idLogisticProcess: number,
  idTask: number,
) => {
  const params = {idLogisticProcess, idTask};
  return await newBackendAPI.post(`/single-activity`, params);
};

export const PostponeActivity = async (
  activityId: number,
  date: string,
): Promise<AxiosResponse<PostponeActivityReturn>> => {
  return await newBackendAPI.patch(`/postpone-activity/${activityId}`, {date});
};
