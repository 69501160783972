import pxToRem from "hooks/usePxToRem";
import {Stack, styled} from "@mui/material";

interface StyledBoxProps {
  isSidebarOpen?: boolean;
}

const forwardProp = {
  shouldForwardProp: (prop) => prop !== "isSidebarOpen",
};

// prettier-ignore
const StyledStack = styled(Stack, forwardProp)<StyledBoxProps>(({theme, isSidebarOpen}) => `
  .navigation {
    padding: 0 ${pxToRem(18)};

    &__title {
      text-transform: uppercase;
      color: ${theme.palette.mode === "light" ? theme.palette.grey[600] : theme.palette.text.secondary};
    }

    &__list {
      display: grid;
      gap: ${pxToRem(10)};

      padding: 0;
      width: ${isSidebarOpen ? "100%" : "50%"};
      margin: 0 ${isSidebarOpen ? "initial" : "auto"};

      transition: width 300ms 40ms ease-in-out;
    }
  }
`,
);

export default StyledStack;
