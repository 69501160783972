import useAlertMessage from "hooks/useAlertMessage";
import {useState} from "react";
import {GetVoyageTypes} from "services/api_v2/Processes/Processes.service";

const useVoyageTypes = () => {
  const [showAlert] = useAlertMessage();

  const [loading, setLoading] = useState(false);

  const getVoyageTypes = async (setVoaygeTypes) => {
    try {
      setLoading(true);
      const {
        data: {data},
      } = await GetVoyageTypes();

      const voyageTypesAutocompleteData = data.map((item) => ({
        label: item.name,
        id: item.id,
      }));

      setVoaygeTypes(voyageTypesAutocompleteData);
    } catch (error: any) {
      console.error(error);
      showAlert(error?.response?.data?.message, "error");
    } finally {
      setLoading(false);
    }
  };

  return {getVoyageTypes, loading};
};

export default useVoyageTypes;
