import pxToRem from "hooks/usePxToRem";

import {
  Box,
  Chip,
  Stack,
  SxProps,
  Tab,
  Tabs,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";

import {Dispatch, ReactElement, SetStateAction} from "react";
import {NavLink} from "./components";
import {CustomButton} from "ui";
import CopyToClipboardIcon from "components/CopyToClipboardIcon";

type Props = {
  title: string;
  otherActions?: ReactElement;
  navItems?: {name: string; linkTo: string}[];
  description?: string;
  buttonCancelChanges?: {handleCancel: () => void};
  buttonSaveChanges?: boolean;
  navLink?: boolean;
  tabs?: {tabsValue: number; setTabsValue: Dispatch<SetStateAction<number>>};
  withoutBorderBottom?: boolean;
  sx?: SxProps<Theme>;
  badgeText?: string;
  copyBadge?: {
    tooltipText: string;
    tooltipPlacement?:
      | "top"
      | "right"
      | "bottom"
      | "left"
      | "bottom-end"
      | "bottom-start"
      | "left-end"
      | "left-start"
      | "right-end"
      | "right-start"
      | "top-end"
      | "top-start";
  };
};

const HeaderWithNavigation = (props: Props) => {
  const {
    title,
    otherActions,
    navItems,
    description,
    buttonCancelChanges,
    buttonSaveChanges,
    navLink,
    tabs,
    withoutBorderBottom,
    sx,
    badgeText,
    copyBadge,
  } = props;

  const theme = useTheme();

  const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => {
    tabs?.setTabsValue(newValue);
  };

  return (
    <Box sx={{...sx}} mb={pxToRem(20)}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack spacing={pxToRem(4)}>
          <Stack direction="row" gap={pxToRem(8)} alignItems={"center"}>
            <Typography variant="h4" component="h2">
              {title}
            </Typography>
            {badgeText && (
              <Chip
                label={badgeText}
                color="primary"
                onDelete={() => void 0}
                deleteIcon={
                  <CopyToClipboardIcon
                    textToBeCopied={badgeText}
                    tooltipTitle={copyBadge?.tooltipText}
                    tooltipPlacement={copyBadge?.tooltipPlacement}
                  />
                }
              />
            )}
          </Stack>
          <Typography
            variant="body2"
            component="p"
            sx={{color: "text.secondary"}}
          >
            {description}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={pxToRem(12).toString()}>
          {otherActions}
          {buttonCancelChanges && (
            <CustomButton
              size="medium"
              variant="outlined"
              onClickFn={buttonCancelChanges.handleCancel}
            >
              Cancelar alterações
            </CustomButton>
          )}
          {buttonSaveChanges && (
            <CustomButton size="medium" type="submit" endIcon="save">
              Salvar
            </CustomButton>
          )}
        </Stack>
      </Stack>

      {navLink && (
        <Stack
          direction="row"
          spacing={pxToRem(28)}
          sx={{
            borderBottom: withoutBorderBottom
              ? "0"
              : `2px solid ${theme.palette.grey[100]}`,
            paddingBottom: pxToRem(8),
          }}
        >
          {navItems?.map((navLink, index) => (
            <NavLink
              key={navLink.name ?? index}
              name={navLink.name}
              linkTo={navLink.linkTo}
            />
          ))}
        </Stack>
      )}
      {tabs && (
        <Tabs value={tabs.tabsValue} onChange={handleTabsChange}>
          {navItems?.map((item) => <Tab key={item.name} label={item.name} />)}
        </Tabs>
      )}
    </Box>
  );
};

export default HeaderWithNavigation;
