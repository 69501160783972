import {AxiosRequestConfig, AxiosResponse} from "axios";
import {newBackendAPI} from "services/apiConsumer";
import {QueryPagination} from "types/GenericTypes";

interface NewVersionData {
  version: string;
}

interface NewVersionUpdateData {
  title: string;
  description: string;
  file: string;
  idVersion: number;
  versionName?: string;
}
export interface NewRouteData {
  name: string;
  path: string;
  idMenu?: number | null;
  idSubmenu?: number | null;
  icon?: string | null;
}

export interface NewMenuData {
  name: string;
}

export interface NewSubmenuData {
  name: string;
  icon: string;
  idMenu: number;
}

export interface RoutePermissionsData {
  id: number;
  name: string;
}

interface GetPermissionsResponseData {
  data: RoutePermissionsData[];
  meta: {
    currentPage: number;
    lastPage: number;
  };
}

export const NewVersion = async (version: NewVersionData) => {
  return await newBackendAPI.post("/version-control", version);
};

export const AllVersions = async () => {
  return await newBackendAPI.get("/version-control");
};

export const NewVersionUpdate = async (versionUpdate: NewVersionUpdateData) => {
  return await newBackendAPI.post("/version-update", versionUpdate);
};

export const AllVersionUpdates = async () => {
  return await newBackendAPI.get("/version-update");
};

export const UpdateVersionUpdate = async (
  id: string,
  data: Partial<NewVersionUpdateData>,
) => {
  return await newBackendAPI.patch(`/version-update/${id}`, data);
};

export const DeleteVersionUpdate = async (id: number) => {
  return await newBackendAPI.delete(`/version-update/${id}`);
};

export const NewRoute = async (routeData: NewRouteData) => {
  return await newBackendAPI.post("/routes", routeData);
};

export const AllRoutes = async (pagination: QueryPagination) => {
  const params = {...pagination};
  return await newBackendAPI.get("/routes", {params});
};

export const UpdateRoute = async (id: number, data: NewRouteData) => {
  return await newBackendAPI.patch(`/routes/${id}`, data);
};

export const InactivateRoute = async (id: number) => {
  return await newBackendAPI.delete(`/routes/${id}`);
};

export const NewMenuGroup = async (menuData: NewMenuData) => {
  return await newBackendAPI.post("/menus", menuData);
};

export const AllMenuGroups = async () => {
  return await newBackendAPI.get("/menus");
};

export const UpdateMenuGroup = async (id: number, data: NewMenuData) => {
  return await newBackendAPI.patch(`/menus/${id}`, data);
};

export const InactivateMenuGroup = async (id: number) => {
  return await newBackendAPI.delete(`/menus/${id}`);
};

export const AllMenusWithSubmenus = async () => {
  return await newBackendAPI.get("/menu-with-submenu");
};

export const NewSubmenu = async (menuData: NewSubmenuData) => {
  return await newBackendAPI.post("/submenus", menuData);
};

export const AllSubmenus = async () => {
  return await newBackendAPI.get("/submenus");
};

export const UpdateSubmenu = async (
  id: number,
  data: Partial<NewSubmenuData>,
) => {
  return await newBackendAPI.patch(`/submenus/${id}`, data);
};

export const InactivateSubmenu = async (id: number) => {
  return await newBackendAPI.delete(`/submenus/${id}`);
};

export const getPermissions = async (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetPermissionsResponseData>> => {
  return await newBackendAPI.get("/role", options);
};
