import {
  Box,
  Divider,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {ReactElement, ReactNode} from "react";

interface SectionContainerType {
  title: string;
  children: ReactNode;
  paddingTop?: string;
  paddingLeft?: string;
  isSubcard?: boolean;
  hasDivider?: boolean;
  id?: string;
  sx?: SxProps<Theme>;
  actions?: ReactElement;
  titleSize?: number;
}

const SectionContainer = (props: SectionContainerType) => {
  const {
    title,
    children,
    paddingTop,
    paddingLeft,
    isSubcard,
    hasDivider,
    id,
    sx,
    actions,
    titleSize,
  } = props;

  const theme = useTheme();

  return (
    <Box
      sx={{
        ...sx,
        position: "relative",
        paddingTop: paddingTop ?? 0,
        paddingLeft: paddingLeft ?? (isSubcard ? pxToRem(8) : 0),
      }}
      component="section"
      id={id}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{marginBottom: pxToRem(10)}}
      >
        <Typography
          variant={"subtitle2"}
          component="strong"
          sx={{
            position: "relative",
            fontWeight: 600,
            paddingLeft: isSubcard ? pxToRem(14) : 0,
            fontSize: (titleSize && pxToRem(titleSize)) ?? "default",

            "&::after": {
              content: '""',
              position: "absolute",
              left: 0,
              bottom: pxToRem(-4),

              height: isSubcard ? pxToRem(28) : pxToRem(4),
              width: isSubcard ? pxToRem(4) : pxToRem(38),
              backgroundColor: theme.palette.primary.main,
              borderRadius: pxToRem(30),
            },
          }}
        >
          {title}
        </Typography>
        {actions}
      </Stack>
      {children}

      {hasDivider && (
        <Box pt={pxToRem(18)}>
          <Divider />
        </Box>
      )}
    </Box>
  );
};

export default SectionContainer;
