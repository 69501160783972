import {Fragment, useState, memo} from "react";
import pxToRem from "hooks/usePxToRem";

import {Box, Divider, Grid, Stack} from "@mui/material";

import {
  BasicRateInfo,
  ModalComposition,
  Payment,
  Receipt,
  TaxesListing,
} from "./components";
import {DefaultCardStructure} from "components";

const ProcessTaxes = () => {
  const [isModalCompositionOpen, setIsModalCompositionOpen] = useState(false);

  const handleOpenModalComposition = () => setIsModalCompositionOpen(true);
  const handleCloseModalComposition = () => setIsModalCompositionOpen(false);
  const [selectedChargeId, setSelectedChargeId] = useState<number>();
  return (
    <Fragment>
      <ModalComposition
        isModalOpen={isModalCompositionOpen}
        onRequestCloseModal={handleCloseModalComposition}
      />

      <DefaultCardStructure
        title="Taxas"
        linkId="taxes"
        hasDivider={false}
        titleMarginBottom={5}
        sx={{mt: pxToRem(30)}}
      >
        <Stack sx={{gap: pxToRem(20)}}>
          <TaxesListing
            onRequestOpenModalComposition={handleOpenModalComposition}
            setSelectedChargeId={setSelectedChargeId}
          />
          <BasicRateInfo chargeId={selectedChargeId} />
        </Stack>
      </DefaultCardStructure>

      <Grid
        container
        display="grid"
        gridTemplateColumns="1fr auto 1fr"
        gap={pxToRem(20)}
        mt={pxToRem(20)}
      >
        <Grid item>
          <Payment />
        </Grid>

        <Grid item alignSelf="flex-end">
          <Divider orientation="vertical" sx={{height: "98%"}} />
        </Grid>

        <Grid item>
          <Receipt />
        </Grid>
      </Grid>

      <Box mt={pxToRem(24)}>
        <Divider />
      </Box>
    </Fragment>
  );
};

export default memo(ProcessTaxes);
