import pxToRem from "hooks/usePxToRem";

import {Paper, styled} from "@mui/material";

interface StyledPaperProps {
  isActive?: boolean;
}

const forwardProp = {
  shouldForwardProp: (prop) => prop !== "isActive",
};

const footerColorAccordingToTheme = (theme) => {
  return theme.palette.mode === "dark"
    ? theme.palette.primary[500]
    : theme.palette.primary[700];
};

// prettier-ignore
const StyledPaper = styled(
  Paper,
  forwardProp,
)<StyledPaperProps>(
  ({theme, isActive}) => `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: ${pxToRem(140)};

  position: relative;

  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
  cursor: pointer;
  pointer-events: ${isActive && "none"};
  overflow: hidden;
  border: ${isActive && `1px solid ${theme.palette.primary[700]}`};
  box-shadow: ${isActive && "0px 0px 0px rgba(0, 0, 0, 0.1)"};

  transition: border 280ms ease-in-out, box-shadow 280ms ease-in-out;

  
  &:hover {
    border: 1px solid ${theme.palette.primary[700]};
    
    &::after {
    content: "";
    position: absolute;
    top: ${pxToRem(-24)};
    right: ${pxToRem(-47)};
    width: ${pxToRem(96)};
    height: ${pxToRem(64)};
    background-color: ${theme.palette.primary.main};
    rotate: 47deg;
    opacity: 1;

    transition: opacity 280ms ease-in-out;
  }
}

  .card {
    &__content {
      position: relative;
      z-index: 1;

      padding: ${pxToRem(10)};
    }

    &__text {
      flex-basis: 75%;
    }

    &__title {
      font-size: ${pxToRem(28)};
    }

    &__icon {
      &--box {
        height: ${pxToRem(35)};
        width: ${pxToRem(35)};
        padding: ${pxToRem(8)};
        background-color: ${theme.palette.primary[50]};
        color: ${theme.palette.primary[700]};
        border-radius: 4px;
        font-size: ${pxToRem(24)};
      }
    }

    &__footer {
      padding: ${pxToRem(10)} ${pxToRem(14)};
      background-color: ${isActive ? theme.palette.primary.main : "default"};
      color: ${
        isActive
          ? theme.palette.background.paper
          : footerColorAccordingToTheme(theme)
      };

      transition: background-color 280ms ease-in-out, color 280ms ease-in-out;
      &--title {
        font-size: ${pxToRem(12)}
      }

      &--icon {
        font-size: ${pxToRem(18)};
        color: 
      }
    }
  }
`,
);

export default StyledPaper;
