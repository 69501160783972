import {useDispatch} from "react-redux";
import {Types as alertTypes} from "store/reducers/alertMessageReducer";

type ReturnType = [
  (
    message: string,
    severity?: "warning" | "error" | "success" | "primary",
  ) => void,
  () => void,
];

const useAlertMessage = (): ReturnType => {
  const dispatch = useDispatch();

  const showAlert = (
    message: string,
    severity: "warning" | "error" | "success" | "primary" = "error",
  ) => {
    dispatch({
      type: alertTypes.SHOW_ALERT_MESSAGE,
      open: true,
      message: message,
      severity: severity,
    });
  };

  const closeAlert: () => void = () => {
    dispatch({
      type: alertTypes.SHOW_ALERT_MESSAGE,
      open: false,
    });
  };

  return [showAlert, closeAlert];
};

export default useAlertMessage;
