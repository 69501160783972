import {createTheme} from "@mui/material/styles";

import {breakpointValues, priorityColors, typographyConfigs} from "../base";
import {defaultBadgeStyles, defaultButton} from "global/components";

const lightTheme = createTheme({
  breakpoints: {
    values: {
      ...breakpointValues,
    },
  },

  typography: {
    ...typographyConfigs,
  },

  components: {
    ...defaultButton,
    ...defaultBadgeStyles,
  },

  palette: {
    mode: "light",
    ...priorityColors,

    primary: {
      main: "#7E38DA",
      50: "#F8F2FF",
      100: "#F2E8FE",
      200: "#ECDEFE",
      300: "#D5B8FB",
      400: "#AF82E9",
      500: "#9153E1",
      600: "#7E38DA",
      700: "#702DC7",
      800: "#582D91",
      900: "#442271",
    },

    grey: {
      50: "#F7F7F7",
      100: "#E6E6E6",
      200: "#D1D1D1",
      300: "#C8C8C8",
      400: "#646464",
      500: "#757575",
      600: "#646464",
      700: "#4B4B4B",
      800: "#3D3D3D",
      900: "#171717",
      A400: "#F9FAFB",
    },

    background: {
      default: "#F0F2F5",
      paper: "#ffffff",
    },

    text: {
      primary: "#3D3D3D",
      secondary: "#575757",
    },
  },
});

export default lightTheme;
