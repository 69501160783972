/* eslint-disable @typescript-eslint/no-explicit-any */
import {createActions, createReducer} from "reduxsauce";

type initial = {
  newMessages: number;
  data: Array<any>;
  processSelected: any;
  messagesProcess: Array<any>;
  viewHistory: boolean;
  messagesHistory: Array<any>;
};

const INITIAL_STATE: initial = {
  newMessages: 0,
  data: [],
  processSelected: {_id: "", processNumber: "", type: ""},
  messagesProcess: [],
  viewHistory: false,
  messagesHistory: [],
};

export const {Types, Creators} = createActions({
  myListChat: ["newMessages, data"],
  handleChatNotify: ["newMessages"],
  handleChatRead: ["data"],
  alterProcessSelected: ["processSelected"],
  handleMessagesProcess: ["messagesProcess"],
  handleViewHistory: ["viewHistory"],
  handleMessagesHistory: ["messagesHistory"],
});

const myListChat = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  newMessages: action.newMessages,
  data: action.data,
});

const handleChatNotify = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  newMessages: action.newMessages,
});

const handleChatRead = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  data: action.data,
});

const alterProcessSelected = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  processSelected: action.processSelected,
});

const handleMessagesProcess = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  messagesProcess: action.messagesProcess,
});

const handleViewHistory = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  viewHistory: action.viewHistory,
});

const handleMessagesHistory = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  messagesHistory: action.messagesHistory,
});

export default createReducer(INITIAL_STATE, {
  [Types.MY_LIST_CHAT]: myListChat,
  [Types.HANDLE_CHAT_NOTIFY]: handleChatNotify,
  [Types.HANDLE_CHAT_READ]: handleChatRead,
  [Types.ALTER_PROCESS_SELECTED]: alterProcessSelected,
  [Types.HANDLE_MESSAGES_PROCESS]: handleMessagesProcess,
  [Types.HANDLE_VIEW_HISTORY]: handleViewHistory,
  [Types.HANDLE_MESSAGES_HISTORY]: handleMessagesHistory,
});
