import {readLocalStorage} from "hooks/useLocalStorage";

const redirectToDashboardOrLogin = () => {
  window.location.pathname = readLocalStorage("user_info")
    ? "/dashboards"
    : "/login";

  return null;
};

export default redirectToDashboardOrLogin;
