/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
import pxToRem from "hooks/usePxToRem";

import {Box, Chip, Grid, Stack, Typography} from "@mui/material";

import {
  CustomAutocomplete,
  CustomButton,
  CustomIconButton,
  CustomInput,
  CustomInputWrapper,
  CustomSelect,
} from "ui";
import {ConfirmModal, DefaultCardStructure} from "components";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import {
  DeleteLogisticBillLadingLoad,
  GetEquipmentsList,
  GetPackageContainersList,
} from "services/api_v2/Processes/Processes.service";
import useHandleFieldLists from "views/ProcessDetails/hooks/useHandleFieldLists";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";
import {getTooltipText} from "views/ProcessDetails/utils/getTooltipText";
import useChangeDAteAndAutocomplete from "views/ProcessDetails/hooks/useChangeDAteAndAutocomplete";
import useAlertMessage from "hooks/useAlertMessage";
import useToast from "hooks/useToast";
import {useDebounceCallback} from "hooks/useDebounceCallback";
import {
  DefaultProcessDetails,
  ProcessEquipment,
} from "views/ProcessDetails/Process.types";
import {TypeItemLoad, WoodPackage} from "util/globalEnums";
import {calcConsideredWeight} from "views/ProcessDetails/components/ProcessLoad/utils/calculateConsideredWeight";

type Props = {
  refetchProcess: () => Promise<DefaultProcessDetails | undefined>;
};

const ActivityCargoItems = (props: Props) => {
  const {refetchProcess} = props;
  const {control, setValue, getValues} = useFormContext();
  const [showToast] = useToast();
  const [showAlert] = useAlertMessage();
  const loadIndex = 0;

  const equipments = useWatch({name: "logisticEquipments"});

  const {fields, append, remove} = useFieldArray({
    name: `billLadingLoads`,
    keyName: "idForm",
  });

  const [equipmentsOptions, setEquipmentsOptions] = useState(
    [] as AutoCompleteData[],
  );
  const [search, setSearch] = useState<string>();

  const {
    getOptions: getEquipmentsOptions,
    onBottomScroll: onBottomScrollEquipments,
    isLoadingOptions: isLoadingEquipmentsOptions,
  } = useHandleFieldLists(GetEquipmentsList);

  const {handleChangeAutocomplete, mountOptions} =
    useChangeDAteAndAutocomplete();

  const totalOfEquipments = () => {
    const total =
      equipments?.reduce((acc, curr) => acc + curr?.quantity, 0) ?? 0;
    return total;
  };

  const handleAppendNewItem = () => {
    const isDirectMaster = getValues("isDirectMaster");
    if (!isDirectMaster && Object.keys(getValues("houseBL")[0]).length === 0) {
      showToast("Não existe um house para o processo!", "error");
      return;
    }

    if (getValues("billLadingLoads").length === totalOfEquipments()) {
      showToast(
        "O número de equipamentos é igual ou maior ao número de itens já existente",
        "warning",
      );
    } else {
      append({
        number: "",
        seal: "",
        equipment: null,
        quantity: 0,
        netWeight: 0,
        grossWeight: 0,
        measurement: 0,
        tare: 0,
        typeItemLoad: "",
        barSeal: "",
        typePackages: null,
        packageContainer: null,
        dangerousGoodsClass: "",
        dangerousGoodsCode: "",
      });
    }
  };

  const setHouseMeasurements = () => {
    const cargoItems = getValues("billLadingLoads");
    const totalGrossWeight = cargoItems.reduce(
      (acc, prev) => acc + prev.grossWeight,
      0,
    );
    setValue(`processLoads[${loadIndex}].grossWeight`, totalGrossWeight, {
      shouldDirty: true,
    });
    const totalMeasurements = cargoItems.reduce(
      (acc, prev) => acc + prev.measurement,
      0,
    );
    setValue(`processLoads[${loadIndex}].cubicMeters`, totalMeasurements, {
      shouldDirty: true,
    });
    const totalQuantities = cargoItems.reduce(
      (acc, prev) => acc + prev.quantity,
      0,
    );
    setValue(`processLoads[${loadIndex}].quantities`, totalQuantities, {
      shouldDirty: true,
    });

    const loadData = calcConsideredWeight(
      totalMeasurements ?? 0,
      totalGrossWeight ?? 0,
    );
    setValue(
      `processLoads[${loadIndex}].consideredWeight`,
      loadData.consideredWeight,
      {shouldDirty: true},
    );
    setValue(`processLoads[${loadIndex}].cubeWeight`, loadData.cubeWeight, {
      shouldDirty: true,
    });
  };
  const setHouseMeasurementsDebounced = useDebounceCallback(
    setHouseMeasurements,
    1000,
  );

  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{
    itemId?: number;
    index?: number;
  }>({});

  const handleDeleteLogisticBillLading = async (field: any, index: number) => {
    if (!field.id) {
      remove(index);
      return;
    }

    setItemToDelete({itemId: field.id as number, index: index});
    setConfirmModalIsOpen(true);
  };

  const handleClose = () => {
    setConfirmModalIsOpen(false);
  };
  const confirmDeletionPackage = async () => {
    if (!itemToDelete?.itemId || itemToDelete?.index === undefined) {
      return;
    }
    try {
      await DeleteLogisticBillLadingLoad(itemToDelete.itemId);
      remove(itemToDelete.index);

      await refetchProcess();
      handleClose();

      showToast("Item removido com sucesso", "success");
    } catch (e: any) {
      console.error(e);
      showAlert(e?.response?.data?.message, "error");
    }
  };

  const getEquipmentsIds = () => {
    const equipments: ProcessEquipment[] = getValues("logisticEquipments");
    const idsList = equipments?.map((item) => item?.equipment?.id);
    return idsList;
  };

  const [equipmentsIdsList] =
    useState<(number | undefined)[]>(getEquipmentsIds());

  const [packagesOptions, setPackagesOptions] = useState(
    [] as AutoCompleteData[],
  );
  const [searchPackages, setSearchPackages] = useState<string>();

  const {
    getOptions: getPackagesOptions,
    onBottomScroll: onBottomScrollPackages,
    isLoadingOptions: isLoadingPackagesOptions,
  } = useHandleFieldLists(GetPackageContainersList);

  const [
    extraSpaceWhenCargoIsNotDangerous,
    setExtraSpaceWhenCargoIsNotDangerous,
  ] = useState(0);

  const DANGEROUS_FIELD_SIZE = 1;
  const [isCargoDangerous] = useState(
    getValues(`processLoads[0].cargoDangerous`),
  );

  useEffect(() => {
    if (!isCargoDangerous) {
      setExtraSpaceWhenCargoIsNotDangerous((2 * DANGEROUS_FIELD_SIZE) / 10);
    }
  }, []);

  return (
    <>
      {confirmModalIsOpen && (
        <ConfirmModal
          isOpen={confirmModalIsOpen}
          title={"Deletar item de carga"}
          subtitle={`Esta ação não pode ser revertida!`}
          confirmButtonFn={() => confirmDeletionPackage()}
          onClose={handleClose}
        />
      )}
      <DefaultCardStructure
        title="Itens Da Carga - FCL"
        hasDivider={false}
        isSubcard={false}
        button={
          <CustomButton startIcon="add" onClickFn={handleAppendNewItem}>
            Incluir item
          </CustomButton>
        }
      >
        <Stack sx={{gap: pxToRem(8)}}>
          {!fields?.length ? (
            <Typography>Não existem itens de carga no processo.</Typography>
          ) : (
            <CustomInputWrapper xs={2.4} title="Wood Package">
              <Controller
                name={`processLoads[${loadIndex}].woodPackage`}
                control={control}
                render={({field: {onChange, value}}) => (
                  <CustomSelect
                    name={`processLoads[${loadIndex}].woodPackage`}
                    value={value}
                    onChange={onChange}
                    content={Object.values(WoodPackage)}
                    size="small"
                  />
                )}
              />
            </CustomInputWrapper>
          )}
          {fields?.map((field: any, index) => (
            <Grid key={field.idForm} container columnSpacing={pxToRem(1)}>
              <CustomInputWrapper xs={0.4} title="">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <Chip label={index + 1} color="primary" size="small" />
                </Box>
              </CustomInputWrapper>

              <CustomInputWrapper
                title="Tipo item"
                xs={0.9 + extraSpaceWhenCargoIsNotDangerous}
                tooltip="Tipo item carga"
                required
              >
                <Controller
                  name={`billLadingLoads.${index}.typeItemLoad`}
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <CustomSelect
                      content={Object.values(TypeItemLoad)}
                      value={value ?? ""}
                      name={`billLadingLoads.${index}.typeItemLoad`}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      size="small"
                      required
                    />
                  )}
                />
              </CustomInputWrapper>

              <CustomInputWrapper
                xs={0.8 + extraSpaceWhenCargoIsNotDangerous}
                title="C. Num "
                required
              >
                <Controller
                  name={`billLadingLoads.${index}.number`}
                  control={control}
                  render={({field: {value, onChange}}) => (
                    <CustomInput
                      name={`billLadingLoads.${index}.number`}
                      type="text"
                      placeholder="N° container"
                      value={value ?? ""}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      size="small"
                      required
                    />
                  )}
                />
              </CustomInputWrapper>

              <CustomInputWrapper
                xs={0.8 + extraSpaceWhenCargoIsNotDangerous}
                title="Selo"
                required
              >
                <Controller
                  name={`billLadingLoads.${index}.seal`}
                  control={control}
                  render={({field: {value, onChange}}) => (
                    <CustomInput
                      name={`billLadingLoads.${index}.seal`}
                      type="text"
                      placeholder="Selo"
                      value={value ?? ""}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      size="small"
                      required
                    />
                  )}
                />
              </CustomInputWrapper>

              <CustomInputWrapper
                xs={1 + extraSpaceWhenCargoIsNotDangerous}
                title="BarSeal"
              >
                <Controller
                  name={`billLadingLoads.${index}.barSeal`}
                  control={control}
                  render={({field: {value, onChange}}) => (
                    <CustomInput
                      name={`billLadingLoads.${index}.barSeal`}
                      type="text"
                      placeholder="BarSeal"
                      value={value ?? ""}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      size="small"
                    />
                  )}
                />
              </CustomInputWrapper>

              <CustomInputWrapper
                xs={1.2 + extraSpaceWhenCargoIsNotDangerous}
                title="Equipamento"
                required
              >
                <Controller
                  name={`billLadingLoads.${index}.equipment`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomAutocomplete
                      name={`billLadingLoads.${index}.equipment`}
                      placeholder="Equipamento"
                      tooltipText={getTooltipText(value?.name, 3)}
                      loading={isLoadingEquipmentsOptions}
                      options={mountOptions(value, equipmentsOptions)}
                      value={value?.name ?? null}
                      onOpen={
                        equipmentsOptions.length > 0
                          ? undefined
                          : () =>
                              getEquipmentsOptions(
                                setEquipmentsOptions,
                                1,
                                search,
                              )
                      }
                      onInputChange={(e, value) => {
                        if (e !== null) {
                          setSearch(value);
                          getEquipmentsOptions(setEquipmentsOptions, 1, search);
                        }
                      }}
                      getNextPage={() => {
                        onBottomScrollEquipments(setEquipmentsOptions, search);
                      }}
                      onChange={(e, value) => {
                        handleChangeAutocomplete(
                          e,
                          value,
                          `billLadingLoads.${index}.equipment`,
                        );
                      }}
                      isOptionEqualToValue={(option, value) =>
                        value === option?.label
                      }
                      getOptionDisabled={(option) =>
                        !equipmentsIdsList?.includes(option?.id)
                      }
                      required
                    />
                  )}
                />
              </CustomInputWrapper>

              <CustomInputWrapper
                xs={0.5 + extraSpaceWhenCargoIsNotDangerous}
                title="Quant."
                tooltip="Quantidade"
                required
              >
                <Controller
                  name={`billLadingLoads.${index}.quantity`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomInput
                      name={`billLadingLoads.${index}.quantity`}
                      type="number"
                      placeholder="Quant."
                      value={value ?? ""}
                      onChange={(e) => {
                        setValue(
                          `billLadingLoads.${index}.quantity`,
                          +e.target.value,
                          {shouldDirty: true},
                        );
                        setHouseMeasurementsDebounced();
                      }}
                      size="small"
                      inputProps={{
                        style: {
                          fontSize: pxToRem(14),
                        },
                        min: 0.0,
                        step: 1.0,
                        onClick: (e) => value === 0 && e.currentTarget.select(),
                      }}
                      required
                    />
                  )}
                />
              </CustomInputWrapper>
              <CustomInputWrapper
                xs={1.2 + extraSpaceWhenCargoIsNotDangerous}
                title={`Pacote`}
                required
              >
                <Controller
                  name={`billLadingLoads.${index}.packageContainer`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomAutocomplete
                      name={`billLadingLoads.${index}.packageContainer`}
                      placeholder="Pacote"
                      tooltipText={getTooltipText(value?.name, 3)}
                      loading={isLoadingPackagesOptions}
                      options={mountOptions(value, packagesOptions)}
                      value={value?.name ?? null}
                      onOpen={
                        packagesOptions.length > 0
                          ? undefined
                          : () =>
                              getPackagesOptions(
                                setPackagesOptions,
                                1,
                                searchPackages,
                              )
                      }
                      onInputChange={(e, value) => {
                        if (e !== null) {
                          setSearchPackages(value);
                          getPackagesOptions(
                            setPackagesOptions,
                            1,
                            searchPackages,
                          );
                        }
                      }}
                      getNextPage={() => {
                        onBottomScrollPackages(
                          setPackagesOptions,
                          searchPackages,
                        );
                      }}
                      onChange={(e, value) => {
                        handleChangeAutocomplete(
                          e,
                          value,
                          `billLadingLoads.${index}.packageContainer`,
                        );
                      }}
                      isOptionEqualToValue={(option, value) =>
                        value === option?.label
                      }
                      required
                    />
                  )}
                />
              </CustomInputWrapper>
              {/* Peso líquido oculto por enquanto */}
              {/* <CustomInputWrapper md={1.5} title="Peso líq.">
                <Controller
                  name={`billLadingLoads.${index}.netWeight`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomInput
                      name={`billLadingLoads.${index}.netWeight`}
                      type="number"
                      placeholder="Peso liq."
                      value={value ?? ""}
                      onChange={(e) => {
                        setValue(
                          `billLadingLoads.${index}.netWeight`,
                          +e.target.value,
                          {shouldDirty: true},
                        );
                        fieldIsRequired(index);
                      }}
                      size="small"
                      inputProps={{
                        style: {
                          fontSize: pxToRem(14),
                        },
                        min: 0.0,
                        step: 0.001,
                      }}
                    />
                  )}
                />
              </CustomInputWrapper> */}
              <CustomInputWrapper
                xs={1 + extraSpaceWhenCargoIsNotDangerous}
                title="P. Bruto"
                tooltip="Peso bruto - kg"
                required
              >
                <Controller
                  name={`billLadingLoads.${index}.grossWeight`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomInput
                      name={`billLadingLoads.${index}.grossWeight`}
                      type="number"
                      placeholder="Peso bruto"
                      value={value ?? ""}
                      onChange={(e) => {
                        setValue(
                          `billLadingLoads.${index}.grossWeight`,
                          +e.target.value,
                          {shouldDirty: true},
                        );
                        setHouseMeasurementsDebounced();
                      }}
                      size="small"
                      inputProps={{
                        style: {
                          fontSize: pxToRem(14),
                        },
                        min: 0.0,
                        step: 0.001,
                        onClick: (e) => value === 0 && e.currentTarget.select(),
                      }}
                      required
                    />
                  )}
                />
              </CustomInputWrapper>

              <CustomInputWrapper
                xs={1 + extraSpaceWhenCargoIsNotDangerous}
                title="CBM"
                required
              >
                <Controller
                  name={`billLadingLoads.${index}.measurement`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomInput
                      name={`billLadingLoads.${index}.measurement`}
                      type="number"
                      placeholder="medidas"
                      value={value?.toString() ?? ""}
                      onChange={(e) => {
                        setValue(
                          `billLadingLoads.${index}.measurement`,
                          +e.target.value,
                          {shouldDirty: true},
                        );
                        setHouseMeasurementsDebounced();
                      }}
                      size="small"
                      inputProps={{
                        style: {
                          fontSize: pxToRem(14),
                        },

                        min: 0.0,
                        step: 0.001,
                        onClick: (e) => value === 0 && e.currentTarget.select(),
                      }}
                      required
                    />
                  )}
                />
              </CustomInputWrapper>

              <CustomInputWrapper
                xs={1 + extraSpaceWhenCargoIsNotDangerous}
                title="Tara"
              >
                <Controller
                  name={`billLadingLoads.${index}.tare`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomInput
                      name={`billLadingLoads.${index}.tare`}
                      type="number"
                      placeholder="Tara"
                      value={value}
                      onChange={(e) => {
                        setValue(
                          `billLadingLoads.${index}.tare`,
                          +e.target.value,
                          {shouldDirty: true},
                        );
                      }}
                      size="small"
                      inputProps={{
                        style: {
                          fontSize: pxToRem(14),
                        },
                        min: 0.0,
                        step: 0.001,
                        onClick: (e) => value === 0 && e.currentTarget.select(),
                      }}
                    />
                  )}
                />
              </CustomInputWrapper>

              {isCargoDangerous && (
                <>
                  <CustomInputWrapper
                    xs={1 + extraSpaceWhenCargoIsNotDangerous}
                    title="Classe"
                    tooltip="Classe mercadoria perigosa"
                  >
                    <Controller
                      name={`billLadingLoads.${index}.dangerousGoodsClass`}
                      control={control}
                      render={({field: {value}}) => (
                        <CustomInput
                          name={`billLadingLoads.${index}.dangerousGoodsClass`}
                          placeholder="Classe"
                          value={value?.toString() ?? ""}
                          onChange={(e) => {
                            setValue(
                              `billLadingLoads.${index}.dangerousGoodsClass`,
                              +e.target.value,
                              {shouldDirty: true},
                            );
                          }}
                          size="small"
                          required
                        />
                      )}
                    />
                  </CustomInputWrapper>

                  <CustomInputWrapper
                    xs={1}
                    title="Cód."
                    tooltip="Cód. mercadoria perigosa"
                  >
                    <Controller
                      name={`billLadingLoads.${index}.dangerousGoodsCode`}
                      control={control}
                      render={({field: {value}}) => (
                        <CustomInput
                          name={`billLadingLoads.${index}.dangerousGoodsCode`}
                          placeholder="Código"
                          value={value?.toString() ?? ""}
                          onChange={(e) => {
                            setValue(
                              `billLadingLoads.${index}.dangerousGoodsCode`,
                              +e.target.value,
                              {shouldDirty: true},
                            );
                          }}
                          size="small"
                          required
                        />
                      )}
                    />
                  </CustomInputWrapper>
                </>
              )}

              <Grid
                item
                xs={0.2}
                display="flex"
                alignItems="end"
                justifyContent="flex-start"
              >
                <CustomIconButton
                  iconName="delete"
                  sx={{
                    pr: 0,
                    ":hover": {
                      color: "red",
                    },
                  }}
                  onClick={() => {
                    handleDeleteLogisticBillLading(field, index);
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Stack>
      </DefaultCardStructure>
    </>
  );
};

export default ActivityCargoItems;
