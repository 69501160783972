/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import {RetrieveUserInformation} from "./User/user.service";
import {RouteComponentProps} from "react-router-dom";
import * as Sentry from "@sentry/react";

export const newBackendAPI = axios.create({
  baseURL: process.env.REACT_APP_NEW_BACKEND,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    //"ngrok-skip-browser-warning": true, necessário para se conectar no backend via ngrok
  },
});

export const keyCloakAPI = axios.create({
  baseURL: process.env.REACT_APP_KEYCLOAK_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

export const handleRequestInterceptor = (token: string) => {
  newBackendAPI.interceptors.request.use(async (config) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
};

export const handleResponseInterceptor = (
  history: RouteComponentProps["history"],
  accessDeniedHandler: () => void,
  handle401Status: () => void,
  axiosInstance = newBackendAPI,
) => {
  axiosInstance.interceptors.response.use(
    async (response) => {
      return response;
    },
    async (error) => {
      if (error) {
        Sentry.captureException(error);
      }
      if (error.response?.status === 403) {
        if (error.response?.config?.method === "get") {
          history.push("/access-denied");
        } else {
          accessDeniedHandler();
        }
      } else if (error.response?.status === 401) {
        handle401Status();
      }
      throw error;
    },
  );
};

export const newDataAPI = axios.create({
  baseURL: process.env.REACT_APP_NEW_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

newDataAPI.interceptors.request.use(async (config) => {
  const token: string | undefined =
    RetrieveUserInformation()?.accessToken || "";

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

newDataAPI.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    if (error) {
      Sentry.captureException(error);
    }
    throw error;
  },
);

export const localBackendAPI = axios.create({
  baseURL: process.env.REACT_APP_LOCAL_BACKEND,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const buildURL = (
  query: any,
  sort: any,
  page: number | null,
  limit: number,
) => {
  let url = "";
  if (query !== null && query !== undefined && query !== false)
    url = url.concat(`?q=${query}`);
  if (sort !== null && sort !== undefined) url = url.concat(`&sort=${sort}`);
  if (page !== null && page !== undefined) url = url.concat(`&_page=${page}`);
  if (limit !== null && limit !== undefined)
    url = url.concat(query ? `&_pageSize=${limit}` : `?_pageSize=${limit}`);

  return url;
};
