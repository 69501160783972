import {
  InputAdornment,
  Tooltip,
  Typography,
  useTheme,
  Box,
} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {CustomInput} from "ui";

interface InputWithPermanentPlaceholderProps {
  permanentPlaceholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  required?: boolean;
  placeholder?: string;
  name?: string;
  tooltipOff?: boolean;
  disabled?: boolean;
  isMultiline?: boolean;
  height?: string;
}

const InputWithPermanentPlaceholder = (
  props: InputWithPermanentPlaceholderProps,
) => {
  const theme = useTheme();
  const {
    permanentPlaceholder,
    value,
    onChange,
    type = "number",
    required = true,
    placeholder = type === "number" ? "0" : "",
    name,
    tooltipOff,
    disabled,
    isMultiline,
    height,
  } = props;

  return (
    <Tooltip title={tooltipOff ? "" : value} arrow placement="top">
      <Box>
        <CustomInput
          height={height}
          disabled={disabled}
          required={required}
          type={type}
          size="small"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          name={name}
          isMultiline={isMultiline}
          startAdornment={
            <InputAdornment
              position="start"
              sx={{
                color: theme.palette.grey[500],
                maxWidth: "70%",
              }}
            >
              <Typography
                sx={{
                  fontSize: pxToRem(14),
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {permanentPlaceholder}
              </Typography>
            </InputAdornment>
          }
          sx={{
            fontSize: pxToRem(14),
            fontWeight: 500,
            "& .MuiInputBase-input": {
              "&::placeholder": {
                fontWeight: 400,
                opacity: 1,
              },
            },
          }}
        />
      </Box>
    </Tooltip>
  );
};

export default InputWithPermanentPlaceholder;
