import {useState} from "react";
import {ListGroupFiles} from "services/api_v2/Processes/Processes.service";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";

const useTypeDocumentAutocomplete = () => {
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const FIRST_PAGE = 1;
  const PAGE_LIMIT = 30;
  const [pageState, setPageState] = useState({currentPage: 1, lastPage: 1});
  const [documentOptions, setDocumentOptions] = useState<AutoCompleteData[]>(
    [],
  );
  const getOptions = async (
    page = FIRST_PAGE,
    itemsPerPage = PAGE_LIMIT,
    search?: string,
  ) => {
    setIsLoadingOptions(true);

    try {
      const response = await ListGroupFiles(page, itemsPerPage, search);

      const formattedData = response.data.data.map((item) => ({
        id: item.id,
        label: item.name,
      }));

      if (page !== FIRST_PAGE) {
        setDocumentOptions((prev) => [...prev, ...formattedData]);
      } else {
        setDocumentOptions(formattedData);
      }

      setPageState((prev) => ({
        ...prev,
        currentPage: response.data.meta.currentPage,
        lastPage: response.data.meta.lastPage,
      }));
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoadingOptions(false);
    }
  };

  const [categorySearch, setCategorySearch] = useState<string>();
  const [helperText, setHelperText] = useState(false);

  const getFilteredCategory = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
  ) => {
    if (event.type === "click" || value === "") {
      setDocumentOptions([]);
      setCategorySearch("");
    } else if (value?.length < 3) {
      setDocumentOptions([]);
      setHelperText(true);
      return;
    } else {
      setCategorySearch(value);
      setHelperText(false);
      getOptions(FIRST_PAGE, PAGE_LIMIT, value);
    }
  };

  return {
    isLoadingOptions,
    pageState,
    documentOptions,
    getOptions,
    categorySearch,
    helperText,
    getFilteredCategory,
  };
};

export default useTypeDocumentAutocomplete;
