import {useMemo, useState} from "react";
import {
  ProcessDocumentData,
  ProcessDocumentsByCategoryData,
} from "../../ProcessDocuments.types";

import {MRT_ColumnDef} from "material-react-table";
import {DeleteProcessFile} from "services/api_v2/Processes/Processes.service";
import useToast from "hooks/useToast";
import {format} from "date-fns";
import useAlertMessage from "hooks/useAlertMessage";
import {ProcessFile} from "views/ProcessDetails/Process.types";

const useProcessDocuments = (files: ProcessFile[]) => {
  const [showAlert] = useAlertMessage();
  const [showToast] = useToast();

  const columnsData = useMemo<MRT_ColumnDef<ProcessDocumentData>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Nome",
        minSize: 340,
      },

      {
        accessorKey: "sendDate",
        header: "Data de envio",
      },
    ],
    [],
  );

  const baseDocuments = files.map((file) => ({
    id: file?.id.toString(),
    name: file?.originalName,
    sendDate:
      file?.createdAt !== undefined
        ? format(new Date(file?.createdAt), "dd/MM/yyyy - HH:mm")
        : "",
    link: file?.url,
    documentType: file?.category ?? "draft",
  }));

  const deleteDocument = async (documentId: number) => {
    try {
      await DeleteProcessFile(documentId);
      showToast("Documento apagado com sucesso", "success");
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    }
  };

  const [processDocumentsData, setProcessDocumentsData] = useState({
    originalDocs: [...baseDocuments],
    draft: [...baseDocuments],
    merchant: [...baseDocuments],
    financial: [...baseDocuments],
    pricing: [...baseDocuments],
    others: [...baseDocuments],
  } as ProcessDocumentsByCategoryData);

  return {
    columnsData,
    processDocumentsData,
    setProcessDocumentsData,
    deleteDocument,
  };
};

export default useProcessDocuments;
