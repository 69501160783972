/* eslint-disable @typescript-eslint/no-explicit-any */
import {alpha, Drawer} from "@mui/material";
import {Dispatch, SetStateAction} from "react";

type MyDrawerTypes = {
  children: React.ReactNode;
  open: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  handleClose?: () => void;
  anchor?: "right" | "left" | "bottom" | "top";
};

const MyDrawer = (props: MyDrawerTypes) => {
  const {children, open, setOpen, anchor, handleClose} = props;

  const handleCloseDrawer = () => {
    setOpen ? setOpen(false) : handleClose?.();
  };

  return (
    <Drawer
      open={open}
      anchor={anchor ?? "right"}
      onClose={handleCloseDrawer}
      sx={(theme) => ({
        "& .MuiPaper-root": {
          backgroundColor: alpha(theme.palette.background.paper, 0.8),
        },
      })}
    >
      {children}
    </Drawer>
  );
};

export default MyDrawer;
