import {createActions, createReducer} from "reduxsauce";

type initial = {
  userInfo: {
    access_token: string;
    id_token: string;
    user: {
      id: number;
      avatar: string;
      name: string;
      email: string;
      logged_at: string;
    };
    settings: {
      acceptPrivacy: boolean;
      darkMode: boolean;
      expandedMenu: boolean;
      showNotifications: boolean;
    };
    menu: Array<unknown>;
  };
  currentDateTime: string;
};

const INITIAL_STATE: initial = {
  userInfo: {
    access_token: "",
    id_token: "",
    user: {
      id: 0,
      avatar: "",
      name: "",
      email: "",
      logged_at: "",
    },
    settings: {
      acceptPrivacy: false,
      darkMode: true,
      expandedMenu: true,
      showNotifications: true,
    },
    menu: [],
  },
  currentDateTime: "",
};

export const {Types, Creators} = createActions({
  setUserInfo: ["userInfo"],
  setCurrentDateTime: ["currentDateTime"],
});

const setUserInfo = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  userInfo: action.userInfo,
});

const setCurrentDateTime = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  currentDateTime: action.currentDateTime,
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_USER_INFO]: setUserInfo,
  [Types.SET_CURRENT_DATE_TIME]: setCurrentDateTime,
});
