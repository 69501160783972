import StyledPopper from "views/Activities/Poppper.styles";
import {Box} from "@mui/system";
import Grid from "@mui/material/Grid2";
import {DateCalendar, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ptBR from "date-fns/locale/pt-BR";
import {useState} from "react";
import {Grow, Paper, ClickAwayListener, Chip, Divider} from "@mui/material";
import {addDays, format} from "date-fns";
import pxToRem from "hooks/usePxToRem";
import {CustomButton} from "ui";
import useLoading from "hooks/useLoading";
import {PostponeActivity} from "services/api_v2/CompleteActivity/CompleteActivity.service";
import useToast from "hooks/useToast";
import useAlertMessage from "hooks/useAlertMessage";
import {DAYS_POSSIBLE_TO_POSTPONE_ACTIVITY} from "global/constants";

type Props = {
  anchorEl: HTMLElement | (() => HTMLElement) | null | undefined;
  postponePopperIsOpen: boolean;
  processNumberToPostpone: string;
  processIdToPostpone: number | null;
  handleClose: () => void;
  onClose: () => Promise<void>;
};

const PostPoneActivityPopper = (props: Props) => {
  const {
    postponePopperIsOpen,
    processNumberToPostpone,
    processIdToPostpone,
    anchorEl,
    handleClose,
    onClose,
  } = props;
  const [postponeDate, setPostponeDate] = useState<Date | null | undefined>(
    null,
  );
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  const [setLoading] = useLoading();
  const [showToast] = useToast();
  const [showAlert] = useAlertMessage();

  const postponeActivity = async (activityId: number, date: string) => {
    try {
      setLoading(true);
      await PostponeActivity(activityId, date);
      onClose();
      showToast("Atividade adiada com sucesso!", "success");
    } catch (err: any) {
      console.error(err);
      showAlert(err?.response?.data?.message, "error");
    } finally {
      setLoading(false);
    }
  };
  const handlePostponeActivity = async () => {
    if (!processIdToPostpone || !postponeDate) return;

    const formattedDate = format(postponeDate, "yyyy-MM-dd");
    await postponeActivity(processIdToPostpone, formattedDate);
    handleClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <StyledPopper
        open={postponePopperIsOpen}
        anchorEl={anchorEl}
        placement="right"
        transition
        sx={{zIndex: 1200}}
        modifiers={[
          {
            name: "arrow",
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
          {
            name: "flip",
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: "document",
              padding: 8,
            },
          },
        ]}
      >
        {({TransitionProps}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "left bottom",
            }}
          >
            <Paper elevation={2}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box>
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      sx={{pt: pxToRem(10), pb: pxToRem(10)}}
                    >
                      <Chip label={processNumberToPostpone} color="primary" />
                    </Box>

                    <Divider />
                  </Box>
                  <Box>
                    <DateCalendar
                      maxDate={addDays(
                        new Date(),
                        Number(DAYS_POSSIBLE_TO_POSTPONE_ACTIVITY ?? 10),
                      )}
                      value={postponeDate}
                      onChange={(value) => {
                        setPostponeDate(value);
                      }}
                      disablePast
                    />
                  </Box>
                  <Grid
                    container
                    spacing={pxToRem(8)}
                    p={`0 ${pxToRem(20)} ${pxToRem(20)}`}
                  >
                    <Grid size={12} pb={pxToRem(10)}>
                      <Divider />
                    </Grid>
                    <Grid size={6}>
                      <CustomButton
                        fullWidth
                        size="small"
                        variant="outlined"
                        onClickFn={handleClose}
                      >
                        Cancelar
                      </CustomButton>
                    </Grid>
                    <Grid size={6}>
                      <CustomButton
                        fullWidth
                        size="small"
                        endIcon="update"
                        onClickFn={handlePostponeActivity}
                      >
                        Reagendar
                      </CustomButton>
                    </Grid>
                  </Grid>
                  <Box
                    ref={setArrowRef}
                    component="span"
                    className="MuiPopper-arrow"
                    sx={{
                      position: "absolute",
                      fontSize: 7,
                      width: "3em",
                      height: "3em",
                      "&::before": {
                        content: '""',
                        margin: "auto",
                        display: "block",
                        width: 0,
                        height: 0,
                        borderStyle: "solid",
                      },
                    }}
                  />
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </LocalizationProvider>
  );
};

export default PostPoneActivityPopper;
