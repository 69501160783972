/* eslint-disable @typescript-eslint/no-explicit-any */
import {createActions, createReducer} from "reduxsauce";

type initial = {
  filteredProcesses: any[];
  processesPagination: {
    pageIndex: number;
    pageSize: number;
    totalNumberOfPages: number;
  };
};

const INITIAL_STATE: initial = {
  filteredProcesses: [],
  processesPagination: {
    pageIndex: 0,
    pageSize: 20,
    totalNumberOfPages: 1,
  },
};

export const {Types, Creators} = createActions({
  setFilteredProcesses: ["filteredProcesses"],
  setProcessesPagination: ["processesPagination"],
});

const setFilteredProcesses = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  filteredProcesses: action.filteredProcesses,
});

const setProcessesPagination = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  processesPagination: action.processesPagination,
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_FILTERED_PROCESSES]: setFilteredProcesses,
  [Types.SET_PROCESSES_PAGINATION]: setProcessesPagination,
});
