import {newBackendAPI} from "services/apiConsumer";

export enum PersonTypes {
  "cargoAgent" = "cargoAgent",
  "agenciesLogistic" = "agenciesLogistic",
  "client" = "client",
  "containerTerminal" = "containerTerminal",
  "customBroker" = "customBroker",
  "employer" = "employer",
  "internationalAssociation" = "internationalAssociation",
  "representative" = "representative",
  "transportCompany" = "transportCompany",
  "insurer" = "insurer",
}

export const GetPersonsByTypes = async (
  page = 1,
  limit = 50,
  name = "",
  filters: Array<PersonTypes>,
) => {
  return await newBackendAPI.get(
    `/persons-by-type?page=${page}&limit=${limit}&name=${name}&filters=${JSON.stringify(
      filters ?? [],
    )}`,
  );
};

export const GetEmployees = async (
  page = 1,
  limit = 50,
  name = "",
  position = "",
  sector = "",
) => {
  return await newBackendAPI.get(
    `/employees?position=${position}&sector=${sector}&name=${name}&page=${page}&limit=${limit}`,
  );
};
