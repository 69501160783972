/* eslint-disable @typescript-eslint/no-explicit-any */
import {useSelector} from "react-redux";
import {Fragment} from "react";
import pxToRem from "hooks/usePxToRem";

import {
  Box,
  Chip,
  Collapse,
  Icon,
  ListItemButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {NavLink, useLocation} from "react-router-dom";

import SidebarDropdownMenu from "./SidebarDropdownMenu";

import StyledBox from "./SidebarNavItem.styles";
import {matchRoutes} from "react-router-config";
import routes from "routes";
import {retrieveBestMatchRouter} from "components/Sidebar/utils/retrieveBestMatchRouter";

interface SidebarNavItemProps {
  name: string;
  icon: string;
  route?: string;
  badgeText?: number;
  dropdownContent?: Array<{
    id?: number;
    name: string;
    icon: string;
    route: string;
  }>;
  currentDropdownExpanded?: string | null;
  onExpandedDropdownChange?: (arg: string | null) => void;
  isDestructive?: boolean;
  onClickRequest?: (event: any) => void;
  isActive?: boolean;
}

const SidebarNavItem = ({
  name,
  icon,
  route,
  badgeText,
  dropdownContent,
  currentDropdownExpanded,
  onExpandedDropdownChange,
  isDestructive,
  onClickRequest,
  isActive,
}: SidebarNavItemProps) => {
  const ifOnClickRequestPropIsNotTrueRenderAsNavLink = !onClickRequest && {
    component: NavLink,
    to: route ?? "",
  };

  const {
    userInfo: {
      settings: {expandedMenu},
      menu,
    },
  } = useSelector((state: any) => state.stateUserInfo);
  const theme = useTheme();
  const {pathname} = useLocation();

  const checkIfRouteMatch = (path: string) => {
    const currentRoute = retrieveBestMatchRouter(menu, path);
    const route = matchRoutes(routes, currentRoute.path);
    const currentRouteMatch = matchRoutes([route[1].route], pathname);
    return currentRouteMatch.length > 0;
  };
  return (
    <StyledBox
      as="li"
      props={{
        expandedMenu: !!expandedMenu,
        isDestructive: !!isDestructive,
        isActive: !!isActive,
      }}
    >
      <Tooltip
        title={!expandedMenu && name}
        placement="left"
        enterDelay={650}
        arrow
      >
        <ListItemButton
          className={`nav-item ${dropdownContent && "dropdown"}`}
          disableRipple
          onClick={onClickRequest}
          {...ifOnClickRequestPropIsNotTrueRenderAsNavLink}
        >
          <Stack direction="row" spacing={pxToRem(8)}>
            <Stack direction="row" alignItems="center" spacing={pxToRem(6)}>
              <Icon className="nav-item__icon">{icon}</Icon>

              <Typography
                className={`nav-item__text`}
                variant="subtitle2"
                component="span"
              >
                {name}
              </Typography>
            </Stack>

            {badgeText && (
              <Chip
                label={badgeText > 99 ? "99+" : badgeText}
                className="nav-item__badge"
                size="small"
              />
            )}
          </Stack>

          {dropdownContent && expandedMenu && (
            <Icon>
              {currentDropdownExpanded === name ? "expand_less" : "expand_more"}
            </Icon>
          )}
        </ListItemButton>
      </Tooltip>

      {dropdownContent && expandedMenu ? (
        <Collapse
          in={currentDropdownExpanded === name}
          timeout="auto"
          unmountOnExit
          className="nav-item__dropdown-content"
        >
          {dropdownContent.map((item: any) => {
            return (
              <Box key={item?.name} className="nav-item__dropdown-item">
                <ListItemButton
                  className="nav-item"
                  disableRipple
                  component={NavLink}
                  to={item.path ?? ""}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={pxToRem(6)}
                  >
                    <Icon
                      className="nav-item__icon"
                      sx={{
                        color:
                          checkIfRouteMatch(item.path) &&
                          (theme.palette.mode === "dark"
                            ? theme.palette.primary[600]
                            : theme.palette.primary[700]),
                      }}
                    >
                      {item?.icon}
                    </Icon>

                    <Typography
                      className="nav-item__text"
                      sx={{
                        color:
                          checkIfRouteMatch(item.path) &&
                          (theme.palette.mode === "dark"
                            ? theme.palette.primary[600]
                            : theme.palette.primary[700]),
                      }}
                      variant="subtitle2"
                      component="span"
                    >
                      {item.name}
                    </Typography>
                  </Stack>
                </ListItemButton>
              </Box>
            );
          })}
        </Collapse>
      ) : (
        <Fragment>
          {currentDropdownExpanded === name && (
            <SidebarDropdownMenu
              onExpandedDropdownChange={onExpandedDropdownChange}
              dropdownContent={dropdownContent}
            />
          )}
        </Fragment>
      )}
    </StyledBox>
  );
};

export default SidebarNavItem;
