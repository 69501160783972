import {ChangeEvent, Dispatch, SetStateAction, useState} from "react";
import pxToRem from "hooks/usePxToRem";

import {Icon, Stack, Tooltip, useTheme} from "@mui/material";

import {CustomInput} from "ui";

interface QuickSearchProps {
  searchString: string;
  setSearchString: Dispatch<
    SetStateAction<{onChangeValue: string; formattedValue: string}>
  >;
  placeholder?: string;
  fnHandleClearSearch?: () => void;
  fnOnKeyDown?: () => void;
}

const QuickSearch = (props: QuickSearchProps) => {
  const {
    searchString,
    setSearchString,
    fnHandleClearSearch,
    placeholder,
    fnOnKeyDown,
  } = props;
  const theme = useTheme();
  const [isInputFocused, setIsInputFocused] = useState(false);

  const clearSearch = () => {
    fnHandleClearSearch?.();
    setSearchString((previousState) => ({...previousState, onChangeValue: ""}));
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString((previousState) => ({
      ...previousState,
      onChangeValue: event.target.value,
    }));
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  return (
    <Tooltip title={!isInputFocused && "Pesquisa rápida"} arrow>
      <Stack
        className="Search"
        sx={{
          position: "relative",
          width: "auto",
          "&:hover": {backgroundColor: theme.palette.background.default},
        }}
      >
        <Stack
          className="SearchIconWrapper"
          direction={"row"}
          alignItems="center"
          justifyContent="center"
          sx={{
            padding: `${pxToRem(8)} ${pxToRem(5)} ${pxToRem(8)} ${pxToRem(8)}`,
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
          }}
        >
          <Icon>search</Icon>
        </Stack>
        <CustomInput
          placeholder={placeholder ?? "Pesquisa rápida"}
          iconEnd={searchString && "clear"}
          iconFontSize={pxToRem(23)}
          value={searchString}
          onChange={handleSearchChange}
          fnOnClick={clearSearch}
          fnOnKeyDown={fnOnKeyDown}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          sx={{
            height: pxToRem(47),

            "& .MuiInputBase-input": {
              width: searchString.length > 0 ? pxToRem(48) : pxToRem(1),
              transition: theme.transitions.create("width"),
              paddingLeft: pxToRem(40),
              paddingRight: 0,
              "&:focus": {width: pxToRem(300)},
            },
          }}
        />
      </Stack>
    </Tooltip>
  );
};

export default QuickSearch;
