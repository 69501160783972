import {useMemo} from "react";
import pxToRem from "hooks/usePxToRem";
import {ExternalContactData} from "../Contacts.types";

import {Chip, Stack, Tooltip} from "@mui/material";
import {MRT_ColumnDef} from "material-react-table";

const useExternalContacts = () => {
  const columnsData = useMemo<MRT_ColumnDef<ExternalContactData>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Nome",
      },

      {
        accessorKey: "email",
        header: "Email",
      },

      {
        accessorKey: "phoneNumber",
        header: "Telefone",
      },

      {
        accessorKey: "category",
        header: "Categoria",
        Cell: ({row: {original}}) => (
          <Chip label={original.category} size="small" color="primary" />
        ),
      },

      {
        accessorKey: "messageGroups",
        header: "Grupos de mensagem",
        minSize: 224,
        Cell: ({row: {original}}) => (
          <Stack direction="row" spacing={pxToRem(8)}>
            {original.messageGroups
              ?.slice(0, 2)
              ?.map((item) => (
                <Chip
                  key={item?.sendMessageGroup?.id}
                  label={item?.sendMessageGroup?.groupName}
                  size="small"
                  color="primary"
                />
              ))}
            {original.messageGroups?.length > 2 && (
              <Tooltip
                title={original.messageGroups?.slice(2).join(", ")}
                placement="bottom-end"
                arrow
              >
                <Chip
                  label={`+${original.messageGroups.length - 2}`}
                  color="secondary"
                  size="small"
                />
              </Tooltip>
            )}
          </Stack>
        ),
      },
    ],
    [],
  );

  return {columnsData};
};

export default useExternalContacts;
