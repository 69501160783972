import {Stack, useTheme} from "@mui/material";
import {MRT_Localization_PT_BR} from "material-react-table/locales/pt-BR";
import {
  MaterialReactTable,
  MRT_ToggleGlobalFilterButton,
  MRT_ToggleFiltersButton,
} from "material-react-table";

import pxToRem from "hooks/usePxToRem";

import {CustomIconButton} from "ui";
import {CsvTableDownloader} from "components";
import {
  ButtonData,
  CompanyManagementTableProps,
} from "./CompanyManagementTable.types";
import {Link} from "react-router-dom";
import tableActionColumnStyle from "util/tableActionColumnStyle";

const CompanyManagementTable = <T extends Record<string, unknown>>(
  props: CompanyManagementTableProps<T>,
) => {
  const theme = useTheme();

  const checkBackgroundAndTextColor = (row) => {
    if (row?.original?.status === props?.inactiveStatus) {
      return {
        background: theme.palette.error[300],
        text: "black",
      };
    } else {
      return {
        background: theme.palette.background.paper,
        text: theme.palette.text.primary,
      };
    }
  };

  return (
    <MaterialReactTable
      initialState={{
        density: "compact",
        expanded: true,
      }}
      state={{
        ...props?.state,
      }}
      muiLinearProgressProps={({isTopToolbar}) => ({
        sx: {
          display: isTopToolbar ? "block" : "none",
        },
      })}
      enableFullScreenToggle={false}
      enableDensityToggle={false}
      enableColumnResizing
      enableRowActions={!!props?.actionButtonsData}
      enableStickyFooter
      layoutMode="grid"
      enableStickyHeader
      enableHiding={false}
      positionActionsColumn="first"
      displayColumnDefOptions={tableActionColumnStyle(
        theme,
        props?.actionsColumnSize,
      )}
      renderRowActions={({row}) => (
        <Stack direction="row" justifyContent="center" alignItems="center">
          {props?.actionButtonsData?.map((buttonData: ButtonData<T>) => {
            return (
              <Link
                key={buttonData?.tooltipText}
                to={
                  buttonData?.redirection
                    ? buttonData?.redirection(row?.original)
                    : "#"
                }
              >
                <CustomIconButton
                  tooltipText={buttonData?.tooltipText}
                  tooltipPlacement="top"
                  iconName={buttonData?.iconName}
                  onClick={() =>
                    buttonData?.onClick && buttonData?.onClick(row?.original)
                  }
                />
              </Link>
            );
          })}
        </Stack>
      )}
      muiTableBodyRowProps={({row}) => ({
        sx: {
          hover: true,
          backgroundColor: checkBackgroundAndTextColor(row).background,
        },
      })}
      muiTableContainerProps={{
        sx: (theme) => ({
          flexGrow: 1,
          backgroundColor: theme.palette.background.paper,
        }),
      }}
      muiTablePaperProps={{
        elevation: 7,
        sx: {
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",
        },
      }}
      muiTableHeadCellProps={{
        sx: {
          backgroundColor: theme.palette.background.paper,
          fontSize: pxToRem(13),
          fontWeight: 600,
        },
      }}
      muiTableBodyCellProps={({row}) => ({
        sx: {
          fontSize: pxToRem(12),
          color: checkBackgroundAndTextColor(row).text,
        },
      })}
      localization={MRT_Localization_PT_BR}
      muiBottomToolbarProps={{
        sx: {backgroundColor: theme.palette.background.paper},
      }}
      muiTopToolbarProps={{
        sx: {
          backgroundColor: theme.palette.background.paper,
        },
      }}
      muiToolbarAlertBannerProps={{
        sx: {
          fontSize: ".8rem",
          backgroundColor: theme.palette.background.paper,
        },
      }}
      muiColumnActionsButtonProps={{size: "small"}}
      renderToolbarInternalActions={({table}) => (
        <>
          {!props.disableFilters && (
            <>
              <MRT_ToggleGlobalFilterButton table={table} />
              <MRT_ToggleFiltersButton table={table} />
            </>
          )}
          {props?.enableCsvDownload && <CsvTableDownloader table={table} />}
          {props?.renderNewToolbarAction}
          {props?.personalizedToolbarButton && (
            <Link
              to={
                props.personalizedToolbarButton?.redirection
                  ? props.personalizedToolbarButton?.redirection()
                  : "#"
              }
            >
              <CustomIconButton
                iconName={props?.personalizedToolbarButton?.iconName}
                tooltipText={props?.personalizedToolbarButton?.tooltipText}
                onClick={props?.personalizedToolbarButton?.onClickFn}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.background.default,

                  "&:hover": {
                    opacity: 0.8,
                  },
                }}
              />
            </Link>
          )}
        </>
      )}
      {...props}
    />
  );
};

export default CompanyManagementTable;
