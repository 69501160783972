import {
  Autocomplete,
  TextField,
  CircularProgress,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  TextFieldProps,
  TextFieldVariants,
  Tooltip,
  Typography,
} from "@mui/material";
import useMasks from "hooks/useMasks";
import pxToRem from "hooks/usePxToRem";
import React from "react";

type Props = Omit<AutocompleteProps<any, any, any, any, any>, "renderInput"> & {
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  getListByText?: (text: string) => Promise<void>;
  getNextPage?: () => Promise<void> | void;
  textFieldProps?: TextFieldProps;
  permanentPlaceholderSize?: number;
  InnerInput?: <Variant extends TextFieldVariants>(
    props: {
      variant?: Variant;
    } & Omit<TextFieldProps, "variant">,
  ) => JSX.Element;
  name?: string;
  tooltipText?: string;
  required?: boolean;
  optionSecondaryContent?: boolean;
  textFieldVariant?: "outlined" | "standard" | "filled";
};

// eslint-disable-next-line react/display-name
const CustomAutocomplete = React.forwardRef((props: Props, ref) => {
  const {
    getListByText = () => void 0,
    getNextPage = () => void 0,
    textFieldProps,
    permanentPlaceholderSize = 0.1,
    tooltipText,
    required = false,
    optionSecondaryContent,
    textFieldVariant = "outlined",
    ...rest
  } = props;

  const {cnpjMask} = useMasks();

  const percentPermanentPlaceholderSize = `${permanentPlaceholderSize * 8.33}%`;

  const onBottomScroll = async () => {
    getNextPage();
    return false;
  };

  const optionSecondaryText = (option) => {
    if (typeof option?.additional === "string") {
      return option?.additional;
    }
    if (option?.additional?.cnpj) {
      return `CNPJ: ${cnpjMask(option?.additional?.cnpj)}`;
    } else if (option?.additional?.vatNumber) {
      return `VAT Number: ${option?.additional?.vatNumber}`;
    }
    return "-";
  };

  return (
    <Tooltip title={tooltipText} placement="top" arrow>
      <Autocomplete
        ref={ref}
        sx={{...props.sx}}
        isOptionEqualToValue={(option, value) => option.id == value.id}
        // getOptionLabel={(option) => option.label ?? ""}
        freeSolo={false}
        loading={rest.loading}
        ListboxProps={{
          onScroll: (event: React.SyntheticEvent) => {
            const listboxNode = event.currentTarget;

            if (
              listboxNode.scrollTop + listboxNode.clientHeight >
              listboxNode.scrollHeight - 1
            ) {
              onBottomScroll();
            }
          },
        }}
        noOptionsText="Sem resultados"
        autoComplete
        onInputChange={(e, newInputValue) => {
          if (e?.type === "change") {
            getListByText(newInputValue);
          }
        }}
        {...rest}
        renderOption={(props, option, state) => {
          return (
            <li
              {...props}
              key={`${option?.id}.${state?.index}`}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography style={{fontSize: pxToRem(14)}}>
                {option.label}
              </Typography>
              {optionSecondaryContent && (
                <Typography style={{fontSize: pxToRem(12)}}>
                  {optionSecondaryText(option)}
                </Typography>
              )}
            </li>
          );
        }}
        renderInput={(params) =>
          //Params passados para o componente input que vem por props estão bem genéricos,
          // avaliar conforme o uso se vai ser necessário passar mais coisas
          rest.InnerInput ? (
            <rest.InnerInput {...params} {...textFieldProps} />
          ) : (
            <TextField
              {...params}
              required={required}
              size="small"
              placeholder={rest.placeholder}
              variant={textFieldVariant}
              slotProps={{
                input: {
                  style: {
                    fontSize: rest?.sx?.["fontSize"] ?? pxToRem(14),
                  },
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {rest.loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                },
              }}
              {...textFieldProps}
              sx={{
                fontSize: pxToRem(10),
                ".MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl":
                  {
                    paddingLeft: percentPermanentPlaceholderSize,
                  },
              }}
            />
          )
        }
      />
    </Tooltip>
  );
});

export default CustomAutocomplete;
