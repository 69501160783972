import {
  Box,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
} from "@mui/material";
import {CustomModal} from "components";
import {DefaultModalFooter} from "components/CustomModal/components";
import useAlertMessage from "hooks/useAlertMessage";
import useLoading from "hooks/useLoading";
import useToast from "hooks/useToast";
import {useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import {ChangeActivityObservationById} from "services/api_v2/ActivitiesHistory/activitiesHistory.service";
import {CustomButton} from "ui";

interface ModalProcessObservationsProps {
  activityData: {observations: string; processNumber: string; id: number};
  onClose: () => void;
  isOpen: boolean;
  getActivityData: (
    startDate?: string,
    endDate?: string,
    idClient?: number,
  ) => Promise<void>;
}

const ModalProcessObservations = (props: ModalProcessObservationsProps) => {
  const defaultValues = {
    observation: "",
  };

  const methods = useForm({defaultValues});

  const [showAlert] = useAlertMessage();
  const [showToast] = useToast();
  const [setLoading] = useLoading();

  const onSubmit = (data: {observation: string}) => {
    updateObservation(data.observation);
  };

  const handleRenderData = () => {
    methods.setValue("observation", props.activityData.observations);
  };
  useEffect(() => {
    handleRenderData();
  }, [props.activityData.observations]);

  const updateObservation = async (observation: string) => {
    try {
      setLoading(true);
      await ChangeActivityObservationById(props.activityData.id, observation);
      showToast("Observação alterada com sucesso!");
      props.getActivityData();
    } catch (error: any) {
      console.error(error);
      showAlert(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="Observações"
      titleIcon="info"
      maxWidth="xs"
      dividerBadge={props.activityData.processNumber}
      dataTestId={"modal-process-observations"}
    >
      <DialogContent>
        <Box
          component="form"
          onSubmit={methods.handleSubmit(onSubmit)}
          id="formObservation"
        >
          <Controller
            name="observation"
            control={methods.control}
            render={({field}) => (
              <TextField
                multiline
                rows={14}
                data-testid="observation-field"
                name="observation"
                placeholder="Insira as observações aqui"
                value={field.value}
                onChange={(e) => {
                  // Adiciona \n para os enters serem respeitados
                  const newValue = e.target.value.replace(/\r?\n/g, "\n");
                  field.onChange(newValue);
                }}
                sx={{overflowY: "auto", width: "100%"}}
              />
            )}
          />
        </Box>
      </DialogContent>
      <DefaultModalFooter>
        <DialogActions sx={{width: "100%"}}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            sx={{width: "100%"}}
          >
            <CustomButton
              variant="outlined"
              size="small"
              onClickFn={props.onClose}
              sx={{width: "33%"}}
            >
              Cancelar
            </CustomButton>
            <CustomButton
              form="formObservation"
              type="submit"
              size="small"
              onClickFn={props.onClose}
              sx={{width: "33%"}}
            >
              Confirmar
            </CustomButton>
          </Stack>
        </DialogActions>
      </DefaultModalFooter>
    </CustomModal>
  );
};

export default ModalProcessObservations;
