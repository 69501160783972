import {useMemo} from "react";
import {ResponsibleNextContactData} from "../Contacts.types";

import {Chip} from "@mui/material";
import {MRT_ColumnDef} from "material-react-table";

const useResponsibleNextContacts = () => {
  const responsibleNextContactsTableColumnsData = useMemo<
    MRT_ColumnDef<ResponsibleNextContactData>[]
  >(
    () => [
      {
        accessorKey: "name",
        header: "Nome",
        size: 165,
      },

      {
        accessorKey: "email",
        header: "Email",
        size: 205,
      },

      {
        accessorKey: "role",
        header: "Função",
        Cell: ({row: {original}}) => (
          <Chip label={original.role} size="small" color="primary" />
        ),
        size: 90,
      },

      {
        accessorKey: "phone",
        header: "Telefone",
        size: 90,
      },
    ],
    [],
  );

  return {responsibleNextContactsTableColumnsData};
};

export default useResponsibleNextContacts;
