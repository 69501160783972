/* eslint-disable @typescript-eslint/no-explicit-any */
import {AxiosResponse} from "axios";
import {newDataAPI} from "../apiConsumer";

export const SignIn = async (dto: any) => {
  return await newDataAPI.post("users/authenticate", dto);
};

export const GetAccessTokenByRefreshToken = async (
  refreshToken = "",
): Promise<AxiosResponse<{accessToken: string; refreshToken: string}>> => {
  return await newDataAPI.post("users/token", {refresh_token: refreshToken});
};

export const RequestPasswordReset = async (mail: string) => {
  return await newDataAPI.post("/users/password/reset", {email: mail});
};

export const RequestAccessSystem = async (mail: string) => {
  return await newDataAPI.post(
    "/email/requestaccess",
    {
      email: mail,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};
