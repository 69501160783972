/* eslint-disable @typescript-eslint/no-explicit-any */
import {Icon, Stack, Typography, useTheme} from "@mui/material";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {Types as tabPanelTypes} from "store/reducers/tabPanelReducer";
import {Types as chatTypes} from "store/reducers/chatReducer";
import {Types as openNotifyTypes} from "store/reducers/drawerNotify";
import pxToRem from "hooks/usePxToRem";
import {Dispatch, SetStateAction} from "react";
import {CustomButton} from "ui";

type NotificationCardType = {
  item: any;
  listNotify: any[];
  setListNotify: Dispatch<SetStateAction<any[]>>;
};

const NotificationCard = (props: NotificationCardType) => {
  const {item, listNotify, setListNotify} = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleOpenChat = () => {
    history.push("/chat");
    dispatch({
      type: tabPanelTypes.ALTER_PANEL,
      value: 1,
    });

    dispatch({
      type: chatTypes.ALTER_PROCESS_SELECTED,
      processSelected: {_id: item._id, processNumber: item.processNumber},
    });
    const temp = listNotify;
    const idx = temp.findIndex((obj: any) => obj._id === item._id);

    if (idx !== -1) {
      temp.splice(idx, 1);
    }
    setListNotify(temp);

    dispatch({
      type: openNotifyTypes.OPEN_NOTIFY,
    });
  };

  const handleOpenProcessDetails = () => {
    history.push(`/process-details/${item._id}`);

    dispatch({
      type: openNotifyTypes.OPEN_NOTIFY,
    });
  };

  return (
    <Stack
      alignItems={"center"}
      sx={{
        width: "100%",
        borderRadius: pxToRem(10),
        padding: pxToRem(16),
        backgroundColor: theme.palette.background.paper,
        gap: pxToRem(6),
      }}
    >
      <Stack
        direction={"row"}
        justifyContent="flex-start"
        sx={{gap: pxToRem(24), width: "100%"}}
      >
        <Icon sx={{color: theme.palette.primary.main}}>
          {item.type === "chat" ? "message" : "notifications"}
        </Icon>
        <Typography variant="h6" sx={{fontWeight: 900, fontSize: pxToRem(16)}}>
          {item.title}
        </Typography>
      </Stack>
      <Stack sx={{gap: pxToRem(8)}}>
        <Typography variant="body2" sx={{padding: `${pxToRem(6)} 0`}}>
          Nº do processo: {item.processNumber}
        </Typography>
        <Typography variant="body2">{item.content}</Typography>
        <Typography
          component="span"
          variant="body2"
          sx={{alignSelf: "flex-end", paddingBottom: pxToRem(4)}}
        >
          {item.moment}
        </Typography>
      </Stack>
      <CustomButton
        variant="outlined"
        onClickFn={
          item.type === "chat" ? handleOpenChat : handleOpenProcessDetails
        }
        sx={{width: "100%"}}
      >
        ver {item.type === "chat" ? "chat" : "processo"}
      </CustomButton>
    </Stack>
  );
};

export default NotificationCard;
