import {Chip} from "@mui/material";

const TableStatusCellChip = ({row: {original}}) => (
  <Chip
    label={original.updatedAt !== undefined ? "Enviado" : "Envio pendente"}
    size="small"
    color={original.updatedAt !== undefined ? "success" : "warning"}
  />
);

export default TableStatusCellChip;
