import {Theme} from "@mui/material";

const tableActionColumnStyle: (
  theme: Theme,
  size?: number,
  header?: string,
) => {
  "mrt-row-actions": {
    size: number;
    maxSize: number;
    header: string;
    muiTableHeadCellProps: any;
    muiTableBodyCellProps: any;
  };
} = (theme: Theme, size = 100, header = "Ações") => {
  return {
    "mrt-row-actions": {
      header: header,
      size: size,
      maxSize: size,
      muiTableHeadCellProps: {
        align: "center",
        sx: {
          boxShadow: "4px 0 8px 0 rgba(0, 0, 0, 0.1)",
          backgroundColor: theme.palette.background.paper,
        },
      },
      muiTableBodyCellProps: {
        align: "center",
        sx: {
          boxShadow: "4px 0 8px 0 rgba(0, 0, 0, 0.1)",
          backgroundColor: theme.palette.background.paper,
        },
      },
    },
  };
};

export default tableActionColumnStyle;
