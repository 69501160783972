import {Popper} from "@mui/material";
import {styled} from "@mui/system";

const StyledPopper = styled(Popper)(({theme}) => ({
  zIndex: 1,
  maxWidth: "375px",
  width: "100%",
  '&[data-popper-placement*="bottom"] .MuiPopper-arrow': {
    top: 0,
    left: 0,
    marginTop: "-0.9em",
    width: "3em",
    height: "1em",
    "&::before": {
      borderWidth: "0 1em 1em 1em",
      borderColor: `transparent transparent ${theme.palette?.background?.paper} transparent`,
    },
  },
  '&[data-popper-placement*="top"] .MuiPopper-arrow': {
    bottom: 0,
    left: 0,
    marginBottom: "-0.9em",
    width: "3em",
    height: "1em",
    "&::before": {
      borderWidth: "1em 1em 0 1em",
      borderColor: `${theme.palette?.background?.paper} transparent transparent transparent`,
    },
  },
  '&[data-popper-placement*="right"] .MuiPopper-arrow': {
    left: 0,
    marginLeft: "-0.9em",
    height: "3em",
    width: "1em",
    "&::before": {
      borderWidth: "1em 1em 1em 0",
      borderColor: `transparent ${theme.palette?.background?.paper} transparent transparent`,
    },
  },
  '&[data-popper-placement*="left"] .MuiPopper-arrow': {
    right: 0,
    marginRight: "-0.9em",
    height: "3em",
    width: "1em",
    "&::before": {
      borderWidth: "1em 0 1em 1em",
      borderColor: `transparent transparent transparent ${theme.palette?.background?.paper}`,
    },
  },
}));

export default StyledPopper;
