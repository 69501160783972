import {useDispatch, useSelector} from "react-redux";

import {SaveSettings, UserSettings} from "services/api_v2/User/user.service";
import {Types as userInfoTypes} from "store/reducers/userInfoReducer";
import useAlertMessage from "./useAlertMessage";

const useUserSettings = () => {
  const [showAlert] = useAlertMessage();

  const dispatch = useDispatch();
  const {userInfo} = useSelector((state: any) => state.stateUserInfo);

  const saveUserSettings = async (payload: UserSettings) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {...newUserSettings} = payload;

    const newUser_info = {
      userInfo: {
        ...userInfo,
        settings: {...userInfo?.settings, ...newUserSettings},
      },
    };

    dispatch({
      type: userInfoTypes.SET_USER_INFO,
      userInfo: {
        ...newUser_info.userInfo,
      },
    });

    try {
      await SaveSettings(newUserSettings);
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    }
  };

  return {saveUserSettings};
};

export default useUserSettings;
