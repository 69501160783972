import {Box, Tooltip, useTheme} from "@mui/material";
import {ConfirmModal, DefaultCardStructure} from "components";
import useAlertMessage from "hooks/useAlertMessage";
import pxToRem from "hooks/usePxToRem";
import useToast from "hooks/useToast";
import {MaterialReactTable, MRT_ColumnDef} from "material-react-table";
import {MRT_Localization_PT_BR} from "material-react-table/locales/pt-BR";
import {useMemo, useState} from "react";
import {useFormContext} from "react-hook-form";
import {UnlinkNcms} from "services/api_v2/Processes/Processes.service";
import {CustomIconButton} from "ui";
import tableActionColumnStyle from "util/tableActionColumnStyle";
import {
  ProcessBillLadingNcm,
  DefaultProcessDetails,
} from "views/ProcessDetails/Process.types";

interface GeneralLoadInfoProps {
  onRequestOpenModalAddNcm: () => void;
  refetchProcess: () => Promise<DefaultProcessDetails | undefined>;
  setNcmsTableData: React.Dispatch<
    React.SetStateAction<ProcessBillLadingNcm[]>
  >;
  ncmsTableData: ProcessBillLadingNcm[];
}
const NcmList = ({
  onRequestOpenModalAddNcm,
  refetchProcess,
  ncmsTableData,
  setNcmsTableData,
}: GeneralLoadInfoProps) => {
  const {watch} = useFormContext();
  const cancelledProcess = watch("agencyingSituation") === "Cancelado";
  const theme = useTheme();
  const [showAlert] = useAlertMessage();

  const columnsData = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "ncm.code",
        header: "NCM",
        size: 50,
      },
      {
        accessorKey: "ncm.description",
        header: "Descrição",
        Cell: ({cell, renderedCellValue}) => (
          <Tooltip title={cell.getValue()} arrow placement="top-start">
            <span>{renderedCellValue}</span>
          </Tooltip>
        ),
      },
      {
        accessorKey: "linkedTo",
        header: "Vínculo",
        size: 50,
      },
      {
        accessorKey: "linkedToItem",
        header: "Item",
        size: 50,
      },
    ],
    [],
  );
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [idBillLadingNcm, setIdBillLadingNcm] = useState(0);
  const [showToast] = useToast();

  const handleUnlinkNcm = async (idProcessBillLadingNcm: number) => {
    try {
      await UnlinkNcms(idProcessBillLadingNcm);
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    } finally {
      showToast("NCM desvinculado com sucesso!", "success");
      const result = await refetchProcess();
      setNcmsTableData(result?.processBillLadingNcms ?? []);
      handleCloseConfirmModal();
    }
  };

  const handleOpenConfirmationMessage = (idProcessBillLadingNcm: number) => {
    setIdBillLadingNcm(idProcessBillLadingNcm);
    setIsConfirmModalOpen(true);
  };

  const handleCloseConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  return (
    <DefaultCardStructure title="NCM's" hasDivider titleMarginBottom={5}>
      {isConfirmModalOpen && (
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          title="Desvincular NCM"
          subtitle="Tem certeza que deseja desvincular o NCM deste processo? Essa ação não pode ser revertida."
          confirmButtonFn={() => handleUnlinkNcm(idBillLadingNcm)}
          onClose={handleCloseConfirmModal}
        />
      )}

      <MaterialReactTable
        initialState={{
          density: "compact",
          columnPinning: {left: ["mrt-row-actions"]},
        }}
        columns={columnsData}
        data={ncmsTableData}
        localization={MRT_Localization_PT_BR}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableFilters={false}
        enableSorting={false}
        enableRowSelection={false}
        enableStickyHeader
        enableStickyFooter
        enableRowActions
        layoutMode="grid"
        enableColumnDragging
        enableColumnResizing
        enableColumnOrdering
        columnResizeMode="onEnd"
        positionToolbarAlertBanner="none"
        positionActionsColumn="first"
        renderToolbarInternalActions={() => (
          <CustomIconButton
            disabled={cancelledProcess}
            iconName="add_link"
            tooltipText="Vincular NCM"
            size="small"
            onClick={onRequestOpenModalAddNcm}
          />
        )}
        displayColumnDefOptions={tableActionColumnStyle(
          theme,
          50,
          "Desvincular",
        )}
        renderRowActions={({row}) => (
          <Box sx={{width: "100%", display: "flex", justifyContent: "center"}}>
            <CustomIconButton
              disabled={cancelledProcess}
              iconName="delete"
              onClick={() => handleOpenConfirmationMessage(row.original.id)}
            />
          </Box>
        )}
        muiTableContainerProps={{
          sx: (theme) => ({
            backgroundColor: theme.palette.background.paper,
            flex: 1,
            height: "auto",
          }),
        }}
        muiTablePaperProps={{
          elevation: 0,
          sx: (theme) => ({
            backgroundColor: theme.palette.background.paper,
          }),
        }}
        muiTopToolbarProps={{
          sx: (theme) => ({backgroundColor: theme.palette.background.paper}),
        }}
        muiBottomToolbarProps={{
          sx: (theme) => ({
            backgroundColor: theme.palette.background.paper,
            "& .MuiFormLabel-root": {
              position: "relative",
              color: "#575757",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 400,
              fontSize: "1rem",
              lineHeight: "1.4375em",
              padding: 0,
              display: "block",
              transformOrigin: "top left",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
              WebkitTransition:
                "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
              transition:
                "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
              marginBottom: "0px",
              transform: "unset",
            },
            "& .MuiInputBase-root": {
              marginTop: "unset",
            },
          }),
        }}
        muiTableHeadCellProps={{
          sx: (theme) => ({
            backgroundColor: theme.palette.background.paper,
          }),
        }}
        muiTableBodyCellProps={() => ({
          sx: (theme) => ({
            fontSize: pxToRem(14),
            color: theme.palette.text.secondary,
          }),
        })}
        muiTableBodyRowProps={{
          hover: false,
          sx: (theme) => ({
            backgroundColor: theme.palette.background.paper,
          }),
        }}
      />
    </DefaultCardStructure>
  );
};

export default NcmList;
