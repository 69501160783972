import {AutocompleteRenderGroupParams, Typography} from "@mui/material";
import {Box, useTheme} from "@mui/system";
import pxToRem from "hooks/usePxToRem";

type Props = {
  params: AutocompleteRenderGroupParams;
};

const RenderGroupedList = (props: Props) => {
  const theme = useTheme();
  return (
    <li key={props.params.key} style={{marginLeft: pxToRem(8)}}>
      <Box
        sx={{
          position: "sticky",
          top: pxToRem(-8),
          backgroundColor: theme.palette.grey[100],
          borderRadius: pxToRem(4),
          padding: `${pxToRem(5)} ${pxToRem(10)}`,
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: pxToRem(14),
          }}
        >
          {props.params.group}
        </Typography>
      </Box>

      <ul
        style={{
          paddingBottom: pxToRem(10),
          fontSize: pxToRem(14),
          textTransform: "capitalize",
        }}
      >
        {props.params.children}
      </ul>
    </li>
  );
};

export default RenderGroupedList;
