/* eslint-disable @typescript-eslint/no-explicit-any */
import {DialogActions} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {Dispatch, SetStateAction} from "react";
import {CustomButton} from "ui";

interface ModalActionsProps {
  versionUpdatesData: any[];
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  handleClose: () => void;
}

const ModalActions = (props: ModalActionsProps) => {
  const {page, setPage, versionUpdatesData, handleClose} = props;

  const handleNextPage = () => {
    if (page < versionUpdatesData.length) {
      setPage(page + 1);
    }
  };

  return (
    <DialogActions
      sx={{
        height: "12.5%",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
        padding: `${pxToRem(8)} ${pxToRem(20)}`,
      }}
    >
      <CustomButton
        variant="outlined"
        onClickFn={handleClose}
        disabled={page === versionUpdatesData.length}
        sx={{
          textTransform: "none",
          fontSize: pxToRem(16),
          lineHeight: pxToRem(24),
          width: pxToRem(111),
        }}
      >
        Pular
      </CustomButton>
      <CustomButton
        onClickFn={
          page === versionUpdatesData.length ? handleClose : handleNextPage
        }
        sx={{
          textTransform: "none",
          fontSize: pxToRem(16),
          lineHeight: pxToRem(24),
          width: pxToRem(111),
        }}
      >
        {page === versionUpdatesData.length ? "Pronto!" : "Próxima"}
      </CustomButton>
    </DialogActions>
  );
};

export default ModalActions;
