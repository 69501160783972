import {
  InputAdornment,
  SelectChangeEvent,
  Typography,
  useTheme,
  Tooltip,
  Box,
} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {ReactNode} from "react";
import {CustomSelect} from "ui";

interface SelecetWithPermanentPlaceholderProps {
  permanentPlaceholder: string;
  content: string[] | {name: string; id: string}[];
  value: string;
  onChange: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
  onOpen?: () => void;
  groupOptions?: ReactNode;
  name: string;
  disabled?: boolean;
  required?: boolean;
  tooltipOff?: boolean;
}

const SelectWithPermanentPlaceholder = (
  props: SelecetWithPermanentPlaceholderProps,
) => {
  const theme = useTheme();
  const {
    permanentPlaceholder,
    content,
    value = {id: "", value: ""},
    onChange,
    onOpen,
    groupOptions,
    name,
    disabled,
    required = true,
    tooltipOff,
  } = props;

  return (
    <Tooltip title={tooltipOff ? "" : value} arrow placement="top">
      <Box>
        <CustomSelect
          required={required}
          disabled={disabled}
          name={name}
          content={content}
          size="small"
          value={value}
          onChange={onChange}
          onOpen={onOpen}
          fullWidth
          groupOptions={groupOptions}
          textOnEmptySelect={
            <Typography sx={{fontSize: pxToRem(14), fontWeight: 400}}>
              Selecione
            </Typography>
          }
          startAdornment={
            <InputAdornment
              position="start"
              sx={{color: theme.palette.grey[500]}}
            >
              <Typography sx={{fontSize: pxToRem(14)}}>
                {permanentPlaceholder}
              </Typography>
            </InputAdornment>
          }
          sx={{fontSize: pxToRem(14), fontWeight: 500}}
        />
      </Box>
    </Tooltip>
  );
};

export default SelectWithPermanentPlaceholder;
