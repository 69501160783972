import {
  applyMiddleware,
  legacy_createStore as createStore,
  compose,
} from "redux";
import thunkMiddleware from "redux-thunk";
import {composeWithDevTools} from "@redux-devtools/extension";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./reducers";

import {persistStore, persistReducer, Persistor} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {loadingBarMiddleware} from "react-redux-loading-bar";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["stateUserInfo"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore(preloadedState = {}) {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [thunkMiddleware, sagaMiddleware, loadingBarMiddleware()]; // loggerMiddleware
  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares),
  );

  const composedEnhancers = compose(middlewareEnhancer);

  const store = createStore(
    persistedReducer,
    preloadedState,
    composedEnhancers,
  );

  const persistor: Persistor = persistStore(store);

  return {store, persistor};
}
