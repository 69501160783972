import React, {useState} from "react";
import {DialogActions, DialogContent, Grid} from "@mui/material";

import {CustomButton, CustomUploader} from "ui";
import {CustomModal, TypeDocumentAutocomplete} from "components";
import pxToRem from "hooks/usePxToRem";
import useLoading from "hooks/useLoading";
import useToast from "hooks/useToast";
import {PostProcessFile} from "services/api_v2/Processes/Processes.service";
import {useParams} from "react-router-dom";
import {FieldValues, UseFormSetValue, useFormContext} from "react-hook-form";
import useAlertMessage from "hooks/useAlertMessage";
import useTypeDocumentAutocomplete from "hooks/useTypeDocumentAutocomplete";
import {idOfCategories} from "views/ProcessDetails/utils/idOfCategories";

type ModalUploadProps = {
  open: boolean;
  handleCloseModal: () => void;
  newDocumentsBody: any;
  refetchFiles: (setRhfValues: UseFormSetValue<FieldValues>) => Promise<void>;
};

const UploadDocumentModal = (props: ModalUploadProps) => {
  const [showAlert] = useAlertMessage();
  const {open, handleCloseModal, refetchFiles} = props;
  const [setLoading] = useLoading();

  const [dataToSend, setDataToSend] = useState<{
    files?: File[];
    process?: {description: string; idGroupFile: number; idProcess: number};
  }>();

  const {id: processId} = useParams<{id: string}>();
  const {setValue, getValues} = useFormContext();

  const handleSetDocuments = async (acceptedFiles: File[], group: any) => {
    const isAddingFile = !!group;
    if (isAddingFile) {
      setDataToSend((prev) => ({
        ...prev,
        files: [...(prev?.files ?? []), ...acceptedFiles],
      }));
    } else {
      setDataToSend((prev) => ({
        ...prev,
        files: acceptedFiles,
      }));
    }
  };

  const handleModalCloseAndEffects = () => {
    handleCloseModal();
  };

  const [showToast] = useToast();

  const getInitialNumberOfDocument = (): number => {
    const findNameOfCategoryById = (idGroupFile: number): string => {
      let result = "otherDocuments";
      const categories = Object.keys(idOfCategories);
      for (const category of categories) {
        const findCategory = () => {
          return idOfCategories[category]?.some(
            (categoryId) => categoryId === idGroupFile,
          );
        };

        if (category !== "allDocuments" && findCategory()) {
          result = category;
        }
      }

      return result;
    };
    const fileNumber =
      props.newDocumentsBody[
        findNameOfCategoryById(dataToSend?.process?.idGroupFile ?? 0)
      ]?.length ?? 0;

    return fileNumber;
  };

  const handleAddDocument = async () => {
    if (!dataToSend?.process) {
      showToast("Escolha um tipo de arquivo", "warning");
      return;
    }
    if (!dataToSend.files) {
      showToast("Insira um arquivo para enviar", "warning");
      return;
    }

    for await (const processFile of dataToSend.files) {
      const formData = new FormData();

      formData.append("process", JSON.stringify(dataToSend?.process));
      formData.append("file", processFile);
      try {
        setLoading(true);
        await PostProcessFile(formData);
        showToast("Arquivo vinculado com sucesso");
      } catch (error: any) {
        showAlert(error?.response?.data?.message, "error");
        console.error(error);
      }
    }

    try {
      await refetchFiles(setValue);
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    } finally {
      setLoading(false);
      setDataToSend(undefined);
      handleModalCloseAndEffects();
    }
  };

  const [initialDocumentNumber, setInitialDocumentNumber] = useState(0);

  const handleChangeCategory = (
    _: React.SyntheticEvent<Element, Event>,
    value: {id: number; label: string},
  ) => {
    setDataToSend((prev) => ({
      ...prev,
      process: {
        description: value?.label,
        idGroupFile: value?.id,
        idProcess: +processId,
      },
    }));
    const idGroupFile = value?.id;
    const files = getValues("files");
    const numberOfFilesByGroupId = files?.filter(
      (file) => file?.idGroupFile === idGroupFile,
    )?.length;
    setInitialDocumentNumber(numberOfFilesByGroupId);
  };

  const {
    isLoadingOptions,
    pageState,
    documentOptions,
    getOptions,
    categorySearch,
    helperText,
    getFilteredCategory,
  } = useTypeDocumentAutocomplete();

  return (
    <CustomModal
      isOpen={open}
      onClose={handleCloseModal}
      title="Inserir novo documento"
      titleIcon="upload_file"
    >
      <DialogContent>
        <TypeDocumentAutocomplete
          isLoadingOptions={isLoadingOptions}
          handleChangeCategory={handleChangeCategory}
          documentOptions={documentOptions}
          getOptions={getOptions}
          pageState={pageState}
          getFilteredCategory={getFilteredCategory}
          helperText={helperText}
          categorySearch={categorySearch}
        />
        <Grid item xs={12}>
          <CustomUploader
            handleSetDocuments={handleSetDocuments}
            group={dataToSend?.process?.description}
            fileNameWithTextOverflowElipsis
            singleFile={false}
            customFileNamePrefix={dataToSend?.process?.description
              ?.toUpperCase()
              ?.trim()
              ?.replace(" ", "_")}
            disabled={!dataToSend}
            customFileNameStartsAt={initialDocumentNumber}
          />
        </Grid>
      </DialogContent>
      <DialogActions sx={{p: pxToRem(20)}}>
        <CustomButton variant="outlined" onClickFn={handleModalCloseAndEffects}>
          Cancelar
        </CustomButton>
        <CustomButton onClickFn={handleAddDocument}>Inserir</CustomButton>
      </DialogActions>
    </CustomModal>
  );
};

export default UploadDocumentModal;
