import {LegacyRef} from "react";
import {TextEditingButtons} from "./components";
import {Stack} from "@mui/material";
import pxToRem from "hooks/usePxToRem";

type EmailPreviewProps = {
  emailContent: string;
  editorRef: LegacyRef<HTMLDivElement>;
};

const EmailPreview = (props: EmailPreviewProps) => {
  const {emailContent, editorRef} = props;

  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      sx={{height: "100%", width: "100%"}}
    >
      <Stack
        alignItems={"center"}
        sx={{
          height: "100%",
          width: "100%",
          overflow: "auto",
          paddingRight: pxToRem(25),
        }}
      >
        <div
          style={{height: "auto", width: "auto"}}
          contentEditable
          dangerouslySetInnerHTML={{
            __html: emailContent,
          }}
          ref={editorRef as LegacyRef<HTMLDivElement> | undefined}
        />
      </Stack>
      <TextEditingButtons />
    </Stack>
  );
};

export default EmailPreview;
