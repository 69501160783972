/* eslint-disable @typescript-eslint/no-explicit-any */
import {ExpandMore} from "@mui/icons-material";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import {FormikErrors} from "formik";
import pxToRem from "hooks/usePxToRem";
import React, {ReactNode} from "react";

export type SelectProps = {
  content:
    | string[]
    | {name: string; id: string | number; isDisabled?: boolean}[];
  value?: any;
  onChange?: (event: SelectChangeEvent<any>, child: React.ReactNode) => void;
  fullWidth?: boolean;
  required?: boolean;
  name?: string;
  size?: "medium" | "small";
  disabled?: boolean;
  helperText?:
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | string
    | null;
  error?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onOpen?: () => void;
  sx?: any;
  onFocus?: () => void;
  hasLimitedContentHeight?: boolean;
  textOnEmptySelect?: ReactNode;
  warning?: boolean;
  warningText?: string;
  label?: string;
  startAdornment?: ReactNode;
  groupOptions?: ReactNode;
  placeholderSize?: number;
  isLoading?: boolean;
  multiple?: boolean;
  onBlur?: any;
};

const CustomSelect = (props: SelectProps) => {
  const theme = useTheme();
  const {warningText = "Campo divergente", placeholderSize = 13} = props;
  return (
    <FormControl fullWidth={props.fullWidth}>
      {props.label && (
        <InputLabel
          id={props.label}
          size="small"
          shrink
          htmlFor={props.name}
          sx={(theme) => ({
            position: "relative",
            fontSize: props.sx?.fontSize ?? theme.typography.label1.fontSize,
            fontWeight: theme.typography.label1.fontWeight,
            textTransform: "capitalize",
            marginBottom: pxToRem(4),

            transform: "unset",
          })}
        >
          {props.label}
        </InputLabel>
      )}
      <Select
        IconComponent={(params) => (
          <ExpandMore
            {...params}
            sx={{
              color: theme.palette.grey[600],
              background: theme.palette.background.paper,
              borderRadius: pxToRem(5),
            }}
          />
        )}
        startAdornment={props?.startAdornment}
        onClick={props.onClick}
        value={props.value}
        size={props.size ?? "medium"}
        displayEmpty
        name={props.name}
        multiple={props.multiple}
        onBlur={props.onBlur}
        onChange={props.onChange}
        required={props.required}
        fullWidth={props.fullWidth}
        disabled={props.disabled}
        error={props.error}
        onOpen={props?.onOpen}
        onFocus={props.onFocus}
        labelId={props.label}
        native={!!props.groupOptions}
        renderValue={
          !props.value?.length
            ? () =>
                props.textOnEmptySelect ?? (
                  <Typography
                    component="em"
                    sx={{
                      fontSize: placeholderSize && pxToRem(placeholderSize),
                      opacity: 0.5,
                    }}
                  >
                    Selecione
                  </Typography>
                )
            : undefined
        }
        MenuProps={{
          PaperProps: {
            sx: {maxHeight: `${props.hasLimitedContentHeight && pxToRem(168)}`},
          },
        }}
        sx={{
          fontSize: pxToRem(14),
          ...props.sx,
          ".MuiSelect-select": {padding: props?.sx?.padding},
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: `${
              props.warning ? theme.palette.warning[500] : undefined
            }`,
            borderWidth: props.warning ? pxToRem(2) : undefined,
            padding: 0,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: `${
              props.warning ? theme.palette.warning[500] : undefined
            }`,
            borderWidth: props.warning ? pxToRem(2) : undefined,
          },
        }}
      >
        {props.groupOptions ? (
          <>
            <option value="" disabled>
              Selecione
            </option>
            {props.groupOptions}
          </>
        ) : (
          props.content?.map((item, index: number) => (
            <MenuItem
              key={typeof item === "string" ? index : item.id}
              value={typeof item === "string" ? item : item.id}
              sx={{
                fontSize: props.sx?.fontSize ? props.sx?.fontSize : "inherit",
              }}
              disabled={item.isDisabled}
            >
              {typeof item === "string" ? item : item.name}
            </MenuItem>
          ))
        )}
        {props.isLoading && (
          <MenuItem sx={{display: "flex", justifyContent: "center"}}>
            <CircularProgress color="primary" size={pxToRem(20)} />
          </MenuItem>
        )}
      </Select>

      {(props.error || props.warning) && (
        <FormHelperText
          sx={{
            m: pxToRem(3),
            color: theme.palette[props.error ? "error" : "warning"][700],
            display: "flex",
            alignItems: "center",
          }}
        >
          <Icon sx={{fontSize: pxToRem(20), mr: pxToRem(2)}}>info</Icon>
          <Typography variant="body3" component="span">
            {props.error && props.helperText}
            {props.warning && warningText}
          </Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomSelect;
