/* eslint-disable react-hooks/exhaustive-deps */
import {
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {MaterialReactTable} from "material-react-table";
import {useState, useEffect} from "react";
import {GetMessageGroups} from "services/api_v2/Tasks/tasks.service";
import useHandleFieldLists from "views/ProcessDetails/hooks/useHandleFieldLists";
import {useLinkExternalContacts} from "../../hooks";
import {VerticalListSkeleton} from "ui";
import useWindowDimensions from "hooks/useWindowDimensions";

type Props = {
  setSelectedContact: React.Dispatch<React.SetStateAction<number | undefined>>;
  selectedGroups: number[];
  setSelectedGroups: React.Dispatch<React.SetStateAction<number[]>>;
};

const ExternalContacts = (props: Props) => {
  const {setSelectedContact, selectedGroups, setSelectedGroups} = props;

  const {getContacts, pagination, contactsSearch, table, rowSelection} =
    useLinkExternalContacts();

  const [messageGroupOptions, setMessageGroupOptions] = useState<
    {label: string; id: number}[]
  >([]);

  const formatMessageGroupList = (data) => {
    return data.data?.map((client) => ({
      label: `${client?.groupName} - ${client?.id}`,
      id: client.id,
    }));
  };

  const {
    getOptions: getMessageGroupOptions,
    isLoadingOptions: isLoadingMessageGroups,
  } = useHandleFieldLists(
    (page: number, limit: number, search: string) =>
      GetMessageGroups({page, name: search, limit}),
    formatMessageGroupList,
  );

  const handleSelectGroups = (value: number) => () => {
    const currentIndex = selectedGroups.indexOf(value);
    const newChecked = [...selectedGroups];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedGroups(newChecked);
  };

  useEffect(() => {
    (async () => {
      await getMessageGroupOptions(setMessageGroupOptions, 1);
    })();
  }, []);

  useEffect(() => {
    const [contactId] = Object.keys(rowSelection);
    setSelectedContact(+contactId);
  }, [rowSelection]);

  useEffect(() => {
    (async function () {
      await getContacts();
    })();
  }, [pagination.pageIndex, pagination.pageSize, contactsSearch]);

  const {screenHeight} = useWindowDimensions();

  return (
    <>
      <Grid
        item
        xs={9}
        sx={{
          height: pxToRem(screenHeight < 800 ? 400 : 500),
          display: "flex",
          flexDirection: "column",
        }}
      >
        <MaterialReactTable table={table} />
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          maxHeight: pxToRem(screenHeight < 800 ? 400 : 500),
          overflowY: "auto",
        }}
      >
        <List
          sx={{width: "100%", maxWidth: 360, bgcolor: "background.paper"}}
          dense
          subheader={<ListSubheader>Grupos de mensagem</ListSubheader>}
        >
          {isLoadingMessageGroups && <VerticalListSkeleton />}

          {messageGroupOptions.map((value) => {
            const labelId = `checkbox-list-label-${value}`;

            return (
              <ListItem key={value.id} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={handleSelectGroups(value.id)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedGroups.indexOf(value.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{"aria-labelledby": labelId}}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${value.label}`} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Grid>
    </>
  );
};

export default ExternalContacts;
