import {FormHelperText, Icon, Typography, useTheme} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import React, {Fragment} from "react";
import ReactQuill, {Quill} from "react-quill";
import "react-quill/dist/quill.snow.css";

type ToolbarProp =
  | string[]
  | {
      [key: string]:
        | string
        | Array<string | number | boolean>
        | never[]
        | undefined;
    }[];

type Props = {
  value: string;
  onChange: (text: string) => void;
  placeholder?: string;
  editorStyles?: React.CSSProperties;
  toolbar?: ToolbarProp[];
  error?: boolean;
  helperText?: string;
  editorRef?: React.MutableRefObject<any>;
  defValue?: string;
};

const DefaultTextEditor = (props: Props) => {
  const {
    value,
    onChange,
    editorStyles,
    toolbar,
    placeholder = "...",
    editorRef,
    defValue,
  } = props;

  const editorSizeStyle = Quill.import("attributors/style/size");
  editorSizeStyle.whitelist = ["10px", "12px", "14px", "24px"];
  Quill.register(editorSizeStyle, true);

  const textEditorStyles = {
    height: pxToRem(120),
    width: "100%",
    ...editorStyles,
  };
  const theme = useTheme();
  const modules = {
    toolbar: toolbar ?? [
      ["bold", "italic", "underline", "strike"],
      [{list: "ordered"}, {list: "bullet"}],
      [{size: ["10px", "12px", "14px", "24px"]}],
      [{header: [1, 2, 3, 4, 5, 6, false]}],
      [{color: []}, {background: []}],
      ["link"],
      ["clean"],
    ],
  };

  return (
    <Fragment>
      <ReactQuill
        theme="snow"
        placeholder={placeholder}
        value={value}
        defaultValue={defValue}
        onChange={onChange}
        modules={modules}
        style={textEditorStyles}
        ref={editorRef}
      />
      {props.error && (
        <FormHelperText
          sx={{
            m: pxToRem(3),
            mt: pxToRem(45),
            color: theme.palette[props.error ? "error" : "warning"][700],
            display: "flex",
            alignItems: "center",
          }}
        >
          <Icon sx={{fontSize: pxToRem(20), mr: pxToRem(2)}}>info</Icon>
          <Typography variant="body3" component="span">
            {props.error && props.helperText}
          </Typography>
        </FormHelperText>
      )}
    </Fragment>
  );
};

export default DefaultTextEditor;
