import pxToRem from "hooks/usePxToRem";

import {Grid} from "@mui/material";

import {CustomInput, CustomInputWrapper} from "ui";
import {DefaultCardStructure} from "components";
import {Controller, useFormContext} from "react-hook-form";

const Payment = () => {
  // const {values, handleChange} = useFormikContext<ValuesStoredInFormik>();
  const {control} = useFormContext();

  return (
    <DefaultCardStructure title="Pagamento" hasDivider={false} isSubcard>
      <Grid container rowSpacing={pxToRem(20)} columnSpacing={pxToRem(8)}>
        <CustomInputWrapper md={5} title="Tipo Pagamento" isDisabled>
          <Controller
            name="charges.typePayment"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="charges.typePayment"
                value={value}
                placeholder="Forma de Cobrança"
                onChange={onChange}
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={2} title="Quantidade" isDisabled>
          <Controller
            name="charges.quantityPayment"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="charges.quantityPayment"
                type="text"
                placeholder="Quantidade"
                value={value}
                onChange={onChange}
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={5} title="Pagar Para" isDisabled>
          <Controller
            name="charges.payTo"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="charges.payTo"
                value={value}
                placeholder="Pagar para"
                onChange={onChange}
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={12} title="Pessoa" isDisabled>
          <Controller
            name="charges.paymentPerson"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="charges.paymentPerson"
                type="text"
                placeholder="Pessoa"
                value={value?.name}
                onChange={onChange}
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={2.4} title="Moeda" isDisabled>
          <Controller
            name="charges.paymentCurrency"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="charges.paymentCurrency"
                value={value}
                placeholder="Moeda"
                onChange={onChange}
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={2.1} title="Valor Unitário" isDisabled>
          <Controller
            name="charges.valuePaymentUnit"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="charges.valuePaymentUnit"
                type="text"
                placeholder="Valor un."
                value={value}
                onChange={onChange}
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={2.1} title="Valor Mínimo" isDisabled>
          <Controller
            name="charges.valuePaymentMinimum"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="charges.valuePaymentMinimum"
                type="text"
                placeholder="Valor min."
                value={value}
                onChange={onChange}
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={2.1} title="Valor Total" isDisabled>
          <Controller
            name="charges.valuePaymentTotal"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="charges.valuePaymentTotal"
                type="text"
                placeholder="Valor tot."
                value={value}
                onChange={onChange}
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>

        <CustomInputWrapper md={3.3} title="Modalidade" isDisabled>
          <Controller
            name="charges.modalityPayment"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="charges.modalityPayment"
                value={value}
                placeholder="Modalidade"
                onChange={onChange}
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>

        <CustomInputWrapper title="Faturamento" md={4.5} isDisabled>
          <Controller
            name="charges.billingPayment"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="charges.billingPayment"
                value={value}
                placeholder="Faturamento"
                onChange={onChange}
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>
      </Grid>
    </DefaultCardStructure>
  );
};

export default Payment;
