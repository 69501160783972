import {newBackendAPI} from "../../apiConsumer";

export type UserSettings = {
  acceptPrivacy?: boolean;
  darkMode?: boolean;
  expandedMenu?: boolean;
  showNotifications?: boolean;
};

export interface VersionByUserTypes {
  idPerson: string | number;
  idVersionUpdate: number[];
}

export interface UserPermission {
  idPerson: string | number;
  menus: {
    id: number;
    permissions: {
      view: boolean;
      edit: boolean;
      include: boolean;
      exclude: boolean;
    };
  }[];
}

export const SaveSettings = async (newSetting: UserSettings) => {
  return await newBackendAPI.post("/save-settings", newSetting);
};

export const VersionByUser = async (versionByUserData: VersionByUserTypes) => {
  return await newBackendAPI.post("/version-by-user", versionByUserData);
};

export const AllUsersWithPermission = async () => {
  return await newBackendAPI.get("/menu-users-permissions");
};

export const UserWithPermission = async (id: string | undefined) => {
  return await newBackendAPI.get(`/menu-users-permissions/${id}`);
};

export const NewUserPermission = async (data: UserPermission) => {
  return await newBackendAPI.post("menu-users", data);
};

export const UpdateUserPermission = async (data: UserPermission) => {
  return await newBackendAPI.patch("menu-users", data);
};

export const DashboardActivitiesByUser = async (
  startDate?: string,
  endDate?: string,
  status?: string,
  idClient?: number,
  name?: string,
  processNumber?: string,
) => {
  const params = {
    startDate,
    endDate,
    idClient: idClient === undefined ? idClient : `[${idClient}]`,
    name,
    processNumber,
  };
  return await newBackendAPI.get(`/activities`, {params});
};

export const ActivityDataByUser = async (
  activityId: number | string,
  userId: number | string,
  activityStatus = "",
  page = 1,
  limit = 20,
  startDate?: string,
  endDate?: string,
  idClient?: number,
  processNumber?: string,
) => {
  const params = {
    activityStatus,
    page,
    limit,
    startDate,
    endDate,
    idClient: idClient === undefined ? idClient : `[${idClient}]`,
    processNumber,
  };
  return await newBackendAPI.get(`activities/${activityId}`, {
    params,
    headers: {"x-user-id": userId},
  });
};

export const GetUserPreferencesAndMenu = async () => {
  return await newBackendAPI.get(`/menu-itens`);
};

//o endpoint dessa requisição ainda vai mudar para /employees
export const GetEmployees = async (
  page: number,
  name?: string,
  limit?: number,
) => {
  const params = {
    filters: `["employee"]`,
    page,
    name,
    limit,
  };

  return await newBackendAPI.get(`/persons-by-type`, {params});
};

export const GetOnlyEmployees = async ({
  position,
  sector,
  name,
  page,
  limit,
}: {
  position?: string;
  sector?: string;
  name?: string;
  page?: number;
  limit?: number;
}) => {
  const params = {position, sector, name, page, limit};

  return await newBackendAPI.get(`/employees`, {params});
};
