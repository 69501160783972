import useLoading from "hooks/useLoading";
import useToast from "hooks/useToast";
import {
  PatchFollowUp,
  PostFollowUp,
} from "services/api_v2/Processes/Processes.service";
import {PostFollowUpType} from "./ProcessFollowUpTypes";
import useAlertMessage from "hooks/useAlertMessage";

const ProcessFollowUpData = () => {
  const [showAlert] = useAlertMessage();
  const [setLoading] = useLoading();
  const [showToast] = useToast();

  const postFollowUp = async (followUpData: PostFollowUpType) => {
    try {
      setLoading(true);
      await PostFollowUp(followUpData);
      showToast("Follow-up adicionado com sucesso!", "success");
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateFollowUp = async (
    followUpId: number,
    followUpData: Partial<PostFollowUpType>,
  ) => {
    try {
      setLoading(true);
      await PatchFollowUp(followUpId, followUpData);
      showToast("Follow-up adicionado com sucesso!", "success");
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    postFollowUp,
    updateFollowUp,
  };
};

export default ProcessFollowUpData;
