import pxToRem from "hooks/usePxToRem";

import {Grid} from "@mui/material";
("date-fns/locale/de");

import {
  CustomAutoComplete,
  CustomAutocomplete,
  CustomInput,
  CustomInputWrapper,
  CustomSelect,
} from "ui";
import {useState} from "react";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";
import useHandlePaginatedOptions from "views/ProcessDetails/hooks/useHandlePaginatedOptions";
import {ConfirmModal} from "components";
import {Controller, useFormContext} from "react-hook-form";
import useChangeDAteAndAutocomplete from "views/ProcessDetails/hooks/useChangeDAteAndAutocomplete";
import {
  GetEmployees,
  GetPersonsByTypes,
  PersonTypes,
} from "services/api_v2/Persons/Persons.service";
import useHandleFieldLists from "views/ProcessDetails/hooks/useHandleFieldLists";
import {AgencyingSituation, ReferredBy, ServiceType} from "util/globalEnums";
import {
  GetAllTypes,
  GetCurrenciesList,
  GetLogisticAgency,
  GetTransportCompaniesList,
} from "services/api_v2/Processes/Processes.service";
import {getTooltipText} from "views/ProcessDetails/utils/getTooltipText";
import useValueOrEmpty from "hooks/useValueOrEmpty";
import ProcessDatePicker from "views/ProcessDetails/components/ProcessDatePicker";
import {CorrespondenceModal, HelperIcon} from "components/HelperIconAndModal";

const Transactioners = () => {
  const [shipperOptions, setShipperOptions] = useState<AutoCompleteData[]>(
    [] as AutoCompleteData[],
  );

  const [consigneeOptions, setConsigneeOptions] = useState<AutoCompleteData[]>(
    [] as AutoCompleteData[],
  );

  const [notifyOptions, setNotifyOptions] = useState<AutoCompleteData[]>(
    [] as AutoCompleteData[],
  );

  const [brokerOptions, setBrokerOptions] = useState<AutoCompleteData[]>(
    [] as AutoCompleteData[],
  );
  const [representativeOptions, setRepresentativeOptions] = useState<
    AutoCompleteData[]
  >([] as AutoCompleteData[]);

  const [representativeFinalOptions, setRepresentativeFinalOptions] = useState<
    AutoCompleteData[]
  >([] as AutoCompleteData[]);

  const [sellerOptions, setSellerOptions] = useState<AutoCompleteData[]>(
    [] as AutoCompleteData[],
  );
  const [originAgentOptions, setOriginAgentOptions] = useState<
    AutoCompleteData[]
  >([] as AutoCompleteData[]);

  const [transportCompanyOptions, setTransportCompanyOptions] = useState<
    AutoCompleteData[]
  >([] as AutoCompleteData[]);

  const [currenciesOptions, setCurrenciesOptions] = useState<
    AutoCompleteData[]
  >([] as AutoCompleteData[]);

  const [logisticAgencyOptions, setLogisticAgencyOptions] = useState<
    AutoCompleteData[]
  >([] as AutoCompleteData[]);

  const [typeProcessOptions, setTypeProcessOptions] = useState<
    AutoCompleteData[]
  >([] as AutoCompleteData[]);

  const [indicationOptions, setIndicationOptions] = useState<
    AutoCompleteData[]
  >([] as AutoCompleteData[]);

  const {control} = useFormContext();
  const {valueOrEmptyString} = useValueOrEmpty();

  const {handleChangeAutocomplete, mountOptions} =
    useChangeDAteAndAutocomplete();

  const {
    getOptions: getShipperOptions,
    onBottomScroll: onBottomScrollShipper,
    isLoadingOptions: isLoadingShipperOptions,
  } = useHandlePaginatedOptions();

  const {
    getOptions: getConsigneeOptions,
    onBottomScroll: onBottomScrollConsignee,
    isLoadingOptions: isLoadingConsigneeOptions,
  } = useHandlePaginatedOptions();
  const {
    getOptions: getNotifyOptions,
    onBottomScroll: onBottomScrollNotify,
    isLoadingOptions: isLoadingNotigyOptions,
  } = useHandlePaginatedOptions();

  const [insuranceAlertModalIsOpen, setInsuranceAlertModalIsOpen] =
    useState(false);

  const handleToggleInsuranceAlertModal = () => {
    setInsuranceAlertModalIsOpen(!insuranceAlertModalIsOpen);
  };

  const [searchFields, setSearchFields] = useState({
    shipper: "",
    consignee: "",
    notify: "",
    broker: "",
    representative: "",
    finalRepresentative: "",
    originAgent: "",
    cargoAgent: "",
    seller: "",
    carrierOrigin: "",
    carrierDestination: "",
    currencies: "",
    logisticAgency: "",
    typeProcess: "",
    indicationOptions: "",
  });

  const {
    getOptions: getBrokerOptions,
    onBottomScroll: onBottomScrollBroker,
    isLoadingOptions: isLoadingBrokerOptions,
  } = useHandleFieldLists(GetPersonsByTypes, null, [PersonTypes.customBroker]);

  const {
    getOptions: getRepresentativeOptions,
    onBottomScroll: onBottomScrollRepresentative,
    isLoadingOptions: isLoadingRepresentativeOptions,
  } = useHandleFieldLists(GetPersonsByTypes, null, [
    PersonTypes.representative,
  ]);

  const {
    getOptions: getRepresentativeFinalOptions,
    onBottomScroll: onBottomScrollRepresentativeFinal,
    isLoadingOptions: isLoadingRepresentativeFinalOptions,
  } = useHandleFieldLists(GetPersonsByTypes, null, [
    PersonTypes.representative,
  ]);

  const {
    getOptions: getAgentOptions,
    onBottomScroll: onBottomScrollAgent,
    isLoadingOptions: isLoadingAgentOptions,
  } = useHandleFieldLists(GetPersonsByTypes, null, [PersonTypes.cargoAgent]);

  const formatOp = (data: any) => {
    return data?.data?.flatMap((item: any) => ({
      label: `${item?.person?.name} - ${item?.person?.id}`,
      id: item?.person?.id,
    }));
  };

  const formatLogisticAgency = (data: any) => {
    return data?.data?.map((item: any) => ({
      label: `${item?.person?.name} - ${item?.person?.id}`,
      id: item?.person?.id,
    }));
  };

  const formatTypeProcess = (data: any) => {
    return data?.data?.map((item: any) => ({
      label: `${item?.name} - ${item?.id}`,
      id: item?.id,
    }));
  };

  const {
    getOptions: getLogisticAgencyOptions,
    onBottomScroll: onBottomScrollLogisticAgency,
    isLoadingOptions: isLoadingLogisticAgencyOptions,
  } = useHandleFieldLists(GetLogisticAgency, formatLogisticAgency);

  const {
    getOptions: getSellerOptions,
    onBottomScroll: onBottomScrollSeller,
    isLoadingOptions: isLoadingSellerOptions,
  } = useHandleFieldLists(GetEmployees, formatOp);

  const {
    getOptions: getIndicationOptions,
    onBottomScroll: onBottomScrollIndication,
    isLoadingOptions: isLoadingIndicationOptions,
  } = useHandleFieldLists(GetEmployees, formatOp);

  const {
    getOptions: getCarrierOriginOptions,
    onBottomScroll: onBottomScrollCarrierOrigin,
    isLoadingOptions: isLoadingCarrierOriginOptions,
  } = useHandleFieldLists(GetTransportCompaniesList, formatOp);

  const {
    getOptions: getCarrierDestinationOptions,
    onBottomScroll: onBottomScrollCarrierDestination,
    isLoadingOptions: isLoadingCarrierDestinationOptions,
  } = useHandleFieldLists(GetTransportCompaniesList, formatOp);

  const {
    getOptions: getCurrenciesOptions,
    onBottomScroll: onBottomScrollCurrencies,
    isLoadingOptions: isLoadingCurrenciesOptions,
    pageState: currenciesPageState,
  } = useHandleFieldLists(GetCurrenciesList);

  const {
    getOptions: getTypeProcessOptions,
    onBottomScroll: onBottomScrollTypeProcess,
    isLoadingOptions: isLoadingTypeProcessOptions,
  } = useHandleFieldLists(GetAllTypes, formatTypeProcess);

  const [correspondenceModalIsOpen, setCorrespondenceModalIsOpen] =
    useState(false);
  const [correspondenceModalContent, setCorrespondenceModalContent] =
    useState("");
  const [correspondenceModalTitle, setCorrespondenceModalTitle] = useState("");

  const handleOpenHelperModal = (content: string, title: string) => {
    setCorrespondenceModalTitle(title);
    setCorrespondenceModalContent(content);
    setCorrespondenceModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setCorrespondenceModalIsOpen(false);
    setCorrespondenceModalContent("");
  };

  return (
    <>
      <CorrespondenceModal
        correspondenceText={correspondenceModalContent}
        isOpen={correspondenceModalIsOpen}
        onClose={handleCloseModal}
        correspondenceTitle={correspondenceModalTitle}
      />
      <Grid container columnSpacing={pxToRem(8)} rowSpacing={pxToRem(20)}>
        <Controller
          name="shipper"
          control={control}
          render={({field: {value}}) => (
            <CustomInputWrapper
              title="Exportador"
              xs={3}
              sx={{position: "relative"}}
              helper={
                <HelperIcon
                  onClick={() =>
                    handleOpenHelperModal(
                      valueOrEmptyString(value.correspondence),
                      "Exportador",
                    )
                  }
                />
              }
            >
              <CustomAutocomplete
                name="shipper"
                placeholder="Exportador"
                tooltipText={getTooltipText(value?.name)}
                loading={isLoadingShipperOptions}
                options={mountOptions(value, shipperOptions)}
                value={valueOrEmptyString(value?.name)}
                onOpen={
                  shipperOptions.length > 0
                    ? undefined
                    : () =>
                        getShipperOptions({isShipper: true}, setShipperOptions)
                }
                optionSecondaryContent
                onInputChange={(e, value) => {
                  if (e !== null) {
                    setSearchFields((prev) => ({...prev, shipper: value}));
                    getShipperOptions(
                      {isShipper: true, clientName: value},
                      setShipperOptions,
                    );
                  }
                }}
                getNextPage={() => {
                  onBottomScrollShipper(
                    {isShipper: true, clientName: searchFields.shipper},
                    setShipperOptions,
                  );
                }}
                onChange={(e, value) => {
                  handleChangeAutocomplete(e, value, "shipper");
                }}
                isOptionEqualToValue={(option, value) =>
                  value === option?.label
                }
              />
            </CustomInputWrapper>
          )}
        />
        <Controller
          name="consignee"
          control={control}
          render={({field: {value}}) => (
            <CustomInputWrapper
              title="Importador"
              xs={3}
              helper={
                <HelperIcon
                  onClick={() =>
                    handleOpenHelperModal(
                      valueOrEmptyString(value.correspondence),
                      "Importador",
                    )
                  }
                />
              }
            >
              <CustomAutocomplete
                name="consignee"
                placeholder="Importador"
                tooltipText={getTooltipText(value?.name)}
                loading={isLoadingConsigneeOptions}
                options={mountOptions(value, consigneeOptions)}
                value={valueOrEmptyString(value?.name)}
                optionSecondaryContent
                onOpen={
                  consigneeOptions.length > 0
                    ? undefined
                    : () =>
                        getConsigneeOptions(
                          {isConsignee: true},
                          setConsigneeOptions,
                        )
                }
                onInputChange={(e, value) => {
                  if (e !== null) {
                    setSearchFields((prev) => ({...prev, consignee: value}));
                    getConsigneeOptions(
                      {isConsignee: true, clientName: value},
                      setConsigneeOptions,
                    );
                  }
                }}
                getNextPage={() => {
                  onBottomScrollConsignee(
                    {isConsignee: true, clientName: searchFields.consignee},
                    setConsigneeOptions,
                  );
                }}
                onChange={(e, value) => {
                  handleChangeAutocomplete(e, value, "consignee");
                }}
                isOptionEqualToValue={(option, value) =>
                  value === option?.label
                }
              />
            </CustomInputWrapper>
          )}
        />
        <Controller
          name="notify"
          control={control}
          render={({field: {value}}) => (
            <CustomInputWrapper
              title="Notify"
              xs={3}
              helper={
                <HelperIcon
                  onClick={() =>
                    handleOpenHelperModal(
                      valueOrEmptyString(value.correspondence),
                      "Notify",
                    )
                  }
                />
              }
            >
              <CustomAutocomplete
                name="notify"
                placeholder="Notify"
                tooltipText={getTooltipText(value?.name)}
                loading={isLoadingNotigyOptions}
                options={mountOptions(value, notifyOptions)}
                value={valueOrEmptyString(value?.name)}
                optionSecondaryContent
                onOpen={
                  notifyOptions.length > 0
                    ? undefined
                    : () => getNotifyOptions({isNotify: true}, setNotifyOptions)
                }
                onInputChange={(e, value) => {
                  if (e !== null) {
                    setSearchFields((prev) => ({...prev, notify: value}));
                    getNotifyOptions(
                      {isNotify: true, clientName: value},
                      setNotifyOptions,
                    );
                  }
                }}
                getNextPage={() => {
                  onBottomScrollNotify(
                    {isNotify: true, clientName: searchFields.notify},
                    setNotifyOptions,
                  );
                }}
                onChange={(e, value) => {
                  handleChangeAutocomplete(e, value, "notify");
                }}
                isOptionEqualToValue={(option, value) =>
                  value === option?.label
                }
              />
            </CustomInputWrapper>
          )}
        />
        <CustomInputWrapper title="Despachante" xs={3}>
          <Controller
            name="customsBroker"
            control={control}
            render={({field: {value}}) => (
              <CustomAutocomplete
                name="customsBroker"
                placeholder="Despachante"
                tooltipText={getTooltipText(value?.name)}
                loading={isLoadingBrokerOptions}
                options={mountOptions(value, brokerOptions)}
                value={valueOrEmptyString(value?.name)}
                optionSecondaryContent
                onOpen={
                  brokerOptions.length > 0
                    ? undefined
                    : () => getBrokerOptions(setBrokerOptions)
                }
                onInputChange={(e, value) => {
                  if (e !== null) {
                    setSearchFields((prev) => ({...prev, broker: value}));
                    getBrokerOptions(setBrokerOptions, 1, value);
                  }
                }}
                getNextPage={() => {
                  onBottomScrollBroker(setBrokerOptions, searchFields.broker);
                }}
                onChange={(e, value) => {
                  handleChangeAutocomplete(e, value, "customsBroker");
                }}
                isOptionEqualToValue={(option, value) =>
                  value === option?.label
                }
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Representante" xs={3}>
          <Controller
            name="representative"
            control={control}
            render={({field: {value}}) => (
              <CustomAutocomplete
                name="representative"
                placeholder="Representante"
                tooltipText={getTooltipText(value?.name)}
                loading={isLoadingRepresentativeOptions}
                options={mountOptions(value, representativeOptions)}
                value={valueOrEmptyString(value?.name)}
                optionSecondaryContent
                onOpen={
                  representativeOptions.length > 0
                    ? undefined
                    : () => getRepresentativeOptions(setRepresentativeOptions)
                }
                onInputChange={(e, value) => {
                  if (e !== null) {
                    setSearchFields((prev) => ({
                      ...prev,
                      representative: value,
                    }));
                    getRepresentativeOptions(setRepresentativeOptions);
                  }
                }}
                getNextPage={() => {
                  onBottomScrollRepresentative(
                    setRepresentativeOptions,
                    searchFields.representative,
                  );
                }}
                onChange={(e, value) =>
                  handleChangeAutocomplete(e, value, "representative")
                }
                isOptionEqualToValue={(option, value) =>
                  value === option?.label
                }
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Representante destino final" xs={3}>
          <Controller
            name="representativeFinalDestination"
            control={control}
            render={({field: {value}}) => (
              <CustomAutocomplete
                name="representativeFinalDestination"
                placeholder="Repres. Destino Final"
                tooltipText={getTooltipText(value?.name)}
                loading={isLoadingRepresentativeFinalOptions}
                options={mountOptions(value, representativeFinalOptions)}
                value={valueOrEmptyString(value?.name)}
                optionSecondaryContent
                onOpen={
                  representativeFinalOptions.length > 0
                    ? undefined
                    : () =>
                        getRepresentativeFinalOptions(
                          setRepresentativeFinalOptions,
                        )
                }
                onInputChange={(e, value) => {
                  if (e !== null) {
                    setSearchFields((prev) => ({
                      ...prev,
                      finalRepresentative: value,
                    }));
                    getRepresentativeFinalOptions(
                      setRepresentativeFinalOptions,
                      1,
                      value,
                    );
                  }
                }}
                getNextPage={() => {
                  onBottomScrollRepresentativeFinal(
                    setRepresentativeFinalOptions,
                    searchFields.finalRepresentative,
                  );
                }}
                onChange={(e, value) =>
                  handleChangeAutocomplete(
                    e,
                    value,
                    "representativeFinalDestination",
                  )
                }
                isOptionEqualToValue={(option, value) =>
                  value === option?.label
                }
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Vendedor" xs={3}>
          <Controller
            name="sale"
            control={control}
            render={({field: {value}}) => (
              <CustomAutocomplete
                name="sale"
                placeholder="Vendedor"
                tooltipText={getTooltipText(value?.name)}
                loading={isLoadingSellerOptions}
                options={mountOptions(value, sellerOptions)}
                value={valueOrEmptyString(value?.name)}
                onOpen={
                  sellerOptions.length > 0
                    ? undefined
                    : () => getSellerOptions(setSellerOptions, 1, "")
                }
                onInputChange={(e, value) => {
                  if (e !== null) {
                    setSearchFields((prev) => ({...prev, seller: value}));
                    getSellerOptions(setSellerOptions, 1, value);
                  }
                }}
                getNextPage={() => {
                  onBottomScrollSeller(setSellerOptions, searchFields.seller);
                }}
                onChange={(e, value) =>
                  handleChangeAutocomplete(e, value, "sale")
                }
                isOptionEqualToValue={(option, value) =>
                  value === option?.label
                }
              />
            )}
          />
        </CustomInputWrapper>
        <Controller
          name="originAgent"
          control={control}
          render={({field: {value}}) => (
            <CustomInputWrapper
              title="Agente de origem"
              xs={3}
              sx={{position: "relative"}}
              helper={
                <HelperIcon
                  onClick={() =>
                    handleOpenHelperModal(
                      valueOrEmptyString(value.correspondence),
                      "Cliente",
                    )
                  }
                />
              }
            >
              <CustomAutocomplete
                name="originAgent"
                placeholder="Agente de origem"
                tooltipText={getTooltipText(value?.name)}
                loading={isLoadingAgentOptions}
                options={mountOptions(value, originAgentOptions)}
                value={valueOrEmptyString(value?.name)}
                optionSecondaryContent
                onOpen={
                  originAgentOptions.length > 0
                    ? undefined
                    : () => getAgentOptions(setOriginAgentOptions)
                }
                onInputChange={(e, value) => {
                  if (e !== null) {
                    setSearchFields((prev) => ({
                      ...prev,
                      originAgent: value,
                    }));
                    getAgentOptions(setOriginAgentOptions, 1, value);
                  }
                }}
                getNextPage={() => {
                  onBottomScrollAgent(
                    setOriginAgentOptions,
                    searchFields.originAgent,
                  );
                }}
                onChange={(e, value) => {
                  handleChangeAutocomplete(e, value, "originAgent");
                }}
                isOptionEqualToValue={(option, value) =>
                  value === option?.label
                }
              />
            </CustomInputWrapper>
          )}
        />
        {/* campos novos */}
        <CustomInputWrapper title="Agência logística" xs={3}>
          <Controller
            name="logisticAgency"
            control={control}
            render={({field: {value}}) => (
              <CustomAutocomplete
                name="logisticAgency"
                placeholder="Ag. Logística"
                tooltipText={getTooltipText(value?.name)}
                loading={isLoadingLogisticAgencyOptions}
                options={mountOptions(value, logisticAgencyOptions)}
                value={valueOrEmptyString(value?.name)}
                optionSecondaryContent
                onOpen={
                  logisticAgencyOptions.length > 0
                    ? undefined
                    : () =>
                        getLogisticAgencyOptions(
                          setLogisticAgencyOptions,
                          1,
                          "",
                        )
                }
                onInputChange={(e, value) => {
                  if (e !== null) {
                    setSearchFields((prev) => ({
                      ...prev,
                      logisticAgency: value,
                    }));
                    getLogisticAgencyOptions(
                      setLogisticAgencyOptions,
                      1,
                      value,
                    );
                  }
                }}
                getNextPage={() => {
                  onBottomScrollLogisticAgency(
                    setLogisticAgencyOptions,
                    searchFields.logisticAgency,
                  );
                }}
                onChange={(e, value) => {
                  handleChangeAutocomplete(e, value, "logisticAgency");
                }}
                isOptionEqualToValue={(option, value) =>
                  value === option?.label
                }
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Transp. Origem" xs={3}>
          <Controller
            name="carrierOrigin"
            control={control}
            render={({field: {value}}) => (
              <CustomAutocomplete
                name="carrierOrigin"
                placeholder="Transp. Origem"
                tooltipText={getTooltipText(value?.name)}
                loading={isLoadingCarrierOriginOptions}
                options={mountOptions(value, transportCompanyOptions)}
                value={valueOrEmptyString(value?.name)}
                onOpen={
                  transportCompanyOptions.length > 0
                    ? undefined
                    : () => getCarrierOriginOptions(setTransportCompanyOptions)
                }
                optionSecondaryContent
                onInputChange={(e, value) => {
                  if (e !== null) {
                    setSearchFields((prev) => ({
                      ...prev,
                      carrierOrigin: value,
                    }));
                    getCarrierOriginOptions(
                      setTransportCompanyOptions,
                      1,
                      value,
                    );
                  }
                }}
                getNextPage={() => {
                  onBottomScrollCarrierOrigin(
                    setTransportCompanyOptions,
                    searchFields.carrierOrigin,
                  );
                }}
                onChange={(e, value) => {
                  handleChangeAutocomplete(e, value, "carrierOrigin");
                }}
                isOptionEqualToValue={(option, value) =>
                  value === option?.label
                }
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Transp. Destino" xs={3}>
          <Controller
            name="carrierDestination"
            control={control}
            render={({field: {value}}) => (
              <CustomAutocomplete
                name="carrierDestination"
                placeholder="Transp. Destino"
                tooltipText={getTooltipText(value?.name)}
                loading={isLoadingCarrierDestinationOptions}
                options={mountOptions(value, transportCompanyOptions)}
                value={valueOrEmptyString(value?.name)}
                optionSecondaryContent
                onOpen={
                  transportCompanyOptions.length > 0
                    ? undefined
                    : () =>
                        getCarrierDestinationOptions(setTransportCompanyOptions)
                }
                onInputChange={(e, value) => {
                  if (e !== null) {
                    setSearchFields((prev) => ({
                      ...prev,
                      carrierDestination: value,
                    }));
                    getCarrierDestinationOptions(
                      setTransportCompanyOptions,
                      1,
                      value,
                    );
                  }
                }}
                getNextPage={() => {
                  onBottomScrollCarrierDestination(
                    setTransportCompanyOptions,
                    searchFields.carrierDestination,
                  );
                }}
                onChange={(e, value) =>
                  handleChangeAutocomplete(e, value, "carrierDestination")
                }
                isOptionEqualToValue={(option, value) =>
                  value === option?.label
                }
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Indicação" xs={3}>
          <Controller
            name="indication"
            control={control}
            render={({field: {value}}) => (
              <CustomAutocomplete
                name="indication"
                placeholder="Indicação"
                tooltipText={getTooltipText(value?.name)}
                loading={isLoadingIndicationOptions}
                options={mountOptions(value, indicationOptions)}
                value={valueOrEmptyString(value?.name)}
                onOpen={
                  indicationOptions.length > 0
                    ? undefined
                    : () => getIndicationOptions(setIndicationOptions, 1, "")
                }
                onInputChange={(e, value) => {
                  if (e !== null) {
                    setSearchFields((prev) => ({...prev, indication: value}));
                    getIndicationOptions(setIndicationOptions, 1, value);
                  }
                }}
                getNextPage={() => {
                  onBottomScrollIndication(
                    setIndicationOptions,
                    searchFields.indicationOptions,
                  );
                }}
                onChange={(e, value) =>
                  handleChangeAutocomplete(e, value, "indication")
                }
                isOptionEqualToValue={(option, value) =>
                  value === option?.label
                }
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Encaminhado por" xs={3}>
          <Controller
            name="referredBy"
            control={control}
            render={({field: {value, onChange}}) => (
              <CustomSelect
                size="small"
                content={Object.values(ReferredBy)}
                value={value}
                name="referredBy"
                onChange={onChange}
                required={false}
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Tipo de processo" xs={3}>
          <Controller
            name="processType"
            control={control}
            render={({field: {value}}) => (
              <CustomAutocomplete
                name={`processType`}
                placeholder="Tipo de processo"
                tooltipText={getTooltipText(value?.name)}
                loading={isLoadingTypeProcessOptions}
                options={mountOptions(value, typeProcessOptions)}
                value={valueOrEmptyString(value?.name)}
                onOpen={
                  typeProcessOptions.length > 0
                    ? undefined
                    : () => getTypeProcessOptions(setTypeProcessOptions)
                }
                onInputChange={(e, value) => {
                  if (e !== null) {
                    setSearchFields((prev) => ({
                      ...prev,
                      typeProcess: value,
                    }));
                    getTypeProcessOptions(setTypeProcessOptions, 1, value);
                  }
                }}
                getNextPage={() => {
                  onBottomScrollTypeProcess(
                    setTypeProcessOptions,
                    searchFields.typeProcess,
                  );
                }}
                onChange={(e, value) => {
                  handleChangeAutocomplete(e, value, "processType");
                }}
                isOptionEqualToValue={(option, value) =>
                  value === option?.label
                }
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Tipo de serviço" xs={3}>
          <Controller
            name="serviceType"
            control={control}
            render={({field: {value, onChange}}) => (
              <CustomSelect
                size="small"
                name="serviceType"
                value={value}
                onChange={onChange}
                content={Object.values(ServiceType)}
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Situação do agenciamento" xs={3}>
          <Controller
            name="agencyingSituation"
            control={control}
            render={({field: {value, onChange}}) => (
              <CustomSelect
                size="small"
                name="agencyingSituation"
                value={value}
                onChange={onChange}
                content={Object.values(AgencyingSituation)}
                disabled
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Moeda do processo" xs={3} isDisabled>
          <Controller
            name="processCurrency"
            control={control}
            render={({field: {value}}) => (
              <CustomAutoComplete
                size="small"
                name="processCurrency"
                placeholder="Moeda do processo"
                isDisabled
                isLoading={isLoadingCurrenciesOptions}
                tooltipText={getTooltipText(value?.name)}
                value={valueOrEmptyString(value?.name)}
                options={currenciesOptions}
                onChangeFn={(e, value) =>
                  handleChangeAutocomplete(e, value, "processCurrency")
                }
                onOpen={
                  currenciesOptions.length > 0
                    ? undefined
                    : () => getCurrenciesOptions(setCurrenciesOptions)
                }
                onBottomScroll={() =>
                  onBottomScrollCurrencies(
                    setCurrenciesOptions,
                    searchFields.currencies,
                  )
                }
                hasMorePagesToLoad={
                  currenciesPageState.currentPage < currenciesPageState.lastPage
                }
                onInputChange={(e, value) => {
                  //condição pra não fazer a requisição na primeira atualização do input

                  if (e !== null) {
                    setSearchFields((prev) => ({
                      ...prev,
                      currencies: value,
                    }));
                    getCurrenciesOptions(setCurrenciesOptions, 1, value);
                  }
                }}
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Cotação" xs={3} isDisabled>
          <Controller
            name="quotation"
            control={control}
            render={({field: {value}}) => (
              <CustomAutoComplete
                size="small"
                isDisabled
                name="quotation"
                placeholder="Cotação"
                tooltipText={getTooltipText(value?.name)}
                value={valueOrEmptyString(value?.name)}
                options={[]}
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Data de validade das taxas" xs={3}>
          <Controller
            name="dateValidityFees"
            control={control}
            render={({field: {value}}) => (
              <ProcessDatePicker
                value={value}
                name="dateValidityFees"
                title="Data de validade das taxas"
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Data do cancelamento" xs={3}>
          <Controller
            name="dateCancellation"
            control={control}
            render={({field: {value}}) => (
              <ProcessDatePicker
                value={value}
                name="dateCancellation"
                title="Data do cancelamento"
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper
          title="Motivo de cancelamento"
          xs={3}
          tooltip="Motivo de cancelamento"
        >
          <Controller
            name="logisticCancellationProcess"
            control={control}
            render={({field: {value}}) => (
              <CustomAutoComplete
                size="small"
                name="logisticCancellationProcess"
                placeholder="Motivo de cancelamento"
                value={valueOrEmptyString(value?.name)}
                options={[]}
                tooltipText={getTooltipText(value?.name)}
                isDisabled
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Detalhes do cancelamento" xs={9}>
          <Controller
            name="detailsCancellation"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="detailsCancellation"
                placeholder="Detalhes do cancelamento"
                value={value}
                onChange={onChange}
                size="small"
              />
            )}
          />
        </CustomInputWrapper>
        <ConfirmModal
          isOpen={insuranceAlertModalIsOpen}
          color="warning"
          title="Esse processo não possui seguro cadastrado."
          subtitle="Para conseguir selecionar uma seguradora é necessário primeiro ir até a seção de dados da carga e marcar positivamente a opção de seguro."
          confirmButtonText="Confirmar"
          confirmButtonIconIsDisabled
          titleIcon="warning"
          confirmButtonFn={handleToggleInsuranceAlertModal}
          onClose={handleToggleInsuranceAlertModal}
          hasOnlyConfirmButton
        />
      </Grid>
    </>
  );
};

export default Transactioners;
