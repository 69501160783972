/* eslint-disable react-hooks/exhaustive-deps */
import {useState, Fragment, useEffect} from "react";
import pxToRem from "hooks/usePxToRem";
import {
  AvailableDocumentCategories,
  ProcessDocumentsByCategoryData,
} from "../../ProcessDocuments.types";
import useProcessDocuments from "./useProcessDocuments";

import {Box, Link, Stack, Tab, useTheme} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {
  MaterialReactTable,
  MRT_ToggleGlobalFilterButton,
  MRT_ToggleFiltersButton,
} from "material-react-table";
import {MRT_Localization_PT_BR} from "material-react-table/locales/pt-BR";

import {CustomIconButton} from "ui";
import UploadDocumentModal from "../UploadDocumentModal";
import {format} from "date-fns";
import {ConfirmModal} from "components";
import {FieldValues, UseFormSetValue, useFormContext} from "react-hook-form";
import {idOfCategories} from "views/ProcessDetails/utils/idOfCategories";
import tableActionColumnStyle from "util/tableActionColumnStyle";

type BaseDocuments = {
  id: string;
  name: string;
  sendDate: string;
  link: string;
  category: string;
  idGroupFile: number;
}[];

interface Props {
  refetchFiles: (setRhfValues: UseFormSetValue<FieldValues>) => Promise<void>;
}

const DocumentsList = (props: Props) => {
  const theme = useTheme();

  const availableTabs = [
    {
      label: "Conhecimentos originais",
      value: "originalDocs",
    },

    {
      label: "Draft dos conhecimentos",
      value: "draft",
    },

    {
      label: "Mercante",
      value: "merchant",
    },

    {
      label: "Financeiros",
      value: "financial",
    },

    {
      label: "Pricing",
      value: "pricing",
    },

    {
      label: "Outros",
      value: "others",
    },
  ];
  const [tabsMapped, setTabsMapped] = useState(availableTabs);

  const {refetchFiles} = props;
  const [currentlyActiveDocumentTab, setCurrentlyActiveDocumentTab] =
    useState<AvailableDocumentCategories>("originalDocs");

  const {watch, setValue} = useFormContext();
  const cancelledProcess = watch("agencyingSituation") === "Cancelado";

  const {
    columnsData,
    processDocumentsData,
    setProcessDocumentsData,
    deleteDocument,
  } = useProcessDocuments(watch("files"));

  const filterByCategory = (allDocuments: BaseDocuments, category: string) => {
    if (category === "otherDocuments") {
      return [
        ...allDocuments.filter(
          (item) => !idOfCategories.allDocuments().includes(item.idGroupFile),
        ),
      ];
    }

    return [
      ...allDocuments.filter((item) =>
        idOfCategories[category]?.includes(item?.idGroupFile),
      ),
    ];
  };

  useEffect(() => {
    const baseDocuments = watch("files").map((file) => ({
      id: file?.id.toString(),
      name: file?.originalName,
      sendDate:
        file?.createdAt !== undefined
          ? format(new Date(file?.createdAt), "dd/MM/yyyy - HH:mm")
          : "",
      link: file?.url,
      idGroupFile: file?.idGroupFile,
    }));

    const processDocumentsData = {
      originalDocs: filterByCategory(baseDocuments, "originalDocs"),
      draft: filterByCategory(baseDocuments, "draft"),
      merchant: filterByCategory(baseDocuments, "merchant"),
      financial: filterByCategory(baseDocuments, "financial"),
      pricing: filterByCategory(baseDocuments, "pricing"),
      others: filterByCategory(baseDocuments, "otherDocuments"),
    } as ProcessDocumentsByCategoryData;

    const tabsWithSomeContent = availableTabs.filter(
      (item) => processDocumentsData[item.value]?.length > 0,
    );

    setProcessDocumentsData(processDocumentsData);

    setTabsMapped(tabsWithSomeContent);
    setCurrentlyActiveDocumentTab(
      (tabsWithSomeContent.at(0)?.value as AvailableDocumentCategories) ??
        "originalDocs",
    );
  }, [watch("files")]);

  const currentSelectedDocumentData = Object.entries(
    processDocumentsData,
  ).filter(([documentCategory]) =>
    currentlyActiveDocumentTab.includes(documentCategory),
  );

  const [isUploadDocumentModalOpen, setIsUploadDocumentModalOpen] =
    useState(false);

  const handleChangeCurrentlyActiveDocumentTab = (
    _: React.SyntheticEvent,
    newActiveTab: AvailableDocumentCategories,
  ) => {
    setCurrentlyActiveDocumentTab(newActiveTab);
  };

  const handleToggleUploadDocumentModal = () => {
    setIsUploadDocumentModalOpen(!isUploadDocumentModalOpen);
  };

  const [idOfFileToDelete, setIdOfFileToDelete] = useState<number>(0);
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);

  const handleCloseConfirmModal = () => {
    setIsOpenModalConfirm(false);
  };

  const handOpenDeleteDocumentModal = async (fileId: string) => {
    setIsOpenModalConfirm(true);
    setIdOfFileToDelete(parseInt(fileId));
  };

  const handleConfirmDelete = async () => {
    await deleteDocument(idOfFileToDelete);
    await refetchFiles(setValue);
    handleCloseConfirmModal();
  };

  return (
    <Fragment>
      <UploadDocumentModal
        open={isUploadDocumentModalOpen}
        handleCloseModal={handleToggleUploadDocumentModal}
        newDocumentsBody={processDocumentsData}
        refetchFiles={refetchFiles}
      />

      <ConfirmModal
        isOpen={isOpenModalConfirm}
        title="Apagar documento"
        subtitle="Essa ação não pode ser revertida!"
        confirmButtonFn={handleConfirmDelete}
        onClose={handleCloseConfirmModal}
      />

      <TabContext value={currentlyActiveDocumentTab}>
        <Box sx={{borderBottom: 1, borderColor: "divider"}}>
          <TabList
            onChange={handleChangeCurrentlyActiveDocumentTab}
            sx={{"& .MuiButtonBase-root": {textTransform: "capitalize"}}}
          >
            {tabsMapped.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </TabList>
        </Box>

        {currentSelectedDocumentData.map(([documentCategory, documents]) => (
          <TabPanel
            key={documentCategory}
            value={documentCategory}
            sx={{padding: 0}}
          >
            <MaterialReactTable
              columns={columnsData}
              data={documents}
              initialState={{
                density: "compact",
                columnPinning: {left: ["mrt-row-actions"]},
              }}
              localization={MRT_Localization_PT_BR}
              enableFullScreenToggle={false}
              enableDensityToggle={false}
              enableColumnResizing
              enableRowActions
              layoutMode="grid"
              enableStickyHeader
              enablePagination={false}
              enableHiding={false}
              positionActionsColumn="first"
              positionToolbarAlertBanner="none"
              displayColumnDefOptions={tableActionColumnStyle(theme)}
              renderRowActions={({row}) => (
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Link href={row.original.link}>
                    <CustomIconButton
                      tooltipText="Ver documento para download"
                      iconName="download"
                    />
                  </Link>

                  <CustomIconButton
                    disabled={cancelledProcess}
                    tooltipText="Excluir documento"
                    iconName="delete"
                    onClick={() => handOpenDeleteDocumentModal(row.original.id)}
                  />
                </Stack>
              )}
              muiTableContainerProps={{
                sx: (theme) => ({
                  flex: 1,
                  maxHeight: pxToRem(228),
                  backgroundColor: theme.palette.background.paper,
                }),
              }}
              muiTablePaperProps={{
                elevation: 0,
              }}
              muiTableHeadCellProps={{
                sx: (theme) => ({
                  backgroundColor: theme.palette.background.paper,
                  fontSize: pxToRem(14),
                  fontWeight: 600,

                  ".Mui-TableHeadCell-Content-Wrapper": {
                    whiteSpace: "nowrap",
                  },

                  "& .Mui-TableHeadCell-content": {
                    alignItems: "center",
                  },
                }),
              }}
              muiTableBodyCellProps={() => ({
                sx: (theme) => ({
                  fontSize: pxToRem(14),
                  color: theme.palette.text.secondary,
                }),
              })}
              muiTableBodyRowProps={{
                hover: false,
                sx: (theme) => ({
                  backgroundColor: theme.palette.background.paper,
                }),
              }}
              muiBottomToolbarProps={{
                sx: (theme) => ({
                  backgroundColor: theme.palette.background.paper,
                }),
              }}
              muiTopToolbarProps={{
                sx: (theme) => ({
                  backgroundColor: theme.palette.background.paper,
                }),
              }}
              muiColumnActionsButtonProps={{size: "small"}}
              renderToolbarInternalActions={({table}) => (
                <>
                  <MRT_ToggleGlobalFilterButton table={table} />
                  <MRT_ToggleFiltersButton table={table} />
                  <CustomIconButton
                    disabled={cancelledProcess}
                    iconName="upload_file_rounded"
                    tooltipText="Incluir novo documento"
                    tooltipPlacement="bottom"
                    onClick={handleToggleUploadDocumentModal}
                  />
                </>
              )}
            />
          </TabPanel>
        ))}
      </TabContext>
    </Fragment>
  );
};

export default DocumentsList;
