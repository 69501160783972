import {createActions, createReducer} from "reduxsauce";

type initial = {
  open: boolean;
  message: string;
  severity: string;
};

const INITIAL_STATE: initial = {
  open: false,
  message: "",
  severity: "warning",
};

export const {Types, Creators} = createActions({
  showAlertMessage: ["open", "message", "severity"],
  hiddenAlertMessage: ["open"],
});

const showAlertMessage = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  open: action.open,
  message: action.message,
  severity: action.severity,
});

const hiddenAlertMessage = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  open: action.open,
});

export default createReducer(INITIAL_STATE, {
  [Types.SHOW_ALERT_MESSAGE]: showAlertMessage,
  [Types.HIDDEN_ALERT_MESSAGE]: hiddenAlertMessage,
});
