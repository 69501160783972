import {Alert, Box, Icon, Snackbar, Typography, useTheme} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {useDispatch, useSelector} from "react-redux";
import {Types as alertTypes} from "store/reducers/alertMessageReducer";
import CustomIconButton from "ui/CustomIconButton";

const IconMappingBox = (props: {severity: string}) => {
  const {severity = "primary"} = props;
  const theme = useTheme();

  const getIcon = () => {
    switch (severity) {
      case "primary":
        return "sticky_note_2";
      case "error":
        return "error";
      case "warning":
        return "warning";
      case "success":
        return "check_circle";
    }
  };

  return (
    <Box
      sx={{
        p: pxToRem(8),
        backgroundColor: theme.palette[severity][50],
        borderRadius: pxToRem(4),
      }}
    >
      <Icon sx={{color: theme.palette[severity][700]}}>{getIcon()}</Icon>
    </Box>
  );
};

const CustomAlertMessage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const {open, message, severity} = useSelector(
    (state: any) => state.stateAlertMessage,
  );
  const handleClose = () => {
    dispatch({
      type: alertTypes.HIDDEN_ALERT_MESSAGE,
      open: false,
    });
  };
  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
      }}
    >
      <Snackbar
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
        open={open}
        autoHideDuration={5000}
        sx={{
          width: "100%",
          position: "absolute",
          pl: pxToRem(48),
        }}
        onClose={handleClose}
      >
        <Alert
          variant="filled"
          sx={{
            borderTop: `${pxToRem(4)} solid ${
              theme.palette[severity ?? "primary"][600]
            }`,
            backgroundColor: theme.palette.background.paper,
            boxShadow: `0px 0px ${pxToRem(4)} rgba(0, 0, 0, 0.1)`,
            height: pxToRem(72),
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
          onClose={handleClose}
          severity={severity}
          iconMapping={{
            success: <IconMappingBox severity={severity as string} />,
            warning: <IconMappingBox severity={severity as string} />,
            error: <IconMappingBox severity={severity as string} />,
          }}
          action={
            <CustomIconButton
              iconName={"close"}
              iconSize={20}
              onClick={handleClose}
            />
          }
        >
          <Typography
            variant="body1"
            color={theme.palette.text.primary}
            sx={{fontSize: pxToRem(12)}}
          >
            {message}
          </Typography>
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CustomAlertMessage;
