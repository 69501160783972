import {createActions, createReducer} from "reduxsauce";

type UsersList = {
  avatar: string;
  id: string;
  userName: string;
  userRole: string;
  accessToken: any;
};

type initial = {
  usersList: UsersList[];
  ownerProfile: UsersList;
  userToLogin: string;
  accessToken: any;
};

const INITIAL_STATE: initial = {
  usersList: [],
  ownerProfile: {} as UsersList,
  userToLogin: "",
  accessToken: "",
};

export const {Types, Creators} = createActions({
  setLoginData: ["usersList"],
});

const setLoginData = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  usersList: action.usersList,
  ownerProfile: action.ownerProfile,
  userToLogin: action.userToLogin,
  accessToken: action.accessToken,
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_LOGIN_DATA]: setLoginData,
});
