/* eslint-disable @typescript-eslint/no-explicit-any */
import {createActions, createReducer} from "reduxsauce";

type initial = {
  filter: {
    dateType: string;
    dateStart: string;
    dateEnd: string;
    situations: string[];
    modality: string;
    operation: string;
    processTypes: string[];
  };
};

export const INITIAL_STATE: initial = {
  filter: {
    dateType: "",
    dateStart: "",
    dateEnd: "",
    situations: [],
    modality: "Marítima",
    operation: "Importação",
    processTypes: [],
  },
};

export const {Types, Creators} = createActions({
  setFilters: ["filter"],
});

const setFilters = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  filter: action.filter,
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_FILTERS]: setFilters,
});
