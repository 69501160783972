import {
  Box,
  FormControlLabel,
  Icon,
  Menu,
  MenuItem,
  Radio,
  ToggleButton,
  Tooltip,
  Typography,
} from "@mui/material";
import {Fragment, useState} from "react";
import pxToRem from "hooks/usePxToRem";

const SelectFontSize = () => {
  const [fontSize, setFontSize] = useState("2");
  //to do => fazer o menu item ficar com o ícone de selecionado, quando o tamanho da fonte corresponder com o da palavra/trecho selecionado
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleCloseFontSizeOptions = () => {
    setAnchorEl(null);
  };

  const open = !!anchorEl;

  const fontSizeOptions = [
    {
      name: "Small 1",
      fontSize: "0.63em",
      value: "1",
      selected: fontSize === "1",
    },
    {
      name: "Small 2",
      fontSize: "0.82em",
      value: "2",
      selected: fontSize === "2",
    },
    {
      name: "Medium",
      fontSize: "1em",
      value: "3",
      selected: fontSize === "3",
    },
    {
      name: "Large 1",
      fontSize: "1.13em",
      value: "4",
      selected: fontSize === "4",
    },
    {
      name: "Large 2",
      fontSize: "1.5em",
      value: "5",
      selected: fontSize === "5",
    },
    {
      name: "Huge 1",
      fontSize: "2em",
      value: "6",
      selected: fontSize === "6",
    },
    {
      name: "Huge 2",
      fontSize: "3em",
      value: "7",
      selected: fontSize === "7",
    },
  ];

  const handleOpenFontSizeOptions = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectFontSize = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    value,
  ) => {
    event.preventDefault();
    document.execCommand("fontSize", true, value);
    setFontSize(value);
    handleCloseFontSizeOptions();
  };

  return (
    <Fragment>
      <Tooltip title="Tamanho" arrow placement="left">
        <ToggleButton
          value="fontSize"
          id="font-size-button"
          aria-controls={open ? "font-size-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={!!open}
          onClick={handleOpenFontSizeOptions}
        >
          <Icon sx={{fontSize: pxToRem(18), margin: pxToRem(7)}}>
            format_size
          </Icon>
          <Icon fontSize="small">arrow_drop_down</Icon>
        </ToggleButton>
      </Tooltip>
      <Menu
        id="font-size-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseFontSizeOptions}
        MenuListProps={{
          "aria-labelledby": "font-size-button",
        }}
      >
        {fontSizeOptions.map((item) => (
          <MenuItem
            key={item.value}
            onClick={(event) => handleSelectFontSize(event, item.value)}
          >
            <FormControlLabel
              value={item.value}
              control={
                <Radio
                  icon={
                    fontSize === item.value ? (
                      <Icon>check</Icon>
                    ) : (
                      <Box sx={{height: pxToRem(25), width: pxToRem(25)}} />
                    )
                  }
                />
              }
              label={
                <Typography fontSize={item.fontSize}>{item.name}</Typography>
              }
            />
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

export default SelectFontSize;
