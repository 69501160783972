import {DialogTitle, Icon, Stack} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {CustomIconButton} from "ui";

interface ModalHeaderProps {
  handleClose: () => void;
}

const ModalHeader = (props: ModalHeaderProps) => {
  const {handleClose} = props;

  return (
    <Stack
      direction="row"
      alignItems={"center"}
      justifyContent="space-between"
      sx={{
        padding: pxToRem(20),
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
        height: "12%",
      }}
    >
      <Stack direction="row" alignItems={"center"} gap={pxToRem(12)}>
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            color: "primary.main",
            backgroundColor: "background.default",
            height: pxToRem(36),
            width: pxToRem(36),
            borderRadius: pxToRem(5),
          }}
        >
          <Icon
            sx={{
              color: "primary.main",
            }}
          >
            rocket_launch
          </Icon>
        </Stack>
        <DialogTitle
          sx={{
            fontSize: pxToRem(18),
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
            padding: 0,
          }}
        >
          O que há de novo?
        </DialogTitle>
      </Stack>
      <CustomIconButton
        iconName={"close"}
        onClick={handleClose}
        sx={{color: "text.primary", padding: 0}}
      />
    </Stack>
  );
};

export default ModalHeader;
