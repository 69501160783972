import {Switch} from "@mui/material";
import {styled} from "@mui/material/styles";
import pxToRem from "hooks/usePxToRem";

const StyledSwitch = styled(Switch)(({theme}) => ({
  padding: 8,

  ".MuiButtonBase-root:hover": {
    color: theme.palette.primary.main,
    "&.Mui-checked,": {backgroundColor: "transparent"},
  },
  "& .MuiSwitch-track": {
    backgroundColor: theme.palette.grey[100],
    borderRadius: pxToRem(22 / 2),

    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: pxToRem(16),
      height: pxToRem(16),
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0.75px 0.75px 3px rgba(0, 0, 0, 0.1)",
    width: pxToRem(16),
    height: pxToRem(16),
    margin: pxToRem(2),
  },
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      color: theme.palette.background.paper,
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));

export default StyledSwitch;
