/* eslint-disable @typescript-eslint/no-explicit-any */
import {memo, useState} from "react";
import {Grid} from "@mui/material";

import pxToRem from "hooks/usePxToRem";
import {CustomButton} from "ui";
import {FollowUpCard, NewFollowUpModal} from "./components";
import {DefaultCardStructure} from "components";
import ProcessFollowUpData from "./ProcessFollowUpData";
import {FormatedFollowUp, PostFollowUpType} from "./ProcessFollowUpTypes";
import {FieldValues, UseFormSetValue, useFormContext} from "react-hook-form";

interface Props {
  refetchFollowUps: (
    setRhfValues: UseFormSetValue<FieldValues>,
  ) => Promise<void>;
}

const ProcessFollowUps = (props: Props) => {
  const {refetchFollowUps} = props;
  const {watch, setValue} = useFormContext();

  const {postFollowUp, updateFollowUp} = ProcessFollowUpData();

  const cancelledProcess = watch("agencyingSituation") === "Cancelado";

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);

  const [currentFollowUp, setCurrentFollowUp] = useState<FormatedFollowUp>(
    {} as FormatedFollowUp,
  );

  const handleToggleNewFollowUpModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const handlePostNewFollowUp = async (followUpData: PostFollowUpType) => {
    await postFollowUp(followUpData);
    await refetchFollowUps(setValue);
  };

  const handleUpdateFollowUp = async (
    id: number,
    followUpData: Partial<PostFollowUpType>,
  ) => {
    await updateFollowUp(id, followUpData);
    await refetchFollowUps(setValue);
  };

  return (
    <DefaultCardStructure
      title="Acompanhamentos"
      linkId="followUps"
      hasDivider
      button={
        <CustomButton
          onClickFn={handleToggleNewFollowUpModal}
          disabled={cancelledProcess}
        >
          Novo acompanhamento
        </CustomButton>
      }
    >
      <NewFollowUpModal
        isOpen={modalIsOpen}
        onCloseModal={handleToggleNewFollowUpModal}
        processNumber={watch("processNumber")}
        handlePostNewFollowUp={handlePostNewFollowUp}
        handleUpdateFollowUp={handleUpdateFollowUp}
        idGroupActivity={watch("idGroupActivity")}
        modalEdit={modalEdit}
        setModalEdit={setModalEdit}
        currentFollowUp={currentFollowUp}
      />
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          gap: pxToRem(24),
          maxHeight: pxToRem(500),
          padding: `${pxToRem(2)} ${pxToRem(12)} ${pxToRem(2)} ${pxToRem(2)}`,
          overflow: "auto",
        }}
      >
        {watch("followUps")?.map((followUp: FormatedFollowUp) => (
          <FollowUpCard
            key={followUp.id}
            followUp={followUp}
            setCurrentFollowUp={setCurrentFollowUp}
            handleOpenModal={() => {
              setModalIsOpen(true);
              setModalEdit(true);
            }}
            handleDeleteFollow={handleUpdateFollowUp}
          />
        ))}
      </Grid>
    </DefaultCardStructure>
  );
};

export default memo(ProcessFollowUps);
