import {Search, Source} from "@mui/icons-material";
import {Stack, Box} from "@mui/system";
import CustomDatePicker from "components/CustomDatePicker";
import pxToRem from "hooks/usePxToRem";
import {CustomSearchSelect, CustomButton, CustomInput} from "ui";
import SelectDateButton from "components/ActivitiesHeader/components/SelectDateButton";
import {useSelector} from "react-redux";
import useHeaderFilters from "components/ActivitiesHeader/hooks/useHeaderFilters";

type Props = {
  getActivities: (
    startDate?: string,
    endDate?: string,
    status?: string,
    idClient?: number,
  ) => Promise<void>;
};

const HeaderFilters = (props: Props) => {
  const {getActivities} = props;
  const {definedRanges, companyName, refNext} = useSelector(
    (state: any) => state.stateDashboardFilter,
  );
  const {
    getDatePickerValues,
    handleFilterActivities,
    getCompanies,
    companies,
    onBottomScroll,
    companiesPage,
    handleChangeCompanyName,
    isLoadingCompanies,
    handleChangeRefNext,
  } = useHeaderFilters({getActivities});

  return (
    <Stack
      direction="row"
      justifyContent={"flex-end"}
      gap={pxToRem(8)}
      flexWrap={"nowrap"}
      sx={{height: "100%", width: "85%"}}
    >
      <Box position="relative" sx={{width: `32.5%`}}>
        <Search
          sx={(theme) => ({
            position: "absolute",
            top: pxToRem(9),
            left: pxToRem(12),
            color: theme.palette.text.secondary,
            fontSize: pxToRem(24),
          })}
        />
        <CustomSearchSelect
          paddingTopBottom
          options={companies}
          size="small"
          fullWidth
          loading={isLoadingCompanies}
          fontSize={pxToRem(14)}
          fontWeight={400}
          placeholder="Nome do cliente"
          sizeOfPlaceholder={3}
          permanentPlaceholder
          fnOnOpen={companies?.length === 0 ? () => getCompanies() : undefined}
          fnOnInputChange={(event, newValue) => {
            if (event?.type === "click") return;
            if (event !== null) {
              getCompanies(1, newValue);
            }
          }}
          value={companyName}
          fnOnChange={handleChangeCompanyName}
          onBottomScroll={onBottomScroll}
          hasMorePagesToLoad={
            companiesPage.currentPage < companiesPage.lastPage
          }
        />
      </Box>
      <Box position="relative" sx={{width: `23.5%`}}>
        <Source
          fontSize="small"
          sx={(theme) => ({
            position: "absolute",
            top: pxToRem(10),
            left: pxToRem(12),
            color: theme.palette.text.secondary,
          })}
        />
        <CustomInput
          size="small"
          placeholder="Ref. Next"
          value={refNext}
          onChange={handleChangeRefNext}
          sx={{height: pxToRem(40), paddingLeft: pxToRem(30)}}
        />

        {/*
        Isso aqui vai ser mantido por um período, para caso os op derem falta desse campo seja mais fácil a gente retornar com ele 
        <CustomSelect
          size="small"
          content={selectFilterAvailableValues}
          value={currentFilterSelected}
          onChange={handleChangeCurrentFilter}
          hasLimitedContentHeight
          textOnEmptySelect="Período"
          fullWidth
          sx={{
            outerWidth: pxToRem(194),
            fontSize: pxToRem(14),
            height: pxToRem(40),

            ".MuiSelect-select.MuiSelect-outlined.MuiInputBase-input": {
              paddingRight: pxToRem(42),
              paddingLeft: pxToRem(40),

              "> em": {
                fontStyle: "normal",
              },
            },
          }}
        /> */}
      </Box>
      <Box sx={{width: `32.5%`}}>
        <CustomDatePicker
          fullWidth
          variant="range"
          customInput={<SelectDateButton fullWidth />}
          onDatesConfirm={getDatePickerValues}
          placeholderText="Seleciona a data"
          shouldCloseOnSelect
          showFooter
          getDatePickerValuesOnClose={getDatePickerValues}
          showDateHeader={false}
          preDefinedRange={definedRanges}
          openToDate={definedRanges[1]}
        />
      </Box>
      <CustomButton
        onClickFn={handleFilterActivities}
        size="small"
        sx={{
          height: pxToRem(40),
          width: pxToRem(110),
        }}
        endIcon="search"
      >
        Buscar
      </CustomButton>
    </Stack>
  );
};

export default HeaderFilters;
