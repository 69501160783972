import {Tooltip, Typography, useTheme} from "@mui/material";
import {Box} from "@mui/system";
import pxToRem from "hooks/usePxToRem";
import {ReactNode} from "react";

interface AutocompleteWithPermanentPlaceholderProps {
  permanentPlaceholder: string;
  children: ReactNode;
  value?: any;
  tooltipOff?: boolean;
  maxWidth?: string;
}

const PermanentPlaceholderWrapper = (
  props: AutocompleteWithPermanentPlaceholderProps,
) => {
  const theme = useTheme();
  const {
    permanentPlaceholder,
    value,
    tooltipOff,
    children,
    maxWidth = "70%",
  } = props;

  return (
    <Tooltip title={tooltipOff ? "" : value?.label} arrow placement="top">
      <Box position="relative">
        <Box
          position="absolute"
          sx={{
            color: theme.palette.grey[500],
            left: pxToRem(14),
            top: "21.25%",
            maxWidth: maxWidth,
          }}
        >
          <Typography
            sx={{
              fontSize: pxToRem(14),
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {permanentPlaceholder}
          </Typography>
        </Box>
        {children}
      </Box>
    </Tooltip>
  );
};

export default PermanentPlaceholderWrapper;
