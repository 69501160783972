import {AxiosResponse} from "axios";

import {newBackendAPI} from "services/apiConsumer";
import {DefaultResponse} from "types/GenericTypes";
import {ActivityStatus} from "util/globalEnums";
import {SearchParams} from "views/ActivitiesHistory/ActivitiesHistory.types";

export const GetAllActivities = async <T>(
  props: Partial<SearchParams>,
): Promise<AxiosResponse<DefaultResponse<T>>> => {
  const {page = 1, limit = 20, ...rest} = props;

  const params = {...rest, page, limit};
  return await newBackendAPI.get(`/filter-activities`, {params});
};

export const GetAllTasks = async <T>(
  isGeneratedSeparately?: boolean,
  idTaskGroup?: number,
): Promise<AxiosResponse<T>> => {
  const params = {isGeneratedSeparately, idTaskGroup};
  return await newBackendAPI.get(`/tasks`, {params});
};

export const ChangeActivityStatusById = async (
  id: number,
  newStatus: ActivityStatus,
) => {
  return await newBackendAPI.patch(`activities/${id}`, {
    activityStatus: newStatus,
  });
};

export const ChangeActivityObservationById = async (
  id: number,
  observation: string,
) => {
  return await newBackendAPI.patch(`activities/${id}`, {
    observation,
  });
};
