/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect} from "react";
import {
  Snackbar,
  Slide,
  SlideProps,
  useTheme,
  Box,
  Icon,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import {useDispatch, useSelector} from "react-redux";
import {Types as toastTypes} from "store/reducers/toastReducer";
import pxToRem from "hooks/usePxToRem";

type TransitionProps = Omit<SlideProps, "direction">;

function SlideTransition(props: Readonly<SlideProps>) {
  return <Slide {...props} direction="up" />;
}

const IconMappingBox = (props: {severity: string}) => {
  const {severity = "primary"} = props;

  const theme = useTheme();

  const getIcon = () => {
    switch (severity) {
      case "primary":
        return "sticky_note_2";
      case "error":
        return "error";
      case "warning":
        return "warning";
      case "success":
        return "check_circle";
    }
  };

  return (
    <Box
      sx={{
        p: pxToRem(6.5),
        backgroundColor: theme.palette[severity][50],
        height: pxToRem(28),
        width: pxToRem(28),
        borderRadius: pxToRem(4),
        display: "flex",
      }}
    >
      <Icon
        sx={{
          color: theme.palette[severity][700],
          fontSize: pxToRem(15),
        }}
        fontSize="small"
      >
        {getIcon()}
      </Icon>
    </Box>
  );
};

const MyToast = () => {
  const dispatch = useDispatch();

  const {open, message, severity} = useSelector(
    (state: any) => state.stateToast,
  );

  const [transition, setTransition] = React.useState<
    React.ComponentType<TransitionProps> | undefined
  >(undefined);

  const handleClick =
    (Transition: React.ComponentType<TransitionProps>) => () => {
      setTransition(() => Transition);
    };

  useEffect(() => {
    if (open) {
      handleClick(SlideTransition);
    }
  }, [open]);

  const handleClose = () => {
    dispatch({
      type: toastTypes.HIDDEN_TOAST,
      open: false,
    });
  };
  const theme = useTheme();
  return (
    <Snackbar
      anchorOrigin={{vertical: "top", horizontal: "right"}}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      TransitionComponent={transition}
    >
      <Alert
        onClose={handleClose}
        severity={severity === "primary" ? undefined : severity}
        variant="filled"
        iconMapping={{
          success: <IconMappingBox severity={severity as string} />,
          warning: <IconMappingBox severity={severity as string} />,
          error: <IconMappingBox severity={severity as string} />,
        }}
        sx={{
          width: "100%",
          height: pxToRem(44),
          display: "flex",
          alignItems: "center",
          backgroundColor: theme.palette.background.paper,
          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
          borderLeft: `${pxToRem(3)} solid ${
            theme.palette[severity ?? "primary"][700]
          }`,
          "& .MuiAlert-action": {
            color: theme.palette.text.primary,
            "& ::before": {
              content: '""',
              position: "absolute",
              left: pxToRem(-8),
              bottom: pxToRem(-7),
              backgroundColor: theme.palette.grey[50],
              width: pxToRem(2),
              height: pxToRem(46),
            },
          },
          "& .MuiAlert-message": {
            mr: pxToRem(15),
          },
        }}
      >
        <Typography variant="body2" color={theme.palette.text.primary}>
          {message}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default MyToast;
