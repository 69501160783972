import {Grid} from "@mui/material";

import {Voyages} from "./components";
import {DefaultCardStructure} from "components";
import {useFormContext} from "react-hook-form";
import useHandleBoardingFields from "views/ProcessDetails/useHandleBoardingFields";
import pxToRem from "hooks/usePxToRem";
import {memo} from "react";
import {
  DefaultProcessDetails,
  Voyage,
} from "views/ProcessDetails/Process.types";

type Props = {
  addNewVoyage: (
    id: number,
    voyages: Partial<Voyage>[],
  ) => Promise<DefaultProcessDetails | undefined>;
  refetchProcess: () => Promise<DefaultProcessDetails | undefined>;
};

const ProcessBoarding = (props: Props) => {
  const {watch} = useFormContext();

  const {fclCase, mainCase} = useHandleBoardingFields();

  return (
    <DefaultCardStructure linkId="boarding" title="Embarque" hasDivider={true}>
      <Grid container columnSpacing={pxToRem(8)} rowSpacing={pxToRem(20)}>
        {watch("processLoads[0].typeLoad") === "FCL"
          ? fclCase.map((item, idx) => <item.item key={idx} xs={item.xs} />)
          : mainCase.map((item, idx) => <item.item key={idx} xs={item.xs} />)}

        <Voyages
          addNewVoyage={props.addNewVoyage}
          refetchProcess={props.refetchProcess}
        />

        {/* <Transshipment /> */}
      </Grid>
    </DefaultCardStructure>
  );
};

export default memo(ProcessBoarding);
