import {useTheme} from "@mui/material";
import {isBefore, subMinutes} from "date-fns";

export const useBackgroundAndTextColor = () => {
  const theme = useTheme();
  /**
   * A pedido da operação, estamos mantendo apenas a verificação do dia na agenda.
   * As atividades só ficam com o status de atrasada se venceu ontem ou anotes, desconsiderando
   * as horas.
   */
  const checkBackgroundAndTextColor = (row) => {
    const finish = row.original?.estimatedFinish
      ? subMinutes(
          new Date(row.original?.estimatedFinish),
          -new Date().getTimezoneOffset(),
        )
      : new Date();
    const actualDate = new Date();

    const textStyle = {
      text:
        theme.palette.mode === "dark"
          ? theme.palette.grey[900]
          : theme.palette.text.primary,
    };

    const activityIsLate = isBefore(finish, actualDate);

    if (activityIsLate) {
      return {
        background:
          theme.palette.mode === "dark"
            ? theme.palette.error[200]
            : theme.palette.error[50],
        ...textStyle,
      };
    } else {
      return {
        background: theme.palette.background.paper,
        text: theme.palette.text.primary,
      };
    }
  };

  return {checkBackgroundAndTextColor};
};
