/* eslint-disable @typescript-eslint/no-explicit-any */
import {Backdrop, CircularProgress} from "@mui/material";
import {useSelector} from "react-redux";

const CustomLoading = () => {
  const {isLoading} = useSelector((state: any) => state.stateLoading);

  const {userInfo} = useSelector((state: any) => state.stateUserInfo);

  return (
    <Backdrop open={isLoading || false} sx={{zIndex: 99999}}>
      <CircularProgress
        sx={{
          color: userInfo?.settings?.darkMode ? "#00b2af" : "#6f2c91",
        }}
      />
    </Backdrop>
  );
};

export default CustomLoading;
