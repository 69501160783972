/* eslint-disable @typescript-eslint/no-explicit-any */
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

type Routes = {
  icon: string;
  id: number;
  name: string;
  path: string;
};
type MenuType = {
  id: number;
  name: string;
  routes: Array<Routes>;
  subCollection: Array<Omit<Routes, "path"> & {routes: Array<Routes>}>;
};

const SidebarMenuData = () => {
  const {
    userInfo: {menu},
  } = useSelector((state: any) => state.stateUserInfo);

  const menuNavigationData = menu?.filter((item) => item.name);
  const {pathname} = useLocation();

  const localMenu: MenuType[] = menu;
  const flatMenu = localMenu?.flatMap((item) => item?.subCollection);
  const menusWithPaths = flatMenu?.map((item) => ({
    name: item?.name,
    paths: item?.routes.map((route) => route.path),
  }));

  const findSelectedMenu = menusWithPaths?.find((item) =>
    item.paths?.includes(pathname),
  );

  const initialExpandedMenu = findSelectedMenu?.name ?? "";

  return {menuNavigationData, initialExpandedMenu};
};

export default SidebarMenuData;
