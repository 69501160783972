import pxToRem from "hooks/usePxToRem";

import {Paper, styled} from "@mui/material";

interface StyledPaperProps {
  isSidebarOpen?: boolean;
}

const forwardProp = {
  shouldForwardProp: (prop) => prop !== "isSidebarOpen",
};

// prettier-ignore
const StyledPaper = styled(
  Paper,
  forwardProp,
)<StyledPaperProps>(
  ({theme, isSidebarOpen}) => `
  display: flex;
  flex-direction: column;

  border-right: 1px solid ${theme.palette.divider};
  border-radius: unset;
  height: 100vh;
  width: ${pxToRem(isSidebarOpen ? 248 : 86)};

  transition: width 300ms ease-in-out;

  .sidebar {
    &__header {
      position: relative;
      padding: ${pxToRem(16)} ${pxToRem(18)};
    }

    &__logo {
      &--box {
        width: ${isSidebarOpen ? "100%" : pxToRem(27)};
        overflow: hidden;
        display: flex;
        justify-content: ${isSidebarOpen ? "center" : "flex-start"};
        transition: width 300ms 120ms ease-in-out;
      }

      width: ${pxToRem(127.6)};
      height: ${pxToRem(45)};
    }

    &__button {      
      position: ${isSidebarOpen ? "unset" : "absolute"};
      right: ${isSidebarOpen ? "0" : pxToRem(-15)};

      height: ${pxToRem(28)};
      width: ${pxToRem(28)};
      background-color: ${theme.palette.background.paper};
      color: ${theme.palette.text.secondary};
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      border: 1px solid ${
        theme.palette.mode === "dark" ? theme.palette.divider : "transparent"
      };

      transition: color 280ms ease-in-out, border-color 280ms ease-in-out, box-shadow 280ms ease-out, rotate 300ms ease-in-out, right 280ms ease-in;

      rotate: ${isSidebarOpen && "-180deg"};

      &:hover {
        color: ${theme.palette.primary.main};
        border-color: ${theme.palette.primary.main};
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
      }

      &--icon {
        font-size: ${pxToRem(20)};
      }
    }

    &__user-card {
      margin: ${pxToRem(22)} 0 ${pxToRem(28)};
      padding: 0 ${pxToRem(18)};
    }

    &__nav {
      overflow-y: auto;
      scrollbar-width: none; //remover a barra de scroll para firefox
      overflow: -moz-scrollbars-none; //remover a barra de scroll para firefox

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__footer {
      margin-top: 20px;
      
      &--list {
        display: grid;
        gap: ${pxToRem(10)};
  
        padding: ${pxToRem(16)} ${pxToRem(18)};
        width: ${isSidebarOpen ? "100%" : "70%"};
        margin: 0 ${isSidebarOpen ? "initial" : "auto"};
  
        transition: width 300ms 40ms ease-in-out;
      }
    }
  }
`,
);

export default StyledPaper;
