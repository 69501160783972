/* eslint-disable @typescript-eslint/no-explicit-any */
import {RetrieveUserInformation} from "services/User/user.service";
import {newDataAPI} from "../apiConsumer";

//Este endpoint busca todos os chats que o usuário faz parte e devolve eles em uma lista.
// Caso não tenha nenhum chat retorna um array vazio []

// vendo qual  a função do usuário logado
const ROLE: Array<any> | undefined = RetrieveUserInformation()?.roles;

export const getListChat = async (id: string) => {
  const response = await newDataAPI.get(`chats/${id}/list`);
  return response;
};

//Este endpoint busca o chat por processo

export const getProcessChat = async (id: string) => {
  const response = await newDataAPI.get(`chats/${id}`);
  return response;
};

// Pesquisa o processo pelo processNumber e  caso tenha chat criado retorna para o usuário do contrário ele cria um novo chat.
// necessário passar o processNumber e o tipo do chat.

export const searchChat = async (item: string) => {
  return await newDataAPI.post(`chats/`, {
    processNumber: item,
    type: ROLE ? ROLE[0]?.toLowerCase() : "",
  });
};

export const newMessage = async (idChat: string, data: any) => {
  return await newDataAPI.post(`chats/${idChat}/message`, data);
};

export const getHistoryOldChat = async (idGroupChat: string) => {
  return await newDataAPI.get(`chat/gethistory/${idGroupChat}`);
};

export const patchFavorite = async (id: string, data: any) => {
  return await newDataAPI.patch(`chats/${id}/favorite`, data);
};

// endpoint para adicionar um membro ao chat quando acontece uma troca de responsáveis .

export const postAddNewMemberToChat = async (
  oldMember: string,
  newMember: string,
) => {
  return await newDataAPI.post(`chats/update_responsibles`, {
    from: oldMember,
    to: newMember,
  });
};
