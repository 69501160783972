import {ReactNode} from "react";
import pxToRem from "hooks/usePxToRem";

import {Chip, Grid, Typography} from "@mui/material";

type Props = {
  title: string;
  subtitle: string;
  badgeText?: string;
  badgeColor?:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error";
  actions?: ReactNode;
};

const CustomPageHeader = (props: Props) => {
  const {title, subtitle, badgeText, badgeColor = "primary", actions} = props;

  return (
    <Grid container pt={pxToRem(22)} mb={pxToRem(24)} alignItems="center">
      <Grid item xs={8}>
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          gap={pxToRem(8)}
          mb={pxToRem(2)}
        >
          <Typography variant="h4">{title}</Typography>

          {badgeText && <Chip label={badgeText} color={badgeColor} />}
        </Grid>

        <Typography variant="body2" color="text.secondary">
          {subtitle}
        </Typography>
      </Grid>

      <Grid
        item
        xs={4}
        display="flex"
        justifyContent="flex-end"
        gap={pxToRem(10)}
      >
        {actions}
      </Grid>
    </Grid>
  );
};

export default CustomPageHeader;
