import {GetMessageGroups} from "services/api_v2/Tasks/tasks.service";
import useAlertMessage from "hooks/useAlertMessage";
import {SyntheticEvent, useState} from "react";

const useMessageGroups = () => {
  const [showAlert] = useAlertMessage();

  const [messageGroups, setMessageGroups] = useState<
    {id: string; label: string}[]
  >([]);
  const [messageGroupsPagination, setMessageGroupsPagination] = useState({
    currentPage: 0,
    lastPage: 1,
  });

  const [messageGroupsInput, setMessageGroupsInput] = useState("");

  const formatData = (data) => {
    const formattedData = data?.map((item) => ({
      label: item?.name ?? item?.groupName,
      id: item?.id,
    }));

    return formattedData;
  };

  const onTypeMessageGroups = (
    e: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setMessageGroupsInput(newValue);
    getAllMessageGroups(1, 20, newValue);
  };

  const getAllMessageGroups = async (
    page: number,
    limit = 20,
    value?: string,
  ) => {
    try {
      const response = await GetMessageGroups({
        page: page,
        limit: limit,
        name: value,
      });

      if (value) {
        setMessageGroups(formatData(response.data.data));
        setMessageGroupsPagination({
          currentPage: 0,
          lastPage: 1,
        });
      } else if (page === 1) {
        setMessageGroups(formatData(response.data.data));
        setMessageGroupsPagination({
          currentPage: response.data.meta.currentPage,
          lastPage: response.data.meta.lastPage,
        });
      } else {
        setMessageGroups((state) => [
          ...state,
          ...formatData(response.data.data),
        ]);
        setMessageGroupsPagination({
          currentPage: response.data.meta.currentPage,
          lastPage: response.data.meta.lastPage,
        });
      }
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    }
  };

  const onMessageGroupBottomScroll = () => {
    getAllMessageGroups(messageGroupsPagination.currentPage + 1);
    return false;
  };

  return {
    getAllMessageGroups,
    messageGroups,
    onMessageGroupBottomScroll,
    messageGroupsPagination,
    messageGroupsInput,
    onTypeMessageGroups,
  };
};

export default useMessageGroups;
