import {SelectChangeEvent} from "@mui/material";
import {DatePickerValues} from "components/CustomDatePicker/CustomDatePicker";
import {add, format, sub} from "date-fns";
import useAlertMessage from "hooks/useAlertMessage";
import {SyntheticEvent, useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {GetFilteredLegalPersons} from "services/api_v2/LegaPersons/LegalPersons.service";
import {Types as dashboardTypes} from "store/reducers/dashboardFiltersReducer";

interface HeaderFiltersProps {
  getActivities: (
    startDate?: string,
    endDate?: string,
    status?: string,
    idClient?: number,
    refNext?: string,
  ) => Promise<void>;
}

const useHeaderFilters = (props: HeaderFiltersProps) => {
  const [showAlert] = useAlertMessage();

  const dispatch = useDispatch();
  const {
    definedRanges,
    dateFilter,
    currentActivityType = "",
    companyName,
    refNext,
  } = useSelector((state: any) => state.stateDashboardFilter);

  const {getActivities: getUserActivities} = props;

  const [currentFilterSelected, setCurrentFilterSelected] =
    useState(dateFilter);
  const [companies, setCompanies] = useState<any[]>([]);

  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);

  const [companiesPage, setCompaniesPage] = useState({
    currentPage: 0,
    lastPage: 1,
  });

  const selectFilterAvailableValues = [
    "Hoje",
    "Ontem",
    "Últimos 7 dias",
    "Últimos 15 dias",
    "Últimos 30 dias",
    "Amanhã",
    "Próximos 7 dias",
    "Próximos 15 dias",
    "Próximos 30 dias",
  ];

  const handleChangeCompanyName = (
    _: SyntheticEvent<Element, Event>,
    newValue: any,
  ) => {
    dispatch({
      type: dashboardTypes.SET_COMPANY_NAME,
      companyName: newValue,
    });
  };

  const handleChangeRefNext = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: dashboardTypes.SET_REF_NEXT,
      refNext: event.target.value,
    });
  };

  const handleChangeCurrentFilter = (event: SelectChangeEvent) => {
    const selected = event.target.value;
    setCurrentFilterSelected(selected);

    dispatch({
      type: dashboardTypes.SET_DATE_FILTER,
      dateFilter: selected,
    });
    switch (selected) {
      case "Hoje":
        dispatch({
          type: dashboardTypes.SET_DEFINED_RANGES,
          definedRanges: [new Date(), null],
        });
        break;
      case "Ontem":
        dispatch({
          type: dashboardTypes.SET_DEFINED_RANGES,
          definedRanges: [sub(new Date(), {days: 1}), null],
        });
        break;
      case "Últimos 7 dias":
        dispatch({
          type: dashboardTypes.SET_DEFINED_RANGES,
          definedRanges: [sub(new Date(), {days: 7}), new Date()],
        });
        break;
      case "Últimos 15 dias":
        dispatch({
          type: dashboardTypes.SET_DEFINED_RANGES,
          definedRanges: [sub(new Date(), {days: 15}), new Date()],
        });
        break;
      case "Últimos 30 dias":
        dispatch({
          type: dashboardTypes.SET_DEFINED_RANGES,
          definedRanges: [sub(new Date(), {days: 30}), new Date()],
        });
        break;
      case "Amanhã":
        dispatch({
          type: dashboardTypes.SET_DEFINED_RANGES,
          definedRanges: [add(new Date(), {days: 1}), null],
        });
        break;
      case "Próximos 7 dias":
        dispatch({
          type: dashboardTypes.SET_DEFINED_RANGES,
          definedRanges: [new Date(), add(new Date(), {days: 7})],
        });
        break;
      case "Próximos 15 dias":
        dispatch({
          type: dashboardTypes.SET_DEFINED_RANGES,
          definedRanges: [new Date(), add(new Date(), {days: 15})],
        });
        break;
      case "Próximos 30 dias":
        dispatch({
          type: dashboardTypes.SET_DEFINED_RANGES,
          definedRanges: [new Date(), add(new Date(), {days: 30})],
        });
        break;
    }
  };

  const getDatePickerValues = useCallback((values: DatePickerValues) => {
    const {startDate, endDate} = values;

    dispatch({
      type: dashboardTypes.SET_DEFINED_RANGES,
      definedRanges: [startDate, endDate],
    });
    setCurrentFilterSelected("");
  }, []);

  const handleFilterActivities = () => {
    const x = [definedRanges];

    const flat = x.flat();
    if (flat.length === 2) {
      const [startDate, endDate] = flat;
      getUserActivities(
        format(startDate as Date, "yyyy-MM-dd"),
        endDate === null ? undefined : format(endDate as Date, "yyyy-MM-dd"),
        currentActivityType,
        companyName?.id,
        refNext,
      );
    } else if (flat.length === 1) {
      const startDate = flat[0];

      getUserActivities(
        format(startDate as Date, "yyyy-MM-dd"),
        "",
        currentActivityType,
        companyName?.id,
        refNext,
      );
    }
  };

  const getCompanies = async (
    currentPage = companiesPage.currentPage + 1,
    personName = "",
  ) => {
    try {
      setIsLoadingCompanies(true);
      const response = await GetFilteredLegalPersons({
        isClient: true,
        page: currentPage,
        clientName: personName,
      });

      const formattedResponse = response.data.data.map((item) => ({
        id: item.id,
        label: item.name,
      }));

      if (currentPage === 1) {
        setCompanies(formattedResponse);
      } else {
        setCompanies((state) => [...state, ...formattedResponse]);
      }

      setCompaniesPage({
        currentPage: response.data.meta.currentPage,
        lastPage: response.data.meta.lastPage,
      });
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    } finally {
      setIsLoadingCompanies(false);
    }
  };

  const onBottomScroll = () => {
    getCompanies();
    return false;
  };

  return {
    selectFilterAvailableValues,
    currentFilterSelected,
    handleChangeCurrentFilter,
    getDatePickerValues,
    handleFilterActivities,
    getCompanies,
    companies,
    onBottomScroll,
    companiesPage,
    handleChangeCompanyName,
    isLoadingCompanies,
    handleChangeRefNext,
  };
};

export default useHeaderFilters;
